import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// MUI
import { Typography, Box, Link, Breadcrumbs } from "@mui/material";
import { FiArrowLeft } from "react-icons/fi";

export default function Warranty() {
	const styles = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<div>
			<div
				style={{ paddingBottom: 30, justifyContent: "space-between" }}
				className={styles.flexMiddle}
			>
				<div
					className={styles.flexMiddle}
					style={{ paddingBottom: 30 }}
				>
					<FiArrowLeft
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							marginRight: 10,
						}}
					/>
					<span
						onClick={() => navigate(-1)}
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							textTransform: "uppercase",
							cursor: "pointer",
						}}
					>
						{t("button.back")}
					</span>
				</div>
				<div>
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/dashboard"
						>
							{t("title.dashboard")}
						</Link>
						<Typography color="text.primary">
							{t("title.warranty")}
						</Typography>
					</Breadcrumbs>
				</div>
			</div>

			<div
				className={styles.flexCenterMiddle}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "calc(100vh - 250px)",
					maxHeight: "100%",
				}}
			>
				<div
					className={`head ${styles.flexMiddle} ${styles.flexDirection}`}
				>
					<Link
						underline="none"
						component={RouterLink}
						to={`/warranty-check`}
					>
						<Box
							className={styles.menuStyle}
							style={{ margin: 15 }}
						>
							<img
								src="images/icons/warrantyCheck.png"
								className={styles.menuImg}
								alt="warranty check"
							/>
							<Typography style={{ paddingTop: 15 }}>
								{t("warranty.checkWarranty")}
							</Typography>
						</Box>
					</Link>
					<Link
						underline="none"
						to={`/warranty-reports`}
						component={RouterLink}
					>
						<Box
							className={styles.menuStyle}
							style={{ margin: 15 }}
						>
							<img
								src="images/icons/warrantyReport.png"
								className={styles.menuImg}
								alt="report"
							/>
							<Typography style={{ paddingTop: 15 }}>
								{t("warranty.warrantyReport")}
							</Typography>
						</Box>
					</Link>
				</div>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	menuStyle: {
		background: "#fff",
		borderRadius: 15,
		boxShadow: "2px 2px 10px 5px rgb(209 209 209 / 30%)",
		padding: 10,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		color: theme.palette.gray.dtext,
		[theme.breakpoints.down("sm")]: {
			width: 200,
			height: 200,
		},
		[theme.breakpoints.up("sm")]: {
			width: 250,
			height: 250,
		},
	},
	menuImg: {
		[theme.breakpoints.down("sm")]: {
			width: 120,
			height: 120,
		},
		[theme.breakpoints.up("sm")]: {
			width: 150,
			height: 150,
		},
	},
	flexCenterMiddle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	flexMiddle: {
		display: "flex",
		alignItems: "center",
	},
	flexDirection: {
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
		[theme.breakpoints.up("sm")]: {
			flexDirection: "row",
		},
	},
}));
