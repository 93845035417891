import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@mui/styles";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import useNotificationLoading from "../../helpers/useNotificationLoading";
import { getUrl } from "../../helpers/ApiAction";
import { useSelector } from "react-redux";
import { GENTING_URL } from '../../configs/Config';
import _ from "lodash";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useQuery } from '../../helpers/Tools';

// MUI
import {
    Grid,
    Typography,
    Link,
    LinearProgress,
    Breadcrumbs,
    Hidden,
    Button, 
    TextField,
    OutlinedInput,
    FormControl,
    Box
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    gridClasses,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridActionsCellItem,
} from "@mui/x-data-grid";

//TABS
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
// import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabUnstyled from "@mui/base/TabUnstyled";

import { useDemoData } from "@mui/x-data-grid-generator";

import { FiArrowLeft } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";

function CustomToolbar() {
    return (
        <GridToolbarContainer
            className={gridClasses.toolbarContainer}
            style={{ paddingBottom: 10 }}
        >
            <GridToolbarExport
                style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
                csvOptions={{
                    allColumns: true,
                    fileName: `Products_${new Date()
                        .toISOString()
                        .slice(0, 10)}`,
                }}
                printOptions={{ disableToolbarButton: true }}
            />
            <GridToolbarDensitySelector
                style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
            />
            <GridToolbarFilterButton
                style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
            />
            <GridToolbarColumnsButton
                style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
            />
        </GridToolbarContainer>
    );
}

export default function ProductListing() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    let query = useQuery();
    const location = useLocation();
    // const productType = location.pathname === "/products" ? "new" : "trade-in";
    const { permissions } = useSelector((state) => state.general);

    const [pageSize, setPageSize] = useState(20);
    const [tableLoading, setTableLoading] = useState(false);
    const [state, setState] = useState([]);
    const [tabValue, setTabValue] = useState(query.get("tabvalue") ? parseInt(query.get("tabvalue")) : 2);
    const { addAlert, setLoading } = useNotificationLoading();
    const { data } = useDemoData({
        dataSet: "product",
        rowLength: 5,
        maxColumns: 10,
    });
    let navigate = useNavigate();

    const BRANCH = window.location.href.indexOf(GENTING_URL) > -1 ? 'genting' : 'others';

    const editDetails = React.useCallback(
        (id, productType) => () => {
            console.log(productType);
            let navi = false;
            if (productType === "new") {
                if (permissions.includes("product-edit")) {
                    navi = true;
                }
            } else if (productType === "trade-in") {
                if (permissions.includes("tradein-edit")) {
                    navi = true;
                }
            } else if (productType === "consignment") {
                if (permissions.includes("consignment-edit")) {
                    navi = true;
                }
            }
            if (navi) {
                navigate(
                    `/${productType === "trade-in" ? "tradein-edit" : productType === "consignment" ? "consignment-edit" : "product-edit"
                    }/${id}`
                );
            } else {
                addAlert("", "No Permission", "error", "");
            }
        },
        // eslint-disable-next-line
        []
    );
    const [searchText, setSearchText] = useState(query.get('search') || "");
    const [dateFilter, setDateFilter] = useState({
        from_date: query.get('from_date') || null, // moment().format('L hh:mm A'),
        to_date: query.get('to_date') || null, // moment().format('L hh:mm A'),
    });

    //table
    const columns = useMemo(
        () => [
            {
                field: "name",
                headerName: `${t("product.name")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
            },
            {
                field: "type",
                headerName: `${t("product.type")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
            },
            {
                field: "brand",
                headerName: `${t("product.brand")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
                valueGetter: ({ value }) => value?.name,
            },
            {
                field: "serial_no",
                headerName: `${t("product.serialNo")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
            },
            {
                field: "created_at",
                headerName: `${t("customer.createdAt")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
            },
            {
                field: "actions",
                type: "actions",
                headerName: `${t("table.actions")}`,
                width: 70,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<MdModeEdit size={25} />}
                        label={t("button.edit")}
                        onClick={editDetails(params.id, params.row?.type)}
                    />,
                ],
            },
        ],
        [editDetails, t]
    );

    /* useEffect(() => {
        setTableLoading(true);
        getUrl("/products", { status: tabValue })
            .then((response) => {
                setTableLoading(false);
                if (response.status) {
                    setState(response.data.listing);
                } else {
                    addAlert("", t("error.contactSupport"), "error", "");
                }
            })
            .catch((error) => {
                setTableLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        // eslint-disable-next-line
    }, [tabValue]); */

    const callApi = ()=>{
        const from_date = dateFilter.from_date ? moment(dateFilter.from_date).format('YYYY-MM-DD HH:mm:ss'): '';
        const to_date = dateFilter.to_date ? moment(dateFilter.to_date).format('YYYY-MM-DD HH:mm:ss'): '';
        setLoading(true);
        let apiUrl=`/products`;
        getUrl(apiUrl, { status: tabValue, search: searchText, sdate: from_date, edate: to_date })
            .then((response) => {
                setLoading(false);
                if (response.status) {
                    setState(response.data.listing);
                } else {
                    addAlert("", t("error.contactSupport"), "error", "");
                }
            })
            .catch((error) => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        // eslint-disable-next-line
    }

    useEffect(() => {
        setLoading(true);
        callApi();
        // eslint-disable-next-line
    }, [tabValue]);

    const handleDateFilterChange = (newValue, type) => {
        setDateFilter({ ...dateFilter, [type]: newValue });
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const clearFilter = useCallback(() => {
        // setDateFilter({ from_date: null, to_date: null });
        // setSearchText('');
        // callApi();
        window.location.href = location.pathname;
    }, []);
    // const searchOrder = (e) => {
    //     // setTableLoading(true);
    //     if (e.key === 'Enter') {
    //         callApi();
    //     }
    // }


    return (
        <div>
            <div className={styles.flexMiddle} style={{ justifyContent: 'space-between', paddingBottom: 30, }}>
                <Link underline="none" component={RouterLink} to={-1}>
                    <div
                        className={styles.flexMiddle}
                        style={{ paddingBottom: 30 }}
                    >
                        <FiArrowLeft
                            style={{
                                color: theme.palette.gray.main,
                                fontSize: 16,
                                marginRight: 10,
                            }}
                        />
                        <span style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase', cursor:'pointer' }}>{t('button.back')}</span>
                    </div>
                </Link>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            color="inherit"
                            href="/dashboard"
                        >
                            {t("title.dashboard")}
                        </Link>
                        <Typography color="text.primary">
                            {t("title.products")}
                        </Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <Box component="form" method="get" action={location.pathname} display="flex" flexDirection="row" padding="10px">
                <input name="tabvalue" value={tabValue} type="hidden" />
                <div style={{ width: '100%', maxWidth: '100%', marginRight:10 }}>
                    <ThemeProvider theme={inputStyle}>
                        <FormControl style={{ width: '100%', maxWidth: '100%' }}>
                            <OutlinedInput placeholder={t('product.searchProduct')} name="search" value={searchText} onChange={({ target }) => setSearchText(target.value)} /* onKeyDown={searchOrder} */ />
                        </FormControl>
                    </ThemeProvider>
                </div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                        label={t('report.fromDate')}
                        mask='__/__/____ __:__ _M'
                        value={dateFilter.from_date}
                        onChange={(value) => handleDateFilterChange(value, 'from_date')}
                        renderInput={(params) => <TextField name="from_date" style={{ marginRight: 10 }} {...params} />}
                    />
                    <DateTimePicker
                        label={t('report.toDate')}
                        mask='__/__/____ __:__ _M'
                        value={dateFilter.to_date}
                        onChange={(value) => handleDateFilterChange(value, 'to_date')}
                        renderInput={(params) => <TextField name="to_date" style={{ marginRight: 10 }} {...params} />}
                    />
                </LocalizationProvider>
                <Button type="submit "variant="outlined" /* onClick={callApi} */ style={{ marginRight: 10 }}>
                    <Typography>{t('general.filter')}</Typography>
                </Button>
                <Button variant="outlined" onClick={clearFilter}>
                    <Typography>{t('general.clear')}</Typography>
                </Button>
            </Box>
            <div style={{ paddingBottom: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ paddingBottom: 5 }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            paddingBottom: 15,
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: 21,
                                marginRight: 15,
                                color: theme.palette.gray.main,
                            }}
                        >
                            <b>{t("product.productList")}</b>
                        </Typography>
                        <Link
                            underline="none"
                            to={BRANCH === 'others' ? `/product` : `/product-add`}
                            component={RouterLink}
                        >
                            <div
                                style={{
                                    height: 30,
                                    width: 30,
                                    textAlign: "center",
                                    borderRadius: 5,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "2px solid #CDAC89",
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: 21,
                                        color: theme.palette.secondary.main,
                                    }}
                                >
                                    +
                                </Typography>
                            </div>
                        </Link>
                        <Link
                            underline="none"
                            to={`/product-stock-report`}
                            component={RouterLink}
                        >
                            <div
                                style={{
                                    height: 30,
                                    // width: 30,
                                    padding: 5,
                                    textAlign: "center",
                                    borderRadius: 5,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "2px solid #CDAC89",
                                }}
                            >
                                <Typography
                                    style={{
                                        // fontSize: 21,
                                        color: theme.palette.secondary.main,
                                    }}
                                >
                                    Stock
                                </Typography>
                            </div>
                        </Link>
                    </div>
                  
                </Grid>
            </div>
            <TabsUnstyled defaultValue={3} onChange={handleTabChange}>
                <div
                    className={`head ${styles.flexMiddle} ${styles.justifyRight}`}
                    style={{ width: "100%" }}
                >
                    <TabsListUnstyled>
                        <TabUnstyled value={2} className={styles.tabStyle} style={{backgroundColor: tabValue === 2 ? theme.palette.secondary.secondary : theme.palette.gray.dtext}}>
                            <Typography
                                style={{
                                    textAlign: "center",
                                    color: tabValue === 2 ? 'white' : '#ffffff73',
                                    cursor: 'pointer'
                                }}
                            >
                                {t('product.pending')}
                            </Typography>
                        </TabUnstyled>
                        <TabUnstyled value={1} className={styles.tabStyle} style={{backgroundColor: tabValue === 1? theme.palette.secondary.secondary : theme.palette.gray.dtext}}>
                            <Typography
                                style={{
                                    textAlign: "center",
                                    color: tabValue === 1 ? 'white' : '#ffffff73',
                                    cursor: 'pointer'
                                }}
                            >
                                {t('product.active')}
                            </Typography>
                        </TabUnstyled>
                        <TabUnstyled value={0} className={styles.tabStyle} style={{backgroundColor: tabValue === 0 ? theme.palette.secondary.secondary : theme.palette.gray.dtext}}>
                            <Typography
                                style={{
                                    textAlign: "center",
                                    color: tabValue === 0 ? 'white' : '#ffffff73',
                                    cursor: 'pointer'
                                }}
                            >
                                {t('product.inactive')}
                            </Typography>
                        </TabUnstyled>
                        <TabUnstyled value={3} className={styles.tabStyle} style={{ backgroundColor: tabValue === 3 ? theme.palette.secondary.secondary : theme.palette.gray.dtext }}>
                            <Typography
                                style={{
                                    textAlign: "center",
                                    color: tabValue === 3 ? 'white' : '#ffffff73',
                                    cursor: 'pointer'
                                }}
                            >
                                {t('product.returned')}
                            </Typography>
                        </TabUnstyled>
                    </TabsListUnstyled>
                </div>
                <div
                    style={{
                        height: 450,
                        width: "100%",
                        overflowX: "scroll",
                        boxShadow: "0 0 20px 0 #dadada",
                        borderRadius: "15px 0 15px 15px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            height: "100%",
                            padding: 0,
                        }}
                    >
                        <div style={{ flexGrow: 1 }}>
                            {
                                _.size(state) > 0 ? (
                                    <DataGrid
                                        {...data}
                                        rows={state}
                                        columns={columns}
                                        // columnVisibilityModel={{
                                        // 	actions:
                                        // 		productType === "new"
                                        // 			? permissions.includes(
                                        // 					"product-edit"
                                        // 			  )
                                        // 			: permissions.includes(
                                        // 					"tradein-edit"
                                        // 			  ),
                                        // }}
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize) =>
                                            setPageSize(newPageSize)
                                        }
                                        rowsPerPageOptions={[5, 10, 20]}
                                        components={{
                                            Toolbar: CustomToolbar,
                                            LoadingOverlay: LinearProgress,
                                        }}
                                        loading={
                                            tableLoading ? true : false
                                        }
                                        sx={{
                                            marginTop: 0,
                                            borderRadius:
                                                "15px 0 15px 15px",
                                            paddingTop: "15px",
                                            border: "none",
                                            "& .super-app-theme--header": {
                                                backgroundColor:
                                                    theme.palette.gray
                                                        .tableHeader,
                                                color: theme.palette.gray
                                                    .tableHeaderText,
                                            },
                                            backgroundColor: "white",
                                            "& 	.MuiDataGrid-menuIconButton":
                                            {
                                                color: theme.palette
                                                    .gray
                                                    .tableHeaderText,
                                            },
                                            "& 	.MuiDataGrid-sortIcon": {
                                                color: theme.palette.gray
                                                    .tableHeaderText,
                                            },
                                        }}
                                    />
                                ) : (
                                    <div
                                        className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
                                        style={{
                                            flexDirection: "column",
                                            backgroundColor: "#fff",
                                            height: "100%",
                                        }}
                                    >
                                        <img
                                            src="images/emptyStatus/emptycart.png"
                                            alt="empty"
                                            style={{ width: 180 }}
                                        />
                                        <Typography
                                            style={{
                                                margin: 10,
                                                textAlign: "center",
                                            }}
                                        >
                                            {t("emptyStatus.noProduct")}
                                        </Typography>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </TabsUnstyled>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    flexMiddle: {
        display: "flex",
        alignItems: "center",
    },
    justifyRight: {
        justifyContent: "flex-end",
    },
    justifyCenter: {
        justifyContent: "center",
    },
    tabStyle: {
        boxShadow:
            "rgb(209 209 209 / 35%) 2px 2px 10px 5px, inset 1px -7px 5px 0px #0000000a",
        borderRadius: "11px 11px 0 0",
        padding: "8px 15px",
        marginRight: 15,
        minWidth: 110,
        justifyContent: "center",
        textAlign: "center",
        border: "none",
        backgroundColor: "#fff",
    }
}));

const inputStyle = createTheme({
    components: {
      // Name of the component
      MuiOutlinedInput: {
        styleOverrides: {
          // Name of the slot
          input: {
            // Some CSS
            padding:'28.5px 14px',
            boxSizing:'inherit',
            borderRadius:48
          },
          root:{
              borderRadius:48,
              boxShadow:'3px 3px 8px 0 #ccc'
          },
        },
      },
    },
  })