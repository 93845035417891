import { makeStyles, useTheme } from "@mui/styles";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";

// MUI
import {
	Breadcrumbs, Button, FormControl, Hidden, InputLabel, Link, MenuItem, OutlinedInput, Paper, Select, Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow, TextField,
	Typography
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FiArrowLeft } from "react-icons/fi";

import { getUrl, postUrl } from "../../helpers/ApiAction";
import { currencyFormat } from "../../helpers/Tools";
import useNotificationLoading from "../../helpers/useNotificationLoading";

export default function DailySettlementAdd() {
	const [dateList, setDateList] = useState();
	const [data, setData] = useState();
	const [state, setState] = useState({ drawer_date: "", password: "" });
	const [inputErrors, setInputErrors] = useState({ drawer_date: null });

	const styles = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const { name } = useSelector((state) => state.user);
	const { addAlert, setLoading } = useNotificationLoading();
	const navigate = useNavigate();

	const isMountedRef = useRef();

	useEffect(() => {
		setLoading(true);
		isMountedRef.current = true;

		getUrl(`daily-settlements-date`)
			.then((response) => {
				console.log("response", response);
				const { status, data } = response;
				if (status && isMountedRef.current) {
					setDateList(data.listing);
					if (_.size(data.listing)) {
						setState({ drawer_date: data.listing[0] });
					}
				}
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});

		return () => (isMountedRef.current = false);
	}, []);

	const getDateDrawer = useCallback(() => {
		if (state.drawer_date) {
			getUrl(`daily-settlements-prepare/${state.drawer_date}`)
				.then((response) => {
					if (response.status) {
						setData(response.data);
					}
				})
				.catch((error) => {
					setLoading(false);
					addAlert(
						"",
						error.message || t("error.contactSupport"),
						"error",
						""
					);
				});
		}
	}, [state.drawer_date]);

	const handleChange = ({ target }) => {
		const { name, value } = target;
		console.log("target", target);
		setState({ ...state, [name]: value });
	};

	const closeDateSettlement = () => {
		if (state.drawer_date) {
			postUrl(`daily-settlements`, state)
				.then((response) => {
					const { status, data, errors, message } = response;
					if (status) {
						const toDeleteDate = state.drawer_date;
						addAlert("", t("success.createSuccess"), "success", "");
						setState({ drawer_date: "" });
						setInputErrors({ drawer_date: null });
						const newDateList = _.remove(
							dateList,
							(c) => c !== toDeleteDate
						);
						setDateList(newDateList);
						setData(null);
						navigate(`/daily-settlement/${toDeleteDate}`);
					} else {
						if (errors) {
							setInputErrors(errors);
						}
						addAlert(
							"",
							message ? message : t("error.contactSupport"),
							"error",
							""
						);
					}
				})
				.catch((error) => {
					setLoading(false);
					addAlert(
						"",
						error.message || t("error.contactSupport"),
						"error",
						""
					);
				});
		}
	};

	return (
		<div>
			<div
				style={{ paddingBottom: 30, justifyContent: "space-between" }}
				className={styles.flexMiddle}
			>
				<div
					className={styles.flexMiddle}
					style={{ paddingBottom: 30, width: "100%" }}
				>
					<FiArrowLeft
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							marginRight: 10,
						}}
					/>
					<span
						onClick={() => navigate(-1)}
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							textTransform: "uppercase",
							cursor: "pointer",
						}}
					>
						{t("button.back")}
					</span>
				</div>
				<div>
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/dashboard"
						>
							{t("title.dashboard")}
						</Link>

						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/accounting"
						>
							{t("title.accounting")}
						</Link>
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/daily-settlements"
						>
							{t("title.dailyClosing")}
						</Link>
						<Typography color="text.primary">
							{t("accounting.createDailyClosing")}
						</Typography>
					</Breadcrumbs>
				</div>
			</div>

			<div style={{ padding: "20px 0" }}>
				<Typography
					style={{
						color: theme.palette.gray.dtext,
						fontSize: 18,
						paddingRight: 15,
					}}
				>
					<b>{t("accounting.createDailyClosing")}</b>
				</Typography>
				<FormControl sx={{ m: 1, width: "100%", marginTop: "15px" }}>
					<InputLabel id="drawer_date-label">
						{t("accounting.selectDate")}
					</InputLabel>
					<Select
						labelId="drawer_date-label"
						id="drawer_date"
						name="drawer_date"
						value={state.drawer_date}
						onChange={handleChange}
						input={
							<OutlinedInput label={t("accounting.selectDate")} />
						}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
							},
						}}
					>
						{_.map(dateList, (dateItem) => (
							<MenuItem key={dateItem} value={dateItem}>
								{dateItem}
							</MenuItem>
						))}
					</Select>
					{inputErrors && inputErrors.drawer_date ? (
						<Typography variant="caption" style={{ color: "red" }}>
							{inputErrors.drawer_date[0]}
						</Typography>
					) : null}
				</FormControl>

				{data ? (
					<div style={{ paddingTop: 30 }}>
						<ThemeProvider theme={tableStyle}>
							<TableContainer
								component={Paper}
								style={{
									marginTop: 15,
									boxShadow: "0 0 20px 0 #e0e0e0",
									borderRadius: 15,
								}}
							>
								<div style={{ padding: "25px 15px" }}>
									<div
										className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}
										style={{ width: "100%" }}
									>
										<div>
											<Typography
												style={{
													color: theme.palette.gray
														.dtext,
													fontSize: 28,
													textTransform: "uppercase",
												}}
											>
												<b>
													{t(
														"accounting.dailySalesReport"
													)}
												</b>
											</Typography>
											<Typography
												style={{
													color: theme.palette.gray
														.dtext,
												}}
											>
												SWISS HOUR SDN.BHD (153246-A)
											</Typography>
										</div>

										<Hidden smDown>
											<div
												style={{
													color: theme.palette.gray
														.ltext,
												}}
												className={styles.flexMiddle}
											>
												<div style={{ width: 77 }}>
													<Typography>{`${t(
														"accounting.date"
													)}:`}</Typography>
													<Typography>{`${t(
														"accounting.user"
													)}:`}</Typography>
												</div>
												<div
													style={{
														color: theme.palette
															.gray.dtext,
													}}
												>
													<Typography>
														{state.drawer_date}
													</Typography>
													<Typography>
														{name}
													</Typography>
												</div>
											</div>
										</Hidden>
									</div>
									<Hidden smUp>
										<div
											style={{
												color: theme.palette.gray.ltext,
												paddingTop: 28,
											}}
											className={styles.flexMiddle}
										>
											<div style={{ width: 77 }}>
												<Typography>{`${t(
													"accounting.date"
												)}:`}</Typography>
												<Typography>{`${t(
													"accounting.user"
												)}:`}</Typography>
											</div>
											<div
												style={{
													color: theme.palette.gray
														.dtext,
												}}
											>
												<Typography>
													{state.drawer_date}
												</Typography>
												<Typography>{name}</Typography>
											</div>
										</div>
									</Hidden>
								</div>
								<Table
									stickyHeader
									sx={{ minWidth: 650 }}
									size="small"
									aria-label="sticky table pagination table"
								>
									<TableHead>
										<TableRow>
											<TableCell
												style={{
													color: theme.palette.gray
														.ltext,
												}}
											>{`${t(
												"accounting.date"
											)}: `}</TableCell>
											<TableCell
												style={{
													color: theme.palette.gray
														.ltext,
												}}
											>{`${t(
												"accounting.description"
											)}: `}</TableCell>
											<TableCell
												style={{
													color: theme.palette.gray
														.ltext,
												}}
												align="right"
											>{`${t(
												"accounting.walletType"
											)}: `}</TableCell>
											<TableCell
												style={{
													color: theme.palette.gray
														.ltext,
												}}
												align="right"
											>{`${t(
												"accounting.cashOut"
											)}: `}</TableCell>
											<TableCell
												style={{
													color: theme.palette.gray
														.ltext,
												}}
												align="right"
											>{`${t(
												"accounting.cashIn"
											)}: `}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{_.has(data, "drawer_lists") &&
											_.map(
												data.drawer_lists,
												(dataItem) => {
													return (
														<TableRow
															key={dataItem.id}
														>
															<TableCell
																style={{
																	width: 200,
																}}
																component="th"
																scope="row"
															>
																{
																	dataItem.created_at
																}
															</TableCell>
															<TableCell>{`${t(
																`accounting.refTypeList.${dataItem.ref_type}`
															)} ${
																(Array.isArray(dataItem.remark) && dataItem.remark.length===0) ? (dataItem.order_no!==null? "(OrderNo-"+dataItem.order_no+")":"") : ""
															}. ${t(
																"accounting.remark"
															)}: ${
																dataItem.remark ??
																"-"
															}`}</TableCell>
															<TableCell align="right">
																{
																	dataItem.wallet_display
																}
															</TableCell>
															<TableCell
																style={{
																	color: "red",
																}}
																align="right"
															>
																{dataItem.type ===
																"out"
																	? `-${currencyFormat(
																			parseFloat(
																				dataItem.amount
																			)
																	  )}`
																	: ""}
															</TableCell>
															<TableCell
																style={{
																	color: "green",
																}}
																align="right"
															>
																{dataItem.type ===
																"in"
																	? `+${currencyFormat(
																			parseFloat(
																				dataItem.amount
																			)
																	  )}`
																	: ""}
															</TableCell>
														</TableRow>
													);
												}
											)}
										<TableRow>
											<TableCell
												colSpan={3}
												align="right"
											>
												<b>{t("accounting.total")}</b>
											</TableCell>
											<TableCell
												style={{ color: "red" }}
												align="right"
											>
												{_.has(data, "total_cash_out")
													? currencyFormat(
															data.total_cash_out
													  )
													: "-"}
											</TableCell>
											<TableCell
												style={{ color: "green" }}
												align="right"
											>
												{_.has(data, "total_cash_in")
													? currencyFormat(
															data.total_cash_in
													  )
													: "-"}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
								<Typography
									style={{
										padding: "20px 12px",
										textAlign: "right",
										color: theme.palette.gray.dtext,
										fontSize: 18,
									}}
								>
									<span
										style={{
											color: theme.palette.gray.ltext,
											paddingRight: 18,
										}}
									>{`${t("accounting.totalAmount")}: `}</span>
									{_.has(data, "total_balance")
										? currencyFormat(data.total_balance)
										: "-"}
								</Typography>
								<div style={{ padding: 30 }}>
									<TextField
										fullWidth
										required
										type="password"
										variant="outlined"
										value={state.password}
										error={
											inputErrors.password ? true : false
										}
										InputLabelProps={{ shrink: true }}
										helperText={
											inputErrors && inputErrors.password
												? inputErrors.password
												: ""
										}
										style={{ paddingBottom: 20 }}
										onChange={handleChange}
										name="password"
										placeholder={t("accounting.password")}
									/>
									<Button
										onClick={closeDateSettlement}
										style={{
											backgroundColor:
												theme.palette.secondary.main,
											width: "100%",
											padding: "8px 15px",
											borderRadius: 5,
											boxShadow:
												"2px 2px 6px 0 #8f72547a",
										}}
									>
										<Typography style={{ color: "#fff" }}>
											{t("accounting.createCloseReport")}
										</Typography>
									</Button>
								</div>
							</TableContainer>
						</ThemeProvider>
					</div>
				) : null}
				<div
					className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
					style={{ paddingTop: 25 }}
				>
					<Link
						underline="none"
						component={RouterLink}
						to={`/accounting`}
					>
						<Button
							style={{
								padding: "8px 15px",
								width: 180,
								marginRight: 15,
								maxWidth: "100%",
								borderRadius: 48,
								border: "2px solid #cdac89",
								boxShadow: "2px 2px 20px 0 #efefef",
								textAlign: "center",
							}}
						>
							<Typography
								style={{
									color: "#fff",
									fontSize: 18,
									textAlign: "center",
									textTransform: "uppercase",
									color: theme.palette.secondary.main,
								}}
							>
								{t("button.cancel")}
							</Typography>
						</Button>
					</Link>
					<Button
						onClick={getDateDrawer}
						style={{
							padding: "8px 15px",
							width: 180,
							maxWidth: "100%",
							borderRadius: 48,
							background: theme.palette.secondary.main,
							boxShadow: "2px 2px 20px 0 #efefef",
							textAlign: "center",
						}}
					>
						<Typography
							style={{
								color: "#fff",
								fontSize: 18,
								textAlign: "center",
								textTransform: "uppercase",
							}}
						>
							{t("accounting.generate")}
						</Typography>
					</Button>
				</div>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	justifyCenter: {
		justifyContent: "center",
	},
	spaceBetween: {
		justifyContent: "space-between",
	},
	flexMiddle: {
		display: "flex",
		alignItems: "center",
	},
	flexTtop: {
		display: "flex",
		alignItems: "flex-start",
	},
}));

const tableStyle = createTheme({
	components: {
		// Name of the component
		MuiTableCell: {
			styleOverrides: {
				// Name of the slot
				root: {
					// Some CSS
					lineHeight: "2.5rem",
				},
			},
		},
	},
});
