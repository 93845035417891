import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { makeStyles, useTheme } from '@mui/styles';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl } from '../../helpers/ApiAction';
import { currencyFormat, useQuery } from "../../helpers/Tools";
import { GENTING_URL } from '../../configs/Config';
import _ from 'lodash';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import { ThemeProvider, createTheme } from '@mui/material/styles'

// MUI
import { Box, Typography, Link, LinearProgress, Button, TextField, Breadcrumbs, FormControl, OutlinedInput } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarColumnsButton, GridActionsCellItem } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { withStyles } from '@mui/styles';

// ICONS
import { FiArrowLeft } from "react-icons/fi";
import { MdVisibility } from "react-icons/md";
import { StaticTimePicker } from '@mui/x-date-pickers';

function CustomToolbar() {
    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer} style={{ paddingBottom: 10 }}>
            <GridToolbarExport style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} csvOptions={{ allColumns: true, fileName: `Sale_Profit_${new Date().toISOString().slice(0, 10)}` }} printOptions={{ disableToolbarButton: true }} />
            <GridToolbarDensitySelector style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
            <GridToolbarFilterButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
            <GridToolbarColumnsButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
        </GridToolbarContainer>
    );
}


const StyledDataGrid = withStyles({
    root: {
        "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important"
        },
        "& .MuiDataGrid-cell": {
            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal"
        },
        "& .MuiDataGrid-row": {
            maxHeight: "none !important"
        }
    }
})(DataGrid);

export default function SalesProfit() {
    const navigate = useNavigate();
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    let query = useQuery();
    const location = useLocation();

    // table
    const [pageSize, setPageSize] = useState(20);
    const [tableLoading, setTableLoading] = useState(false);
    const [state, setState] = useState([]);

    const { addAlert, setLoading } = useNotificationLoading();
    const { data } = useDemoData({
        dataSet: 'salesProfit',
        rowLength: 5,
        maxColumns: 10,
    });
    const [dateFilter, setDateFilter] = useState({
        from_date: query.get('from_date') || null, // moment().format('L hh:mm A'),
        to_date: query.get('to_date') || null, // moment().format('L hh:mm A'),
    });

    const [searchName, setSearchName] = useState(query.get('name') || "");
    const [searchOrderNo, setSearchOrderNo] = useState(query.get('order_no') || "");
    const [searchProductName, setSearchProductName] = useState(query.get('product') || "");
    const [searchBrand, setSearchBrand] = useState(query.get('brand') || "");
    // const [searchAmount, setSearchAmount] = useState('');
    // const [searchTotalCost, setSearchTotalCost] = useState('');

    // const [recentOrder, setRecentOrder] = useState([]);
    // const isMountedRef = useRef(null);
    const [total, setTotal] = useState([]);

    const BRANCH = window.location.href.indexOf(GENTING_URL) > -1 ? 'genting' : 'others';

    //useEffect(() => {
    //    filterDate();
    // eslint-disable-next-line
    // }, []);

    // const filterDate = () => {
    //     const from_date = dateFilter.from_date ? moment(dateFilter.from_date).format('YYYY-MM-DD HH:mm:ss'): '';
    //    const to_date = dateFilter.to_date ? moment(dateFilter.to_date).format('YYYY-MM-DD HH:mm:ss'): '';

    //      setTableLoading(true);

    // let extraQuery = from_date ? `?sdate=${from_date}` : '';
    //  extraQuery += `${extraQuery ? '&' : '?'}${to_date ? `edate=${to_date}` : ''}`;
    //  getUrl(`/report/sales-profit${extraQuery}`).then(response => {
    //     setTableLoading(false);
    //     if (response.status) {
    //         setState(response.data.listing);
    //     } else {
    //          addAlert("", t('error.contactSupport'), 'error', '');
    //      }
    //   }).catch(error => {
    //       setTableLoading(false);
    //         addAlert('', error.message || t('error.contactSupport'), 'error', '');
    //     });
    //  }

    const handleDateFilterChange = (newValue, type) => {
        setDateFilter({ ...dateFilter, [type]: newValue });
    }

    const clearFilter = useCallback(() => {
        // setDateFilter({ from_date: null, to_date: null });
        // setSearchName("");
        // setSearchOrderNo("");
        // setSearchProductName("");
        // setSearchBrand("");
        // callApi();
        window.location.href = location.pathname;
    }, []);

    // function getProductList(params) {
    //     let products='';
    //     if (_.size(params.value) > 0) {
    //         _.map(params.value,product=>{
    //             products +=`( ${product.product_name}, ${t('product.sellPrice')}:${currencyFormat(parseFloat(product.sell_price))}, ${t('product.costPrice1')}:${currencyFormat(parseFloat(BRANCH === 'genting' ? product.cost_price1 : product.cost_price2)+parseFloat(product.total_add_ons_price))}, ${t('report.profit')}:${currencyFormat(parseFloat(product.sell_price) - (parseFloat(BRANCH === 'genting' ? product.cost_price1 : product.cost_price2)+parseFloat(product.total_add_ons_price)))} ),`
    //         })
    //     }
    //     return `${products}`;
    // }

    //table
    const columns = useMemo(() => [
        {
            field: 'order_no',
            headerName: `${t('order.receiptNo')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => { return <Link underline='none' to={`/orders/${params.id}`} component={RouterLink}><div key={params.row.id}>{params.value ? params.value : "-"}</div></Link> }
        },
        {
            field: 'product_name',
            headerName: `${t('report.productName')}`,
            flex: 2,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => { return <div key={params.row.id}>{params.value ? params.value : "-"}</div>; }
        },
        {
            field: 'brand_name',
            headerName: `${t('product.brand')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => { return <div key={params.row.id}>{params.value ? params.value : "-"}</div>; }
        },
        {
            field: 'user_name',
            headerName: `${t('order.salesperson')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => { return <div key={params.row.id}>{params.value ? params.value : "-"}</div>; }
        },
        {
            field: 'created_at',
            headerName: `${t('report.date')}`,
            flex: 2,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => { return <div key={params.row.id}>{params.value ? params.value : "-"}</div>; }
        },
        {
            field: 'updated_at',
            headerName: `${t('report.updatedAt')}`,
            flex: 2,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => { return <div key={params.row.id}>{params.value ? params.value : "-"}</div>; }
        },
        {
            field: 'ori_sell_price',
            headerName: `${t('report.sellPrice')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => { return <div key={params.row.id}>{params.value ? currencyFormat(parseFloat(params.value)) : "-"}</div>; }
        },
        {
            field: 'discount',
            headerName: `${t('report.discount')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return currencyFormat(parseFloat(params.row.ori_sell_price - params.row.sell_price));
            }
        },
        {
            field: 'sell_price',
            headerName: `${t('report.finalSellPrice')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => { return <div key={params.row.id}>{params.value ? currencyFormat(parseFloat(params.value)) : "-"}</div>; }
        },
        {
            field: 'cost_price2',
            headerName: `${t('report.costPrice')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return currencyFormat(parseFloat(BRANCH === 'genting' ? params.row.cost_price : params.row.cost_price2));
            }
        },
        {
            field: 'add_ons_price',
            headerName: `${t('report.addOnsPrice')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => { return <div key={params.row.id}>{params.value ? currencyFormat(parseFloat(params.value)) : "-"}</div>; }
        },
        {
            field: 'profit',
            headerName: `${t('report.profit')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return currencyFormat(parseFloat(params.row.sell_price - parseFloat(BRANCH === 'genting' ? params.row.cost_price : params.row.cost_price2) - params.row.add_ons_price));
            }
        },
        // {
        //     field: 'products',
        //     headerName: `${t('product.productDetails')}`,
        //     flex: 1,
        //     align: 'center',
        //     minWidth: 300,
        //     headerAlign: 'center',
        //     headerClassName: 'super-app-theme--header',
        //     renderCell: (params) => {
        //         return (
        //             <div>
        //                 {
        //                     _.map(params.value, (product, key) => {
        //                         return (
        //                             <div key={key} style={{ paddingBottom: 5 }}>
        //                                 <Typography key={key} style={{ width: '7vw', fontWeight: 'bold' }} variant="subtitle2">{product.product_name}</Typography >
        //                                 <Typography key={key} style={{ width: '7vw' }} variant="body2">{t('product.sellPrice')} : {currencyFormat(parseFloat(product.sell_price))}</Typography >
        //                                 <Typography key={key} style={{ width: '7vw' }} variant="body2">{t('product.costPrice1')} : {currencyFormat(parseFloat(BRANCH === 'genting' ? product.cost_price2 : product.cost_price1))}</Typography >
        //                                 <Typography style={{ width: '7vw' }} variant="body2">Add On:</Typography >
        //                                 {
        //                                     _.map(product.add_ons, (addon, k) => {
        //                                         return (<Typography style={{ width: '7vw' }} variant="body2">+ {addon.description} : {currencyFormat(parseFloat(addon.amount))}</Typography>)
        //                                     })
        //                                 }
        //                                 <Typography key={key} style={{ width: '7vw' }} variant="body2">{t('report.profit')} : {currencyFormat(parseFloat(product.sell_price) - (parseFloat(BRANCH === 'genting' ? product.cost_price2 : product.cost_price1) + parseFloat(product.total_add_ons_price)))}</Typography >
        //                             </div>
        //                         )
        //                     })
        //                 }
        //             </div>
        //         )
        //     },
        //     valueFormatter: getProductList,
        // },
        // {
        //     field: 'total_sell_price',
        //     headerName: `${t('report.totalAmount')} (RM)`,
        //     flex: 1,
        //     align: 'center',
        //     headerAlign: 'center',
        //     headerClassName: 'super-app-theme--header',
        //     renderCell: (params) => { return <div key={params.value}>{params.value ? currencyFormat(parseFloat(params.value)) : "-"}</div>; }
        // },
        // {
        //     field: 'total_cost_price',
        //     headerName: `${t('report.costPrice')} (RM)`,
        //     flex: 1,
        //     align: 'center',
        //     headerAlign: 'center',
        //     headerClassName: 'super-app-theme--header',
        //     valueGetter: (params) => {
        //         return currencyFormat(parseFloat(BRANCH === 'genting' ? params.row.total_cost_price2 : params.row.total_cost_price1));
        //     }
        // },
        // {
        //     field: 'total_profit',
        //     headerName: `${t('report.totalProfit')} (RM)`,
        //     flex: 1,
        //     align: 'center',
        //     headerAlign: 'center',
        //     headerClassName: 'super-app-theme--header',
        //     valueGetter: (params) => {
        //         return currencyFormat(parseFloat(params.row.total_sell_price) - (parseFloat(BRANCH === 'genting' ? params.row.total_cost_price2 : params.row.total_cost_price1) + parseFloat(params.row.total_add_ons)));
        //     }
        // },

    ],
        [t],
    );

    const callApi = () => {
        const from_date = dateFilter.from_date ? moment(dateFilter.from_date).format('YYYY-MM-DD HH:mm:ss') : '';
        const to_date = dateFilter.to_date ? moment(dateFilter.to_date).format('YYYY-MM-DD HH:mm:ss') : '';
        setLoading(true);

        let apiUrl = `/report/sales-profit`;
        getUrl(apiUrl, { search: searchName, search2: searchOrderNo, search3: searchProductName, search4: searchBrand, sdate: from_date, edate: to_date })
            .then((response) => {
                setLoading(false);
                if (response.status) {
                    setState(response.data.listing);
                    setTotal(response.data.total);
                } else {
                    addAlert("", t("error.contactSupport"), "error", "");
                }
            })
            .catch((error) => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
    }

    useEffect(() => {
        setLoading(true);
        callApi();
        // eslint-disable-next-line
    }, []);

    const searchOrder = (e) => {
        // setTableLoading(true);
        if (e.key === 'Enter') {
            callApi();
        }
    }

    return (
        <div>
            <div style={{ paddingBottom: 30, justifyContent:'space-between' }} className={styles.flexMiddle}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <span onClick={() => navigate(-1)} style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase', cursor:'pointer' }}>{t('button.back')}</span>
                </div>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to="/salesReport">
                            {t('title.salesReport')}
                        </Link>
                        <Typography color="text.primary">{t('title.salesProfitReport')}</Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <Box className={styles.flexMiddle} style={{ paddingBottom: 5, justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t('report.salesProfit')}</b></Typography>
                </div>
            </Box>
            <Box component="form" method="get" action={location.pathname} display="flex" flexDirection="row" padding="10px">
                <div style={{ width: '100%', maxWidth: '100%', marginRight: 10 }}>
                    <ThemeProvider theme={inputStyle}>
                        <FormControl style={{ width: '20%', maxWidth: '20%' }}>
                            <OutlinedInput placeholder={t('report.nameSearch')} name="name" value={searchName} onChange={({ target }) => setSearchName(target.value)} /* onKeyDown={searchOrder} */ />
                        </FormControl>
                    </ThemeProvider>
                    <ThemeProvider theme={inputStyle}>
                        <FormControl style={{ width: '20%', maxWidth: '20%' }}>
                            <OutlinedInput placeholder={t('report.orderNoSearch')} name="order_no" value={searchOrderNo} onChange={({ target }) => setSearchOrderNo(target.value)} /* onKeyDown={searchOrder} */ />
                        </FormControl>
                    </ThemeProvider>
                    <ThemeProvider theme={inputStyle}>
                        <FormControl style={{ width: '20%', maxWidth: '20%' }}>
                            <OutlinedInput placeholder={t('report.productNameSearch')} name="product" value={searchProductName} onChange={({ target }) => setSearchProductName(target.value)} /* onKeyDown={searchOrder} */ />
                        </FormControl>
                    </ThemeProvider>
                    <ThemeProvider theme={inputStyle}>
                        <FormControl style={{ width: '20%', maxWidth: '20%' }}>
                            <OutlinedInput placeholder={t('report.brandSearch')} name="brand" value={searchBrand} onChange={({ target }) => setSearchBrand(target.value)} /* onKeyDown={searchOrder} */ />
                        </FormControl>
                    </ThemeProvider>
                </div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                        label={t('report.fromDate')}
                        mask='__/__/____ __:__ _M'
                        value={dateFilter.from_date}
                        onChange={(value) => handleDateFilterChange(value, 'from_date')}
                        renderInput={(params) => <TextField name="from_date" style={{ marginRight: 10 }} {...params} />}
                    />
                    <DateTimePicker
                        label={t('report.toDate')}
                        mask='__/__/____ __:__ _M'
                        value={dateFilter.to_date}
                        onChange={(value) => handleDateFilterChange(value, 'to_date')}
                        renderInput={(params) => <TextField name="to_date" style={{ marginRight: 10 }} {...params} />}
                    />
                </LocalizationProvider>
                <Button variant="outlined" type="submit"/* onClick={callApi} */ style={{ marginRight: 10 }}>
                    <Typography>{t('general.filter')}</Typography>
                </Button>
                <Button variant="outlined" onClick={clearFilter}>
                    <Typography>{t('general.clear')}</Typography>
                </Button>
            </Box>
            {/* TABLE */}
            <div style={{ height: 450, width: '100%', overflowX: 'scroll', boxShadow: '0 0 20px 0 #dadada', borderRadius: '15px' }}>
                <div style={{ display: 'flex', height: '100%', padding: 0 }}>
                    <div style={{ flexGrow: 1 }}>
                        <StyledDataGrid
                            {...data}
                            rows={state}
                            columns={columns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 30]}
                            components={{
                                Toolbar: CustomToolbar,
                                LoadingOverlay: LinearProgress,
                            }}
                            loading={tableLoading ? true : false}
                            disableSelectionOnClick
                            sx={{
                                marginTop: 0,
                                borderRadius: '15px',
                                paddingTop: '15px',
                                border: 'none',
                                '& .super-app-theme--header': {
                                    // backgroundColor: theme.palette.primary.main,
                                    backgroundColor: theme.palette.gray.tableHeader,
                                    color: theme.palette.gray.tableHeaderText,
                                },
                                backgroundColor: 'white',
                                '& 	.MuiDataGrid-menuIconButton': {
                                    color: theme.palette.gray.tableHeaderText,
                                },
                                '& 	.MuiDataGrid-sortIcon': {
                                    color: theme.palette.gray.tableHeaderText,
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell rowSpan={4} />
                        <TableCell colSpan={2}>{t('report.totalSales')}</TableCell>
                        <TableCell align="right">{currencyFormat(parseFloat(total.total_sell)) || "0.00"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>{t('report.totalCost')}</TableCell>
                        <TableCell align="right">{currencyFormat(parseFloat(BRANCH === 'genting' ? total.total_cost2 : total.total_cost1)) || "0.00"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>{t('report.totalAddOns')}</TableCell>
                        <TableCell align="right">{currencyFormat(parseFloat(total.total_add_ons)) || "0.00"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>{t('report.totalProfit')}</TableCell>
                        <TableCell align="right">{currencyFormat(parseFloat(BRANCH === 'genting' ? total.total_profit : total.total_profit2)) || "0.00"}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const inputStyle = createTheme({
    components: {
        // Name of the component
        MuiOutlinedInput: {
            styleOverrides: {
                // Name of the slot
                input: {
                    // Some CSS
                    padding: '28.5px 14px',
                    boxSizing: 'inherit',
                    borderRadius: 48
                },
                root: {
                    borderRadius: 48,
                    boxShadow: '3px 3px 8px 0 #ccc'
                },
            },
        },
    },
})