import React ,{useState , useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { postUrl, putUrl, deleteUrl , getUrl } from '../../helpers/ApiAction';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import PropTypes from "prop-types";

// MUI
import { Box, Button, Typography, Modal, Grid, FormControl, MenuItem, TextField, FormControlLabel, Radio, RadioGroup } from '@mui/material';

// ICONS
import { FiPlus, FiMinus, FiFile } from "react-icons/fi";

// MODAL
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    p: 4,
};

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    
    return (
        <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value,
                },
            });
        }}
        thousandSeparator
        isNumericString
        />
        );
    });
    
    NumberFormatCustom.propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    };
    
    
    export default function PaymentModal({
        type, // order-payments, instalment-details
        order, setOrder, tradeInList, paymentStatusList,
        paymentModal, setPaymentModal, paymentApproveModal, setPaymentApproveModal, inputErrors, setInputErrors,
        updateTotalPayment, selectedPaymentList, setSelectedPaymentList, // order-payments and instalment-details have diff value
        paymentID, orderPaymentList, setOrderPaymentList, // instalment
    }) {
        const styles = useStyles();
        const theme = useTheme();
        const { t, i18n } = useTranslation();
        const { addAlert, setLoading } = useNotificationLoading();
        const [saveCreditNote , setSaveCreditNote] =useState(true);
        const [creditNoteId , setCreditNoteId] =useState(true);
        const [typeTab, setTypeTab] = useState('all');
        
        
        function buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
                Object.keys(data).forEach(key => {
                    buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
                });
            } else {
                const value = data == null ? '' : data;
                formData.append(parentKey, value);
            }
        }
        
        // ADD/EDIT/DELETE PAYMENT
        const handlePaymentModalClose = () => {
            setInputErrors({});
            setPaymentModal({
                open: false,
                key: '',
                payment: {},
                payment_info: {}
            });
            setSaveCreditNote(true);
        }
        const handleAmountChange = (type, amount) => {
            if (type === 'amount') {
                setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, amount: parseFloat(amount) } });
            } else if (type === 'add') {
                setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, amount: parseFloat(parseFloat(paymentModal.payment.amount) + parseFloat(amount)).toFixed(2) } });
            } else if (type === 'deduct') {
                setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, amount: parseFloat(parseFloat(paymentModal.payment.amount) - parseFloat(amount)).toFixed(2) } });
            }
        }
        const updatePaymentMethod = () => {
            setLoading(true);
            setInputErrors({});
            
            //validate credit note before save/edit
            let valid =true;
            if (paymentModal.payment?.payment_method === "credit-note") {
                valid = validateCreditNote();
            }
            
            if(valid){
                if (parseInt(paymentModal.payment.id) === 0) {
                    // ADD PAYMENT/INSTALMENT
                    let postData = {
                        payment_method: paymentModal.payment.payment_method,
                        payment_amount: paymentModal.payment.amount,
                        payment_details: paymentModal.payment.payment_details,
                    }
                    
                    if (type === 'order-payments') {
                        postData['order_id'] = order.id;
                    } else if (type === 'instalment-details') {
                        postData['order_payment_id'] = paymentID;
                    }
                    
                    const formData = new FormData();
                    buildFormData(formData, postData);
                    
                    
                    postUrl(`/${type}`, formData).then(response => {
                        setLoading(false);
                        if (response.status) {
                            if (type === 'instalment-details') {
                                // update order's payment's instalment
                                let tempPaymentList = orderPaymentList;
                                _.map(tempPaymentList, (pData, key) => {
                                    if (pData.id === paymentID) {
                                        pData.instalment_details = response.data;
                                    }
                                })
                                setOrderPaymentList(tempPaymentList);
                            }
                            setSelectedPaymentList(response.data);
                            addAlert('', response.message, 'success', '');
                            handlePaymentModalClose();
                            updateTotalPayment(response.data);
                        } else {
                            setInputErrors(response.errors);
                            addAlert('', response.message, 'error', '');
                        }
                    }).catch(error => {
                        setLoading(false);
                        addAlert('', error.message || t('error.contactSupport'), 'error', '');
                    });
                } else {
                    // UPDATE PAYMENT/INSTALMENT
                    let postData = {
                        payment_method: paymentModal.payment.payment_method,
                        payment_amount: paymentModal.payment.amount,
                        payment_details: paymentModal.payment.payment_details,
                    }
                    putUrl(`/${type}/${paymentModal.payment.id}`, postData).then(response => {
                        setLoading(false);
                        if (response.status) {
                            if (type === 'instalment-details') {
                                // update order's payment's instalment
                                let tempPaymentList = orderPaymentList;
                                _.map(tempPaymentList, (pData, key) => {
                                    if (pData.id === paymentID) {
                                        pData.instalment_details[paymentModal.key] = response.data;
                                    }
                                })
                                setOrderPaymentList(tempPaymentList);
                            }
                            addAlert('', response.message, 'success', '');
                            let temp = selectedPaymentList;
                            temp[paymentModal.key] = response.data;
                            handlePaymentModalClose();
                            setSelectedPaymentList(temp);
                            updateTotalPayment(temp);
                        } else {
                            setInputErrors(response.errors);
                            addAlert('', response.message, 'error', '');
                        }
                    }).catch(error => {
                        setLoading(false);
                        addAlert('', error.message || t('error.contactSupport'), 'error', '');
                    });
                }
            }
        }
        const deletePaymentMethod = () => {
            // DELETE PAYMENT
            setLoading(true);
            deleteUrl(`/${type}/${paymentModal.payment.id}`).then(response => {
                setLoading(false);
                if (response.status) {
                    if (type === 'instalment-details') {
                        // update order's payment's instalment
                        let tempPaymentList = orderPaymentList;
                        _.map(tempPaymentList, (pData, key) => {
                            if (pData.id === paymentID) {
                                pData.instalment_details = _.filter(pData.instalment_details, (data, key) => { return key !== paymentModal.key; });
                            }
                        })
                        setOrderPaymentList(tempPaymentList);
                    }
                    addAlert('', response.message, 'success', '');
                    let temp = selectedPaymentList;
                    temp = _.filter(temp, (data, key) => { return key !== paymentModal.key; })
                    handlePaymentModalClose();
                    setSelectedPaymentList(temp);
                    updateTotalPayment(temp);
                } else {
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        
        // APPROVE/REJECT PAYMENT
        const handlePaymentApproveModalClose = () => {
            setInputErrors({});
            setPaymentApproveModal({
                open: false,
                key: '',
                payment: {},
                payment_info: {}
            });
        }
        const updatePaymentStatus = () => {
            setLoading(true);
            putUrl(`/${type}/${paymentApproveModal.payment.id}/status`, { status: parseInt(paymentApproveModal.payment.status), remark: paymentApproveModal.payment.remark }).then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert('', response.message, 'success', '');
                    handlePaymentApproveModalClose();
                    setOrder(response.data.order);
                    if (type === 'order-payments') {
                        setSelectedPaymentList(response.data.order.order_payments);
                        updateTotalPayment(response.data.order.order_payments);
                    } else if (type === 'instalment-details') {
                        setOrderPaymentList(response.data.order.order_payments);
                        setSelectedPaymentList(response.data.order_payment.instalment_details);
                        updateTotalPayment(response.data.order_payment.instalment_details);
                    }
                } else {
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        
        //update credit note amount
        /* useEffect(() => {
            if (paymentModal.payment && paymentModal.payment?.payment_method === "credit-note" && paymentModal.payment?.payment_details?.credit_note_id > 0) {
                getUrl(`/credit-notes/${paymentModal.payment.payment_details.credit_note_id}`).then(response => {
                    setLoading(false);
                    if (response.status) {
                        if (response.data.credit_note.status === 10 && response.data.credit_note.customer_id === order.customer_id && response.data.credit_note.type === 'available') {
                            setSaveCreditNote(true);
                            handleAmountChange('amount', response.data.credit_note.amount);
                        } else {
                            setSaveCreditNote(false);
                            addAlert('', t('error.creditNoteStatusError'), 'error', '');
                        }
                    }
                }).catch(error => {
                    setLoading(false);
                    setSaveCreditNote(false);
                    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                });
            }
            
            
        }, [paymentModal.payment?.payment_details?.credit_note_id]); */
        
        const validateCreditNote = () => {
            let credit_note_list = _.size(selectedPaymentList) > 0? selectedPaymentList.filter(list => list.payment_method === "credit-note") :[];
            let selectedCreditNote = credit_note_list.filter(list => list.payment_details?.credit_note_id === paymentModal.payment?.payment_details?.credit_note_id && (list.status === 20 || list.status === 40));
            
            if (_.size(selectedCreditNote) > 0 ) {
                setLoading(false);
                setSaveCreditNote(false);
                addAlert('', t('error.creditNoteDuplicateError'), 'error', '');
                return false;
            }
            return true;
        }
        
        useEffect(() => {
            getUrl('/credit-notes', { status: 10 , customer_id : order.customer_id}).then(response => {
                setLoading(false);
                if (response.status) {
                    setCreditNoteId(response.data.listing);
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
            // eslint-disable-next-line
        }, []);
        
        return (
            <div>
            {/* ADD/EDIT/DELETE PAYMENT MODAL */}
            <Modal
            open={paymentModal.open}
            onClose={handlePaymentModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={modalStyle}>
            <div style={{ textAlign: 'center', color: theme.palette.gray.ltext }}>
            {/* TITLE & DELETE */}
            <div className={`${styles.flexMiddle} ${styles.spaceBetween}`}>
            <Typography><b>{t('pos.paymentMethod')}</b></Typography>
            {
                parseInt(paymentModal.payment.id) !== 0
                ?
                <Button style={{ color: theme.palette.secondary.main }} onClick={deletePaymentMethod}>{t('button.delete')}</Button>
                : null
            }
            </div>
            {/* AMOUNT */}
            <Typography style={{ color: theme.palette.gray.dtext, paddingTop: 25 }}>{paymentModal.payment_info[i18n.language] + ' ' + t('menu.amount') + ' (RM)'}</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ backgroundColor: theme.palette.button.main, width: 30, height: 30, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '2px 2px 6px 0 #8f72547a', pointerEvents:paymentModal.payment?.payment_method === "credit-note" ? "none":"auto" }} onClick={() => handleAmountChange('deduct', 100)}>
            <FiMinus style={{ fontSize: 20, color: '#fff' }} />
            </div>
            <TextField
            sx={{ input: { color: theme.palette.button.main, fontSize: 25, fontWeight: "bold", textAlign: "center", } }}
            variant="standard"
            // type="number"
            value={paymentModal.payment.amount}
            InputProps={{ 
                disableUnderline: true,
                inputComponent: NumberFormatCustom, 
            }}
            onChange={(event) => handleAmountChange('amount', event.target.value)}
            name="amount"
            disabled={paymentModal.payment?.payment_method === "credit-note"}
            />
            <div style={{ backgroundColor: theme.palette.button.main, width: 30, height: 30, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '2px 2px 6px 0 #8f72547a', pointerEvents:paymentModal.payment?.payment_method === "credit-note" ? "none":"auto" }} onClick={() => handleAmountChange('add', 100)}>
            <FiPlus style={{ fontSize: 20, color: '#fff' }} />
            </div>
            </div>
            {/* DETAILS */}
            {
                _.size(paymentModal.payment.payment_details) > 0
                ?
                <Grid container style={{ paddingTop: 20 }}>
                <Grid item xs={12} style={{ borderTop: "2px #8f72547a dashed", padding: 10 }}></Grid>
                {
                    _.map(paymentModal.payment.payment_details, (data, code) => {
                        let displayName = (_.find(paymentModal.payment_info.details, function (p) { return p.code === code; }))[i18n.language];
                        return (
                            <Grid item xs={12} key={code} style={{ margin: 10 }}>
                            {
                                paymentModal.payment.payment_method === 'usdt' && code === 'type'
                                ?
                                <TextField
                                fullWidth
                                id="standard-basic"
                                select
                                variant="outlined"
                                size="small"
                                label={displayName}
                                value={data}
                                onChange={({ target }) => setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, payment_details: { ...paymentModal.payment.payment_details, [code]: target.value } } })}
                                >
                                {
                                    _.map(paymentModal.payment_info.type, usdt_type => {
                                        return (
                                            <MenuItem key={usdt_type} value={usdt_type}>
                                            {usdt_type}
                                            </MenuItem>
                                            )
                                        })
                                    }
                                    </TextField>
                                    :
                                    paymentModal.payment.payment_method === 'trade-in' && code === 'trade-in-id'
                                    ?
                                    <TextField
                                    fullWidth
                                    id="standard-basic"
                                    select
                                    variant="outlined"
                                    size="small"
                                    label={displayName}
                                    value={data}
                                    onChange={({ target }) => setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, payment_details: { ...paymentModal.payment.payment_details, [code]: target.value }, amount: (_.find(tradeInList, function (t) { return t.id === target.value; })).trade_price } })}
                                    >
                                    {
                                        _.map(tradeInList, trade => {
                                            return (
                                                <MenuItem key={trade.id} value={trade.id}>
                                                {'[' + trade.id + '] ' + trade.customer.name + ' - ' + trade.product.name}
                                                </MenuItem>
                                                )
                                            })
                                        }
                                        </TextField>
                                        :
                                        code === 'attachment'
                                        ?
                                        <TextField
                                        fullWidth
                                        id="standard-basic"
                                        type="file"
                                        variant="outlined"
                                        size="small"
                                        label={displayName}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ accept: "image/*,application/pdf" }}
                                        onChange={({ target }) => setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, payment_details: { ...paymentModal.payment.payment_details, [code]: target.files[0] } } })}
                                        />
                                        : code === 'credit_note_id'
                                        ?
                                        
                                        ( _.size(creditNoteId) > 0
                                        ?
                                        <TextField
                                        fullWidth
                                        select
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{ native: true }}
                                        id="standard-basic"
                                        variant="outlined"
                                        size="small"
                                        label={displayName}
                                        // value={data}
                                        onChange={(event) => {setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, amount: parseFloat(event.target.selectedOptions[0].getAttribute('data-amount')), payment_details: { ...paymentModal.payment.payment_details, [code]: event.target.value } } });
                                        
                                    }}
                                    >
                                    <option key={0} value={0} data-amount={0}>
                                    
                                    Please select
                                    
                                    
                                    </option>
                                    {creditNoteId.map((option,index) => (
                                        <option key={index} value={option.id} data-amount={option.amount}>
                                        
                                        ID:{option.id} &nbsp;
                                        Name:{option.customer.name}
                                        &nbsp; &nbsp;Total Amount:
                                        {parseFloat(option.amount).toFixed(2)}
                                        
                                        
                                        </option>
                                        ))}
                                        </TextField>
                                        :  <Typography><b>{t('pos.unavailableMsg')}</b></Typography>
                                        ) : 
                                        <TextField
                                        fullWidth
                                        
                                        InputLabelProps={{ shrink: true }}
                                        
                                        id="standard-basic"
                                        
                                        variant="outlined"
                                        size="small"
                                        label={displayName}
                                        value={data}
                                        onChange={({ target }) => setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, payment_details: { ...paymentModal.payment.payment_details, [code]: target.value } } })}
                                        />        
                                    }
                                    </Grid>
                                    )
                                })
                            }
                            </Grid>
                            : null
                        }
                        {/* ERROR */}
                        {
                            _.size(inputErrors) > 0
                            ?
                            <div style={{ padding: '0 25px' }}>
                            {
                                _.map(inputErrors, (err, key) => {
                                    return (
                                        <Typography key={key} style={{ color: theme.palette.red.main }}>{err}</Typography>
                                        )
                                    })
                                }
                                </div>
                                : null
                            }
                            {/* BUTTON */}
                            <div style={{ paddingTop: 25, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <Button style={{ padding: '8px 15px', width: 130, marginRight: 15, maxWidth: '100%', borderRadius: 48, border: theme.palette.button.borderMain, boxShadow: '2px 2px 20px 0 #efefef', color: theme.palette.button.main, fontSize: 15, textAlign: 'center', textTransform: 'uppercase' }} onClick={handlePaymentModalClose}>{t('button.cancel')}</Button>
                            <Button style={{ padding: '8px 15px', width: 130, maxWidth: '100%', borderRadius: 48, background: theme.palette.button.main, boxShadow: '2px 2px 20px 0 #efefef', color: '#fff', fontSize: 15, textAlign: 'center', textTransform: 'uppercase',pointerEvents:saveCreditNote === false ? "none":"auto" }} onClick={updatePaymentMethod}>{t('button.save')}</Button>
                            </div>
                            </div>
                            </Box>
                            </Modal>
                            
                            {/* APPROVE/REJECT PAYMENT MODAL */}
                            <Modal
                            open={paymentApproveModal.open}
                            onClose={handlePaymentApproveModalClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            >
                            <Box sx={modalStyle}>
                            <div style={{ color: theme.palette.gray.dtext, paddingBottom: 25 }}>
                            <div style={{ paddingBottom: 20 }}>
                            <div style={{ textAlign: 'center' }}>
                            <Typography>{paymentApproveModal.payment_info[i18n.language] + ' ' + t('menu.amount') + ' (RM)'}</Typography>
                            <Typography style={{ color: theme.palette.secondary.main, fontSize: 28, fontWeight: 'bold' }}>
                            <NumberFormat thousandSeparator={true} value={paymentApproveModal.payment.amount} displayType="text" />
                            </Typography>
                            </div>
                            {
                                _.size(paymentApproveModal.payment.payment_details) > 0
                                ?
                                <Grid container style={{ paddingTop: 15 }}>
                                {
                                    _.map(paymentApproveModal.payment.payment_details, (data, code) => {
                                        let displayName = (_.find(paymentApproveModal.payment_info.details, function (p) { return p.code === code; }))[i18n.language];
                                        return (
                                            <Grid item xs={6} style={{ textAlign: 'center', color: theme.palette.gray.dtext }} key={code}>
                                            <Typography style={{ fontSize: 14, color: theme.palette.gray.ltext }}>{displayName}</Typography>
                                            {
                                                code === "attachment" && data ?
                                                <a href={data} target="_blank"><FiFile style={{ fontSize: 18, color: theme.palette.secondary.main }} /></a>
                                                :
                                                <Typography><b>{data || "-"}</b></Typography>
                                            }
                                            </Grid>
                                            )
                                        })
                                    }
                                    </Grid>
                                    : null
                                }
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                <div style={{ backgroundColor: '#efefef', width: '100%', height: 3 }} />
                                <div style={{ backgroundColor: '#e0e0e0', width: '100%', height: 3 }} />
                                </div>
                                <div>
                                <Typography style={{ color: theme.palette.gray.ltext, fontSize: 20 }}><b>{t('order.paymentStatus')}</b></Typography>
                                {
                                    _.size(selectedPaymentList) > 0
                                    ?
                                    selectedPaymentList[paymentApproveModal.key] && selectedPaymentList[paymentApproveModal.key].status === 20
                                    ?
                                    <FormControl>
                                    <RadioGroup
                                    row
                                    defaultValue={selectedPaymentList[paymentApproveModal.key] ? selectedPaymentList[paymentApproveModal.key].status : ''}
                                    onChange={(event) => setPaymentApproveModal({ ...paymentApproveModal, payment: { ...paymentApproveModal.payment, status: event.target.value } })}
                                    >
                                    {
                                        _.size(paymentStatusList) > 0 &&
                                        _.map(paymentStatusList, status => {
                                            return (
                                                <FormControlLabel key={status.code} value={status.code} control={<Radio />} label={status[i18n.language]} />
                                                )
                                            })
                                        }
                                        </RadioGroup>
                                        </FormControl>
                                        :
                                        <>
                                        {
                                            selectedPaymentList[paymentApproveModal.key]
                                            ?
                                            _.size(paymentStatusList) > 0 &&
                                            _.map(paymentStatusList, status => {
                                                if (selectedPaymentList[paymentApproveModal.key].status === status.code) {
                                                    return (
                                                        <Typography key={status.code}>{t('order.currentPaymentStatus') + ': ' + status[i18n.language]}</Typography>
                                                        )
                                                    }
                                                })
                                                : null
                                            }
                                            </>
                                            : null
                                        }
                                        </div>
                                        </div>
                                        <Box id="modal-modal-description"
                                        sx={{
                                            width: 500,
                                            maxWidth: '100%',
                                        }}
                                        >
                                        <Typography style={{ color: theme.palette.gray.dtext, paddingBottom: 8, fontSize: 18 }}><b>{t('order.remark')}</b></Typography>
                                        <TextField
                                        fullWidth
                                        id="standard-basic"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        value={paymentApproveModal.payment.remark ? paymentApproveModal.payment.remark : ''}
                                        onChange={(event) => setPaymentApproveModal({ ...paymentApproveModal, payment: { ...paymentApproveModal.payment, remark: event.target.value } })}
                                        />
                                        </Box>
                                        <div className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} style={{ paddingTop: 80, width: '100%' }} >
                                        <Button style={{ padding: '8px 15px', width: 180, marginRight: 15, maxWidth: '100%', borderRadius: 48, border: theme.palette.button.borderMain, boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center' }} onClick={handlePaymentApproveModalClose}>
                                        <Typography style={{ color: theme.palette.button.main, fontSize: 18, textAlign: 'center', textTransform: 'uppercase' }}>{t('button.cancel')}</Typography>
                                        </Button>
                                        <Button style={{ padding: '8px 15px', width: 180, maxWidth: '100%', borderRadius: 48, background: theme.palette.button.main, boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center' }} onClick={updatePaymentStatus}>
                                        <Typography style={{ color: '#fff', fontSize: 18, textAlign: 'center', textTransform: 'uppercase' }}>{t('button.save')}</Typography>
                                        </Button>
                                        </div>
                                        </Box>
                                        </Modal>
                                        </div>
                                        )
                                    }
                                    
                                    const useStyles = makeStyles(theme => ({
                                        spaceBetween: {
                                            justifyContent: 'space-between'
                                        },
                                        justifyCenter: {
                                            justifyContent: 'center'
                                        },
                                        flexMiddle: {
                                            display: 'flex',
                                            alignItems: 'center',
                                        },
                                    }));