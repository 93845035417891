import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme, styled } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import _ from 'lodash';

// MUI
import { Grid, Typography, Box, Link, Radio, TextField , Hidden, Modal, Button, FormControlLabel, OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import { FiPlus, FiArrowLeft, FiEdit2 } from "react-icons/fi";

//RADIO BUTTON
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import { getUrl, postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';

import WarrantlyReceipt from './WarrantlyReceipt';

//Radio Button
const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        '.MuiFormControlLabel-label': checked && {
            color: theme.palette.primary.main,
        },
    }),
);

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}

export default function WarrantyReportDetail() {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);
    const [statusList, setStatusList] = useState(null);
    const [state, setState] = useState({
        description: "",
        status: "",
        remark: "",
        images: []
    });
    const [inputErrors, setInputErrors] = useState({
        description: null,
        status: null,
        remark: null,
        images: null,
    });
    const [editDescription, setEditDescription] = useState(false);
    const [selectedImages, setSelectedImages] = useState(null);

    let { id } = useParams();
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();

    const isMountedRef = useRef(null);
    const uploadRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`services/${id}`).then(response => {
            setLoading(false);
            if(response.status && isMountedRef.current) {
                setData(response.data);
                setStatusList(response.status_list);
                if(_.size(response.data)) {
                    setState({ 
                        ...state, 
                        status: response.data.status,
                        description: response.data.description ?? ""
                    });
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if(state.images) {
            let files = [];
            for (let file of state.images) {
                files.push(URL.createObjectURL(file));
            }
            setSelectedImages(files);
        }
    }, [state.images])

    //modal
    const toggleModal = () => {
        setOpen(!open);
        setState({ ...state, remark: "" })
    }
    
    const handleInputChange = ({ target }) => {
        setState({ ...state, [target.name]: target.value });
    }

    const updateWarranty = () => {
        if(id) {
            setLoading(true);

            const formData = new FormData();

            _.map(state, (stateItem, stateKey) => {
                formData.append(stateKey, stateItem);
                if(stateKey === "images") {
                    _.map(stateItem, imageItem => {
                        formData.append("images[]", imageItem);
                    })
                }
            })
            formData.append("_method", "put");

            postUrl(`services/${id}`, formData).then(response => {
                setLoading(false);
                if(response.status) {
                    addAlert('', t('success.updateSuccess'), 'success', '');
                    if(response.data) {
                        setData(response.data);
                    }
                    setEditDescription(false);
                }
                
                if(response.errors) {
                    setInputErrors(response.errors);
                }

                setState({ ...state, images: [] });
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            })
        }
    }

    const uploadImage = ({ target }) => {
        let newImage = state.images;
        _.map(target.files, file => {
            newImage = _.concat(newImage, file);
        })

        setState({ ...state, images: newImage });
    }

    const clearImage = () => {
        setState({ ...state, images: [] });
    }

    return(
        <div>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Link underline='none' component={RouterLink} to={`/warranty`}>
                    <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                        <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                        <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.backTo')}{t('title.warranty')}</Typography>
                    </div>
                </Link>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <WarrantlyReceipt statusList={statusList} data={data} state={state} label={t('button.print')} />
                </div>
            </Grid>
            
            <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{paddingBottom:20, borderBottom:'1px dashed #777'}}>
                <div>
                <Typography style={{fontSize:18, fontWeight: 'bold'}}>{`${t('warranty.serviceNo')} ${data ? data.service_no : '-'}`}</Typography>
                <Hidden smUp><Typography>{`${t('textField.date')}: ${data ? data.created_at : '-'}`}</Typography></Hidden>
                </div>
                <Hidden only={'xs'}>
                    <Typography>{`${t('textField.date')}: ${data ? data.created_at : '-'}`}</Typography>
                </Hidden>
            </div>

            <div style={{padding:'20px 0'}}>
                <Hidden smUp>
                    <Grid item xs={12} sm={6}>
                        <Typography style={{ fontSize: 25, }}><b>WARRANTY RECEIPT</b></Typography>
                    </Grid>
                </Hidden>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Typography style={{ fontSize: 28, fontWeight: 'bold' }}>{ data ? data.customer.name : '-' }</Typography>
                        <Typography>{ data ? data.customer.mobile : '-' }<span style={{ margin: '0 15px' }}>|</span> { data ? data.customer.email : '-' }</Typography>
                    </Grid>
                    <Hidden only={'xs'}>
                        <Grid item xs={12} sm={6}>
                            <Typography style={{ fontSize: 25, textAlign: 'right', fontWeight: 'bold' }}>WARRANTY <br/> RECEIPT</Typography>
                        </Grid>
                    </Hidden>
                </Grid>
            </div>

            <div className={styles.flexMiddle}>
                <div>
                    <img src={ data ? (_.size(data.product.images) ? data.product.images[0]['file_name'] : "/images/emptyStatus/noproductimg.jpg") : null } style={{ width: 120, borderRadius: 8 }} alt="product"/>
                </div>
                <div style={{color:theme.palette.gray.dtext, padding:'0 20px'}}>
                    <Typography className={styles.productTitle}>{ data ? data.product.name : '-' }</Typography>
                    <Typography className={styles.productContentText}>{`${t('warranty.serialNo')}: ${data ? data.product.serial_no : '-'}`}</Typography>
                    <Hidden only={'xs'}>
                        <Typography className={styles.productContentText} style={{ paddingTop: 15 }}>{`${t('warranty.dateOfPurchase')}: ${data ? data.order.created_at : '-'}`}</Typography>
                    </Hidden>
                    <Hidden smUp>
                        <Typography className={styles.productContentText} style={{ paddingTop: 15 }}>{`${t('warranty.dateOfPurchase')}: ${data ? data.order.created_at : '-'}`}</Typography>
                    </Hidden>
                </div>
            </div>

            <div style={{padding:'25px 0 20px', borderBottom:'1px dashed #777'}}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 8 }}>
                    <Typography style={{ color: theme.palette.gray.ltext, fontWeight: 'bold' }}>{ t('warranty.description') }</Typography>
                    <div onClick={() => setEditDescription(!editDescription)} style={{ width: 23, height: 23, borderRadius: 48, padding: 5, backgroundColor: theme.palette.secondary.main, marginLeft: 10, cursor: 'pointer' }} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                        <FiEdit2 style={{ color: '#fff', fontSize: 18 }} />
                    </div>
                </div>
                { editDescription ? 
                    <FormControl style={{ width: '100%', maxWidth: '100%' }}>
                        <OutlinedInput name="description" value={state.description} onChange={handleInputChange} />
                    </FormControl> : 
                    <Typography style={{ lineHeight: 1.2, color: theme.palette.gray.dtext }}>{ state.description }</Typography>
                }
            </div>

            {/* // Update Status */}
            <div style={{padding:'20px 0 30px', color:theme.palette.gray.dtext,  borderBottom:'1px dashed #777'}}>
                <Typography style={{paddingBottom:15}}>{t('warranty.status')} - <Typography component="span" style={{paddingBottom:15, color:'#ff7272', textTransform:'uppercase'}}>{ data ? t(`warranty.statusList.${data.status}`) : '-' }</Typography></Typography>
                { data && _.map(data.logs, (log, logIndex) => {
                    return (
                        <div key={logIndex}>
                            <Hidden smUp>
                                <div style={{color:theme.palette.gray.ltext}} className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                                    <Typography style={{fontSize:14}}>{ log.time }</Typography>
                                    <Typography className={styles.textLine1} style={{fontSize:14, maxWidth:'60%', textAlign:'right'}}>{ log.updated_by_name }</Typography>
                                </div>
                                <Typography>{ t(`warranty.statusLog.${log.status}`) }</Typography>
                            </Hidden>
                            <Hidden only={'xs'}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={3} md={2}>
                                        <Typography style={{fontSize:14}}>{ log.time }</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={7}>
                                        <Typography>{ t(`warranty.statusLog.${log.status}`) } { log.remark ? <Typography component="span" style={{fontSize:14, color:theme.palette.gray.ltext}}>{`- ${log.remark}`}</Typography> : null }</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography className={styles.textLine1} style={{fontSize:14, maxWidth:'100%', textAlign:'right'}}>{ log.updated_by_name }</Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </div>
                    )
                })}
                { data && data.status !== 40 ?
                    <Button onClick={toggleModal} style={{backgroundColor:theme.palette.secondary.secondary, width:'100%', padding:'8px 15px', borderRadius:8, marginTop:20, boxShadow:'0 3px 4px 0 #e0be8d'}}>
                        <Typography style={{color:'#fff', textAlign:'center' }}>{t('warranty.updateStatus')}</Typography>
                    </Button>
                : null }
                
            </div>

            {/* //Support Documents */}
            <div style={{padding:'20px 0'}}>
                <Typography className={styles.flexMiddle} style={{ padding: '20px 0' }}>
                    <Typography component="span">{t('warranty.supportDocuments')}</Typography>
                </Typography>
                { inputErrors.images ? 
                    _.map(inputErrors.images, error => {
                        return <Typography key={error} variant="caption" style={{ color: 'red' }}>{ inputErrors.images }</Typography>
                    })
                : null }
                { data && data.status !== 40 ?
                    <div style={{ flexDirection: 'row', paddingBottom: 20 }}>
                        <Typography variant="caption">{ t('warranty.totalImageSelected', { total: _.size(state.images) }) }</Typography>
                        { _.size(state.images) ? <Typography onClick={clearImage} variant="caption" style={{ paddingLeft: 10, color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>{ t('general.clear') }</Typography> : null }
                    </div>
                : null }
                <Grid container spacing={3}>
                    { data && data.status !== 40 ?
                        <Grid item xs={12} sm={4} md={2}>
                            <div onClick={() => uploadRef.current.click() } style={{backgroundColor:'#e5e5e5', borderRadius:15, height:'100%', padding:15, cursor: 'pointer'}} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                                <div style={{ color: '#9a9a9a' }} className={`head ${styles.flexMiddle} ${styles.justifyCenter} ${styles.directionColumn}`}>
                                    <FiPlus style={{ fontSize: '5.5rem', textAlign:'center', paddingBottom:8 }} />
                                    <Typography style={{ textAlign: 'center'}}>{t('warranty.uploadImage')}</Typography>
                                </div>
                            </div>
                            <TextField
                                inputRef={uploadRef}
                                id="standard-basic"
                                label={t('brand.logo')}
                                variant="outlined"
                                type="file"
                                style={{ display: 'none' }}
                                inputProps={{ multiple: true }}
                                InputLabelProps={{ shrink: true }}
                                onChange={uploadImage}
                            />
                        </Grid>
                    : null }
                    { selectedImages && _.map(selectedImages, (serviceImage, key) => {
                        return (
                            <Grid item xs={6} sm={4} md={2} key={key}>
                                <img src={serviceImage} style={{ width: '100%', borderRadius: 8, boxShadow: '2px 2px 12px 0 #d3d3d3' }} alt="serviceImage"/>
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid container spacing={3}>
                    { data && _.map(data.images, serviceImage => {
                        return (
                            <Grid item xs={6} sm={4} md={2} key={serviceImage.id}>
                                <img src={serviceImage.file_name} style={{ width: '100%', borderRadius: 8, boxShadow: '2px 2px 12px 0 #d3d3d3' }} alt="serviceImage"/>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        
            <div className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} style={{ paddingTop: 25 }} >
                <Link underline='none' component={RouterLink} to={`/warranty`}>
                    <div style={{ padding: '8px 15px', width: 180, marginRight: 15, maxWidth: '100%', borderRadius: 48, border: '2px solid #cdac89', boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center' }}>
                    <Typography style={{ color: theme.palette.secondary.main, fontSize: 18, textAlign: 'center', textTransform: 'uppercase' }}>{t('button.cancel')}</Typography>
                </div>
                </Link>
                { data && data.status !== 40 ?
                    <div onClick={updateWarranty} style={{ padding: '8px 15px', width: 180, maxWidth: '100%', borderRadius: 48, background: theme.palette.secondary.main, boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center' }}>
                        <Typography style={{ color: '#fff', fontSize: 18, textAlign: 'center', textTransform: 'uppercase', cursor: 'pointer' }}>{t('warranty.updateWarranty')}</Typography>
                    </div>
                : null }
            </div>

            <Modal
                open={open}
                onClose={toggleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{color:theme.palette.gray.dtext, paddingBottom:25}}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ fontWeight: 'bold' }}>
                            {t('warranty.selectStatus')}
                        </Typography>
                        <Typography>{`${t('warranty.nextStatus')}: ${ data && data.status <= 30 ? t(`warranty.statusList.${parseInt(data.status) + 10}`) : '-' }`}</Typography>
                        <div style={{ paddingTop: 25 }}>
                            <RadioGroup name="status" value={`${state.status}`} defaultValue={`${state.status}`} onChange={handleInputChange}>
                                <Grid container spacing={3}>
                                    { _.map(statusList, (status, statusKey) => {
                                        return (
                                            <Grid key={statusKey} item xs={6} sm={6} style={{paddingTop:0}}>
                                                <MyFormControlLabel disabled={state.status > statusKey ? true : false} value={statusKey} label={t(`warranty.statusList.${statusKey}`)} control={<Radio />} /><br/>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </RadioGroup>
                        </div>
                    </div>
                    <Box id="modal-modal-description"
                        sx={{
                            width: 500,
                            maxWidth: '100%',
                        }}
                    >
                        <Typography style={{color:theme.palette.gray.dtext, paddingBottom:8, fontSize:18, fontWeight: 'bold'}}>{t('warranty.remark')}</Typography>
                        <TextField fullWidth name="remark" value={state.remark} onChange={handleInputChange} id="fullWidth" />
                    </Box>

                    <div className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} style={{ paddingTop: 80 }} >
                        <button onClick={toggleModal} style={{ padding: '8px 15px', width: 180, marginRight: 15, maxWidth: '100%', borderRadius: 48, border: '2px solid #cdac89', boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center' }}>
                            <Typography style={{ color: theme.palette.secondary.main, fontSize: 18, textAlign: 'center', textTransform: 'uppercase', cursor: 'pointer' }}>{t('button.cancel')}</Typography>
                        </button>
                        { data && data.status !== 40 ?
                            <div onClick={updateWarranty} style={{ padding: '8px 15px', width: 180, maxWidth: '100%', borderRadius: 48, background: theme.palette.secondary.main, boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center', cursor: 'pointer' }}>
                                <Typography style={{ color: '#fff', fontSize: 18, textAlign: 'center', textTransform: 'uppercase' }}>{t('warranty.updateStatus')}</Typography>
                            </div>
                        : null }
                    </div>
                </Box>
            </Modal>
        </div>
        
    );
}

const useStyles = makeStyles(theme => ({
   
    spaceBetween:{
        justifyContent:'space-between'
    },
    flexMiddle:{
        display:'flex',
        alignItems:'center',
    },
    justifyCenter:{
        justifyContent:'center'
    },
    directionColumn:{
        flexDirection:'column'
    },
    textLine2: {
        display: '-webkit-box',
        maxWidth: '100%',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    },
    textLine1: {
        
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'noWrap'
    },
    productTitle:{
        lineHeight:1.2,
        paddingBottom:8,
        fontWeight: 'bold!important',
        [theme.breakpoints.down('sm')]: {
            fontSize:'16px!important'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize:'24px!important',
        },
    },
    productContentText:{
        [theme.breakpoints.down('sm')]: {
            fontSize:'14px!important'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize:'16px!important',
        },
    }
}));

  //modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius:3,
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxWidth:'90%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


 