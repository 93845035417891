import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { postUrl } from '../../helpers/ApiAction';
// import { ThemeProvider, createTheme } from '@mui/material/styles';

// MUI
import { Grid, Typography, Link, TextField, Box, Button , Breadcrumbs } from '@mui/material';
import { FiArrowLeft } from "react-icons/fi";

export default function SupplierAdd() {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        name: '',
        email: '',
        mobile: '',
        ic: '',
        country: '',
        zip: '',
        state: '',
        city: '',
        address: '',
        address2: ''
    });
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        postUrl('/suppliers', state).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.createSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div>
            <div style={{ paddingBottom: 30, justifyContent:'space-between' }} className={styles.flexMiddle}>
            <Link underline='none' component={RouterLink} to={`/suppliers`}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.back')}</Typography>
                </div>
            </Link>
            
            
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                                {t('title.dashboard')}
                            </Link>
                            <Link underline="hover" color="inherit" component={RouterLink} to="/suppliers">
                                {t('title.suppliers')}
                            </Link>
                            <Typography color="text.primary">{t('title.supplierCreate')}</Typography>
                        </Breadcrumbs>
                    
                    </div>
            <div style={{ paddingBottom: '5%' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ paddingBottom: 15, fontSize: 18 }}><b>{t('title.supplierCreate')}</b></Typography>
                    </div>
                 
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('supplier.supplierName')}
                                variant="outlined"
                                type="text"
                                autoComplete="name"
                                value={state.name}
                                InputLabelProps={{ shrink: true }} 
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('supplier.nric_regNo')}
                                variant="outlined"
                                autoComplete="ic"
                                value={state.ic}
                                InputLabelProps={{ shrink: true }} 
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.ic ? inputErrors.ic : ''}
                                error={inputErrors && inputErrors.ic ? true : false}
                                onChange={({ target }) => setState({ ...state, ic: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.email')}
                                variant="outlined"
                                type="email"
                                autoComplete="email"
                                value={state.email}
                                InputLabelProps={{ shrink: true }} 
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                error={inputErrors && inputErrors.email ? true : false}
                                onChange={({ target }) => setState({ ...state, email: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.mobile')}
                                variant="outlined"
                                autoComplete="mobile"
                                value={state.mobile}
                                InputLabelProps={{ shrink: true }} 
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                error={inputErrors && inputErrors.mobile ? true : false}
                                onChange={({ target }) => setState({ ...state, mobile: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.address')}
                                variant="outlined"
                                type="text"
                                autoComplete="address"
                                value={state.address}
                                InputLabelProps={{ shrink: true }} 
                                helperText={inputErrors && inputErrors.address ? inputErrors.address : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, address: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField id="standard-basic"
                                label={t('user.address2')}
                                variant="outlined"
                                type="text"
                                autoComplete="address2"
                                value={state.address2}
                                InputLabelProps={{ shrink: true }} 
                                helperText={inputErrors && inputErrors.address2 ? inputErrors.address2 : ''}
                                error={inputErrors && inputErrors.address2 ? true : false}
                                onChange={({ target }) => setState({ ...state, address2: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.country')}
                                variant="outlined"
                                type="text"
                                autoComplete="country"
                                value={state.country}
                                InputLabelProps={{ shrink: true }} 
                                helperText={inputErrors && inputErrors.country ? inputErrors.country : ''}
                                error={inputErrors && inputErrors.country ? true : false}
                                onChange={({ target }) => setState({ ...state, country: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.zip')}
                                variant="outlined"
                                autoComplete="zip"
                                value={state.zip}
                                InputLabelProps={{ shrink: true }} 
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.zip ? inputErrors.zip : ''}
                                error={inputErrors && inputErrors.zip ? true : false}
                                onChange={({ target }) => setState({ ...state, zip: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.city')}
                                type="text"
                                autoComplete="city"
                                value={state.city}
                                InputLabelProps={{ shrink: true }} 
                                variant="outlined" helperText={inputErrors && inputErrors.city ? inputErrors.city : ''}
                                error={inputErrors && inputErrors.city ? true : false}
                                onChange={({ target }) => setState({ ...state, city: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.state')}
                                type="text"
                                autoComplete="state"
                                value={state.state}
                                InputLabelProps={{ shrink: true }} 
                                variant="outlined" helperText={inputErrors && inputErrors.state ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.state ? true : false}
                                onChange={({ target }) => setState({ ...state, state: target.value })}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link underline='none' to={`/suppliers`} component={RouterLink}>
                        <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                    </Link>
                    <Button size="large" variant="contained" className={styles.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
                </Box>
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle:{
        display:'flex', 
        alignItems:'center'
    }

}));


