import React from 'react';
import { Snackbar,Box,Backdrop,CircularProgress,Alert,AlertTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import useNotificationLoading from '../../helpers/useNotificationLoading';
  
const NotificationLoading = () => {
    const { alert, removeAlert, loading } = useNotificationLoading();
    const classes = useStyles();

    let anchorOrigin = { vertical: 'bottom', horizontal: 'right' };
    if(alert.position) {
        const newAnchorOrigin = _.split(alert.position, '-');
        anchorOrigin = { vertical: newAnchorOrigin[0], horizontal: newAnchorOrigin[1] };
    }

    return (
        <Box>
            {
                alert.open
                ?
                <Snackbar open={ !!alert.open } autoHideDuration={ alert.severity === 'error' ?  10000 : 3000 } onClose={ removeAlert } anchorOrigin={ anchorOrigin }>
                    <Alert elevation={ 6 } variant="filled" onClose={ removeAlert } severity={ alert.severity }>
                        <AlertTitle>{ alert.title }</AlertTitle>
                        { alert.message }
                    </Alert>
                </Snackbar>
                : null
            }
            {
                loading ?
                <Backdrop className={ classes.backdrop } open={ loading }>
                    <CircularProgress color="primary" />
                </Backdrop>
                : null
            }
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.modal + 1, // zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default NotificationLoading;