import { makeStyles, useTheme } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import _ from "lodash";

// MUI
import {
    Hidden, Paper, Table,
    TableBody,
    TableCell,
    TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FiArrowLeft } from "react-icons/fi";

import { getUrl } from "../../helpers/ApiAction";
import { currencyFormat } from "../../helpers/Tools";
import useNotificationLoading from "../../helpers/useNotificationLoading";

export default function DailySettlementDetails() {
	const [data, setData] = useState([]);
	const navigate = useNavigate();
	let { date } = useParams();
	const styles = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const { addAlert, setLoading } = useNotificationLoading();

	const isMountedRef = useRef();

	useEffect(() => {
		if (date) {
			setLoading(true);
			isMountedRef.current = true;

			getUrl(`daily-settlements/${date}`)
				.then((response) => {
					console.log("response", response);
					setLoading(false);
					const { status, data } = response;
					if (status && isMountedRef.current) {
						setData(data);
					}
				})
				.catch((error) => {
					setLoading(false);
					addAlert(
						"",
						error.message || t("error.contactSupport"),
						"error",
						""
					);
				});
		}
		return () => (isMountedRef.current = false);
	}, [date]);

	return (
		<div>
			<div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
				<FiArrowLeft
					style={{
						color: theme.palette.gray.main,
						fontSize: 16,
						marginRight: 10,
					}}
				/>
				<span
					onClick={() => navigate(-1)}
					style={{
						color: theme.palette.gray.main,
						fontSize: 16,
						textTransform: "uppercase",
						cursor: "pointer",
					}}
				>
					{t("button.back")}
				</span>
			</div>

			<div style={{ padding: "20px 0" }}>
				<div style={{ paddingTop: 30 }}>
					<ThemeProvider theme={tableStyle}>
						<TableContainer
							component={Paper}
							style={{
								marginTop: 15,
								boxShadow: "0 0 20px 0 #e0e0e0",
								borderRadius: 15,
							}}
						>
							<div style={{ padding: "25px 15px" }}>
								<div
									className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}
									style={{ width: "100%" }}
								>
									<div style={{ width: "100%" }}>
										<Typography
											style={{
												color: theme.palette.gray.dtext,
												fontSize: 28,
												textTransform: "uppercase",
											}}
										>
											<b>
												{t(
													"accounting.dailySalesReport"
												)}
											</b>
										</Typography>
										<Typography
											style={{
												color: theme.palette.gray.dtext,
											}}
										>
											SWISS HOUR SDN.BHD (153246-A)
										</Typography>
									</div>

									<Hidden smDown>
										<div
											style={{
												color: theme.palette.gray.ltext,
											}}
											className={styles.flexMiddle}
										>
											<div style={{ width: 77 }}>
												<Typography>{`${t(
													"accounting.date"
												)}:`}</Typography>
												<Typography>{`${t(
													"accounting.user"
												)}:`}</Typography>
											</div>
											<div
												style={{
													color: theme.palette.gray
														.dtext,
												}}
											>
												<Typography>
													{_.has(data, "data") &&
													_.has(
														data.data,
														"settlement_date"
													)
														? data.data
																.settlement_date
														: "-"}
												</Typography>
												<Typography>
													{_.has(data, "data") &&
													_.has(
														data.data,
														"creator"
													) &&
													_.has(
														data.data.creator,
														"name"
													)
														? data.data.creator.name
														: "-"}
												</Typography>
											</div>
										</div>
									</Hidden>
								</div>
								<Hidden smUp>
									<div
										style={{
											color: theme.palette.gray.ltext,
											paddingTop: 28,
										}}
										className={styles.flexMiddle}
									>
										<div style={{ width: 77 }}>
											<Typography>{`${t(
												"accounting.date"
											)}:`}</Typography>
											<Typography>{`${t(
												"accounting.user"
											)}:`}</Typography>
										</div>
										<div
											style={{
												color: theme.palette.gray.dtext,
											}}
										>
											<Typography>
												{_.has(data, "data") &&
												_.has(
													data.data,
													"settlement_date"
												)
													? data.data.settlement_date
													: "-"}
											</Typography>
											<Typography>
												{_.has(data, "data") &&
												_.has(data.data, "creator") &&
												_.has(data.data.creator, "name")
													? data.data.creator.name
													: "-"}
											</Typography>
										</div>
									</div>
								</Hidden>
							</div>
							<Table
								stickyHeader
								sx={{ minWidth: 650 }}
								size="small"
								aria-label="sticky table pagination table"
							>
								<TableHead>
									<TableRow>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
										>{`${t(
											"accounting.date"
										)}: `}</TableCell>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
										>{`${t(
											"accounting.description"
										)}: `}</TableCell>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
											align="right"
										>{`${t(
											"accounting.walletType"
										)}: `}</TableCell>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
											align="right"
										>{`${t(
											"accounting.cashOut"
										)}: `}</TableCell>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
											align="right"
										>{`${t(
											"accounting.cashIn"
										)}: `}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{_.has(data, "data") &&
										_.has(data.data, "drawers") &&
										_.map(data.data.drawers, (dataItem) => {
											if (
												dataItem.ref_type !==
												"daily-cashout"
											) {
												return (
													<TableRow key={dataItem.id}>
														<TableCell
															style={{
																width: 200,
															}}
															component="th"
															scope="row"
														>
															{
																dataItem.created_at
															}
														</TableCell>
														<TableCell>{`${t(
															`accounting.refTypeList.${dataItem.ref_type}`
														)} ${
															(Array.isArray(dataItem.remark) && dataItem.remark.length===0) ? (dataItem.order_no!==null? "(OrderNo-"+dataItem.order_no+": "+dataItem.product_name+")":"") : ""
														}. ${t(
															"accounting.remark"
														)}: ${
															dataItem.remark ??
															"-"
														}`}</TableCell>
														<TableCell align="right">
															{
																dataItem.wallet_display
															}
														</TableCell>
														<TableCell
															style={{
																color: "red",
															}}
															align="right"
														>
															{dataItem.type ===
															"out"
																? `-${currencyFormat(
																		parseFloat(
																			dataItem.amount
																		)
																  )}`
																: ""}
														</TableCell>
														<TableCell
															style={{
																color: "green",
															}}
															align="right"
														>
															{dataItem.type ===
															"in"
																? `+${currencyFormat(
																		parseFloat(
																			dataItem.amount
																		)
																  )}`
																: ""}
														</TableCell>
													</TableRow>
												);
											}
											return null;
										})}
									<TableRow>
										<TableCell colSpan={3} align="right">
											<b>{t("accounting.total")}</b>
										</TableCell>
										<TableCell
											style={{ color: "red" }}
											align="right"
										>
											{_.has(data, "summary") &&
											_.has(
												data.summary,
												"total_cash_out"
											)
												? currencyFormat(
														data.summary
															.total_cash_out
												  )
												: "-"}
										</TableCell>
										<TableCell
											style={{ color: "green" }}
											align="right"
										>
											{_.has(data, "summary") &&
											_.has(data.summary, "total_cash_in")
												? currencyFormat(
														data.summary
															.total_cash_in
												  )
												: "-"}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
							<Typography
								style={{
									padding: "20px 12px",
									textAlign: "right",
									color: theme.palette.gray.dtext,
									fontSize: 18,
								}}
							>
								<span
									style={{
										color: theme.palette.gray.ltext,
										paddingRight: 18,
									}}
								>{`${t("accounting.totalAmount")}: `}</span>
								{_.has(data, "summary") &&
								_.has(data.summary, "total_balance")
									? currencyFormat(data.summary.total_balance)
									: "-"}
							</Typography>
						</TableContainer>
					</ThemeProvider>
				</div>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	justifyCenter: {
		justifyContent: "center",
	},
	spaceBetween: {
		justifyContent: "space-between",
	},
	flexMiddle: {
		display: "flex",
		alignItems: "center",
		width: "100%",
	},
	flexTtop: {
		display: "flex",
		alignItems: "flex-start",
	},
}));

const tableStyle = createTheme({
	components: {
		// Name of the component
		MuiTableCell: {
			styleOverrides: {
				// Name of the slot
				root: {
					// Some CSS
					lineHeight: "2.5rem",
				},
			},
		},
	},
});
