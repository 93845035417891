import React, { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";

import { useNavigate } from "react-router-dom";

// MUI
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Typography, Link, OutlinedInput, Breadcrumbs } from "@mui/material";
import FormControl from "@mui/material/FormControl";

import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { getUrl } from "../../helpers/ApiAction";
import useNotificationLoading from "../../helpers/useNotificationLoading";

export default function WarrantyCheck() {
	const [searchText, setSearchText] = useState("");
	const [data, setData] = useState(null);
	const navigate = useNavigate();
	const styles = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const { addAlert } = useNotificationLoading();

	const searchWarranty = (e) => {
		if (e.key === "Enter" && searchText) {
			getUrl(`/orders?q=${searchText}`)
				.then((response) => {
					if (response.status) {
						setData(response.data.listing);
					} else {
						addAlert("", t("error.contactSupport"), "error", "");
					}
				})
				.catch((error) => {
					addAlert(
						"",
						error.message || t("error.contactSupport"),
						"error",
						""
					);
				});
		}
	};

	return (
		<div>
			<div
				style={{ paddingBottom: 30, justifyContent: "space-between" }}
				className={styles.flexMiddle}
			>
				<div
					className={styles.flexMiddle}
					style={{ paddingBottom: 30 }}
				>
					<FiArrowLeft
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							marginRight: 10,
						}}
					/>
					<span
						onClick={() => navigate(-1)}
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							textTransform: "uppercase",
							cursor: "pointer",
						}}
					>
						{t("button.back")}
					</span>
				</div>
				<div>
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/dashboard"
						>
							{t("title.dashboard")}
						</Link>
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/warranty"
						>
							{t("title.warranty")}
						</Link>
						<Typography color="text.primary">
							{t("warranty.checkWarranty")}
						</Typography>
					</Breadcrumbs>
				</div>
			</div>

			<div
				style={{
					width: "100%",
					maxWidth: "100%",
					margin: "20px 0 30px",
				}}
			>
				<Typography
					style={{
						fontSize: 24,
						color: theme.palette.gray.dtext,
						paddingBottom: 8,
						fontWeight: "bold",
					}}
				>{`${t("button.search")} ${t(
					"warranty.warrantyRecord"
				)}`}</Typography>
				<ThemeProvider theme={inputStyle}>
					<FormControl style={{ width: "100%", maxWidth: "100%" }}>
						<OutlinedInput
							placeholder={t("warranty.searchByOrder")}
							value={searchText}
							onChange={({ target }) =>
								setSearchText(target.value)
							}
							onKeyDown={searchWarranty}
						/>
					</FormControl>
				</ThemeProvider>
			</div>

			<div style={{ paddingTop: 15 }}>
				<div style={{ color: theme.palette.gray.dtext }}>
					<Typography
						style={{
							paddingBottom: 15,
							color: theme.palette.gray.ltext,
						}}
					>
						{t("general.resultFound", { total: _.size(data) })}
					</Typography>
				</div>

				{_.map(data, (order) => {
					return _.map(order.order_details, (orderDetail) => (
						<ResultProduct
							key={orderDetail.id}
							data={orderDetail}
						/>
					));
				})}
			</div>
		</div>
	);
}

const ResultProduct = memo(({ data }) => {
	const { product } = data;

	const styles = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<div
			className={`head ${styles.flexMiddle} ${styles.spaceBetween} ${styles.ShadowBox}`}
			style={{ marginBottom: 20 }}
		>
			<div className={styles.flexMiddle}>
				<div>
					<img
						src={
							data
								? _.size(data.product.images)
									? data.product.images[0]["file_name"]
									: "/images/emptyStatus/noproductimg.jpg"
								: null
						}
						style={{
							width: 120,
							height: 120,
							objectFit: "contain",
							borderRadius: 8,
							boxShadow: "2px 2px 6px 0 #cccccc",
						}}
						alt={data.product_name ?? "-"}
					/>
				</div>
				<div
					style={{
						padding: "0 20px",
						color: theme.palette.gray.dtext,
					}}
				>
					<Typography
						className={`head ${styles.productTitle} ${styles.textLine2}`}
					>
						{data.product_name ?? "-"}
					</Typography>
					<Typography>{`${t("warranty.serialNo")}: ${
						product.serial_no ?? "-"
					}`}</Typography>
					<Typography>{`${t("warranty.dateOfPurchase")}: ${
						data.created_at ?? "-"
					}`}</Typography>
					<Typography>{`${t("warranty.endDate")}: ${
						data.warranty_end_date ?? "-"
					}`}</Typography>
				</div>
			</div>
			{data.warranty_expired ? (
				<Typography
					style={{
						color: theme.palette.gray.main,
						fontSize: 16,
						textTransform: "uppercase",
					}}
				>
					{t("warranty.warrantyExpired")}
				</Typography>
			) : (
				<Link
					underline="none"
					component={RouterLink}
					to={`/warranty-report-add/${data.id}`}
				>
					<div className={styles.flexMiddle}>
						<Typography
							style={{
								color: theme.palette.gray.main,
								fontSize: 16,
								textTransform: "uppercase",
							}}
						>
							{t("warranty.makeServices")}
						</Typography>
						<FiArrowRight
							style={{
								color: theme.palette.gray.main,
								fontSize: 16,
								marginLeft: 10,
							}}
						/>
					</div>
				</Link>
			)}
		</div>
	);
});

const useStyles = makeStyles((theme) => ({
	productTitle: {
		color: theme.palette.gray.dtext,
		fontSize: "18px!important",
		paddingTop: 10,
		fontWeight: "bold!important",
	},
	ShadowBox: {
		[theme.breakpoints.down("sm")]: {
			borderTopRightRadius: 18,
			padding: 0,
		},
		[theme.breakpoints.up("sm")]: {
			borderTopRightRadius: 175,
			padding: "20px 50px 20px 20px",
			boxShadow: "4px 4px 7px 0 #00000033, -4px -4px 1px 0 #00000005",
			borderRadius: 18,
		},
	},
	spaceBetween: {
		justifyContent: "space-between",
	},
	justifyCenter: {
		justifyContent: "center",
	},
	flexMiddle: {
		display: "flex",
		alignItems: "center",
	},
	textLine2: {
		display: "-webkit-box",
		maxWidth: "100%",
		WebkitLineClamp: 2,
		WebkitBoxOrient: "vertical",
		overflow: "hidden",
	},
}));

const inputStyle = createTheme({
	components: {
		// Name of the component
		MuiOutlinedInput: {
			styleOverrides: {
				// Name of the slot
				input: {
					// Some CSS
					padding: "28.5px 14px",
					boxSizing: "inherit",
					borderRadius: 48,
				},
				root: {
					borderRadius: 48,
					boxShadow: "3px 3px 8px 0 #ccc",
				},
			},
		},
	},
});
