import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import PaymentModal from './PaymentModal';
import PaymentListing from './PaymentListing';

// MUI
import { Box, Typography, Modal, IconButton, Button } from '@mui/material';

// ICONS
import { FiFileText } from "react-icons/fi";

// MODAL
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    p: 4,
    overflow: 'auto',
    maxHeight: '90%',
    display: 'flex',
    flexDirection: 'column'
};

export default function InstalmentDetail({
    order, setOrder, payment, tradeInList, paymentStatusList, paymentMethodList, orderPaymentList, setOrderPaymentList,
    inputErrors, setInputErrors, FPXRedirect
}) {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [totalPayment, setTotalPayment] = useState(0);
    const [selectedPaymentList, setSelectedPaymentList] = useState([]);
    const [instalmentDetailOpen, setInstalmentDetailOpen] = useState(false);
    const [paymentModal, setPaymentModal] = useState({
        open: false,
        key: '',
        payment: {},
        payment_info: {}
    });
    const [paymentApproveModal, setPaymentApproveModal] = useState({
        open: false,
        key: '',
        payment: {},
        payment_info: {}
    });

    useEffect(() => {
        setSelectedPaymentList(payment.instalment_details);
        updateTotalPayment(payment.instalment_details);
        // eslint-disable-next-line
    }, [payment]);

    const updateTotalPayment = (listing) => {
        let total = 0;
        _.map(listing, payment => {
            if (payment.status !== 60) {
                total += parseFloat(payment.amount);
            }
        })
        setTotalPayment(parseFloat(total).toFixed(2));
    }

    // ADD/EDIT/DELETE PAYMENT
    const handlePaymentSelect = (code) => {
        let paymentInfo = _.find(paymentMethodList, function (c) { return c.code === code; });
        let tempDetails = {};
        if (paymentInfo.details) {
            _.map(paymentInfo.details, detail => {
                tempDetails[detail.code] = '';
            })
        }
        setPaymentModal({
            open: true,
            key: '',
            payment: { id: 0, payment_method: paymentInfo.code, amount: 0, payment_details: tempDetails, status: 0, remark: '' },
            payment_info: paymentInfo
        });
    }
    const handlePaymentModalOpen = (key, payment) => {
        let paymentInfo = _.find(paymentMethodList, function (c) { return c.code === payment.payment_method; });
        setPaymentModal({
            open: true,
            key: key,
            payment: payment,
            payment_info: paymentInfo
        });
    }

    // APPROVE/REJECT PAYMENT
    const handlePaymentApproveModalOpen = (key, payment) => {
        let paymentInfo = _.find(paymentMethodList, function (c) { return c.code === payment.payment_method; });
        setPaymentApproveModal({
            open: true,
            key: key,
            payment: payment,
            payment_info: paymentInfo
        });
    }

    return (
        <div>
            <div className={styles.flexMiddle}>
                <Typography style={{ color: theme.palette.gray.dtext, fontSize: 14 }}>{t('order.instalmentDetails')}</Typography>
                <div style={{ paddingLeft: 15 }}>
                    <IconButton onClick={() => setInstalmentDetailOpen(true)}>
                        <FiFileText style={{ fontSize: 18, color: theme.palette.secondary.main }} />
                    </IconButton>
                </div>
            </div>

            {/* INSTALMENT DETAIL MODAL */}
            <Modal
                open={instalmentDetailOpen}
                onClose={() => setInstalmentDetailOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <PaymentListing
                        type='instalment-details' pendingStatus={payment.status} totalAmount={payment.amount}
                        paymentMethodList={paymentMethodList} selectedPaymentList={selectedPaymentList} totalPayment={totalPayment}
                        FPXRedirect={FPXRedirect} handlePaymentSelect={handlePaymentSelect} handlePaymentModalOpen={handlePaymentModalOpen} handlePaymentApproveModalOpen={handlePaymentApproveModalOpen}
                    />
                    <div style={{ textAlign: 'center' }}>
                        <Button style={{ width: '50%', background: theme.palette.secondary.main, textTransform: 'uppercase', color: '#fff', textAlign: 'center', padding: '8px 15px', borderRadius: 8 }} onClick={() => setInstalmentDetailOpen(false)}>
                            <Typography>{t('button.close')}</Typography>
                        </Button>
                    </div>
                </Box>
            </Modal>

            <PaymentModal
                type="instalment-details"
                order={order} setOrder={setOrder} tradeInList={tradeInList} paymentStatusList={paymentStatusList}
                paymentModal={paymentModal} setPaymentModal={setPaymentModal} paymentApproveModal={paymentApproveModal} setPaymentApproveModal={setPaymentApproveModal} inputErrors={inputErrors} setInputErrors={setInputErrors}
                updateTotalPayment={updateTotalPayment} selectedPaymentList={selectedPaymentList} setSelectedPaymentList={setSelectedPaymentList}
                paymentID={payment.id} orderPaymentList={orderPaymentList} setOrderPaymentList={setOrderPaymentList}
            />
        </div >
    )
}

const useStyles = makeStyles(theme => ({
    spaceBetween: {
        justifyContent: 'space-between'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    flexBottom: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    flexTtopMiddle: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    titleText: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold!important',
        fontSize: '21px!important'
    },
    columnReverse: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    checkPriceButton: {
        backgroundColor: theme.palette.secondary.main,
        color: '#fff',
        height: 48,
        marginTop: -25,
        borderRadius: '11px 0 0 11px',
        position: 'absolute',
        right: 0
    },
    textLine2: {
        display: '-webkit-box',
        maxWidth: '100%',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    },
    addressAlign: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
        },
    },
    statusBar: {
        width: 450,
        maxWidth: '80%',
        margin: '-31px auto 15px',
        padding: '12px 15px',
        borderRadius: 13,
        boxShadow: '1px 6px 12px 0 #0000002e, inset 0 3px 0px 0 #ffffff29, inset 0px -3px 0px 0 #ffffff17',
    },
}));