import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// MUI
import { Typography, Box, Link  } from '@mui/material';

import { FiArrowLeft } from "react-icons/fi";


export default function Dashboard() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const { permissions } = useSelector(state => state.general);
    const navigate = useNavigate();
    return(
        <div>
            
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <span onClick={() => navigate(-1)} style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase',
cursor:'pointer' }}>{t('button.back')}</span>
                </div>
          

            <div className={styles.flexCenterMiddle} style={{display:'flex', justifyContent:'center', alignItems:'center', height:'70vh'}}>
                <div className={styles.flexMiddle}>
                    {
                        permissions.includes('tradein-create') && 
                        <Link underline="none" component={RouterLink} to={`/tradein-add`}>
                            <Box className={styles.menuStyle} style={{ marginRight: 30 }}>
                                <Typography>{t('product.tradeInProduct')}</Typography>
                            </Box>
                        </Link>
                    }
                    {
                        permissions.includes('product-create') && 
                        <Link underline='none' to={`/product-add`} component={RouterLink}>
                            <Box className={styles.menuStyle} style={{ marginRight: 30 }}>
                                <Typography>{t('product.newProduct')}</Typography>
                            </Box>
                        </Link>
                    }
                    {
                        permissions.includes('consignment-create') &&
                        <Link underline='none' to={`/consignment-add`} component={RouterLink}>
                            <Box className={styles.menuStyle}>
                                <Typography>{t('product.consignmentProduct')}</Typography>
                            </Box>
                        </Link>
                    }
                </div>
            </div>
        </div>
        
    );
}

const useStyles = makeStyles(theme => ({
    menuStyle: {
        background: '#fff',
        borderRadius: 15,
        boxShadow: '2px 2px 10px 5px rgb(209 209 209 / 30%)',
        padding: 10,
        width:250,
        height:250,
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    flexCenterMiddle:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    flexMiddle:{
        display:'flex',
        alignItems:'center',
    },
  }));


