import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl, postUrl } from '../../helpers/ApiAction';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import PropTypes from "prop-types";

// MUI
import { Box, Button, Typography, Modal, Grid, FormControl, MenuItem, Select, TextField,Tooltip } from '@mui/material';

// ICONS
import { FiPlus, FiMinus, FiEdit2,FiAlertCircle } from "react-icons/fi";

// MODAL
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '80%',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
};


const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    
    return (
        <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value,
                },
            });
        }}
        thousandSeparator
        isNumericString
        />
        );
    });
    
    NumberFormatCustom.propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    };
    
    
    export default function Step3Payment({ handleBack, paymentMethodList, cartList, sumTotal, selectedPaymentList, setSelectedPaymentList, totalPayment, setTotalPayment, customerId, description, setDescription }) {
        
        const styles = useStyles();
        const theme = useTheme();
        const { t, i18n } = useTranslation();
        const { addAlert, setLoading } = useNotificationLoading();
        const [paymentModal, setPaymentModal] = useState({
            open: false,
            type: '',
            key: '',
            payment: {},
            payment_info: {}
        });
        const [inputErrors, setInputErrors] = useState({});
        const [tradeInList, setTradeInList] = useState({});
        const [saveCreditNote , setSaveCreditNote] =useState(true);
        const [creditNoteId , setCreditNoteId] =useState(true);
        let navigate = useNavigate();
        
        useEffect(() => {
            setLoading(true);
            getUrl(`/trade-ins`).then(response => {
                setLoading(false);
                if (response.status) {
                    setTradeInList(response.data.listing);
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
            // eslint-disable-next-line
            
            getUrl('/credit-notes', { status: 10 , customer_id : customerId}).then(response => {
                setLoading(false);
                if (response.status) {
                    setCreditNoteId(response.data.listing);
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
            // eslint-disable-next-line
        }, []);
        
        const handlePaymentSelect = (code) => {
            let paymentInfo = _.find(paymentMethodList, function (c) { return c.code === code; });
            let tempList = selectedPaymentList;
            let tempDetails = {};
            if (paymentInfo.details) {
                _.map(paymentInfo.details, detail => {
                    tempDetails[detail.code] = '';
                })
            }
            tempList = tempList.concat({ code: paymentInfo.code, amount: 0, details: tempDetails });
            setSelectedPaymentList(tempList);
            setPaymentModal({
                open: true,
                type: 'add',
                key: _.size(tempList)-1,
                payment: { code: paymentInfo.code, amount: 0, details: tempDetails },
                payment_info: paymentInfo
            });
        }
        const handlePaymentModalOpen = (key, payment) => {
            let paymentInfo = _.find(paymentMethodList, function (c) { return c.code === payment.code; });
            setPaymentModal({
                open: true,
                type: 'edit',
                key: key,
                payment: payment,
                payment_info: paymentInfo
            });
        }
        const handlePaymentModalClose = () => {
            setPaymentModal({
                open: false,
                type: '',
                key: '',
                payment: {},
                payment_info: {}
            });
            setSaveCreditNote(true);
        }
        const handleAmountChange = (type, amount) => {
            if (type === 'amount') {
                setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, amount: parseFloat(amount) } });
                
            } else if (type === 'add') {
                setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, amount: parseFloat(parseFloat(paymentModal.payment.amount) + parseFloat(amount)).toFixed(2) } });
            } else if (type === 'deduct') {
                setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, amount: parseFloat(parseFloat(paymentModal.payment.amount) - parseFloat(amount)).toFixed(2) } });
            }
        }
        const updatePaymentMethod = () => {
            let temp = selectedPaymentList;
            let tempPayment = paymentModal.payment;
            let valid = true;
            //valdiate credit note 
            if (paymentModal.payment?.code === "credit-note") {
                valid = validateCreditNote();
            }
            if(valid){
                tempPayment.amount = tempPayment.amount ? parseFloat(tempPayment.amount).toFixed(2) : 0.00;
                temp[paymentModal.key] = tempPayment;
                setSelectedPaymentList(temp);
                handlePaymentModalClose();
                updateTotalPayment(temp);
            }
        }
        const deletePaymentMethod = () => {
            let temp = selectedPaymentList;
            temp = _.filter(temp, (data, key) => { return key !== paymentModal.key; })
            setSelectedPaymentList(temp);
            handlePaymentModalClose();
            updateTotalPayment(temp);
        }
        const checkPaymentValues = (type) => {
            // if all field no value, remove it 
            
            let remove = true;
            if(parseFloat(paymentModal.payment.amount) > 0){
                remove = false;
            }else if(_.size(paymentModal.payment.details)){
                _.map(paymentModal.payment.details, (data, code) => {
                    if(data){
                        remove = false;
                    }
                })
            }
            if(remove){
                deletePaymentMethod();
            }else{
                if(type === 'save'){
                    updatePaymentMethod();
                }else{
                    if(paymentModal.type === 'add'){
                        deletePaymentMethod();
                    }else{
                        handlePaymentModalClose();
                    }
                }
            }
        }
        const updateTotalPayment = (listing) => {
            let total = 0;
            _.map(listing, payment => {
                total += parseFloat(payment.amount);
            })
            setTotalPayment(parseFloat(total).toFixed(2));
        }
        
        function buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
                Object.keys(data).forEach(key => {
                    buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
                });
            } else {
                const value = data == null ? '' : data;
                
                formData.append(parentKey, value);
            }
        }
        
        const addOrder = () => {
            setLoading(true);
            setInputErrors({});
            let cart_array = [];
            _.map(cartList, cartItem => {
                if (parseInt(cartItem.status) === 1) {
                    cart_array = cart_array.concat(cartItem.id);
                }
            });
            let postData = {
                cart_id: cart_array,
                customer_id: customerId,
                payment: selectedPaymentList,
                description: description,
            };
            
            const formData = new FormData();
            buildFormData(formData, postData);
            
            postUrl('/orders', formData).then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert('', response.message, 'success', '');
                    navigate(`/orders/${response.data.id}`);
                    // if (response.payment_gateway) {
                    //     // redirect to payment_gateway
                    // } else {
                    //     // redirect to orderDetails page
                    // }
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        
        //update credit note amount
        // useEffect(() => {
        //     if (paymentModal.payment && paymentModal.payment?.code === "credit-note" && paymentModal.payment?.details?.credit_note_id > 0) {
        //         getUrl(`/credit-notes/${paymentModal.payment.details.credit_note_id}`).then(response => {
        //             setLoading(false);
        //             if (response.status) {
        //                 if (response.data.credit_note.status === 10 && response.data.credit_note.customer_id === customerId && response.data.credit_note.type === 'available') {
        //                     setSaveCreditNote(true);
        //                     handleAmountChange('amount', response.data.credit_note.amount);
        //                 } else {
        //                     setSaveCreditNote(false);
        //                     addAlert('', t('error.creditNoteStatusError'), 'error', '');
        //                 }
        //             }
        //         }).catch(error => {
        //             setLoading(false);
        //             setSaveCreditNote(false);
        //             addAlert('', error.message || t('error.contactSupport'), 'error', '');
        //         });
        //     }
        //     // eslint-disable-next-line
        // }, [paymentModal.payment?.details?.credit_note_id]);
        
        const validateCreditNote = () => {
            let credit_note_list = selectedPaymentList.filter(list => list.code === "credit-note");
            let selectedCreditNote = _.find(credit_note_list, function (c) { return c.details.credit_note_id === paymentModal.payment?.details?.credit_note_id; });
            if (selectedCreditNote?.details?.credit_note_id) {
                setLoading(false);
                setSaveCreditNote(false);
                addAlert('', t('error.creditNoteDuplicateError'), 'error', '');
                return false;
            }
            return true;
        }
        
        return (
            <Box sx={{ width: '100%' }}>
            <div style={{ padding: 20 }}>
            {
                _.size(inputErrors.general) > 0
                ?
                <div style={{ padding: '0 25px' }}>
                {
                    _.map(inputErrors.general, (err, key) => {
                        return (
                            <Typography key={key} style={{ color: theme.palette.red.main }}>{err}</Typography>
                            )
                        })
                    }
                    </div>
                    : null
                }
                <Grid container className={styles.columnReverse}>
                <Grid item xs={12} sm={6} md={8} style={{ padding: '0 25px', marginBottom: 25 }}>
                {/* PAYMENT */}
                <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{ paddingBottom: 15 }}>
                <Typography className={styles.titleText}>{t('pos.payment')}</Typography>
                <FormControl >
                <Select
                IconComponent={FiPlus}
                style={{ width: 30, height: 30, borderRadius: 5, color: theme.palette.button.main }}
                className={`head ${styles.flexMiddle} ${styles.center}`}
                onChange={(event) => handlePaymentSelect(event.target.value)}
                value=''
                >
                {
                    _.map(paymentMethodList, payment => {
                        if (payment.available) {
                            return (
                                <MenuItem key={payment.code} value={payment.code}>{payment[i18n.language]}</MenuItem>
                                )
                            }
                        })
                    }
                    </Select>
                    </FormControl>
                    </div>
                    {/* SELECTED PAYMENT METHOD */}
                    {
                        _.map(selectedPaymentList, (payment, key) => {
                            let display_name = (_.find(paymentMethodList, function (c) { return c.code === payment.code; }))[i18n.language];
                            return (
                                <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{ width: '100%' }} key={key}>
                                <div className={styles.flexMiddle} style={{ paddingBottom: 15 }}>
                                <Button onClick={() => handlePaymentModalOpen(key, payment)}>
                                <FiEdit2 style={{ fontSize: 23, marginRight: 15, color: theme.palette.button.main }} />
                                </Button>
                                <Typography style={{ fontSize: 18, color: theme.palette.gray.dtext, paddingRight: 15 }}>
                                {display_name}
                                {
                                    _.size(payment.details)
                                    ?
                                    <span style={{ color: theme.palette.gray.ltext, fontSize: 15, }}>
                                    <i>
                                    {
                                        _.map(payment.details, (data, code) => {
                                            return (' ' + (code === "attachment" ? (data ? data.name : "") : data));
                                        })
                                    }
                                    </i>
                                    </span>
                                    : null
                                }
                                {
                                    _.size(inputErrors.payment_errors)> 0 && inputErrors.payment_errors[key] && <Tooltip placement="right" title={
                                        _.map(inputErrors.payment_errors[key],(error,key)=>{
                                            return <div key={key}>{error}</div>
                                        })
                                    }><Button disableRipple sx={{':hover': {backgroundColor: 'white'},}}><FiAlertCircle style={{color:'red',paddingLeft:'3px',fontSize: 23}} /></Button></Tooltip>
                                }
                                </Typography>
                                </div>
                                <Typography style={{ fontSize: 16, color: theme.palette.gray.dtext, textAlign: 'right', minWidth: 130 }}><NumberFormat thousandSeparator={true} prefix="RM " value={payment.amount} displayType="text" /></Typography>
                                </div>
                                )
                            })
                        }
                        {/* TOTAL & BALANCE */}
                        <div style={{ width: '100%', maxWidth: '100%', borderTop: '2px solid #b9b9b9', borderBottom: '2px solid #b9b9b9', padding: '5px 0', marginTop: 25 }}>
                        <div style={{ borderBottom: '2px solid #b9b9b9', padding: '5px 0', color: theme.palette.gray.dtext }} className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                        <Typography>
                        {t('pos.totalPaymentAmount')}
                        </Typography>
                        <Typography style={{ textAlign: 'right', fontSize: 18 }}>
                        <NumberFormat thousandSeparator={true} prefix="RM " value={totalPayment} displayType="text" />
                        </Typography>
                        </div>
                        </div>
                        <div style={{ paddingTop: 12 }}>
                        <Typography style={{ textAlign: 'right', color: '#ce4653' }}>{t('pos.balancePaymentAmount') + ': '}<NumberFormat thousandSeparator={true} value={parseFloat(sumTotal - totalPayment).toFixed(2)} displayType="text" /></Typography>
                        </div>
                        {/* DESCRIPTION */}
                        <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{ paddingBottom: 15, paddingTop: 12 }}>
                        <Typography className={styles.titleText}>{t('pos.description')}</Typography>
                        </div>
                        <div>
                        <TextField
                        fullWidth
                        id="standard-basic"
                        type="text"
                        variant="outlined"
                        size="small"
                        placeholder={t('pos.description')}
                        value={description}
                        onChange={({ target }) => setDescription(target.value)}
                        />
                        </div>
                        {/* BUTTON */}
                        <div className={`head ${styles.flexMiddle} ${styles.center}`} style={{ paddingTop: 25 }} >
                        <Button style={{ padding: '8px 15px', width: 180, marginRight: 15, maxWidth: '100%', borderRadius: 48, border: '2px solid #cdac89', boxShadow: '2px 2px 20px 0 #efefef', color: theme.palette.secondary.main, fontSize: 18, textAlign: 'center', textTransform: 'uppercase' }} onClick={handleBack}>{t('button.backStep')}</Button>
                        <Button style={{ padding: '8px 15px', width: 180, maxWidth: '100%', borderRadius: 48, background: theme.palette.secondary.main, boxShadow: '2px 2px 20px 0 #efefef', color: '#fff', fontSize: 18, textAlign: 'center', textTransform: 'uppercase' }} onClick={addOrder}>{t('pos.checkout')}</Button>
                        </div>
                        </Grid>
                        {/* ORDER LIST */}
                        <Grid item xs={12} sm={6} md={4} style={{ padding: '0 15px', marginBottom: 25 }}>
                        <div style={{ boxShadow: '0 0 18px 0 #d0d0d0', borderRadius: 15, padding: 15, height: '100%' }}>
                        <div>
                        <Typography className={styles.titleText}>{t('menu.listing')}</Typography>
                        <Typography style={{ color: theme.palette.gray.ltext, fontSize: 15 }}>{t('menu.totalItems', { total: _.size(_.filter(cartList, function (c) { return (parseInt(c.status) === 1); })) })}</Typography>
                        </div>
                        {
                            _.map(cartList, cartItem => {
                                if (parseInt(cartItem.status) === 1) {
                                    return (
                                        <div style={{ padding: '25px 0', borderBottom: '2px dashed #eaeaea' }} className={styles.flexMiddle} key={cartItem.id}>
                                        <img src={_.size(cartItem.product.images) ? cartItem.product.images[0].file_name : "/images/emptyStatus/noproductimg.jpg"} style={{ width: 80, maxWidth: '100%', borderRadius: 8, boxShadow: '#0000002e 2px 2px 6px 0px' }} alt="product_image" />
                                        <div style={{ paddingLeft: 15 }}>
                                        <Typography className={styles.textLine2} style={{ color: theme.palette.secondary.secondary, lineHeight: 1.2 }}>{cartItem.product.name}</Typography>
                                        <Typography style={{ color: theme.palette.gray.main, paddingTop: 10, fontSize: 14 }}><NumberFormat thousandSeparator={true} prefix="RM " value={cartItem.sell_price} displayType="text" /></Typography>
                                        </div>
                                        </div>
                                        )
                                    }
                                })
                            }
                            </div>
                            </Grid>
                            </Grid>
                            </div>
                            {/* EDIT/DELETE PAYMENT MODAL */}
                            <Modal
                            open={paymentModal.open}
                            onClose={() => checkPaymentValues('cancel')}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            >
                            <Box sx={modalStyle}>
                            <div style={{ textAlign: 'center', color: theme.palette.gray.ltext }}>
                            {/* TITLE & DELETE */}
                            <div className={`${styles.flexMiddle} ${styles.spaceBetween}`}>
                            <Typography><b>{t('pos.paymentMethod')}</b></Typography>
                            <Button style={{ color: theme.palette.secondary.main }} onClick={deletePaymentMethod}>{t('button.delete')}</Button>
                            </div>
                            {/* AMOUNT */}
                            <Typography style={{ color: theme.palette.gray.dtext, paddingTop: 25 }}>{paymentModal.payment_info[i18n.language] + ' ' + t('menu.amount') + ' (RM)'}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ backgroundColor: theme.palette.button.main, width: 30, height: 30, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '2px 2px 6px 0 #8f72547a', pointerEvents:paymentModal.payment.code === "credit-note" ? "none":"auto" }} onClick={() => handleAmountChange('deduct', 100)} >
                            <FiMinus style={{ fontSize: 20, color: '#fff' }} />
                            </div>
                            <TextField
                            sx={{ input: { color: theme.palette.button.main, fontSize: 25, fontWeight: "bold", textAlign: "center", } }}
                            variant="standard"
                            // type="number"
                            value={paymentModal.payment.amount}
                            InputProps={{ 
                                disableUnderline: true,
                                inputComponent: NumberFormatCustom, 
                            }}
                            onChange={(event) => handleAmountChange('amount', event.target.value)}
                            name="amount"
                            disabled={paymentModal.payment.code === "credit-note"}
                            // disabled={paymentModal.payment?.payment_method === "credit-note"}
                            />
                            <div style={{ backgroundColor: theme.palette.button.main, width: 30, height: 30, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '2px 2px 6px 0 #8f72547a',pointerEvents:paymentModal.payment.code === "credit-note" ? "none":"auto" }} onClick={() => handleAmountChange('add', 100)} >
                            <FiPlus style={{ fontSize: 20, color: '#fff' }} />
                            </div>
                            </div>
                            {/* DETAILS */}
                            {
                                _.size(paymentModal.payment.details) > 0
                                ?
                                <Grid container style={{ paddingTop: 20 }}>
                                <Grid item xs={12} style={{ borderTop: "2px #8f72547a dashed", padding: 10 }}></Grid>
                                {
                                    _.map(paymentModal.payment.details, (data, code) => {
                                        let displayName = (_.find(paymentModal.payment_info.details, function (p) { return p.code === code; }))[i18n.language];
                                        return (
                                            <Grid item xs={12} key={code} style={{ margin: 10 }}>
                                            {
                                                paymentModal.payment.code === 'usdt' && code === 'type'
                                                ?
                                                <TextField
                                                fullWidth
                                                id="standard-basic"
                                                select
                                                variant="outlined"
                                                size="small"
                                                label={displayName}
                                                value={data}
                                                onChange={({ target }) => setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, details: { ...paymentModal.payment.details, [code]: target.value } } })}
                                                >
                                                {
                                                    _.map(paymentModal.payment_info.type, usdt_type => {
                                                        return (
                                                            <MenuItem key={usdt_type} value={usdt_type}>
                                                            {usdt_type}
                                                            </MenuItem>
                                                            )
                                                        })
                                                    }
                                                    </TextField>
                                                    :
                                                    paymentModal.payment.code === 'trade-in' && code === 'trade-in-id'
                                                    ?
                                                    <TextField
                                                    fullWidth
                                                    id="standard-basic"
                                                    select
                                                    variant="outlined"
                                                    size="small"
                                                    label={displayName}
                                                    value={data}
                                                    onChange={({ target }) => setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, details: { ...paymentModal.payment.details, [code]: target.value }, amount: (_.find(tradeInList, function (t) { return t.id === target.value; })).trade_price } })}
                                                    >
                                                    {
                                                        _.map(tradeInList, trade => {
                                                            return (
                                                                <MenuItem key={trade.id} value={trade.id}>
                                                                {'[' + trade.id + '] ' + trade.customer.name + ' - ' + trade.product.name}
                                                                </MenuItem>
                                                                )
                                                            })
                                                        }
                                                        </TextField>
                                                        :
                                                        code === 'attachment'
                                                        ?
                                                        <TextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        type="file"
                                                        variant="outlined"
                                                        size="small"
                                                        label={displayName}
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ accept:"image/*,application/pdf" }}
                                                        onChange={({ target }) => setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, details: { ...paymentModal.payment.details, [code]: target.files[0] } } })}
                                                        />
                                                        : code === 'credit_note_id'
                                                        ?
                                                        
                                                        ( _.size(creditNoteId) > 0
                                                        ?
                                                        <TextField
                                                        fullWidth
                                                        select
                                                        InputLabelProps={{ shrink: true }}
                                                        SelectProps={{ native: true }}
                                                        id="standard-basic"
                                                        
                                                        variant="outlined"
                                                        size="small"
                                                        label={displayName}
                                                        // value={data}
                                                        onChange={(event) => {setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, amount: parseFloat(event.target.selectedOptions[0].getAttribute('data-amount')), details: { ...paymentModal.payment.details, [code]: event.target.value } } });
                                                    }}
                                                    >
                                                    <option key={0} value={0} data-amount={0}>
                                                    
                                                    {t('pos.defaultSelect')}
                                                    
                                                    
                                                    </option>
                                                    {creditNoteId.map((option,index) => (
                                                        <option key={index} value={option.id} data-amount={option.amount}>
                                                        
                                                        {t('pos.creditNoteId')}:{option.id} &nbsp;
                                                        {t('pos.customerName')}:{option.customer.name}
                                                        &nbsp; &nbsp;{t('pos.totalAmount')}:
                                                        {parseFloat(option.amount).toFixed(2)}
                                                        
                                                        
                                                        </option>
                                                        ))}
                                                        </TextField>
                                                        :  <Typography><b>{t('pos.unavailableMsg')}</b></Typography>
                                                        ) : 
                                                        <TextField
                                                        fullWidth
                                                        
                                                        InputLabelProps={{ shrink: true }}
                                                        
                                                        id="standard-basic"
                                                        
                                                        variant="outlined"
                                                        size="small"
                                                        label={displayName}
                                                        value={data}
                                                        onChange={({ target }) => setPaymentModal({ ...paymentModal, payment: { ...paymentModal.payment, details: { ...paymentModal.payment.details, [code]: target.value } } })}
                                                        />        
                                                    }
                                                    </Grid>
                                                    )
                                                })
                                            }
                                            </Grid>
                                            : null
                                        }
                                        {/* BUTTON */}
                                        <div style={{ paddingTop: 25, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                        <Button style={{ padding: '8px 15px', width: 180, marginRight: 15, maxWidth: '100%', borderRadius: 48, border: theme.palette.button.borderMain, boxShadow: '2px 2px 20px 0 #efefef', color: theme.palette.button.main, fontSize: 18, textAlign: 'center', textTransform: 'uppercase' }} onClick={() => checkPaymentValues('cancel')}>{t('button.cancel')}</Button>
                                        <Button style={{ padding: '8px 15px', width: 180, maxWidth: '100%', borderRadius: 48, background: theme.palette.button.main, boxShadow: '2px 2px 20px 0 #efefef', color: '#fff', fontSize: 18, textAlign: 'center', textTransform: 'uppercase',pointerEvents:saveCreditNote === false ? "none":"auto" }} onClick={() => checkPaymentValues('save')}>{t('button.save')}</Button>
                                        </div>
                                        </div>
                                        </Box>
                                        </Modal>
                                        </Box>
                                        )
                                    }
                                    
                                    const useStyles = makeStyles(theme => ({
                                        spaceBetween: {
                                            justifyContent: 'space-between'
                                        },
                                        center: {
                                            justifyContent: 'center'
                                        },
                                        flexMiddle: {
                                            display: 'flex',
                                            alignItems: 'center',
                                        },
                                        titleText: {
                                            color: theme.palette.secondary.main,
                                            fontWeight: 'bold!important',
                                            fontSize: '21px!important'
                                        },
                                        columnReverse: {
                                            [theme.breakpoints.down('sm')]: {
                                                flexDirection: 'column-reverse',
                                            },
                                            [theme.breakpoints.up('sm')]: {
                                                flexDirection: 'row',
                                            },
                                        },
                                        textLine2: {
                                            display: '-webkit-box',
                                            maxWidth: '100%',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                        },
                                    }));