import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';


// MUI
import { Grid, Typography, Box, Link,Breadcrumbs } from '@mui/material';

import { FiArrowLeft } from "react-icons/fi";

export default function Dashboard() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const { permissions } = useSelector(state => state.general);

    return (
        <div>
            
 <div style={{ paddingBottom: 30, justifyContent:'space-between' }} className={styles.flexMiddle}>
            <Link underline='none' component={RouterLink} to={`/dashboard`}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.back')}</Typography>
                </div>
            </Link>

            <div>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t('title.setting')}</Typography>
                </Breadcrumbs>
            </div>

            </div>

            <div className={styles.flexCenterMiddle} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:'100%', minHeight:'calc(100vh - 250px)', maxHeight:'100%' }}>
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                    {/* {
                        _.includes(permissions, 'role') &&
                        <Grid item xs={12} sm={6} md={3} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                            <Link underline="none" component={RouterLink} to={`/roles`}> */}
                                {/* <Box className={styles.menuStyle} style={{ backgroundColor: '#5e4536', color: '#fff' }}>
                                    <Typography>{t('title.roles')}</Typography>
                                </Box> */}
                                    {/* <Box className={styles.menuStyle} >
                                        <img src="/images/icons/roles.png" className={styles.menuImg} alt="roles"/>
                                        <Typography>{t('title.roles')}</Typography>
                                    </Box>

                            </Link>
                        </Grid>
                    } */}
                    {/* {
                        _.includes(permissions, 'permission') &&
                        <Grid item xs={12} sm={6} md={3} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                            <Link underline='none' to={`/permissions`} component={RouterLink}> */}
                                {/* <Box className={styles.menuStyle} style={{ backgroundColor: '#c4a283', color: '#fff' }}>
                                    <Typography>{t('title.permissions')}</Typography>
                                </Box> */}
                                {/* <Box className={styles.menuStyle} >
                                    <img src="/images/icons/permissions.png" className={styles.menuImg} alt="permissions"/>
                                    <Typography>{t('title.permissions')}</Typography>
                                </Box>
                            </Link>
                        </Grid>
                    } */}
                    <Grid item xs={12} sm={6} md={3} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                        <Link underline='none' to={`/myAccount`} component={RouterLink}>
                            {/* <Box className={styles.menuStyle} style={{ backgroundColor: '#969493', color: '#fff' }}>
                                <Typography>{t('title.myAccount')}</Typography>
                            </Box> */}
                            <Box className={styles.menuStyle} >
                                <img src="/images/icons/account.png" className={styles.menuImg} alt="myAccount"/>
                                <Typography>{t('title.myAccount')}</Typography>
                            </Box>
                        </Link>
                    </Grid>
                    {/* {
                        _.includes(permissions, 'brand') &&
                        <Grid item xs={12} sm={6} md={3} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                            <Link underline='none' to={`/brands`} component={RouterLink}>
                                {/* <Box className={styles.menuStyle} style={{ backgroundColor: '#457876', color: '#fff' }}>
                                    <Typography>{t('title.brands')}</Typography>
                                </Box> */}
                                {/* <Box className={styles.menuStyle} >
                                    <img src="/images/icons/brands.png" className={styles.menuImg} alt="brands"/>
                                    <Typography>{t('title.brands')}</Typography>
                                </Box>
                            </Link>
                        </Grid>
                    }  */}
                    {
                        _.includes(permissions, 'expense-title') &&
                        <Grid item xs={12} sm={6} md={3} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                            <Link underline='none' to={`/expense-titles`} component={RouterLink}>
                                {/* <Box className={styles.menuStyle} style={{ backgroundColor: '#457876', color: '#fff' }}>
                                    <Typography>{t('title.expenseTitles')}</Typography>
                                </Box> */}
                                <Box className={styles.menuStyle} >
                                    <img src="/images/icons/price2.png" className={styles.menuImg} alt="expenseTitles" style={{ maxWidth: "70%"}}/>
                                    <Typography>{t('title.expenseTitles')}</Typography>
                                </Box>
                            </Link>
                        </Grid>
                    }
                </Grid>
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    menuStyle: {
        background: '#fff',
        borderRadius: 15,
        boxShadow: '2px 2px 10px 5px rgb(209 209 209 / 30%)',
        padding: 10,
        width: 250,
        height: 250,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection:'column',
    },
    menuImg: {
        maxWidth: '100%',
        width: '100%'
    },
    menuText: {
        textAlign: 'center',
        fontSize: '14px!important',
        paddingTop: 15
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    flexTtop: {
        display: 'flex',
        alignItems: 'flex-start',
    },

}));

