import { makeStyles, useTheme } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";

// MUI
import {
    Breadcrumbs, Button, Link, Menu, MenuItem, Paper, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FiArrowLeft, FiChevronDown } from "react-icons/fi";

import _ from "lodash";
import { useMemo } from "react";
import { getUrl } from "../../helpers/ApiAction";
import { currencyFormat } from "../../helpers/Tools";
import useNotificationLoading from "../../helpers/useNotificationLoading";

export default function DrawerSummary() {
	const [anchorEl, setAnchorEl] = useState(null);
	const [type, setType] = useState("today");
	const [summary, setSummary] = useState();
	const navigate = useNavigate();

	const styles = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const { addAlert, setLoading } = useNotificationLoading();

	const isMountedRef = useRef();

	useEffect(() => {
		setLoading(true);
		isMountedRef.current = true;

		getUrl(`drawer-logs-summary?type=${type}`)
			.then((response) => {
				setLoading(false);
				if (response.status && isMountedRef.current) {
					setSummary(response.data);
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});

		return () => (isMountedRef.current = false);
	}, [type]);

	const typeList = useMemo(() => {
		return [
			{ key: "today", name: t(`accounting.typeList.today`) },
			{ key: "monthly", name: t(`accounting.typeList.monthly`) },
			{ key: "annually", name: t(`accounting.typeList.annually`) },
		];
	}, [i18n.language]);

	const totalBalance = useMemo(() => {
		let totalBalance = 0;

		_.map(summary, (summaryItem) => {
			totalBalance += summaryItem.balance;
		});

		return totalBalance;
	}, [summary]);

	//DropDown Menu
	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const selectType = (selectedType) => {
		setType(selectedType);
		setAnchorEl(null);
	};

	return (
		<div>
			<div
				style={{ paddingBottom: 30, justifyContent: "space-between" }}
				className={styles.flexMiddle}
			>
				<div
					className={styles.flexMiddle}
					style={{ paddingBottom: 30 }}
				>
					<FiArrowLeft
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							marginRight: 10,
						}}
					/>
					<span
						onClick={() => navigate(-1)}
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							textTransform: "uppercase",
							cursor: "pointer",
						}}
					>
						{t("button.back")}
					</span>
				</div>
				<div>
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/dashboard"
						>
							{t("title.dashboard")}
						</Link>
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/accounting"
						>
							{t("title.accounting")}
						</Link>
						<Typography color="text.primary">
							{t("accounting.drawerSummary")}
						</Typography>
					</Breadcrumbs>
				</div>
			</div>

			<div style={{ padding: "20px 0" }}>
				<Typography style={{ color: theme.palette.gray.dtext }}>
					{t("accounting.totalAmount")} (RM)
				</Typography>
				<Typography
					style={{
						color: theme.palette.secondary.secondary,
						fontSize: 40,
					}}
				>
					<b>{currencyFormat(totalBalance)}</b>
				</Typography>

				<div style={{ paddingTop: 30 }}>
					<div
						className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}
					>
						<Typography
							style={{
								color: theme.palette.gray.ltext,
								fontSize: 18,
							}}
						>
							<b>{t("accounting.drawerSummary")}</b>
						</Typography>
						<div>
							<Button
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleMenu}
								color="inherit"
							>
								<div
									style={{ color: theme.palette.gray.ltext }}
									className={styles.flexMiddle}
								>
									<Typography style={{ fontSize: 18 }}>
										{t(`accounting.typeList.${type}`)}
									</Typography>
									<Typography
										style={{
											paddingTop: 5,
											paddingLeft: 15,
										}}
									>
										<FiChevronDown />
									</Typography>
								</div>
							</Button>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorEl)}
								onClose={() => setAnchorEl(null)}
							>
								{_.map(typeList, (typeItem) => {
									return (
										<MenuItem
											key={typeItem.key}
											onClick={() =>
												selectType(typeItem.key)
											}
										>
											{typeItem.name}
										</MenuItem>
									);
								})}
							</Menu>
						</div>
					</div>
					<ThemeProvider theme={tableStyle}>
						<TableContainer
							component={Paper}
							style={{
								marginTop: 15,
								boxShadow: "0 0 20px 0 #e0e0e0",
								borderRadius: 15,
							}}
						>
							<Table
								sx={{ minWidth: 650 }}
								size="small"
								aria-label="a dense table"
							>
								<TableHead>
									<TableRow>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
										>
											{t("accounting.walletType")}
										</TableCell>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
											align="right"
										>
											{t("accounting.cashIn")}
										</TableCell>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
											align="right"
										>
											{t("accounting.cashOut")}
										</TableCell>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
											align="right"
										>
											{t("accounting.balance")}
										</TableCell>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
											align="right"
										>
											{t("accounting.action")}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{_.map(summary, (walletItem, walletKey) => {
										return (
											<TableRow
												key={walletKey}
												// sx={{ '&:last-child td, &:last-child th': { border: 0, color:theme.palette.gray.dtext } }}
											>
												<TableCell
													component="th"
													scope="row"
												>
													{
														walletItem[
															`name_${i18n.language}`
														]
													}
												</TableCell>
												<TableCell
													style={{ width: 160 }}
													align="right"
												>
													{currencyFormat(
														walletItem.cash_in
													)}
												</TableCell>
												<TableCell
													style={{ width: 160 }}
													align="right"
												>
													{currencyFormat(
														walletItem.cash_out
													)}
												</TableCell>
												<TableCell
													style={{ width: 160 }}
													align="right"
												>
													{currencyFormat(
														walletItem.balance
													)}
												</TableCell>

												<TableCell
													style={{ width: 120 }}
													align="right"
												>
													<Link
														underline="none"
														to={`/drawer-wallet-summary?wallet=${walletKey}`}
														component={RouterLink}
													>
														{t("button.view")}
													</Link>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</ThemeProvider>
				</div>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	justifyCenter: {
		justifyContent: "center",
	},
	spaceBetween: {
		justifyContent: "space-between",
	},
	flexMiddle: {
		display: "flex",
		alignItems: "center",
		width: "100%",
	},
	flexTtop: {
		display: "flex",
		alignItems: "flex-start",
	},
}));

const tableStyle = createTheme({
	components: {
		// Name of the component
		MuiTableCell: {
			styleOverrides: {
				// Name of the slot
				root: {
					// Some CSS
					lineHeight: "2.5rem",
				},
			},
		},
	},
});
