import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl, putUrl } from '../../helpers/ApiAction';
import _ from 'lodash';
// import { ThemeProvider, createTheme } from '@mui/material/styles';

import { FiArrowLeft } from "react-icons/fi";

// MUI
import { Grid, Typography, Link, TextField, Box, Button, Breadcrumbs, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
// import { useTheme } from '@emotion/react';

export default function SupplierEdit() {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [state, setState] = useState({
        name: '',
        email: '',
        mobile: '',
        ic: '',
        status: 0,
    });
    const [supplierAddress, setSupplierAddress] = useState({
        country: '',
        zip: '',
        state: '',
        city: '',
        address: '',
        address2: ''
    });
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    let { id } = useParams();
    const isMountedRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getUrl(`/suppliers/${id}`).then(response => {
            setLoading(false);
            if (isMountedRef.current) {
                if (response.status) {
                    setState({
                        name: response.data.name,
                        email: response.data.email,
                        mobile: response.data.mobile,
                        ic: response.data.ic,
                        status: response.data.status,
                    });
                    if (_.size(response.data.addresses) > 0) {
                        _.map(response.data.addresses, listing => {
                            setSupplierAddress({
                                ...supplierAddress,
                                address: listing.address1,
                                address2: listing.address2 ? listing.address2 : '',
                                country: listing.country,
                                zip: listing.zip,
                                state: listing.state,
                                city: listing.city,
                            });
                        })
                    }
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, id])

    const submitData = () => {
        let postData = {
            name: state.name,
            email: state.email,
            mobile: state.mobile,
            ic: state.ic,
            status: state.status,
            country: supplierAddress.country,
            zip: supplierAddress.zip,
            state: supplierAddress.state,
            city: supplierAddress.city,
            address: supplierAddress.address,
            address2: supplierAddress.address2,
        }
        setLoading(true);
        setInputErrors();
        putUrl(`/suppliers/${id}`, postData).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div>
            <Link underline='none' component={RouterLink} to={`/supplier-profile/${id}`}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.backTo')}{t('button.profile')}</Typography>
                </div>
            </Link>
            <div style={{ paddingBottom: '5%' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ paddingBottom: 15, fontSize: 18 }}><b>{t('title.supplierEdit')}</b></Typography>
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                                {t('title.dashboard')}
                            </Link>
                            <Link underline="hover" color="inherit" component={RouterLink} to="/suppliers">
                                {t('title.suppliers')}
                            </Link>
                            <Typography color="text.primary">{t('title.supplierEdit')}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('supplier.supplierName')}
                                variant="outlined"
                                type="text"
                                autoComplete="name"
                                value={state.name}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('supplier.nric_regNo')}
                                variant="outlined"
                                autoComplete="ic"
                                value={state.ic}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.ic ? inputErrors.ic : ''}
                                error={inputErrors && inputErrors.ic ? true : false}
                                onChange={({ target }) => setState({ ...state, ic: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.email')}
                                variant="outlined"
                                type="email"
                                autoComplete="email"
                                value={state.email}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                error={inputErrors && inputErrors.email ? true : false}
                                onChange={({ target }) => setState({ ...state, email: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.mobile')}
                                variant="outlined"
                                autoComplete="mobile"
                                value={state.mobile}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                error={inputErrors && inputErrors.mobile ? true : false}
                                onChange={({ target }) => setState({ ...state, mobile: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.address')}
                                variant="outlined"
                                type="text"
                                autoComplete="address"
                                value={supplierAddress.address}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.address ? inputErrors.address : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={({ target }) => setSupplierAddress({ ...supplierAddress, address: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField id="standard-basic"
                                label={t('user.address2')}
                                variant="outlined"
                                type="text"
                                autoComplete="address2"
                                value={supplierAddress.address2}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.address2 ? inputErrors.address2 : ''}
                                error={inputErrors && inputErrors.address2 ? true : false}
                                onChange={({ target }) => setSupplierAddress({ ...supplierAddress, address2: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.country')}
                                variant="outlined"
                                type="text"
                                autoComplete="country"
                                value={supplierAddress.country}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.country ? inputErrors.country : ''}
                                error={inputErrors && inputErrors.country ? true : false}
                                onChange={({ target }) => setSupplierAddress({ ...supplierAddress, country: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.zip')}
                                variant="outlined"
                                autoComplete="zip"
                                value={supplierAddress.zip}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.zip ? inputErrors.zip : ''}
                                error={inputErrors && inputErrors.zip ? true : false}
                                onChange={({ target }) => setSupplierAddress({ ...supplierAddress, zip: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.city')}
                                type="text"
                                autoComplete="city"
                                value={supplierAddress.city}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                helperText={inputErrors && inputErrors.city ? inputErrors.city : ''}
                                error={inputErrors && inputErrors.city ? true : false}
                                onChange={({ target }) => setSupplierAddress({ ...supplierAddress, city: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.state')}
                                type="text"
                                autoComplete="state"
                                value={supplierAddress.state}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                helperText={inputErrors && inputErrors.state ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.state ? true : false}
                                onChange={({ target }) => setSupplierAddress({ ...supplierAddress, state: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{t('supplier.status.title')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.status}
                                    label={t('supplier.status.title')}
                                    error={inputErrors && inputErrors.status ? true : false}
                                    onChange={({ target }) => setState({ ...state, status: target.value })}
                                >
                                    {
                                        _.map([0, 1], statusType => {
                                            return <MenuItem key={statusType} value={statusType}>{t(`supplier.status.${statusType}`)}</MenuItem>
                                        })
                                    }
                                </Select>
                                <FormHelperText style={{ color: 'red' }}>{inputErrors && inputErrors.status ? inputErrors.status : ''}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>

                <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link underline='none' to={`/suppliers`} component={RouterLink}>
                        <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15, color:theme.palette.button.main, border:theme.palette.button.borderMain }}>{t('button.cancel')}</Button>
                    </Link>
                    <Button size="large" variant="contained" className={styles.buttonStyle}  style={{backgroundColor:theme.palette.button.main}} onClick={() => submitData()}>{t('button.edit')}</Button>
                </Box>
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle:{
        display:'flex', 
        alignItems:'center'
    }
}));