import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl, postUrl, putUrl } from '../../helpers/ApiAction';
import NumberFormat from 'react-number-format';
import { useQuery } from '../../helpers/Tools';
import _ from 'lodash';
import DetailPrint from './DetailPrint';
import PaymentModal from './PaymentModal';
import PaymentListing from './PaymentListing';
import AddOnModal from './AddOnModal';

// MUI
import { Box, Button, Typography, Modal, Grid, FormControl, TextField, Link, Hidden, FormControlLabel, Radio, RadioGroup, IconButton } from '@mui/material';

// ICONS
import { FiArrowLeft,FiEdit2 } from "react-icons/fi";

// MODAL
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    p: 4,
};

export default function Detail() {
    const navigate = useNavigate();
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions, role } = useSelector(state => state.general);
    const [order, setOrder] = useState({});
    const [orderStatusList, setOrderStatusList] = useState({});
    const [paymentStatusList, setPaymentStatusList] = useState({});
    const [paymentMethodList, setPaymentMethodList] = useState({});
    const [selectedPaymentList, setSelectedPaymentList] = useState([]);
    const [totalPayment, setTotalPayment] = useState(0);
    const [tradeInList, setTradeInList] = useState({});
    const [paymentModal, setPaymentModal] = useState({
        open: false,
        key: '',
        payment: {},
        payment_info: {}
    });
    const [paymentApproveModal, setPaymentApproveModal] = useState({
        open: false,
        key: '',
        payment: {},
        payment_info: {}
    });
    const [orderModal, setOrderModal] = useState({
        open: false,
        status: '',
        remark: ''
    });
    const [inputErrors, setInputErrors] = useState({});
    let { id } = useParams();
    let query = useQuery();
    const rmOrderPaymentId = query.get('orderId');
    const rmStatus = query.get('status');
    const [updateOrderPayment, setUpdateOrderPayment] = useState(false);
    const [addOnModal , setAddOnModal]=useState({
        open:false,
        details:[],
        errors:{},
        orderDetailId:0,
    }
    );

    useEffect(() => {
        setLoading(true);
        getUrl(`/trade-ins`).then(response => {
            if (response.status) {
                setTradeInList(response.data.listing);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        getUrl(`/orders/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                setOrder(response.data.order);
                setSelectedPaymentList(response.data.order.order_payments);
                setOrderStatusList(response.data.status_list);
                setPaymentStatusList(response.data.payment_status_list);
                setPaymentMethodList(response.data.payment_method);
                updateTotalPayment(response.data.order.order_payments);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [updateOrderPayment]);

    useEffect(() => {
        if (rmStatus === 'EXPIRED' && rmOrderPaymentId) {
            setLoading(true);
            let rmArr = rmOrderPaymentId.split("-");
            let rmID = _.size(rmArr) > 1 ? rmArr[1] : rmArr[0];
            let type = _.size(rmArr) > 1 ? 'instalment-details' : 'order-payments';
            postUrl(`/${type}/${rmID}`).then(response => {
                if (response.status) {
                    window.location.href = response.data;
                } else {
                    setUpdateOrderPayment(!updateOrderPayment);
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        // eslint-disable-next-line
    }, [rmStatus, rmOrderPaymentId]);

    // FPX PAYMENT
    const FPXRedirect = (payment) => {
        if (payment.payment_details.item && payment.payment_details.item.url) {
            setLoading(true);
            window.location.href = payment.payment_details.item.url;
        } else {
            addAlert("", t('error.contactSupport'), 'error', '');
        }
    }

    const updateTotalPayment = (listing) => {
        let total = 0;
        _.map(listing, payment => {
            if (payment.status !== 60) {
                total += parseFloat(payment.amount);
            }
        })
        setTotalPayment(parseFloat(total).toFixed(2));
    }

    // ADD/EDIT/DELETE PAYMENT
    const handlePaymentSelect = (code) => {
        let paymentInfo = _.find(paymentMethodList, function (c) { return c.code === code; });
        let tempDetails = {};
        if (paymentInfo.details) {
            _.map(paymentInfo.details, detail => {
                tempDetails[detail.code] = '';
            })
        }
        setPaymentModal({
            open: true,
            key: '',
            payment: { id: 0, payment_method: paymentInfo.code, amount: 0, payment_details: tempDetails, status: 0, remark: '' },
            payment_info: paymentInfo
        });
    }
    const handlePaymentModalOpen = (key, payment) => {
        let paymentInfo = _.find(paymentMethodList, function (c) { return c.code === payment.payment_method; });
        setPaymentModal({
            open: true,
            key: key,
            payment: payment,
            payment_info: paymentInfo
        });
    }

    // APPROVE/REJECT PAYMENT
    const handlePaymentApproveModalOpen = (key, payment) => {
        let paymentInfo = _.find(paymentMethodList, function (c) { return c.code === payment.payment_method; });
        setPaymentApproveModal({
            open: true,
            key: key,
            payment: payment,
            payment_info: paymentInfo
        });
    }

    // ORDER
    const handleOrderModalOpen = () => {
        setOrderModal({
            open: true,
            status: order.status,
            remark: order.remark
        });
    }
    const handleOrderModalClose = () => {
        setOrderModal({
            open: false,
            status: '',
            remark: ''
        });
    }
    const updateOrderStatus = () => {
        setLoading(true);
        putUrl(`/orders/${id}`, { status: parseInt(orderModal.status), remark: orderModal.remark }).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                setOrder(response.data);
                handleOrderModalClose();
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleAddOnModalOpen = (value, addOnLists) => {
        setAddOnModal({
            ...addOnModal,
            open: true,
            details:addOnLists,
            orderDetailId:value,
        });
    }

    return (
        <Box sx={{ width: '100%' }}>
   
                <div className={styles.flexMiddle}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 ,cursor:'pointer'}} />
                    <span onClick={() => navigate(-1)} style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' ,cursor:'pointer'}}>{t('button.back')}</span>
                </div>
           
            {
                _.size(order) > 0
                    ?
                    <div>
                        <div style={{ width: 800, maxWidth: '100%', margin: '40px auto', borderRadius: 15, boxShadow: '0 0 20px 0 #e1e1e1' }}>
                            {/* HEADER INFO */}
                            <div style={{ backgroundColor: (order.status === 60 ? theme.palette.indicator.red : (order.status === 50 ? theme.palette.indicator.green : theme.palette.indicator.yellow)), color: '#fff', padding: 15, borderRadius: '15px 15px 0 0' }}>
                                {
                                    _.size(orderStatusList) > 0 &&
                                    _.map(orderStatusList, status => {
                                        if (order.status === status.code) {
                                            return (
                                                <div className={styles.statusBar} style={{ backgroundColor: (order.status === 60 ? theme.palette.indicator.red : (order.status === 50 ? theme.palette.indicator.green : theme.palette.indicator.yellow)) }} key={status.code}>
                                                    <Typography style={{ fontSize: 18, textAlign: 'center', color: '#fff', textTransform: 'uppercase' }}>
                                                        <b>{status[i18n.language]}</b>
                                                    </Typography>
                                                </div>
                                            )
                                        }
                                    })
                                }
                                <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{ color: '#fff' }}>
                                    <div >
                                        <Typography style={{ fontSize: 18 }}><b>{t('order.receipt') + ' #'}{order.order_no}</b></Typography>
                                        <Typography style={{ fontSize: 18 }}>{order.created_at}</Typography>
                                        <Typography style={{ fontSize: 18 }}>{order.updated_at} ({t('order.lastUpdate')})</Typography>
                                    </div>
                                    <div>
                                        <Typography style={{ textAlign: 'right', fontSize: 21, lineHeight: 1, fontWeight: 'bold' }}>
                                            RM<br />
                                            <NumberFormat thousandSeparator={true} value={order.total_price} displayType="text" />
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            {/* CUSTOMER DETAILS */}
                            <div style={{ padding: 15, color: theme.palette.gray.dtext }}>
                                <Typography style={{ fontSize: 21 }}><b>{order.customer.name}</b></Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography>{_.size(order.customer.addresses) >0 ? order.customer.addresses[0].full_address : ''}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={styles.addressAlign}>
                                            {order.customer.ic} <br />
                                            {order.customer.email} <br />
                                            {order.customer.mobile}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography>{t('order.salesperson') + ': ' + order.user.name}</Typography>
                            </div>
                            {/* ORDER DETAILS */}
                            <div style={{ padding: 15 }}>
                                <div style={{ color: theme.palette.gray.ltext }} className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                                    <Typography style={{ width: 'fit-content', textTransform: 'uppercase', whiteSpace: 'nowrap' }}>{t('order.itemDetails')}</Typography>
                                    <div style={{ borderBottom: '2px dashed #c6c6c6', width: '100%', marginLeft: 20 }} />
                                </div>
                                {
                                    _.map(order.order_details, detail => {
                                        return (
                                            <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{ padding: '20px 0' }} key={detail.id}>
                                                <div className={styles.flexMiddle}>
                                                    <img src={_.size(detail.product.images) ? detail.product.images[0].file_name : "/images/emptyStatus/noproductimg.jpg"} style={{ width: 90, height: 90, borderRadius: 8, objectFit: 'cover', maxWidth: '100%' }} alt={detail.product_name} />
                                                    <div style={{ paddingLeft: 15 }}>
                                                        <Typography className={styles.textLine2} style={{ color: theme.palette.gray.dtext, fontWeight: "bold" }}>{detail.product_name}</Typography>
                                                        <Typography style={{ color: theme.palette.gray.dtext }}>{t('order.serialNo') + ': ' + (detail.serial_no ? detail.serial_no : '-')}</Typography>
                                                        <Typography style={{ color: theme.palette.gray.dtext }}>{t('order.whatsInBox') + ': ' + (detail.whats_in_box ? detail.whats_in_box : '-')}</Typography>
                                                        <Typography style={{ color: theme.palette.gray.dtext }}>
                                                            {t('order.factoryWarranty') + ': ' + (detail.warranty_period ?
                                                                (detail.warranty_period.factory.sdate ?
                                                                    (detail.warranty_period.factory.edate ? (t('order.startEndDate', { start: detail.warranty_period.factory.sdate, end: detail.warranty_period.factory.edate })) : '-') :
                                                                    (detail.warranty_period.factory.edate ? (t('order.endDate', { end: detail.warranty_period.factory.edate })) : '-')
                                                                )
                                                                : '-'
                                                            )}
                                                        </Typography>
                                                        <Typography style={{ color: theme.palette.gray.dtext }}>
                                                            {t('order.shopWarranty') + ' ' + t('order.period') + ': ' + (detail.warranty_period ? detail.warranty_period.shop.period ? detail.warranty_period.shop.period : '-' : '-')}
                                                        </Typography>
                                                        {
                                                            _.size(_.intersection(role, ['dev', 'super-admin', 'admin'])) > 0 &&
                                                            <>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={5} >
                                                                        <Typography style={{ color: theme.palette.gray.dtext, paddingTop: 5 }}>
                                                                            {t('product.addOnPrice')}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <div>
                                                                            <IconButton onClick={() => handleAddOnModalOpen(detail.id, detail.add_ons)}>
                                                                                <FiEdit2 style={{ fontSize: 18, color: theme.palette.secondary.main }} />
                                                                            </IconButton>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                                {
                                                                    _.size(detail.add_ons) > 0 && _.map(detail.add_ons, (row, key) => {
                                                                        return (
                                                                            <Grid container spacing={2} key={key}>
                                                                                <Grid item xs={6} >
                                                                                    <Typography style={{ color: theme.palette.gray.dtext, paddingTop: 5 }}>
                                                                                        {row.description}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Typography >
                                                                                        <NumberFormat thousandSeparator={true} value={parseFloat(row.amount).toFixed(2)} displayType="text" prefix="RM "  />
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                        <Hidden smUp>
                                                            <Typography className={styles.addressAlign} style={{ color: theme.palette.gray.dtext, whiteSpace: 'nowrap', minWidth: 150 }}>
                                                                x {detail.quantity}<br />
                                                                <NumberFormat thousandSeparator={true} prefix="RM " value={detail.sell_price} displayType="text" />
                                                            </Typography>
                                                        </Hidden>
                                                    </div>
                                                </div>
                                                <Hidden only={'xs'}>
                                                    <Typography className={styles.addressAlign} style={{ color: theme.palette.gray.dtext, whiteSpace: 'nowrap', minWidth: 150 }}>
                                                        x {detail.quantity}<br />
                                                        <NumberFormat thousandSeparator={true} prefix="RM " value={detail.sell_price} displayType="text" />
                                                    </Typography>
                                                </Hidden>
                                            </div>
                                        )
                                    })
                                }
                                <div style={{ width: '100%', maxWidth: '100%', borderTop: '2px solid #c9c8c8', borderBottom: '2px solid #c9c8c8', padding: '5px 0', marginTop: 25 }}>
                                    <div style={{ borderBottom: '2px solid #dedede', padding: '5px 0', color: theme.palette.gray.dtext }} className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                                        <Typography>
                                            {t('order.total')}
                                        </Typography>
                                        <Typography style={{ textAlign: 'right', fontSize: 18 }}>
                                            <NumberFormat thousandSeparator={true} prefix="RM " value={order.total_price} displayType="text" />
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            {/* DESCRIPTION */}
                            <div style={{ padding: 15 }}>
                                <Typography style={{ textTransform: 'uppercase', color: theme.palette.gray.ltext }}>{t('order.description')}</Typography>
                                <Typography className={styles.textLine2} style={{ color: theme.palette.gray.dtext, }}>{order.description ? order.description : '-'}</Typography>
                            </div>
                            {/* PAYMENT */}
                            <PaymentListing
                                type='order-payments' pendingStatus={order.status} totalAmount={order.total_price}
                                paymentMethodList={paymentMethodList} selectedPaymentList={selectedPaymentList} totalPayment={totalPayment}
                                FPXRedirect={FPXRedirect} handlePaymentSelect={handlePaymentSelect} handlePaymentModalOpen={handlePaymentModalOpen} handlePaymentApproveModalOpen={handlePaymentApproveModalOpen}
                                order={order} setOrder={setOrder} tradeInList={tradeInList} paymentStatusList={paymentStatusList} setSelectedPaymentList={setSelectedPaymentList} inputErrors={inputErrors} setInputErrors={setInputErrors}
                            />
                        </div>
                        {/* ACTION BUTTON */}
                        <div className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} style={{ paddingTop: 25 }} >
                            {
                                _.includes(permissions, 'order-edit')
                                    ?

                                    <Button style={{ padding: '8px 15px', minWidth: 130, maxWidth: '100%', borderRadius: 8, background: '#fff', boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center', border: theme.palette.button.borderMain, marginRight: 15 }} onClick={handleOrderModalOpen} >
                                        <Typography style={{ color: theme.palette.button.main, fontSize: 15, textAlign: 'center', textTransform: 'uppercase' }}>{t('order.editStatus')}</Typography>
                                    </Button>
                                    : null
                            }
                            <DetailPrint order={order} orderStatusList={orderStatusList} selectedPaymentList={selectedPaymentList} paymentMethodList={paymentMethodList} totalPayment={totalPayment} />
                        </div>
                    </div>
                    : null
            }

            {/* UPDATE ORDER STATUS MODAL */}
            <Modal
                open={orderModal.open}
                onClose={handleOrderModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <div style={{ color: theme.palette.gray.dtext, paddingBottom: 25 }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            <b>{t('order.editStatus')}</b>
                        </Typography>
                        {
                            _.size(orderStatusList) > 0 &&
                            _.map(orderStatusList, status => {
                                if (order.status === status.code) {
                                    return (
                                        <Typography key={status.code}>{t('order.currentStatus') + ': ' + status[i18n.language]}</Typography>
                                    )
                                }
                            })
                        }
                        {
                            order.status !== 60
                                ?
                                <div style={{ paddingTop: 25 }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            defaultValue={order.status}
                                            onChange={(event) => setOrderModal({ ...orderModal, status: event.target.value })}
                                        >
                                            {
                                                _.size(orderStatusList) > 0 &&
                                                _.map(orderStatusList, status => {
                                                    return (
                                                        <FormControlLabel key={status.code} value={status.code} control={<Radio />} label={status[i18n.language]} />
                                                    )
                                                })
                                            }
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                : null
                        }
                    </div>
                    <Box id="modal-modal-description"
                        sx={{
                            width: 500,
                            maxWidth: '100%',
                        }}
                    >
                        <Typography style={{ color: theme.palette.gray.dtext, paddingBottom: 8, fontSize: 18 }}><b>{t('order.remark')}</b></Typography>
                        <TextField
                            fullWidth
                            id="standard-basic"
                            type="text"
                            variant="outlined"
                            size="small"
                            value={orderModal.remark ? orderModal.remark : ''}
                            onChange={(event) => setOrderModal({ ...orderModal, remark: event.target.value })}
                        />
                    </Box>
                    <div className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} style={{ paddingTop: 40, width: '100%' }} >
                        <Button style={{ padding: '8px 15px', minWidth: 180, marginRight: 15, maxWidth: '100%', borderRadius: 48, border: theme.palette.button.borderMain, boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center' }} onClick={handleOrderModalClose}>
                            <Typography style={{ color: theme.palette.button.main, fontSize: 15, textAlign: 'center', textTransform: 'uppercase' }}>{t('button.cancel')}</Typography>
                        </Button>
                        <Button style={{ padding: '8px 15px', minWidth: 180, maxWidth: '100%', borderRadius: 48, background: theme.palette.button.main, boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center' }} onClick={updateOrderStatus}>
                            <Typography style={{ color: '#fff', fontSize: 15, textAlign: 'center', textTransform: 'uppercase' }}>{t('button.save')}</Typography>
                        </Button>
                    </div>
                </Box>
            </Modal>
           { _.size(order) > 0
                    ?
            <PaymentModal
                type="order-payments"
                order={order} setOrder={setOrder} tradeInList={tradeInList} paymentStatusList={paymentStatusList}
                paymentModal={paymentModal} setPaymentModal={setPaymentModal} paymentApproveModal={paymentApproveModal} setPaymentApproveModal={setPaymentApproveModal} inputErrors={inputErrors} setInputErrors={setInputErrors}
                updateTotalPayment={updateTotalPayment} selectedPaymentList={selectedPaymentList} setSelectedPaymentList={setSelectedPaymentList}
                customerId={order.customer_id}
            />

            :null
           }
            <AddOnModal 
             addOnModal={addOnModal} setAddOnModal={setAddOnModal}
            />
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    spaceBetween: {
        justifyContent: 'space-between'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    textLine2: {
        display: '-webkit-box',
        maxWidth: '100%',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    },
    addressAlign: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
        },
    },
    statusBar: {
        width: 450,
        maxWidth: '80%',
        margin: '-31px auto 15px',
        padding: '12px 15px',
        borderRadius: 13,
        boxShadow: '1px 6px 12px 0 #0000002e, inset 0 3px 0px 0 #ffffff29, inset 0px -3px 0px 0 #ffffff17',
    },
}));