import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { getUrl, postUrl } from "../../helpers/ApiAction";
import useNotificationLoading from "../../helpers/useNotificationLoading";
import CreditNotePrint from "./CreditNotePrint";
import { buildFormData } from "../../helpers/Tools";

import {
    Autocomplete, Box, Breadcrumbs, Button, Grid, Link,
    TextField, Typography
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

import { FiArrowLeft } from "react-icons/fi";
import { MdBadge, MdMailOutline } from "react-icons/md";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
	props,
	ref
) {
	const { onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
			decimalScale={2}
		/>
	);
});

NumberFormatCustom.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default function Index() {
	const navigate = useNavigate();
	let { id } = useParams();
	const { t, i18n } = useTranslation();
	const styles = useStyles();
	const theme = useTheme();
	const isMountedRef = useRef(null);
	const location = useLocation();
	const { addAlert, setLoading } = useNotificationLoading();
	const formType =
		location.pathname === "/create-credit-note" ? "add" : "edit";
	const [inputErrors, setInputErrors] = useState({});
	const [customers, setCustomers] = useState([]);
	const [paymentList, setPaymentList] = useState([]);
	const [statusList, setStatusList] = useState([]);
	const [typeList, setTypeList] = useState([]);
	const [state, setState] = useState({
		customer_id: 0,
		selectedName: null,
		name: "",
		gender: "-",
		email: "",
		mobile: "",
		ic: "",
		country: "",
		zip: "",
		state: "",
		city: "",
		address: "",
		description: "",
		amount: "",
		payment_method: "cash",
		status: 10,
		id: 0,
		created_at: "",
		type: "available",
		used_credit_note: 0,
	});

	useEffect(() => {
		isMountedRef.current = true;

		getDropdownOptions();
		if (formType === "edit" && id) {
			setLoading(true);
			getUrl(`/credit-notes/${id}`)
				.then((response) => {
					setLoading(false);
					if (isMountedRef.current) {
						if (response.status) {
							setState({
								...state,
								customer_id:
									response.data.credit_note.customer_id,
								description:
									response.data.credit_note.description,
								amount: response.data.credit_note.amount,
								status: response.data.credit_note.status,
								id: response.data.credit_note.id,
								created_at:
									response.data.credit_note.created_at,
								type: response.data.credit_note.type,
								used_credit_note:
									response.data.credit_note.used_credit_note,
								existing_attachments: response.data.credit_note.attachments,
							});
							setStatusList(response.data.status_list);
							setTypeList(response.data.type_list);
						} else {
							addAlert(
								"",
								t("error.contactSupport"),
								"error",
								""
							);
						}
					}
				})
				.catch((error) => {
					setLoading(false);
					addAlert(
						"",
						error.message || t("error.contactSupport"),
						"error",
						""
					);
				});
		}

		return () => {
			isMountedRef.current = false;
		};
		// eslint-disable-next-line
	}, [addAlert, id]);

	useEffect(() => {
		isMountedRef.current = true;
		if (isMountedRef.current) {
			if (
				state.customer_id > 0 &&
				!_.size(state.selectedName) &&
				_.size(customers)
			) {
				let selectedName = _.find(customers, {
					id: state.customer_id,
				});
				setState({
					...state,
					selectedName: selectedName,
					gender: selectedName?.gender ?? "-",
					email: selectedName?.email ?? "",
					mobile: selectedName?.mobile ?? "",
					ic: selectedName?.ic ?? "",
					country:
						selectedName && _.size(selectedName.addresses) > 0
							? selectedName?.addresses?.[0].country ?? ""
							: "",
					zip:
						selectedName && _.size(selectedName.addresses) > 0
							? selectedName?.addresses?.[0].zip ?? ""
							: "",
					state:
						selectedName && _.size(selectedName.addresses) > 0
							? selectedName?.addresses?.[0].state ?? ""
							: "",
					city:
						selectedName && _.size(selectedName.addresses) > 0
							? selectedName?.addresses?.[0].city ?? ""
							: "",
					address:
						selectedName && _.size(selectedName.addresses) > 0
							? selectedName?.addresses?.[0].address1 ?? ""
							: "",
				});
			} else if (_.size(state.selectedName)) {
				setState({
					...state,
					gender: state.selectedName.gender,
					email: state.selectedName.email,
					mobile: state.selectedName.mobile,
					ic: state.selectedName.ic,
					country:
						_.size(state.selectedName.addresses) > 0
							? state.selectedName.addresses?.[0].country
							: "",
					zip:
						_.size(state.selectedName.addresses) > 0
							? state.selectedName.addresses?.[0].zip
							: "",
					state:
						_.size(state.selectedName.addresses) > 0
							? state.selectedName.addresses?.[0].state
							: "",
					city:
						_.size(state.selectedName.addresses) > 0
							? state.selectedName.addresses?.[0].city
							: "",
					address:
						_.size(state.selectedName.addresses) > 0
							? state.selectedName.addresses?.[0].address1
							: "",
				});
			} else {
				setState({
					...state,
					gender: "-",
					email: "",
					mobile: "",
					ic: "",
					country: "",
					zip: "",
					state: "",
					city: "",
					address: "",
				});
			}
		}
		return () => {
			isMountedRef.current = false;
		};
		// eslint-disable-next-line
	}, [state.customer_id, customers, state.selectedName]);

	const getDropdownOptions = () => {
		getUrl(`/customers`)
			.then((response) => {
				setLoading(false);
				if (response.status) {
					setCustomers(response.data.listing);
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});

		getUrl(`/order-payments`)
			.then((response) => {
				setLoading(false);
				if (response.status) {
					setPaymentList(response.data);
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});
	};

	const submitData = (e) => {
		e.preventDefault();

		let apiData = {
			customer_id: state.customer_id ? state.customer_id : null,
			description: state.description,
			amount: state.amount,
			payment_method: state.payment_method,
			status: state.status,
			_method: "post",
			attachments: state.attachments
		};
		if (state.customer_id === 0) {
			apiData = {
				...apiData,
				name: state.name,
				gender: state.gender,
				email: state.email,
				mobile: state.mobile,
				ic: state.ic,
				country: state.country,
				zip: state.zip,
				state: state.state,
				city: state.city,
				address: state.address,
			};
		}
		if (formType === "edit") {
			apiData["_method"] = "put";
		}

		setLoading(true);
		setInputErrors();

		const formData = new FormData();
		buildFormData(formData, apiData);

		postUrl(
			`/credit-notes${formType === "edit" ? `/${id}` : ""}`,
			formData
		)
			.then((response) => {
				setLoading(false);
				let { status, data, errors, message } = response;
				if (status) {
					addAlert("", message, "success", "");
					if(formType === "add"){
						navigate(`/credit-note/${data.id}`);
					}
					if (formType === "edit") {
						setState({
							...state,
							customer_id: data.customer_id,
							description: data.description,
							amount: data.amount,
							payment_method: data.payment_method,
							status: data.status,
							id: data.id,
							created_at: data.created_at,
							existing_attachments: data.attachments
						});
					}
				} else {
					setInputErrors(errors);
					addAlert("", message, "error", "");
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});
	};

	return (
		<div>
			<div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
				<FiArrowLeft
					style={{
						color: theme.palette.gray.main,
						fontSize: 16,
						marginRight: 10,
					}}
				/>

				<span
					onClick={() => navigate(-1)}
					style={{
						color: theme.palette.gray.main,
						fontSize: 16,
						textTransform: "uppercase",
						cursor: "pointer",
					}}
				>
					{t("button.back")}
				</span>
			</div>

			<div style={{ paddingBottom: "5%" }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							paddingBottom: 15,
							color: theme.palette.gray.dtext,
						}}
					>
						<Typography style={{ paddingBottom: 15, fontSize: 18 }}>
							<b>
								{formType === "edit"
									? t("title.creditNoteEdit")
									: t("title.creditNoteAdd")}
							</b>
						</Typography>
					</div>
					<div style={{ paddingBottom: 15 }}>
						<Breadcrumbs aria-label="breadcrumb">
							<Link
								underline="hover"
								color="inherit"
								component={RouterLink}
								to="/dashboard"
							>
								{t("title.dashboard")}
							</Link>
							<Link
								underline="hover"
								color="inherit"
								component={RouterLink}
								to="/credit-notes"
							>
								{t("title.creditNotes")}
							</Link>
							<Typography
								style={{ color: theme.palette.button.main }}
							>
								{formType === "edit"
									? t("title.creditNoteEdit")
									: t("title.creditNoteAdd")}
							</Typography>
						</Breadcrumbs>
					</div>
				</Grid>
				<Box
					component="form"
					autoComplete="on"
					sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
					onSubmit={submitData}
				>
					<Grid item xs={12} container spacing={3}>
						<Grid item xs={12} sm={12}>
							<Typography
								style={{
									color: theme.palette.secondary.secondary,
									fontSize: 16,
									paddingBottom: 10,
								}}
							>
								<b>{t("creditNote.customerDetail")}</b>
							</Typography>
						</Grid>
						<Grid item xs={12} sm={8}>
							<Autocomplete
								disablePortal
								id="name-input"
								options={customers}
								getOptionLabel={(option) => option.name || ""}
								renderOption={(prop, option, state) => (
									<div {...prop}>
										{option.name} [<MdBadge />: {option.ic}]
										[<MdMailOutline />: {option.email}]
									</div>
								)}
								filterOptions={(options, state) =>
									options.filter(({ email, name, ic }) =>
										[email, name, ic].some((v) =>
											v?	v
												.toLowerCase()
												.includes(
													state.inputValue.toLowerCase()
												)
											:null
										)
									)
								}
								fullWidth
								freeSolo
								renderInput={(params) => (
									<TextField
										{...params}
										label={t("user.name")}
										helperText={
											inputErrors && inputErrors.name
												? inputErrors.name
												: ""
										}
										error={
											inputErrors && inputErrors.name
												? true
												: false
										}
									/>
								)}
								onChange={(event, newValue) => {
									setState({
										...state,
										name: "",
										selectedName: newValue,
										customer_id: newValue?.id,
									});
								}}
								onInputChange={(event, newValue) => {
									setState({
										...state,
										selectedName: null,
										name: newValue ? newValue : "",
										customer_id: 0,
									});
								}}
								value={state.selectedName || ""}
								disabled={formType === "edit" ? true : false}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="standard-basic"
								label={t("user.gender.title")}
								variant="outlined"
								select
								value={state.gender}
								InputLabelProps={{ shrink: true }}
								helperText={
									inputErrors && inputErrors.gender
										? inputErrors.gender
										: ""
								}
								error={
									inputErrors && inputErrors.gender
										? true
										: false
								}
								onChange={({ target }) =>
									setState({ ...state, gender: target.value })
								}
								SelectProps={{ native: true }}
								disabled={
									_.size(state.selectedName) ? true : false
								}
							>
								{["-", "male", "female"].map((option) => (
									<option
										key={option}
										value={option}
										disabled={
											_.size(state.selectedName)
												? true
												: false
										}
									>
										{t("user.gender." + option)}
									</option>
								))}
							</TextField>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="nric"
								label={t("user.nric")}
								variant="outlined"
								// required
								value={state.ic || ""}
								helperText={
									inputErrors && inputErrors.ic
										? inputErrors.ic
										: ""
								}
								error={
									inputErrors && inputErrors.ic ? true : false
								}
								onChange={({ target }) =>
									setState({ ...state, ic: target.value })
								}
								inputProps={{
									readOnly:
										_.size(state.selectedName) ||
										formType === "edit",
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="mobile"
								label={t("user.mobile")}
								variant="outlined"
								required
								value={state.mobile || ""}
								helperText={
									inputErrors && inputErrors.mobile
										? inputErrors.mobile
										: ""
								}
								error={
									inputErrors && inputErrors.mobile
										? true
										: false
								}
								onChange={({ target }) =>
									setState({ ...state, mobile: target.value })
								}
								inputProps={{
									readOnly:
										_.size(state.selectedName) ||
										formType === "edit",
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="email"
								label={t("user.email")}
								variant="outlined"
								required
								type="email"
								value={state.email || ""}
								helperText={
									inputErrors && inputErrors.email
										? inputErrors.email
										: ""
								}
								error={
									inputErrors && inputErrors.email
										? true
										: false
								}
								onChange={({ target }) =>
									setState({ ...state, email: target.value })
								}
								inputProps={{
									readOnly:
										_.size(state.selectedName) ||
										formType === "edit",
								}}
							/>
						</Grid>
						{formType !== "edit" && (
							<>
								<Grid item xs={12} sm={8}>
									<TextField
										id="address"
										label={t("user.address")}
										variant="outlined"
										value={state.address || ""}
										helperText={
											inputErrors && inputErrors.address
												? inputErrors.address
												: ""
										}
										error={
											inputErrors && inputErrors.address
												? true
												: false
										}
										onChange={({ target }) =>
											setState({
												...state,
												address: target.value,
											})
										}
										inputProps={{
											readOnly:
												_.size(state.selectedName) ||
												formType === "edit",
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										id="city"
										label={t("user.city")}
										variant="outlined"
										value={state.city || ""}
										helperText={
											inputErrors && inputErrors.city
												? inputErrors.city
												: ""
										}
										error={
											inputErrors && inputErrors.city
												? true
												: false
										}
										onChange={({ target }) =>
											setState({
												...state,
												city: target.value,
											})
										}
										inputProps={{
											readOnly:
												_.size(state.selectedName) ||
												formType === "edit",
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										id="state"
										label={t("user.state")}
										variant="outlined"
										value={state.state || ""}
										helperText={
											inputErrors && inputErrors.state
												? inputErrors.state
												: ""
										}
										error={
											inputErrors && inputErrors.state
												? true
												: false
										}
										onChange={({ target }) =>
											setState({
												...state,
												state: target.value,
											})
										}
										inputProps={{
											readOnly:
												_.size(state.selectedName) ||
												formType === "edit",
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										id="zip"
										label={t("user.zip")}
										variant="outlined"
										value={state.zip || ""}
										helperText={
											inputErrors && inputErrors.zip
												? inputErrors.zip
												: ""
										}
										error={
											inputErrors && inputErrors.zip
												? true
												: false
										}
										onChange={({ target }) =>
											setState({
												...state,
												zip: target.value,
											})
										}
										inputProps={{
											readOnly:
												_.size(state.selectedName) ||
												formType === "edit",
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										id="country"
										label={t("user.country")}
										variant="outlined"
										value={state.country || ""}
										helperText={
											inputErrors && inputErrors.country
												? inputErrors.country
												: ""
										}
										error={
											inputErrors && inputErrors.country
												? true
												: false
										}
										onChange={({ target }) =>
											setState({
												...state,
												country: target.value,
											})
										}
										inputProps={{
											readOnly:
												_.size(state.selectedName) ||
												formType === "edit",
										}}
									/>
								</Grid>
							</>
						)}
						<Typography style={{ padding: "20px 0 0 2rem " }}>
							* {t("product.newCustomerAutoCreate")}
						</Typography>
						<Grid item xs={12} sm={12}>
							<Typography
								style={{
									color: theme.palette.secondary.secondary,
									fontSize: 16,
									paddingBottom: 10,
								}}
							>
								<b>{t("creditNote.creditNoteDetail")}</b>
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							sm={
								formType === "edit" &&
								state.type === "available"
									? 4
									: 6
							}
						>
							<TextField
								id="amount"
								label={t("creditNote.amount")}
								variant="outlined"
								value={state.amount}
								helperText={
									inputErrors && inputErrors.amount
										? inputErrors.amount
										: ""
								}
								error={
									inputErrors && inputErrors.amount
										? true
										: false
								}
								onChange={({ target }) =>
									setState({ ...state, amount: target.value })
								}
								InputProps={{
									inputComponent: NumberFormatCustom,
								}}
								name="amount"
								inputProps={{ readOnly: formType === "edit" }}
							/>
						</Grid>
						{formType !== "edit" && (
							<Grid item xs={12} sm={6}>
								<TextField
									id="standard-basic"
									label={t("creditNote.paymentMethod")}
									variant="outlined"
									select
									value={state.payment_method}
									InputLabelProps={{ shrink: true }}
									helperText={
										inputErrors &&
										inputErrors.payment_method
											? inputErrors.payment_method
											: ""
									}
									error={
										inputErrors &&
										inputErrors.payment_method
											? true
											: false
									}
									onChange={({ target }) =>
										setState({
											...state,
											payment_method: target.value,
										})
									}
									SelectProps={{ native: true }}
									disabled={formType === "edit"}
								>
									{paymentList.map((option) => (
										<option
											key={option.code}
											value={option.code}
										>
											{option[`${i18n.language}`]}
										</option>
									))}
								</TextField>
							</Grid>
						)}
						{formType === "edit" && _.size(statusList) > 0 && (
							<Grid
								item
								xs={12}
								sm={
									formType === "edit" &&
									state.type === "available"
										? 4
										: 6
								}
							>
								<TextField
									id="standard-basic"
									label={t("creditNote.status.title")}
									variant="outlined"
									select
									value={state.status}
									InputLabelProps={{ shrink: true }}
									helperText={
										inputErrors && inputErrors.status
											? inputErrors.status
											: ""
									}
									error={
										inputErrors && inputErrors.status
											? true
											: false
									}
									onChange={({ target }) =>
										setState({
											...state,
											status: target.value,
										})
									}
									SelectProps={{ native: true }}
									disabled
								>
									{statusList.map((option) => (
										<option
											key={option.code}
											value={option.code}
										>
											{option[`${i18n.language}`]}
										</option>
									))}
								</TextField>
							</Grid>
						)}
						{formType === "edit" && _.size(typeList) > 0 && (
							<Grid
								item
								xs={12}
								sm={
									formType === "edit" &&
									state.type === "available"
										? 4
										: 6
								}
							>
								<TextField
									id="standard-basic"
									label={t("creditNote.type.title")}
									variant="outlined"
									select
									value={state.type}
									InputLabelProps={{ shrink: true }}
									helperText={
										inputErrors && inputErrors.type
											? inputErrors.type
											: ""
									}
									error={
										inputErrors && inputErrors.type
											? true
											: false
									}
									onChange={({ target }) =>
										setState({
											...state,
											type: target.value,
										})
									}
									SelectProps={{ native: true }}
									disabled
								>
									{_.map(typeList, (option) => (
										<option
											key={option.code}
											value={option.code}
										>
											{option[`${i18n.language}`]}
										</option>
									))}
								</TextField>
							</Grid>
						)}
						{formType === "edit" && state.type === "voided" && (
							<Grid item xs={12} sm={6}>
								<TextField
									label={t("creditNote.id")}
									variant="outlined"
									value={state.used_credit_note}
									helperText={t(
										"creditNote.creditNoteUsedText"
									)}
									name="amount"
									disabled
								/>
							</Grid>
						)}
						<Grid item xs={12} sm={12}>
							<TextField
								id="description"
								label={t("creditNote.description")}
								variant="outlined"
								multiline
								rows={5}
								value={state.description}
								helperText={
									inputErrors && inputErrors.description
										? inputErrors.description
										: ""
								}
								error={
									inputErrors && inputErrors.description
										? true
										: false
								}
								onChange={({ target }) =>
									setState({
										...state,
										description: target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								id="attachments"
								type="file"
								variant="outlined"
								label={t("creditNote.attachment")}
								InputLabelProps={{ shrink: true }}
								inputProps={{ multiple: true , accept: "image/*,application/pdf" }}
								onChange={({ target }) => setState({ ...state, attachments: target.files })}
								helperText={
									inputErrors && _.filter(inputErrors, function(v, k) {
										return _.includes(k, 'attachments.');
									  })
										? _.filter(inputErrors, function(v, k) {
											return _.includes(k, 'attachments.');
										  })
										: ""
								}
								error={
									inputErrors && _.filter(inputErrors, function(v, k) {
										return _.includes(k, 'attachments.');
									  })
										? true
										: false
								}
							/>
							{
								_.size(state.existing_attachments) > 0 && 
								<Grid container direction="column">
									{ _.map(state.existing_attachments, (attached, key)=> {
										return <Link key={key} href={attached.file_name} target="_blank">{key+1}. Attached ({attached.mime === "pdf" ? "File" : "Image"})</Link>
									})}
								</Grid>
							}
							
						</Grid>
					</Grid>
					<Box
						style={{
							paddingTop: "5%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Link
							underline="none"
							to={`/credit-notes`}
							component={RouterLink}
						>
							<Button
								type="button"
								size="large"
								variant="outlined"
								className={styles.buttonStyle}
								style={{
									marginRight: 15,
									color: theme.palette.button.main,
									border: theme.palette.button.borderMain,
									boxShadow: "2px 4px 8px 0 #9f9f9f45",
								}}
							>
								{t("button.cancel")}
							</Button>
						</Link>
						<Button
							type="submit"
							size="large"
							variant="contained"
							style={{
								backgroundColor: theme.palette.button.main,
								boxShadow: "2px 4px 8px 0 #00000045",
							}}
							className={styles.buttonStyle}
						>
							{t(
								`button.${
									formType === "edit" ? "save" : formType
								}`
							)}
						</Button>
						{formType === "edit" && (
							<CreditNotePrint
								customers={customers}
								listing={state}
								statusList={statusList}
								label={t("button.print")}
								typeList={typeList}
							/>
						)}
					</Box>
				</Box>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	buttonStyle: {
		minWidth: 150,
		borderRadius: 8,
	},
	flexMiddle: {
		display: "flex",
		alignItems: "center",
	},
}));
