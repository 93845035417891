import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl } from '../../helpers/ApiAction';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

import { useNavigate } from 'react-router-dom';

// Owl Carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// MUI
import { Grid, Typography, Link, OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import { FiArrowRight, FiArrowLeft } from "react-icons/fi";

export default function Dashboard() {
    const navigate = useNavigate();
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [menuProduct, setMenuProduct] = useState({});
    const [brandList, setBrandList] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchToggle, setSearchToggle] = useState(false);
    let { brand_id } = useParams();

    useEffect(() => {
        setLoading(true);
        getUrl(`/products/brand`).then(response => {
            setLoading(false);
            if (response.status) {
                setBrandList(response.data.listing);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setLoading(true);
        getUrl('/products/menu', { brand_id: brand_id, q: searchText }).then(response => {
            setLoading(false);
            if (response.status) {
                setMenuProduct(response.data.listing);
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [brand_id, searchToggle]);

    return (
        <div>
            {/* BACK TO */}
            <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                <span onClick={() => navigate(-1)} style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase', cursor:'pointer' }}>{t('button.back')}</span>
            </div>
            {/* BRAND SLIDER */}
            <div style={{ paddingBottom: 25 }}>
                {
                    _.size(brandList) &&
                    <OwlCarousel className='owl-theme' items={5} autoWidth={true} loop margin={10} nav>
                        {
                            _.map(brandList, brand => {
                                return (
                                    <div className='item' key={brand.id}>
                                        <div style={{ width: 200 }} key={brand.id}>
                                            <Link underline='none' component={RouterLink} to={`/menu/brand/` + brand.id}>
                                                <div style={{ background: 'linear-gradient(180deg, #fff, #c2c2c2)', padding: 4, borderRadius: 15, width: 164, boxShadow: '5px 3px 7px 0 #c7c7c778' }}>
                                                    <div style={{ background: 'linear-gradient(180deg, #c2c2c2, #fff)', borderRadius: 9, padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 56, overflow:'hidden' }}>
                                                        <img src={brand.logo ? brand.logo.file_name : "/images/emptyStatus/nobrandimg.png"} style={{ maxWidth: '100%', }} alt='brand_logo' />
                                                    </div>
                                                </div>
                                                <Typography className={styles.brandText}>{brand.name}</Typography>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>
                }
            </div>

            {/* SEARCH BAR */}
            <div className={styles.flexMiddle}>
                <div style={{ minWidth: 100 }}>
                    <Typography style={{ fontSize: 18, paddingRight: 15 }}><b>{t('menu.products')}</b></Typography>
                </div>
                <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <Grid item xs={12} sm={8}>
                        <FormControl style={{ width: '100%', maxWidth: '100%' }}>
                            <OutlinedInput
                                placeholder={t('menu.searchPlaceholder')}
                                value={searchText}
                                onChange={(event) => setSearchText(event.target.value)}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                        setSearchToggle(!searchToggle);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>

            {/* PRODUCTS */}
            <div>
                <Typography style={{ color: theme.palette.gray.main, padding: '25px 0' }}>{t('menu.totalSearch', { total: _.size(menuProduct) })}</Typography>
                <Grid container spacing={5}>
                    {
                        _.map(menuProduct, (product) => {
                            return (
                                <Grid item xs={6} sm={4} md={3} key={product.id}>
                                    <Link underline='none' component={RouterLink} to={`/menu/product/` + product.id}>
                                        <img src={_.size(product.images)?product.images[0].file_name:"/images/emptyStatus/noproductimg.jpg"} className={styles.productImg} alt='product_image' />
                                        <div>
                                            <Typography className={styles.productTitle}>
                                                {product.name}
                                            </Typography>
                                            <div className={styles.flexSbM} style={{ paddingTop: 5 }}>
                                                <Typography className={styles.priceText}><NumberFormat thousandSeparator={true} prefix="RM " value={product.sell_price} displayType="text" /></Typography>
                                                <FiArrowRight style={{ marginLeft: 15, fontSize: 17, color: theme.palette.gray.main }} />
                                            </div>
                                        </div>
                                    </Link>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    flexSbM: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    productImg:{
        width: '100%',
         maxWidth: '100%',
         borderRadius: 15,
         objectFit:'cover',
         [theme.breakpoints.down('sm')]: {
            height:164,
          },
          [theme.breakpoints.up('sm')]: {
            height:250,
          },
          [theme.breakpoints.up('md')]: {
            height:350,
          },
    },
    brandText: {
        color: theme.palette.gray.ltext,
        fontSize: '14px!important',
        textTransform: 'uppercase',
        width: 164,
        textAlign: 'center',
        paddingTop: 10
    },

}));

