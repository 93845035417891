import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// MUI
import { Grid, Typography, Box, Link, Breadcrumbs } from "@mui/material";

import { FiArrowLeft } from "react-icons/fi";

export default function Dashboard() {
	const styles = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<div>
			<div
				style={{ paddingBottom: 30, justifyContent: "space-between" }}
				className={styles.flexMiddle}
			>
				<div
					className={styles.flexMiddle}
					style={{ paddingBottom: 30 }}
				>
					<FiArrowLeft
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							marginRight: 10,
						}}
					/>
					<span
						onClick={() => navigate(-1)}
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							textTransform: "uppercase",
							cursor: "pointer",
						}}
					>
						{t("button.back")}
					</span>
				</div>
				<div>
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/dashboard"
						>
							{t("title.dashboard")}
						</Link>
						<Typography color="text.primary">
							{t("title.accounting")}
						</Typography>
					</Breadcrumbs>
				</div>
			</div>
			<div
				className={styles.flexCenterMiddle}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					minHeight: "70vh",
				}}
			>
				<Grid container spacing={3}>
					<Grid
						item
						xs={12}
						sm={4}
						className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
					>
						<Link
							underline="none"
							component={RouterLink}
							to={`/drawer-summary`}
						>
							{/* <Box className={styles.menuStyle} style={{backgroundColor:'#5e4536', color:'#fff'}}>
                                <Typography>{t('accounting.drawerReport')}</Typography>
                            </B/ox> */}
							<Box className={styles.menuStyle}>
								<img
									src="/images/icons/drawer.png"
									alt="drawer"
									className={styles.menuImg}
								/>
								<Typography>
									{t("accounting.drawerReport")}
								</Typography>
							</Box>
						</Link>
					</Grid>
					<Grid
						item
						xs={12}
						sm={4}
						className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
					>
						<Link
							underline="none"
							to={`/drawer-adjustment`}
							component={RouterLink}
						>
							{/* <Box className={styles.menuStyle} style={{ backgroundColor: '#c4a283', color: '#fff' }}>
                                <Typography>{t('accounting.cashInOut')}</Typography>
                            </Box> */}
							<Box className={styles.menuStyle}>
								<img
									src="/images/icons/inout.png"
									alt="cashInOut"
									className={styles.menuImg}
								/>
								<Typography>
									{t("accounting.cashInOut")}
								</Typography>
							</Box>
						</Link>
					</Grid>
					<Grid
						item
						xs={12}
						sm={4}
						className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
					>
						<Link
							underline="none"
							to={`/daily-settlements`}
							component={RouterLink}
						>
							{/* <Box className={styles.menuStyle} style={{ backgroundColor: '#969493', color: '#fff' }}>
                                <Typography>{t('accounting.closingReport')}</Typography>
                            </Box> */}
							<Box className={styles.menuStyle}>
								<img
									src="/images/icons/closing.png"
									alt="closingReport"
									className={styles.menuImg}
								/>
								<Typography>
									{t("accounting.closingReport")}
								</Typography>
							</Box>
						</Link>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	menuStyle: {
		background: "#fff",
		borderRadius: 15,
		boxShadow: "2px 2px 10px 5px rgb(209 209 209 / 30%)",
		padding: 10,
		width: 250,
		height: 250,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		color: theme.palette.gray.dtext,
	},
	menuImg: {
		maxWidth: "100%",
		width: "100%",
	},
	menuText: {
		textAlign: "center",
		fontSize: "14px!important",
		paddingTop: 15,
	},
	justifyCenter: {
		justifyContent: "center",
	},
	flexMiddle: {
		display: "flex",
		alignItems: "center",
		width: "100%",
	},
	flexTtop: {
		display: "flex",
		alignItems: "flex-start",
	},
}));
