import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl } from '../../helpers/ApiAction';
import _ from 'lodash';

import Step1Cart from './Step1Cart';
import Step2Customer from './Step2Customer';
import Step3Payment from './Step3Payment';

// MUI
import { Box, Stepper, Step, StepButton, Link, Typography } from '@mui/material';

// ICONS
import { FiArrowLeft } from "react-icons/fi";

export default function Index() {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [cartList, setCartList] = useState({});
    const [sumTotal, setSumTotal] = useState(0);
    const [customerId, setCustomerId] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [paymentMethodList, setPaymentMethodList] = useState({});
    const [selectedPaymentList, setSelectedPaymentList] = useState([]);
    const [totalPayment, setTotalPayment] = useState(0);
    const [description, setDescription] = useState('');

    const steps = [t('pos.productOrder'), t('pos.customerDetails'), t('pos.payment')];

    useEffect(() => {
        setLoading(true);
        getUrl(`/carts`).then(response => {
            setLoading(false);
            if (response.status) {
                setCartList(response.data.listing);
                setPaymentMethodList(response.data.payment_method);
                calculateSumTotal(response.data.listing);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    // STEPPER
    const handleNext = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        setActiveStep(activeStep + 1);
    };
    const handleBack = () => {
        const newCompleted = completed;
        newCompleted[activeStep - 1] = false;
        setCompleted(newCompleted);
        setActiveStep(activeStep - 1);
    };

    const calculateSumTotal = (listing) => {
        let sum = 0;
        _.map(listing, item => {
            if (item.status === 1) {
                sum += parseFloat(item.sell_price * item.quantity);
            }
        })
        setSumTotal(parseFloat(sum).toFixed(2));
    }

    return (
        <Box sx={{ width: '100%' }}>
            {/* BACK BUTTON */}
            <Link underline='none' component={RouterLink} to={`/dashboard`}>
                <div className={styles.flexMiddle}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.backTo')}{t('title.dashboard')}</Typography>
                </div>
            </Link>

            {/* STEPPER */}
            <Stepper nonLinear activeStep={activeStep} style={{ padding: 20 }}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit">
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            {
                activeStep === 0 &&
                <Step1Cart handleNext={handleNext} cartList={cartList} setCartList={setCartList} calculateSumTotal={calculateSumTotal} sumTotal={sumTotal} />
            }
            {
                activeStep === 1 &&
                <Step2Customer handleBack={handleBack} handleNext={handleNext} customerId={customerId} setCustomerId={setCustomerId} searchText={searchText} setSearchText={setSearchText} />
            }
            {
                activeStep === 2 &&
                <Step3Payment handleBack={handleBack} paymentMethodList={paymentMethodList} cartList={cartList} sumTotal={sumTotal} selectedPaymentList={selectedPaymentList} setSelectedPaymentList={setSelectedPaymentList} totalPayment={totalPayment} setTotalPayment={setTotalPayment} customerId={customerId} description={description} setDescription={setDescription} />
            }
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
}));