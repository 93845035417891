import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// MUI
import { Grid, Typography, Link } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Dashboard() {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { permissions, role } = useSelector(state => state.general);
    const { id } = useSelector(state => state.user);

    return (
        <div>

            <div style={{ paddingBottom: '5%' }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.dtext }}><b>{t('dashboard.general')}</b></Typography>
                <Grid container spacing={3}>
                    {/* {
                        _.includes(permissions, 'warranty') &&
                        <Grid item xs={4} sm={2} >
                            <Link underline='none' to={`/warranty`} component={RouterLink}>
                                <div className={styles.menuStyle} >
                                    <img src='/images/dashboard/warranty.png' className={styles.menuImg} alt="warranty" />
                                </div>
                                <Typography className={styles.menuText}>
                                    {t('dashboard.warranty')}
                                </Typography>
                            </Link>
                        </Grid>
                    }
                    {
                        _.includes(permissions, 'customer') &&
                        <Grid item xs={4} sm={2} >
                            <Link underline='none' to={`/customers`} component={RouterLink}>
                                <div className={styles.menuStyle} >
                                    <img src='/images/dashboard/customer.png' className={styles.menuImg} alt="customer" />
                                </div>
                                <Typography className={styles.menuText}>
                                    {t('dashboard.customer')}
                                </Typography>
                            </Link>
                        </Grid>
                    }
                    {
                        _.includes(permissions, 'supplier') &&
                        <Grid item xs={4} sm={2} >
                            <Link underline='none' to={`/suppliers`} component={RouterLink}>
                                <div className={styles.menuStyle} >
                                    <img src='/images/dashboard/supplier.png' className={styles.menuImg} alt="supplier" />
                                </div>
                                <Typography className={styles.menuText}>
                                    {t('dashboard.supplier')}
                                </Typography>
                            </Link>
                        </Grid>
                    } */}
                    {
                        _.includes(permissions, 'menu') &&
                        <Grid item xs={4} sm={2} >
                            <Link underline='none' to={`/menu`} component={RouterLink}>
                                <div className={styles.menuStyle} >
                                    <img src='/images/dashboard/menu.png' className={styles.menuImg} alt="menu" />
                                </div>
                                <Typography className={styles.menuText}>
                                    {t('dashboard.menu')}
                                </Typography>
                            </Link>
                        </Grid>
                    }
                    {
                        (['product-create', 'tradein-create'].some(permi => permissions.includes(permi))) &&
                        <Grid item xs={4} sm={2} >
                            <Link underline='none' to={`/products`} component={RouterLink}>
                                <div className={styles.menuStyle} >
                                    <img src='/images/dashboard/product.png' className={styles.menuImg} alt="product" />
                                </div>
                                <Typography className={styles.menuText}>
                                    {t('title.products')}
                                </Typography>
                            </Link>
                        </Grid>
                    }
                    {
                        _.includes(permissions, 'order') &&
                        <Grid item xs={4} sm={2} >
                            <Link underline='none' to={`/orders`} component={RouterLink}>
                                <div className={styles.menuStyle} >
                                    <img src='/images/dashboard/orders.png' className={styles.menuImg} alt="checkout" />
                                </div>
                                <Typography className={styles.menuText}>
                                    {t('dashboard.orders')}
                                </Typography>
                            </Link>
                        </Grid>
                    }
                </Grid>
            </div>
            <div style={{ paddingBottom: '5%' }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.dtext }}><b>{t('dashboard.management')}</b></Typography>
                <Grid container spacing={3}>
                    {
                        _.includes(permissions, 'report') &&
                        <Grid item xs={4} sm={2} >
                            <Link underline='none' to={`/salesReport`} component={RouterLink}>
                                <div className={styles.menuStyle} >
                                    <img src='/images/dashboard/sales.png' className={styles.menuImg} alt="salesreport" />
                                </div>
                                <Typography className={styles.menuText}>
                                    {t('dashboard.salesReport')}
                                </Typography>
                            </Link>
                        </Grid>
                    }
                    {
                        role.includes('sales') ?
                        <Grid item xs={4} sm={2} >
                            <Link underline='none' to={`/salesReport/${id}`} component={RouterLink}>
                                <div className={styles.menuStyle} >
                                    <img src='/images/dashboard/sales.png' className={styles.menuImg} alt="salesreport" />
                                </div>
                                <Typography className={styles.menuText}>
                                    {t('report.individualSales')}
                                </Typography>
                            </Link>
                        </Grid>
                        :null
                    }
                    {
                        _.includes(permissions, 'commission') &&
                        <Grid item xs={4} sm={2} >
                            <Link underline='none' to={`/commissions`} component={RouterLink}>
                                <div className={styles.menuStyle} >
                                    <img src='/images/dashboard/comission.png' className={styles.menuImg} alt="commission" />
                                </div>
                                <Typography className={styles.menuText}>
                                    {t('dashboard.commission')}
                                </Typography>
                            </Link>
                        </Grid>
                    }
                    {/* <Grid item xs={4} sm={2} >
                        <Link underline='none' to={`/accounting`} component={RouterLink}>
                            <div className={styles.menuStyle} >
                                <img src='/images/dashboard/account.png' className={styles.menuImg} alt="account" />
                            </div>
                            <Typography className={styles.menuText}>
                                {t('dashboard.accounting')}
                            </Typography>
                        </Link>
                    </Grid> */}
                    {/* {
                        _.includes(permissions, 'staff') &&
                        <Grid item xs={4} sm={2} >
                            <Link underline='none' to={`/staffs`} component={RouterLink}>
                                <div className={styles.menuStyle} >
                                    <img src='/images/dashboard/staff.png' className={styles.menuImg} alt="staff" />
                                </div>
                                <Typography className={styles.menuText}>
                                    {t('dashboard.staff')}
                                </Typography>
                            </Link>
                        </Grid>
                    } */}
                    {
                        _.includes(permissions, 'credit-note') &&
                        <Grid item xs={4} sm={2} >
                            <Link underline='none' to={`/credit-notes`} component={RouterLink}>
                                <div className={styles.menuStyle} >
                                    <img src='/images/dashboard/creditnote.png' className={styles.menuImg} alt="credit note" />
                                </div>
                                <Typography className={styles.menuText}>
                                    {t('title.creditNotes')}
                                </Typography>
                            </Link>
                        </Grid>
                    }


                    <Grid item xs={4} sm={2} >
                        <Link underline='none' to={`/setting`} component={RouterLink}>
                            <div className={styles.menuStyle} >
                                <img src='/images/dashboard/setting.png' className={styles.menuImg} alt="setting" />
                            </div>
                            <Typography className={styles.menuText}>
                                {t('dashboard.setting')}
                            </Typography>
                        </Link>
                    </Grid>
                    {
                        _.includes(permissions, 'expense') &&
                        <Grid item xs={4} sm={2} >
                            <Link underline='none' to={`/expenses`} component={RouterLink}>
                                <div className={styles.menuStyle} >
                                    <img src='/images/dashboard/comission.png' className={styles.menuImg} alt="expense" />
                                </div>
                                <Typography className={styles.menuText}>
                                    {t('dashboard.expenses')}
                                </Typography>
                            </Link>
                        </Grid>
                    }
                </Grid>
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    menuStyle: {
        background: '#fff',
        borderRadius: 15,
        boxShadow: '2px 2px 10px 5px rgb(209 209 209 / 30%)',
        padding: 10,
    },
    menuImg: {
        maxWidth: '100%',
        width: '100%'
    },
    menuText: {
        textAlign: 'center',
        fontSize: '14px!important',
        paddingTop: 15
    }
}));
