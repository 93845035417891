import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl, postUrl } from '../../helpers/ApiAction';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import PropTypes from "prop-types";

// Owl Carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// MUI
import { Grid, Typography, Box, Link, Hidden, Drawer, Button, List, TextField, Divider, Modal } from '@mui/material';

// ICONS
import { FiPlus, FiMinus, FiArrowLeft, FiChevronsRight } from "react-icons/fi";

// AddCartModal
const cartStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '80%',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
};

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
	const { onChange, ...other } = props;
  
	return (
	  <NumberFormat
		{...other}
		getInputRef={ref}
		onValueChange={(values) => {
		  onChange({
			target: {
			  name: props.name,
			  value: values.value,
			},
		  });
		}}
		thousandSeparator
		isNumericString
	  />
	);
  });
  
  NumberFormatCustom.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
  };

export default function MenuProductDetails() {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [product, setProduct] = useState({});
    const [brandList, setBrandList] = useState({});
    const [cartDialogOpen, setCartDialogOpen] = useState(false);
    const [adjustSellPrice, setAdjustSellPrice] = useState(0);
    const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
    const [showHiddenPrice, setShowHiddenPrice] = useState(false);
    const [staffPassword, setStaffPassword] = useState('');
    const { name } = useSelector(state => state.user);
    let navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        setLoading(true);
        getUrl(`/products/brand`).then(response => {
            setLoading(false);
            if (response.status) {
                setBrandList(response.data.listing);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setLoading(true);
        getUrl(`/products/menu/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                setProduct(response.data.listing);
                setAdjustSellPrice(response.data.listing.sell_price);
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);

    // Drawer
    const hiddenPriceCheckDrawer = () => (
        <Box
            sx={{ width: 350 }}
            role="presentation"
        >
            
            <Button onClick={handleDrawerClose} style={{ backgroundColor: theme.palette.button.main, color: '#fff', height: 48, marginTop: 172, marginLeft: -64, position: 'fixed', borderRadius: '11px 0 0 11px', boxShadow:'inset -5px 0px 9px 0 #0000002e' }} className={styles.translateXAnimation} ><FiChevronsRight style={{fontSize:25}} /></Button>
            {
                showHiddenPrice ?
                    <List style={{ padding: '25px 25px' }}>
                        <Typography style={{ color: theme.palette.secondary.main, fontSize: 25, fontWeight: 'bold' }}>
                            {t('menu.listing')}
                        </Typography>
                        {/* <Typography style={{ color: theme.palette.gray.ltext, fontSize: 14 }}>
                            Total: 2 items
                        </Typography> */}
                        <Grid container style={{ margin: 0, padding: '15px 0', maxWidth: '100%' }}>
                            <Grid item xs={5} sm={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={_.size(product.images)?product.images[0].file_name:"/images/emptyStatus/noproductimg.jpg"} style={{ width: '100%' }} alt="product_image" />
                            </Grid>
                            <Grid item xs={7} sm={8} style={{ paddingLeft: 8 }}>
                                <Typography style={{ color: theme.palette.secondary.main, fontSize: 15 }}>{product.name}</Typography>
                                <div style={{ color: theme.palette.gray.ltext, paddingTop: 10 }}>
                                    <Typography style={{ fontSize: 14 }}><b>{t('menu.minPrice') + ': '}<NumberFormat thousandSeparator={true} value={product.min_sell_price} displayType="text" /></b></Typography>
                                    <Typography style={{ fontSize: 14 }}>{t('menu.costPrice') + ': '}<NumberFormat thousandSeparator={true} value={product.cost_price2} displayType="text" /></Typography>
                                    <Typography style={{ fontSize: 14, marginTop: 10 }}>{t('menu.createdBy') + ': '}{product.created_by_name}</Typography>
                                    <Typography style={{ fontSize: 14 }}>{t('menu.updatedBy') + ': '}{product.updated_by_name}</Typography>
                                    <Typography style={{ fontSize: 14 }}>{t('menu.approvedBy') + ': '}{product.approved_by_name}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Button style={{ width: '100%', background: theme.palette.secondary.main, textTransform: 'uppercase', color: '#fff', textAlign: 'center', marginTop: 25, padding: '8px 15px', borderRadius: 8 }} onClick={handleDrawerClose}>
                            <Typography>{t('button.close')}</Typography>
                        </Button>
                    </List>
                    :
                    <List style={{ padding: '25px 25px' }}>
                        <Typography style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                            {t('menu.passwordVerify')}
                        </Typography>
                        <div style={{ padding: '50px 0 25px' }}>
                            <Typography style={{ color: theme.palette.gray.main, fontSize: 12 }}>{t('menu.staffUsername')}</Typography>
                            <Typography style={{ color: theme.palette.secondary.main, fontSize: 18 }}>{name}</Typography>
                        </div>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField id="standard-basic" label={t('textField.password')} variant="outlined" style={{ margin: 0 }} type="password" onChange={(event) => setStaffPassword(event.target.value)} />
                        </Box>
                        <Button style={{ width: '100%', background: theme.palette.secondary.main, color: '#fff', textAlign: 'center', marginTop: 25, padding: '8px 15px', borderRadius: 8 }} onClick={verifyStaffPassword} >
                            <Typography>{t('button.proceed')}</Typography>
                        </Button>
                    </List>
            }
        </Box>
    );
    const verifyStaffPassword = () => {
        getUrl(`/users/password-validation`, { password: staffPassword }).then(response => {
            if (response.status) {
                setShowHiddenPrice(true);
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }
    const handleDrawerClose = () => {
        setShowHiddenPrice(false);
        setRightDrawerOpen(false);
        setStaffPassword('');
    }

    // Dialog
    const handleAmountChange = (type, amount) => {
        if (type === 'amount') {
            setAdjustSellPrice(parseFloat(amount));
        } else if (type === 'add') {
            setAdjustSellPrice(parseFloat(parseFloat(adjustSellPrice) + parseFloat(amount)).toFixed(2));
        } else if (type === 'deduct') {
            setAdjustSellPrice(parseFloat(parseFloat(adjustSellPrice) - parseFloat(amount)).toFixed(2));
        }
    }
    const handleCartModalClose = () => {
        setAdjustSellPrice(product.sell_price);
        setCartDialogOpen(false);
    }
    const addToCart = () => {
        setLoading(true);
        postUrl(`/carts`, { product_id: id, quantity: 1, sell_price: adjustSellPrice }).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                navigate('/checkout');
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div>
            {/* BRAND SLIDER */}
            <div style={{ paddingBottom: 25 }}>
                {
                    _.size(brandList) &&
                    <OwlCarousel className='owl-theme' items={5} autoWidth={true} loop margin={10} nav>
                        {
                            _.map(brandList, brand => {
                                return (
                                    <div className='item' key={brand.id}>
                                        <div style={{ width: 200 }}>
                                            <Link underline='none' component={RouterLink} to={`/menu/brand/` + brand.id}>
                                                <div style={{ background: 'linear-gradient(180deg, #fff, #c2c2c2)', padding: 4, borderRadius: 15, width: 164, boxShadow: '5px 3px 7px 0 #c7c7c778' }}>
                                                    <div style={{ background: 'linear-gradient(180deg, #c2c2c2, #fff)', borderRadius: 9, padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 56 }}>
                                                        <img src={brand.logo ? brand.logo.file_name : ''} style={{ maxWidth: '100%', }} alt="brand_logo" />
                                                    </div>
                                                </div>
                                                <Typography className={styles.brandText}>{brand.name}</Typography>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>
                }
            </div>

            {/* BACK TO & SHOW MIN_SELL_PRICE */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 50 }}>
                <div className={styles.flexMiddle}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <span onClick={() => navigate(-1)} style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase', cursor:'pointer' }}>{t('button.back')}</span>
                </div>
                <div>
                    <div className={styles.checkPriceButton} onClick={() => setRightDrawerOpen(!rightDrawerOpen)}><FiChevronsRight style={{ fontSize: 25 }} /></div>
                    <Drawer
                        anchor="right"
                        open={rightDrawerOpen}
                        onClose={handleDrawerClose}
                    >
                        {hiddenPriceCheckDrawer()}
                    </Drawer>
                </div>
            </div>

            {/* PRODUCT DETAILS */}
            <Grid container spacing={3} style={{ marginTop: 0 }}>
            
                <Grid item xs={12} sm={6} md={3} style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                    {
                        _.size(product.images)
                            ?
                            <OwlCarousel className='owl-theme' items={1} loop margin={10} nav>
                                {
                                    _.map(product.images, (image, key) => {
                                        return (
                                            <div className='item' key={key}>
                                                <img src={image.file_name} style={{ width: '100%', borderRadius: 15 }} alt="product_image" />
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                            : null
                    }
                </Grid>

                <Grid item xs={12} sm={6} md={9}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={8}>
                            <Typography style={{ textTransform: 'uppercase', color: theme.palette.gray.dtext, fontWeight: 'bold', fontSize: 28 }}>{product.brand ? product.brand.name : ''}</Typography>
                            <Hidden only={'xs'}>
                                <div className={styles.flexMiddle} style={{ marginTop: 10 }}>
                                    {
                                        product.extra && product.extra.can_accept_tradein
                                            ?
                                            <img src={"/images/product/tradein-"+i18n.language+".png"} style={{ width: 150, borderRadius: 10, boxShadow: '5px 3px 7px 0 #c7c7c778', maxWidth: '100%', marginRight: 15 }} alt="accept_trade_in" />
                                            :
                                            null
                                    }
                                    {
                                        product.extra && product.extra.can_temp_sell
                                            ?
                                            <img src={"/images/product/tempsell-"+i18n.language+".png"} style={{ width: 150, borderRadius: 10, boxShadow: '5px 3px 7px 0 #c7c7c778', maxWidth: '100%', }} alt="accept_temp_sell" />
                                            :
                                            null
                                    }
                                </div>
                            </Hidden>
                            <Hidden smUp>
                                <div style={{ marginTop: 10 }}>
                                    {
                                        product.extra && product.extra.can_accept_tradein
                                            ?
                                            <img src={"/images/product/tradein-"+i18n.language+".png"} style={{ width: 250, borderRadius: 10, boxShadow: '5px 3px 7px 0 #c7c7c778', maxWidth: '100%' }} alt="accept_trade_in" />
                                            :
                                            null
                                    }
                                    {
                                        product.extra && product.extra.can_temp_sell
                                            ?
                                            <img src={"/images/product/tempsell-"+i18n.language+".png"} style={{ width: 250, marginTop: 15, borderRadius: 10, boxShadow: '5px 3px 7px 0 #c7c7c778', maxWidth: '100%', }} alt="accept_temp_sell" />
                                            :
                                            null
                                    }
                                </div>
                            </Hidden>
                            <div style={{ width: 120, height: 8, backgroundColor: '#c4c4c4', margin: '25px 0' }} />
                            <Typography className={styles.titleText}>
                                {product.name}
                            </Typography>
                            <div style={{ paddingTop: 15 }}>
                                <Typography className={styles.contentText}>{t('menu.model') + ': ' + (product.model ? product.model : '-')}</Typography>
                                <Typography className={styles.contentText}>{t('menu.refNo') + ': ' + (product.ref_no ? product.ref_no : '-')}</Typography>
                                <Typography className={styles.contentText}>{t('menu.diameter') + ': ' + (product.diameter ? product.diameter : '-')}</Typography>
                                <Typography className={styles.contentText}>{t('menu.whatsInBox') + ': ' + (product.whats_in_box ? product.whats_in_box : '-')}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box style={{ borderRadius: 15, boxShadow: '#c7c7c7 0px 0px 12px 0px', padding: 15, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <div>
                                    <Typography className={styles.priceText}>
                                        <NumberFormat thousandSeparator={true} prefix="RM " value={product.sell_price} displayType="text" />
                                    </Typography>
                                    <div style={{width:'100%'}}>
                                        <div style={{ paddingBottom: 15 }}>
                                            <Typography className={styles.contentText} style={{ color: theme.palette.secondary.ltext }}>
                                                {t('menu.serialNo')}
                                            </Typography>
                                            <Typography className={styles.contentText} style={{ lineHeight: 1.5 }}>
                                                {product.serial_no ? product.serial_no : '-'}
                                            </Typography>
                                        </div>
                                        <div style={{ paddingBottom: 15 }}>
                                            <Typography className={styles.contentText} style={{ color: theme.palette.secondary.ltext }}>
                                                {t('menu.factoryWarranty')}
                                            </Typography>
                                            <Typography className={styles.contentText} style={{ lineHeight: 1.5 }}>
                                                {t('menu.startDate') + ': ' + (product.warranty_period ? product.warranty_period.factory.sdate ? product.warranty_period.factory.sdate : '-' : '-')}
                                            </Typography>
                                            <Typography className={styles.contentText} style={{ lineHeight: 1.5 }}>
                                                {t('menu.endDate') + ': ' + (product.warranty_period ? product.warranty_period.factory.edate ? product.warranty_period.factory.edate : '-' : '-')}
                                            </Typography>
                                        </div>
                                        <div style={{ paddingBottom: 15 }}>
                                            <Typography className={styles.contentText} style={{ color: theme.palette.secondary.ltext }}>
                                                {t('menu.shopWarranty')}
                                            </Typography>
                                            <Typography className={styles.contentText} style={{ lineHeight: 1.5 }}>
                                                {t('menu.period') + ': ' + (product.warranty_period ? product.warranty_period.shop.period ? product.warranty_period.shop.period : '-' : '-')}
                                            </Typography>
                                        </div>
                                        <div style={{ paddingBottom: 15 }}>
                                            <Typography className={styles.contentText} style={{ color: theme.palette.secondary.ltext }}>
                                                {t('menu.description')}
                                            </Typography>
                                            <Typography className={styles.contentText} style={{ lineHeight: 1.5 }}>
                                                {product.description ? product.description : '-'}
                                            </Typography>
                                        </div>
                                    </div>
                                    <Button style={{ backgroundColor: theme.palette.button.main, borderRadius: 48, textAlign: 'center', padding: 15, color: '#fff', boxShadow: '3px 3px 6px 0 #8f6f4780', width: '100%' }} onClick={() => setCartDialogOpen(true)}>
                                        <Typography style={{ textTransform: 'capitalize' }}>{t('button.addToCart')}</Typography>
                                    </Button>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* ADD TO CART MODAL */}
            <Modal
                open={cartDialogOpen}
                onClose={handleCartModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={cartStyle}>
                    <center>
                        <img src={_.size(product.images)?product.images[0].file_name:"/images/emptyStatus/noproductimg.jpg"} style={{ width: 150, borderRadius: 10, boxShadow: '5px 3px 7px 0 #c7c7c778', maxWidth: '100%' }} alt="product_image" />
                    </center>
                    <div style={{ textAlign: 'center', paddingTop: 15, color: theme.palette.gray.dtext }}>
                        <Typography>{product.name}</Typography>
                        <Typography style={{ color: theme.palette.gray.ltext, paddingTop: 25 }}>{t('menu.amount') + ' (RM)'}</Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ backgroundColor: theme.palette.secondary.main, width: 30, height: 30, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '2px 2px 6px 0 #8f72547a' }} onClick={() => handleAmountChange('deduct', 100)}>
                                <FiMinus style={{ fontSize: 20, color: '#fff' }} />
                            </div>
                            <TextField
                                sx={{ input: { color: theme.palette.secondary.main, fontSize: 25, fontWeight: "bold", textAlign: "center" } }}
                                variant="standard"
                                // type="number"
                                value={adjustSellPrice}
                                InputProps={{ 
                                    disableUnderline: true,
                                    inputComponent: NumberFormatCustom,
                                 }}
                                onChange={(event) => handleAmountChange('amount', event.target.value)}
                                name="adjustSellPrice"
                            />
                            <div style={{ backgroundColor: theme.palette.secondary.main, width: 30, height: 30, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '2px 2px 6px 0 #8f72547a' }} onClick={() => handleAmountChange('add', 100)}>
                                <FiPlus style={{ fontSize: 20, color: '#fff' }} />
                            </div>
                        </div>
                        <div style={{ paddingTop: 25, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <Button style={{ border: '2px solid #cdac89', width: 'fit-content', minWidth: 150, padding: '8px 15px', borderRadius: 5, boxShadow: '2px 2px 6px 0 #8f72547a' }} onClick={handleCartModalClose}>
                                <Typography style={{ color: theme.palette.button.main }}>{t('button.cancel')}</Typography>
                            </Button>
                            <Button style={{ backgroundColor: theme.palette.button.main, width: 'fit-content', minWidth: 150, padding: '8px 15px', borderRadius: 5, boxShadow: '2px 2px 6px 0 #8f72547a' }} onClick={addToCart}>
                                <Typography style={{ color: '#fff' }}>{t('button.addToCart')}</Typography>
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    titleText: {
        color: theme.palette.gray.dtext,
        fontSize: '28px!important',
        fontWeight: 'bold!important',
    },
    contentText: {
        color: theme.palette.gray.dtext,
        lineHeight: 2,
        fontSize: '18px!important',
    },
    priceText: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold!important',
        fontSize: '32px!important',
        textAlign: 'center',
        paddingBottom: '20px!important'
    },
    brandText: {
        color: theme.palette.gray.ltext,
        fontSize: '14px!important',
        textTransform: 'uppercase',
        width: 164,
        textAlign: 'center',
        paddingTop: 10
    },
    checkPriceButton: {
        backgroundColor: theme.palette.button.main,
        color: '#fff',
        height: 48,
        marginTop: -25,
        borderRadius: '11px 0 0 11px',
        position: 'absolute!important',
        boxShadow:'0 4px 3px 0 #00000026',
        right: 0,
        width:70,
        display:'flex', 
        justifyContent:'center',
        alignItems:'center',
        paddingRight:0,
            transition:'all 0.30s 0.1s',
        "&:hover": {
            backgroundColor: theme.palette.button.main,
            paddingRight:40,
            transition:'all 0.30s 0.1s ease-in'
          },
        cursor: 'pointer',
    },
    translateXAnimation:{
        paddingLeft:0,
            transition:'all 0.30s 0.1s ease-in',
        "&:hover": {
            paddingLeft:27,
            transition:'all 0.30s 0.1s ease-in'
          },
    },
}));

