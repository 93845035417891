import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

// MUI
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, Button, Box, List, ListSubheader, ListItem, ListItemText, ListItemSecondaryAction, Switch} from '@mui/material';

var th = ['', ' thousand', ' million', ' billion', ' trillion', ' quadrillion', ' quintillion'];
var dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
var tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
var tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

function toWords(s) {
    s = s.toString();
    s = s.replace(/[\, ]/g, '');
    if (s != parseFloat(s)) return 'not a number';
    var x = s.indexOf('.');
    if (x == -1) x = s.length;
    if (x > 15) return 'too big';
    var n = s.split('');
    var str = '';
    var sk = 0;
    for (var i = 0; i < x; i++) {
        if ((x - i) % 3 == 2) {
            if (n[i] == '1') {
                str += tn[Number(n[i + 1])] + ' ';
                i++;
                sk = 1;
            } else if (n[i] != 0) {
                str += tw[n[i] - 2] + ' ';
                sk = 1;
            }
        } else if (n[i] != 0) {
            str += dg[n[i]] + ' ';
            if ((x - i) % 3 == 0) str += 'hundred ';
            sk = 1;
        }
        if ((x - i) % 3 == 1) {
            if (sk) str += th[(x - i - 1) / 3] + ' ';
            sk = 0;
        }
    }
    if (x != s.length) {
        var y = s.length;
        str += 'point ';
        for (var i = x + 1; i < y; i++) str += dg[n[i]] + ' ';
    }
    return str.replace(/\s+/g, ' ');
}


export default function DetailPrint({ order, orderStatusList, selectedPaymentList, paymentMethodList, totalPayment }) {
    const theme = useTheme();
    const styles = useStyles();
    const { t } = useTranslation();

    const [showHideAddOnModal, setShowHideAddOnModal] = useState(false);
    const [checked, setChecked] = useState([]);
    const [custChecked, setCustChecked] = useState(false);
    const [orderWithAddOn, setOrderWithAddOn] = useState(false);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleCustToggle = (event) => {
        setCustChecked(event.target.checked);
    };

    useEffect(() => {
        const newChecked = [...checked];
        let orderHasAddOn = false;
        _.map(order.order_details, detail => {
            if(_.size(detail.add_ons) > 0){
                orderHasAddOn = true;
                _.map(detail.add_ons, (addon, key) => {
                    newChecked.push(detail.id+"_"+key);
                })
            }
        })
        setOrderWithAddOn(orderHasAddOn);
        setChecked(newChecked);
        // eslint-disable-next-line
    }, [order])
    

    const printInfo = () => {
        var content = document.getElementById("ifmcontentstoprint");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    var itemcount = 1;
    var totalPaid = 0;
    var balance = 0;
    var hasInstalment = false;
    _.map(order.order_payments, payment => {
        if (payment.payment_method!=='instalment' && payment.status === 40) {
            totalPaid += parseFloat(payment.amount);
        }
        if (payment.payment_method==='instalment' && payment.instalment_details && payment.instalment_details.length>0) {
            _.map(payment.instalment_details, instalment => {
                if(instalment.status===40){
                    hasInstalment = true;
                    totalPaid += parseFloat(instalment.amount);
                }
            })
        }
    })
    balance = parseFloat(order.total_price) - parseFloat(totalPaid);

    return (
        <div>
            <div style={{ padding: '8px 15px', minWidth: 130, maxWidth: '100%', borderRadius: 8, background: theme.palette.secondary.main, boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center', cursor: 'pointer' }} onClick={()=>setShowHideAddOnModal(true)} /* onClick={() => orderWithAddOn ? setShowHideAddOnModal(true) : printInfo()} */>
                <Typography style={{ color: '#fff', fontSize: 15, textAlign: 'center', textTransform: 'uppercase' }}>{t('button.print')}</Typography>
            </div>
            <iframe id="ifmcontentstoprint" style={{ height: 0, width: 0, position: "absolute", display: "none" }} title="order-detail">
                {
                    _.size(order) > 0 ?
                        <div style={{ margin: '0 20px 20px', color: '#4a4a4a' }}>
                            {/* <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <h1>{t('order.printTitle')}</h1>
                            </div> */}
                            {/* LETTERHEAD */}
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '2px solid #0007', paddingBottom: 10, paddingRight:20 }}>
                                <div>
                                    <img src='/images/logo-black.png' alt="logo" style={{width:150, height:150, objectFit:'contain'}}/>
                                </div>
                                <div style={{ paddingLeft: 20, paddingTop: 20, textAlign: 'left' }}>
                                    <p style={{ fontSize: 21, margin: 0 }}><b>SWISS HOUR EMPIRE SDN BHD</b></p>
                                    <p style={{ fontSize: 12, margin: 0 }}>202101013694 (1413993V)</p>
                                    <p style={{ fontSize: 15, margin: 0 }}>Lot No. G/LG 14.00 Lower ground Floor, Grand Hotel, 69000,<br /> Genting Highlands, Pahang, Malaysia</p>
                                    <p style={{ fontSize: 15, margin: 0 }}>Tel: +60 16-232 3502 <span style={{ padding: '0 15px' }}>|</span>    swisshourempire.com.my</p>
                                    {/* <p style={{fontSize:15, margin:0}}>+603 2110 6811 <span style={{padding:'0 15px'}}>|</span> swisshourempire@gmail.com <span style={{padding:'0 15px'}}>|</span> swisshourempire.com.my</p> */}
                                </div>
                            </div>


                            {/* HEADER INFO */}
                            <div style={{ lineHeight: '40%', fontFamily: 'arial', marginTop: 20 }}>
                                <div style={{ position: 'relative' }}>
                                    <p style={{ margin: 0, fontSize: 18, textTransform: 'uppercase', textAlign: 'center', width: '100%' }}><b>{t('order.orderReceipt')}</b></p>
                                    <div style={{width: 'fit-content', position: 'absolute', right: 0, top: 0 }}>
                                        <p style={{ margin: 0, fontSize: 18, textAlign: 'right', }}><b>{order.order_no}</b></p>
                                        
                                    </div>
                                </div>
                            </div>

                            {/* CUSTOMER INFO */}
                            <div style={{ lineHeight: '60%', fontFamily: 'arial', }}>
                                {/* <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <h3>{t('order.customerInfo')}</h3>
                                </div> */}
                                <p style={{ textTransform: 'uppercase' }}>Cash</p>
                                <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', paddingTop: 15 }}>
                                    {/* display: 'grid', */}
                                    <div style={{ gridColumn: 2, width: '50%' }}>
                                        {custChecked ? 
                                        <div style={{ float: 'left', textAlign: 'left', fontSize: 13 }}>
                                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                                <p style={{ minWidth: 100, margin: 0, textAlign: 'left' }}>{t('user.name')} </p>
                                                <p style={{ textTransform: 'uppercase', margin: 0 }}>: {order.customer.name}</p>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0'}}>
                                                <p style={{ minWidth: 100, margin: 0, textAlign: 'left' }}>{t('user.email')} </p>
                                                <p style={{ textTransform: 'uppercase', margin: 0 }}>: {order.customer.email}</p>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0'}}>
                                                <p style={{ minWidth: 100, margin: 0, textAlign: 'left' }}>{t('user.mobile')} </p>
                                                <p style={{ textTransform: 'uppercase', margin: 0 }}>: {order.customer.mobile}</p>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0'}}>
                                                <p style={{ minWidth: 100, margin: 0, textAlign: 'left' }}>{t('user.address')} </p>
                                                <p style={{ textTransform: 'uppercase', margin: 0 }}>: {_.size(order.customer.addresses) > 0 ? order.customer.addresses[0].full_address : ''}</p>
                                            </div>
                                        </div>
                                        : null }
                                        {/* <div>
                                        
                                            <p style={{ textTransform: 'uppercase', fontSize: 13, marginBottom: 10 }}>{order.customer.name}</p>
                                            <p style={{ fontSize: 13, textTransform: 'uppercase', maxWidth: 250, lineHeight: 1.1, marginTop: 10 }}>{_.size(order.customer.addresses) > 0 ? order.customer.addresses[0].full_address : ''}</p>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <p style={{ textTransform: 'uppercase', fontSize: 13, }}>{t('order.tel')}: {order.customer.mobile}</p>
                                                <p style={{ textTransform: 'uppercase', fontSize: 13, }}><span style={{ textTransform: 'uppercase', fontSize: 13, }}>{t('user.email')}</span>: {order.customer.email}</p>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div style={{ gridColumn: 2, width: '50%' }}>
                                        <div style={{ float: 'right', textAlign: 'right', fontSize: 13 }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <p style={{ minWidth: 100, margin: 0, textAlign: 'left' }}>{t('order.salesperson')} </p>
                                                <p style={{ margin: 0 }}>: {order.user.name}</p>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0'  }}>
                                                <p style={{ minWidth: 100, margin: 0, textAlign: 'left' }}>{t('order.yourRef')} </p>
                                                <p style={{ margin: 0 }}>: </p>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
                                                <p style={{ minWidth: 100, margin: 0, textAlign: 'left' }}>{t('order.terms')} </p>
                                                <p style={{ margin: 0 }}>: </p>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
                                                <p style={{ minWidth: 100, margin: 0, textAlign: 'left' }}>{t('textField.date')} </p>
                                                <p style={{ margin: 0 }}>: {order.created_at}</p>
                                            </div>
                                            {/* <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
                                                <p style={{ minWidth: 60, margin: 0, textAlign: 'left' }}>{t('order.page')} </p>
                                                <p style={{ margin: 0 }}>: </p>
                                            </div> */}
                                            {/* <div style={{display:'flex', alignItems:'center', margin:'8px 0'}}>
                                                <p style={{minWidth:50, margin:0, textAlign:'left'}}>{t('user.mobile')} </p>
                                                <p style={{margin:0}}>: {order.customer.mobile}</p>
                                            </div>
                                            <div style={{display:'flex', alignItems:'center', margin:'8px 0'}}>
                                                <p style={{minWidth:50, margin:0, textAlign:'left'}}>{t('user.email')} </p>
                                                <p style={{margin:0}}>: {order.customer.email}</p>
                                            </div> */}
                                            {/* <div style={{display:'flex', alignItems:'center', margin:'8px 0'}}>
                                                <p style={{minWidth:50, margin:0, textAlign:'left'}}>{t('user.nric')} </p>
                                                <p style={{margin:0}}>: {order.customer.ic}</p>
                                            </div> */}

                                            {/* <p><b>E:</b> {order.customer.email}</p>
                                            <p><b>NRIC:</b> {order.customer.ic}</p> */}
                                        </div>
                                    </div>
                                </section>
                            </div>

                            {/* PRODUCT INFO */}
                            <div>

                                {/* <section style={{display:'flex', justifyContent:'space-between', alignItems:'flex-end'}} key={detail.id}>
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <p style={{margin:0, fontSize:18}}><b>{detail.product_name}</b></p>
                                                                <p style={{paddingTop:8, margin:0, fontSize:13}}><b>{t('order.serialNo')} : </b>{t(detail.serial_no ? detail.serial_no : '-')}</p>
                                                            </div>
                                                            <div style={{paddingTop:15, fontSize:13}}>
                                                                <p style={{marginTop:5, marginBottom:0}}><b>{t('order.whatsInBox')} :</b> {t(detail.whats_in_box ? detail.whats_in_box : '-')}</p>
                                                            </div>
                                                            
                                                            <div style={{paddingTop:15, fontSize:13}}>
                                                                <p style={{margin:0}}><b>{t('order.factoryWarranty')} : </b>{t(detail.warranty_period ?
                                                                    (detail.warranty_period.factory.sdate ?
                                                                        (detail.warranty_period.factory.edate ? (t('order.startEndDate', { start: detail.warranty_period.factory.sdate, end: detail.warranty_period.factory.edate })) : '-') :
                                                                        (detail.warranty_period.factory.edate ? (t('order.endDate', { end: detail.warranty_period.factory.edate })) : '-')
                                                                    )
                                                                    : '-'
                                                                )}</p>
                                                                <p style={{marginTop:5}}><b>{t('order.shopWarranty') + ' ' + t('order.period') + ': '} </b>{t(detail.warranty_period ? detail.warranty_period.shop.period ? detail.warranty_period.shop.period : '-' : '-')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={{ float: 'right', textAlign: 'right' }}>
                                                            <p style={{fontSize:15}}>x {detail.quantity}</p>
                                                            <p style={{fontSize:15}}><NumberFormat thousandSeparator={true} prefix="RM " value={detail.sell_price} displayType="text" /></p>
                                                        </div>
                                                    </div>
                                                </section> */}
                                <TableContainer component={Paper}>
                                    <Table style={{ minWidth: 650, width: '100%' }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000', fontSize: 13, width: '5vw' }}>Item</TableCell>
                                                <TableCell style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000', fontSize: 13, width: '7vw', textAlign: 'center' }}>Tax<br />Code</TableCell>
                                                <TableCell style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000', fontSize: 13, width: '38vw', textAlign: 'left', paddingRight: 8 }}>Description</TableCell>
                                                <TableCell style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000', fontSize: 13, width: '5vw', textAlign: 'center' }}>Qty</TableCell>
                                                <TableCell style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000', fontSize: 13, width: '5vw', textAlign: 'center' }}>UOM</TableCell>
                                                <TableCell style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000', fontSize: 13, width: '15vw', textAlign: 'right' }}>U/Price<br />RM</TableCell>
                                                <TableCell style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000', fontSize: 13, width: '15vw', textAlign: 'center' }}>Disc</TableCell>
                                                <TableCell style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000', fontSize: 13, width: '15vw', textAlign: 'right' }}>Total<br />RM</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {
                                            _.map(order.order_details, detail => {
                                                return (
                                                    <TableBody key={detail.id}>
                                                        <TableRow>
                                                            <TableCell style={{ width: '5vw', }} component="th" scope="row">
                                                                {itemcount++}
                                                            </TableCell>
                                                            <TableCell style={{ width: '7vw', textAlign: 'center' }} > </TableCell>
                                                            <TableCell style={{ width: '38vw', textAlign: 'left', paddingRight: 8 }}>
                                                                {detail.product_name} - {t(detail.serial_no ? detail.serial_no : '-')}
                                                                { _.size(detail.add_ons) > 0 ?
                                                                    <div style={{paddingRight:15}}>
                                                                    { _.map(detail.add_ons, (addon, key) => {
                                                                        return checked.indexOf(detail.id+"_"+key) !== -1 && 
                                                                        (
                                                                            <div key={key} style={{color:'#777', fontSize: 14, display:'flex',justifyContent:'space-between'}}>
                                                                                <div style={{width:'50%'}}>
                                                                                    <p style={{margin:0}}>
                                                                                        <i>- {addon.description}</i>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                    </div>
                                                                    :null
                                                                }
                                                            </TableCell>
                                                            <TableCell style={{ width: '5vw', textAlign: 'center' }}>{detail.quantity}</TableCell>
                                                            <TableCell style={{ width: '5vw', textAlign: 'center' }}> </TableCell>
                                                            <TableCell style={{ width: '15vw', textAlign: 'right' }}>{detail.sell_price}</TableCell>
                                                            <TableCell style={{ width: '15vw', textAlign: 'center' }}> </TableCell>
                                                            <TableCell style={{ width: '15vw', textAlign: 'right' }}>{detail.sell_price}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                )
                                            })
                                        }

                                    </Table>
                                </TableContainer>

                            </div>


                            <div style={{ lineHeight: '80%', fontFamily: 'arial', paddingTop: 15, }}>
                                {/* <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <h3>{t('order.itemDetails')}</h3>
                                </div> */}

                                <div style={{marginBottom:15}}>
                                    <div style={{ margin: '0 0 5px', borderTop: '1px solid #000', paddingTop: 10 }} />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ flex: 1 }}>
                                            <p style={{ margin: 0, textTransform: 'uppercase', paddingRight: 15, lineHeight: 1.2, fontSize: 14 }}>RINGGIT MALAYSIA {toWords(parseFloat(order.total_price))} ONLY</p>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 6 }}>
                                                <div style={{ width: '65%' }}>
                                                    <p style={{ textAlign: 'right', fontSize: 14, margin: 0, paddingRight: 8 }}><b>
                                                        {/* Sub Total (Excluding Tax) */}
                                                        Total
                                                    </b></p>
                                                </div>
                                                <div style={{ width: '35%', border: '2px solid #000', padding: '5px' }}>
                                                    <p style={{ textAlign: 'right', margin: 0 }}><b>
                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} value={order.total_price} displayType="text" />
                                                    </b></p>
                                                </div>
                                            </div>
                                            {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: 6 }}>
                                                <div style={{ width: '65%' }}>
                                                    <p style={{ textAlign: 'right', fontSize: 14, margin: 0, paddingRight: 8 }}><b>
                                                        Tax @ 0% on 0.00
                                                    </b></p>
                                                </div>
                                                <div style={{ width: '35%', border: '2px solid #000', padding: '5px' }}>
                                                    <p style={{ textAlign: 'right', margin: 0 }}><b>
                                                        0.00
                                                    </b></p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 6 }}>
                                                <div style={{ width: '65%' }}>
                                                    <p style={{ textAlign: 'right', fontSize: 14, margin: 0, paddingRight: 8 }}><b>
                                                        Tax (Inclusive of Tax)
                                                    </b></p>
                                                </div>
                                                <div style={{ width: '35%', border: '2px solid #000', padding: '5px' }}>
                                                    <p style={{ textAlign: 'right', margin: 0 }}><b>
                                                        <NumberFormat thousandSeparator={true} value={order.total_price} displayType="text" />
                                                        
                                                    </b></p>
                                                </div>
                                            </div> */}
                                            {/* prefix="RM " */}
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 6 }}>
                                                <div style={{ width: '65%' }}>
                                                    <p style={{ textAlign: 'right', fontSize: 14, margin: 0, paddingRight: 8 }}><b>
                                                        Total Payment Made
                                                    </b></p>
                                                </div>
                                                <div style={{ width: '35%', border: '2px solid #000', padding: '5px' }}>
                                                    <p style={{ textAlign: 'right', margin: 0 }}><b>
                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} value={totalPaid} displayType="text" />
                                                    </b></p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 6 }}>
                                                <div style={{ width: '65%' }}>
                                                    <p style={{ textAlign: 'right', fontSize: 14, margin: 0, paddingRight: 8 }}><b>
                                                        Remaining Balance
                                                    </b></p>
                                                </div>
                                                <div style={{ width: '35%', border: '2px solid #000', padding: '5px' }}>
                                                    <p style={{ textAlign: 'right', margin: 0 }}><b>
                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} value={balance} displayType="text" />
                                                    </b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            {/* FOOTER INFO */}
                            {/* <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between',  }}>
                                <div style={{ flex: 1, paddingBottom:15 }}>
                                    <p style={{ lineHeight: 1.2, margin: 0, fontSize: 12, paddingTop: 10 }}>
                                        Note:
                                    </p>
                                    <div style={{ display: 'flex', paddingTop: 5 }}>
                                        <p style={{ width: 40, fontSize: 12, margin: 0 }}>1.</p>
                                        <p style={{ margin: 0, fontSize: 12 }}>All cheques should be crossed and made payable to SWISS HOUR EMPIRE SDN BHD</p>
                                    </div>
                                    <div style={{ display: 'flex', paddingTop: 5 }}>
                                        <p style={{ width: 40, fontSize: 12, margin: 0 }}>2.</p>
                                        <p style={{ margin: 0, fontSize: 12 }}>Goods sold are neither returnable nor refundable. Otherwise a cancellation fee of 20% on purchase price will be imposed.</p>
                                    </div>
                                    
                                    <div style={{ width: '30vw' }}>
                                        <p style={{ marginBottom: 60 }}>{t('order.signatureDesc')}</p>
                                        <div style={{ marginBottom: 20, height: 100 }}></div>

                                        <div style={{ borderTop: '1px solid #4a4a4a' }}>
                                            <p style={{ margin: '5px 0 0', fontSize: 13 }}>{t('order.signName')} : </p>
                                            <p style={{ margin: '5px 0 0', fontSize: 13 }}>{t('order.signDate')} : </p>
                                            <p style={{ margin: '5px 0 0', textAlign: 'center' }}>
                                                <b>{t('order.authorisedSignature')}</b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-start', paddingTop: 10 }}>
                                        <p style={{ margin: 0 }}><b>
                                            Tax Summary
                                        </b></p>
                                        <p style={{ margin: 0 }}><b>
                                            Amount(RM)
                                        </b></p>
                                        <p style={{ margin: 0 }}><b>
                                            Tax(RM)
                                        </b></p>
                                    </div>
                                    <div style={{ marginTop: 20, float: 'right', fontFamily:'arial', color:'#4a4a4a' }}>
                                <div>
                                    <p style={{marginBottom:60}}>{t('order.signatureDesc')}</p>
                                    
                                    <div style={{borderTop:'1px solid #4a4a4a'}}>
                                    <p style={{margin:'5px 0 0', fontSize:13}}>{t('order.signName')} : </p>
                                    <p style={{margin:'5px 0 0', fontSize:13}}>{t('order.signDate')} : </p>
                                    </div>
                                </div>

                            </div>

                                </div>
                            </div> */}
                            {/* installment details */}
                            <div className={styles.pageBreak}>
                                {/* <div style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center', margin:'30px 0 5px',}}>
                                    <div style={{borderBottom: '1px dashed #b7b7b7',height:1, width:'100%' }}/>
                                    <p style={{ color: '#cacaca', padding:'0 15px', whiteSpace:'nowrap' }}><i>Installment Details</i></p>
                                    <div style={{borderBottom: '1px dashed #b7b7b7',height:1, width:'100%' }}/>
                                </div> */}
                                {/* { hasInstalment ? <>
                                <p style={{ color: '#cacaca',  paddingBottom:5, margin:0, fontSize:12}}><i><b>Installment Details</b></i></p>

                                <TableContainer component={Paper}>
                                <Table style={{ minWidth: 650, width: '100%' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000', fontSize: 13, width: '17vw', textAlign: 'left' }}>Date</TableCell>
                                            <TableCell style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000', fontSize: 13, width: '33vw', textAlign: 'left', paddingRight: 8 }}>Payment Method</TableCell>
                                            <TableCell style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000', fontSize: 13, width: '15vw', textAlign: 'right', paddingRight: 8 }}>Amount<br />RM</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    { _.map(order.order_payments, payment => {
                                        if (payment.payment_method === 'instalment' && _.size(payment.instalment_details)>0) {
                                            return(
                                            _.map(payment.instalment_details, instalment => {
                                                if(instalment.status === 40){
                                                    return (
                                                        <TableBody key={instalment.id}>
                                                            <TableRow>
                                                                <TableCell style={{ width: '17vw', textAlign: 'left', borderBottom: '1px solid #cacaca', padding: '6px 0', fontSize: 13, }} >{instalment.created_at}</TableCell>
                                                                <TableCell style={{ width: '33vw', textAlign: 'left', borderBottom: '1px solid #cacaca', padding: '6px 0', fontSize: 13, paddingRight: 8 }}>
                                                                    <p>{instalment.payment_method}</p>
                                                                </TableCell>
                                                                <TableCell style={{ width: '15vw', textAlign: 'right', borderBottom: '1px solid #cacaca', padding: '6px 0', fontSize: 13, }}>{parseFloat(instalment.amount).toFixed(2)}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    )
                                                }
                                            })
                                            )
                                        }
                                    })}
                                </Table>
                                </TableContainer>
                                </> : null } */}
                            </div>

                            <div style={{paddingBottom:10, paddingTop:15}}>
                                    <p style={{ lineHeight: 1.2, margin: 0, fontSize: 12, paddingBottom:5 }}>
                                        <b>Warranty Definition</b>
                                    </p>
                                <p style={{ margin: 0, fontSize: 12 }}>
                                    * For all watches included 1 years of warranty services applicable only with the proof of receipt  from Swiss Hour Empire.<br/>
                                    * Not applicable for watches that has been repaired or polished apart from our company.<br/>
                                    * Guarantees including the movement and parts of the watches, exterior are not included.<br/>
                                    * Fogging or water damage on the inner/caliber is not included.
                                </p>
                                </div>

                                <div style={{paddingBottom:15}}>
                                    <p style={{ lineHeight: 1.2, margin: 0, fontSize: 12,  paddingBottom:5 }}>
                                        <b>Terms & Conditions</b>
                                    </p>
                                <p style={{ margin: 0, fontSize: 12 }}>
                                    * All Items are 100% authentic and satisfaction guaranteed.<br/>
                                    * Swiss Hour Empire not responsible for any maintenance or damages of items.<br/>
                                    * No Exchange, No Refund.<br/>
                                    * In event of disputes, Swiss Hour Empire reserves the right of final decision.<br/>
                                    * Otherwise a cancellation fee of 20% on purchase price will be imposed
                                </p>
                                </div>

                                
                                <div>
                                        <p style={{ margin: 0, fontSize: 12 }}><b><i>{t('order.orderPrintDetail')}</i></b></p>
                                    </div>
                            </div>
                            :
                            <div style={{ margin: 20 }}>
                                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <h4>{t('emptyStatus.noOrder')}</h4>
                                </div>
                            </div>
                        
                }
            </iframe>

            <Modal
                open={showHideAddOnModal}
                onClose={()=>setShowHideAddOnModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <div>
                        Print Customer Info
                        <Switch
                            checked={custChecked}
                            onChange={handleCustToggle}
                            name="custChecked"
                        />
                        { orderWithAddOn ? 
                        <>
                        <p>Print Add On Setting</p>
                        <div>
                        {
                            _.map(order.order_details, detail => (
                                <List key={detail.id} subheader={<ListSubheader>{detail.product_name} - {t(detail.serial_no ? detail.serial_no : '-')}</ListSubheader>} style={{width: '100%', backgroundColor: theme.palette.background.paper}}>
                                    { _.size(detail.add_ons) > 0 &&
                                        _.map(detail.add_ons, (addon, key) => (
                                            <ListItem key={key}>
                                                <ListItemText id={detail.id+"_"+key} primary={addon.description} />
                                                <ListItemSecondaryAction>
                                                    <Switch
                                                        edge="end"
                                                        onChange={handleToggle(detail.id+"_"+key)}
                                                        checked={checked.indexOf(detail.id+"_"+key) !== -1}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            ))
                        }
                        </div>
                        </> : null }
                        {/* BUTTON */}
                        <div style={{ paddingTop: 25, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <Button style={{ padding: '8px 15px', width: 130, marginRight: 15, maxWidth: '100%', borderRadius: 48, border: theme.palette.button.borderMain, boxShadow: '2px 2px 20px 0 #efefef', color: theme.palette.button.main, fontSize: 15, textAlign: 'center', textTransform: 'uppercase' }} onClick={()=>setShowHideAddOnModal(false)}>{t('button.cancel')}</Button>
                            <div style={{ padding: '8px 15px', minWidth: 130, maxWidth: '100%', borderRadius: 8, background: theme.palette.secondary.main, boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center', cursor: 'pointer' }} onClick={printInfo}>
                                <Typography style={{ color: '#fff', fontSize: 15, textAlign: 'center', textTransform: 'uppercase' }}>{t('button.print')}</Typography>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    pageBreak: {
        '@media print': {
            display: 'block'
        },
    },
}));

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    p: 4,
};