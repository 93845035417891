import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import _ from 'lodash';
import NumberFormat from 'react-number-format';

// MUI
import { Button } from '@mui/material';

export default function CreditNotePrint({ customers, listing, label, statusList , typeList }) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const printInfo = () => {
        var content = document.getElementById("ifmcontentstoprint");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    const isMountedRef = useRef(null);

    const [state, setState] = useState({
        name: '',
        gender: '-',
        email: '',
        mobile: '',
        ic: '',
        country: '',
        zip: '',
        state: '',
        city: '',
        address: '',
        selectedName: null,
        full_address: ''
    });

    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current) {
            if (listing.customer_id > 0 && !_.size(state.selectedName) && _.size(customers)) {
                let selectedName = _.find(customers, {
                    id: listing.customer_id
                });
                setState({
                    ...state,
                    selectedName: selectedName,
                    gender: selectedName?.gender ?? '-',
                    email: selectedName?.email ?? "",
                    mobile: selectedName?.mobile ?? "",
                    ic: selectedName?.ic ?? "",
                    country: _.size(selectedName.addresses) > 0 ? selectedName?.addresses?.[0].country ?? "" : '',
                    zip: _.size(selectedName.addresses) > 0 ? selectedName?.addresses?.[0].zip ?? "" : '',
                    state: _.size(selectedName.addresses) > 0 ? selectedName?.addresses?.[0].state ?? "" : '',
                    city: _.size(selectedName.addresses) > 0 ? selectedName?.addresses?.[0].city ?? "" : '',
                    address: _.size(selectedName.addresses) > 0 ? selectedName?.addresses?.[0].address1 ?? "" : '',
                    full_address: _.size(selectedName.addresses) > 0 ? selectedName?.addresses?.[0].full_address ?? "" : '',
                });
            } else if (_.size(state.selectedName)) {
                setState({
                    ...state,
                    gender: state.selectedName.gender,
                    email: state.selectedName.email,
                    mobile: state.selectedName.mobile ?? "",
                    ic: state.selectedName.ic,
                    country: _.size(state.selectedName.addresses) > 0 ? state.selectedName.addresses?.[0].country : '',
                    zip: _.size(state.selectedName.addresses) > 0 ? state.selectedName.addresses?.[0].zip : '',
                    state: _.size(state.selectedName.addresses) > 0 ? state.selectedName.addresses?.[0].state : '',
                    city: _.size(state.selectedName.addresses) > 0 ? state.selectedName.addresses?.[0].city : '',
                    address: _.size(state.selectedName.addresses) > 0 ? state.selectedName.addresses?.[0].address1 : '',
                    full_address: _.size(state.selectedName.addresses) > 0 ? state.selectedName.addresses?.[0].full_address : '',
                });
            } else {
                setState({
                    ...state,
                    gender: '-',
                    email: '',
                    mobile: '',
                    ic: '',
                    country: '',
                    zip: '',
                    state: '',
                    city: '',
                    address: '',
                });
            }
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [listing.customer_id, customers, state.selectedName]);

    const displayValue = (value,list) => {
        if (_.size(list) > 0) {
            let selectedValue = _.find(list, { code: value });
            return selectedValue[`${i18n.language}`];
        }
        return "-";
    }

    return (
        <div>
            <Button size="large" variant="contained" color="secondary" style={{ marginLeft: 15 }} onClick={printInfo}>{label}</Button>
            <iframe id="ifmcontentstoprint" style={{ height: 0, width: 0, position: "absolute", display: "none" }} title="order-detail">
                {
                    _.size(state) > 0 ?
                        <div style={{ margin: '0 20px 20px', color: '#4a4a4a' }}>
                            {/* LETTERHEAD */}
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '-40px' }}>
                                <div>
                                    <img src='/images/logo-black.png' alt="logo" style={{ width: 150, height: 150, objectFit: 'contain' }} />
                                </div>
                                <div style={{ paddingLeft: 20, paddingTop: 20 }}>
                                    <p style={{ fontSize: 21, margin: 0 }}><b>SWISS HOUR EMPIRE SDN BHD</b><span style={{ fontSize: 12, paddingLeft: 15 }}>202101013694 (1413993V)</span></p>
                                    <p style={{ fontSize: 15, margin: 0 }}>Lot No. G/LG 14.00 Lower ground Floor, Grand Hotel, 69000,<br /> Genting Highlands, Pahang, Malaysia</p>
                                    <p style={{ fontSize: 15, margin: 0 }}>+603 2110 6811    <span style={{ padding: '0 15px' }}>|</span>    swisshourempire.com.my</p>
                                </div>
                            </div>
                            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <h2>{t('creditNote.title')}</h2>
                            </div>
                            <hr style={{ borderTop: 'dotted 1px' }} />
                            {/*CUSTOMER INFO */}
                            <div>
                                <div style={{ fontSize: 15, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <b>{t('creditNote.customerDetail')}</b>
                                </div>
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div style={{ lineHeight: 1 }}>{t('user.name')} : {state.selectedName ? state.selectedName.name : "-"}</div>
                                </div>
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div style={{ lineHeight: 1 }}>{t('user.gender.title')} : {state.gender ? t('user.gender.' + state.gender) : "-"}</div>
                                </div>
                                <section style={{ display: 'grid' }}>
                                    <div style={{ gridColumn: 1 }}>
                                        <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                            <div>{t('user.nric')} : {state.ic ? state.ic : "-"}</div>
                                        </div>
                                    </div>
                                    <div style={{ gridColumn: 2 }}>
                                        <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                            <div>{t('user.mobile')} : {state.mobile ? state.mobile : "-"}</div>
                                        </div>
                                    </div>
                                </section>
                                <section style={{ display: 'grid' }}>
                                    <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                        <div>{t('user.email')} : {state.email ? state.email : "-"}</div>
                                    </div>
                                    <div style={{ gridColumn: 2 }}>
                                        <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                            <div>{t('product.date')} : {listing.created_at ? listing.created_at : "-"}</div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <hr style={{ borderTop: 'dotted 1px' }} />
                            {/*CREDIT NOTE INFO */}
                            <div>
                                <div style={{ fontSize: 15, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <b>{t('creditNote.creditNoteDetail')}</b>
                                </div>
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div>ID : {listing.id ? listing.id : "-"}</div>
                                </div>
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div style={{ lineHeight: 'normal' }}>{t('creditNote.type.title')} : {listing.status ? displayValue(listing.type , typeList): "-"}</div>
                                </div>
                                {
                                    listing.type === 'voided' &&
                                    <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                        <div>{t('creditNote.usedCreditNoteId')} : {listing.used_credit_note ? listing.used_credit_note : "-"}</div>
                                    </div>
                                }
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div>{t('creditNote.amount')} : {listing.amount ? <NumberFormat thousandSeparator={true} prefix="RM " value={parseFloat(listing.amount).toFixed(2)} displayType="text" /> : "-"}</div>
                                </div>
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div style={{ lineHeight: 'normal' }}>{t('creditNote.status.title')} : {listing.status ? displayValue(listing.status , statusList): "-"}</div>
                                </div>
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div style={{ lineHeight: 'normal' }}>{t('creditNote.description')} : {listing.description ? listing.description : null}</div>
                                </div>
                                <br />
                                <hr style={{ borderTop: 'solid 1px' }} />
                                <br />
                                <hr style={{ borderTop: 'solid 1px' }} />
                            </div>
                            {/* <hr style={{ borderTop: 'dotted 1px' }} /> */}
                            {/* FOOTER INFO */}
                            <div style={{ lineHeight: '20%' }}>
                                <div style={{ justifyContent: 'left', alignItems: 'center' }}>
                                    <h4>{t('creditNote.creditNotePrintDetail')}</h4>

                                </div>
                                <br />
                            </div>
                        </div>
                        :
                        <div style={{ margin: 20 }}>
                            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <h4>{t('emptyStatus.noCreditNote')}</h4>
                            </div>
                        </div>
                }
            </iframe>
        </div>
    )
}