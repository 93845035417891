import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { putUrl } from '../../helpers/ApiAction';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import PropTypes from "prop-types";

// MUI
import { Box, Button, Typography, Modal, Grid, TextField } from '@mui/material';

// MODAL
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    p: 4,
};

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};


export default function AddOnModal({
    setAddOnModal, addOnModal
}) {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    // ADD/EDIT/DELETE ADD ON
    const handleAddOnModalClose = () => {
        setAddOnModal({
            ...addOnModal,
            open: false,
            details:[],
            errors: {},
        });
    }

    const handleAddOnAdd = () => {
        let newArr = {};
        newArr['description'] = '';
        newArr['amount'] = '';
        let tempArr = addOnModal.details;
        tempArr = tempArr.concat(newArr);
        setAddOnModal({ ...addOnModal, details: tempArr });
    }

    const handleAddOnUpdate = (key, value, label) => {
        setAddOnModal({ ...addOnModal, error: {} });
        let tempData = addOnModal.details;
        tempData[key][label] = value;
        setAddOnModal({ ...addOnModal, details: tempData });

    }
    const handleAddOnDelete = (id) => {
        let tempArr = addOnModal.details;
        tempArr = tempArr.filter((item, key) => key !== id);
        setAddOnModal({ ...addOnModal, details: tempArr });
    }

    const submitAddOn =(e)=>{
       e.preventDefault();
       setLoading(true);
       setAddOnModal({...addOnModal , errors:{}});
       let apiData={
           info:addOnModal.details
        };

       putUrl(`/order-details/${addOnModal.orderDetailId}/add-ons`,apiData).then(response => {
        setLoading(false);
        if (response.status) {
            addAlert('', response.message, 'success', '');
            handleAddOnModalClose();
            window.location.reload(true);
        } else {
            setAddOnModal({...addOnModal , errors:response.errors});
            addAlert('', response.message, 'error', '');
        }
    }).catch(error => {
        setLoading(false);
        addAlert('', error.message || t('error.contactSupport'), 'error', '');
    });
    }

    return (
        <div>
            {/* ADD/EDIT/DELETE ADD ON MODAL */}
            <Modal
                open={addOnModal.open}
                onClose={handleAddOnModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <div style={{ textAlign: 'center', color: theme.palette.gray.ltext }}>
                        {/* TITLE & ADD */}
                        <div className={`${styles.flexMiddle} ${styles.spaceBetween}`}>
                            <Typography><b>{t('product.addOnPrice')}</b></Typography>
                            <div>
                                <Button onClick={handleAddOnAdd} variant="contained">
                                    {t('button.add')}
                                </Button>
                            </div>
                        </div>
                        {
                            _.size(addOnModal.details) > 0 && _.map(addOnModal.details, (row, key) => {
                                return (
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} key={key} style={{ paddingTop:10}}>
                                        <Grid item xs={5} >
                                            <TextField
                                                fullWidth
                                                id="standard-basic"
                                                type="text"
                                                variant="outlined"
                                                size="small"
                                                label={t('product.description')}
                                                name="description"
                                                value={row.description}
                                                onChange={(event) => handleAddOnUpdate(key, event.target.value, event.target.name)}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                fullWidth
                                                id="standard-basic"
                                                type="text"
                                                variant="outlined"
                                                size="small"
                                                label={t('product.amount')}
                                                name="amount"
                                                value={row.amount}
                                                onChange={(event) => handleAddOnUpdate(key, event.target.value, event.target.name)}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button onClick={() => handleAddOnDelete(key)} variant="outlined">
                                                {t('button.remove')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                        {/* ERROR */}
                        {
                            _.size(addOnModal.errors) > 0
                                ?
                                <div style={{ padding: '0 25px' }}>
                                    {
                                        _.map(addOnModal.errors, (err, key) => {
                                            return (
                                                <Typography key={key} style={{ color: theme.palette.red.main }}>{err}</Typography>
                                            )
                                        })
                                    }
                                </div>
                                : null
                        }
                        {/* BUTTON */}
                        <div style={{ paddingTop: 25, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <Button style={{ padding: '8px 15px', width: 130, marginRight: 15, maxWidth: '100%', borderRadius: 48, border: theme.palette.button.borderMain, boxShadow: '2px 2px 20px 0 #efefef', color: theme.palette.button.main, fontSize: 15, textAlign: 'center', textTransform: 'uppercase' }} onClick={handleAddOnModalClose}>{t('button.cancel')}</Button>
                            <Button style={{ padding: '8px 15px', width: 180, maxWidth: '100%', borderRadius: 48, background: theme.palette.button.main, boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center' }} onClick={submitAddOn}>
                            <Typography style={{ color: '#fff', fontSize: 18, textAlign: 'center', textTransform: 'uppercase' }}>{t('button.save')}</Typography>
                        </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div >
    )
}

const useStyles = makeStyles(theme => ({
    spaceBetween: {
        justifyContent: 'space-between'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
}));