import FormControl from "@mui/material/FormControl";
import { makeStyles, useTheme } from "@mui/styles";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { getUrl } from "../../helpers/ApiAction";
import useNotificationLoading from "../../helpers/useNotificationLoading";

// MUI
import {
    Box, Breadcrumbs, Grid, LinearProgress, Link,
    OutlinedInput, Typography
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    DataGrid, GridActionsCellItem, gridClasses, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";

import { FiArrowLeft, FiSearch } from "react-icons/fi";
import { MdModeEdit, MdPassword } from "react-icons/md";

function CustomToolbar() {
	return (
		<GridToolbarContainer
			className={gridClasses.toolbarContainer}
			style={{ paddingBottom: 10 }}
		>
			<GridToolbarExport
				style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
				csvOptions={{
					allColumns: false,
					fileName: `Staffs_${new Date().toISOString().slice(0, 10)}`,
				}}
				printOptions={{ disableToolbarButton: true }}
			/>
			<GridToolbarDensitySelector
				style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
			/>
			<GridToolbarFilterButton
				style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
			/>
			<GridToolbarColumnsButton
				style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
			/>
		</GridToolbarContainer>
	);
}

export default function Staff() {
	const styles = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();

	// table
	const [pageSize, setPageSize] = useState(5);
	const [tableLoading, setTableLoading] = useState(false);
	const [state, setState] = useState([]);
	const [keyword, setKeyword] = useState({});
	const { addAlert } = useNotificationLoading();
	const { data } = useDemoData({
		dataSet: "staff",
		rowLength: 5,
		maxColumns: 10,
	});
	const { permissions } = useSelector((state) => state.general);

	useEffect(() => {
		setTableLoading(true);
		getUrl("/users")
			.then((response) => {
				setTableLoading(false);
				if (response.status) {
					setState(response.data.listing);
				} else {
					addAlert("", t("error.contactSupport"), "error", "");
				}
			})
			.catch((error) => {
				setTableLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});
		// eslint-disable-next-line
	}, []);

	const searchData = () => {
		let param = {};
		if (keyword.length > 0) {
			param = {
				q: keyword,
			};
		}
		getUrl("/users", param)
			.then((response) => {
				setTableLoading(false);
				if (response.status) {
					setState(response.data.listing);
				} else {
					addAlert("", t("error.contactSupport"), "error", "");
				}
			})
			.catch((error) => {
				setTableLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});
	};

	//table
	const columns = useMemo(
		() => [
			{
				field: "name",
				headerName: `${t("user.name")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
				renderCell: (params) => {
					return (
						<div key={params}>
							{params.value ? (
								<Link
									underline="none"
									to={`/staff-profile/${params.row.id}`}
									component={RouterLink}
								>
									{params.value}
								</Link>
							) : (
								"-"
							)}
						</div>
					);
				},
			},
			{
				field: "staff_no",
				headerName: `${t("user.staffNo")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
				renderCell: (params) => {
					return (
						<div key={params}>
							{params.value != null ? params.value : "-"}
						</div>
					);
				},
			},
			{
				field: "bank_name",
				headerName: `${t("user.bankName")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
				renderCell: (params) => {
					return (
						<div key={params}>
							{params.value != null ? params.value : "-"}
						</div>
					);
				},
			},
			{
				field: "bank_account",
				headerName: `${t("user.bankAccount")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
				renderCell: (params) => {
					return (
						<div key={params}>
							{params.value != null ? params.value : "-"}
						</div>
					);
				},
			},
			{
				field: "ic",
				headerName: `${t("user.nric")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
				renderCell: (params) => {
					return (
						<div key={params}>
							{params.value != null ? params.value : "-"}
						</div>
					);
				},
			},
			{
				field: "email",
				headerName: `${t("user.email")}`,
				flex: 1.5,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
			},
			{
				field: "mobile",
				headerName: `${t("user.mobile")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
				renderCell: (params) => {
					return (
						<div key={params}>
							{params.value != null ? params.value : "-"}
						</div>
					);
				},
			},
			{
				field: "status",
				headerName: `${t("customer.status.title")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
				renderCell: (params) => {
					return (
						<div key={params}>
							{params.value != null
								? t("staff.status." + params.value)
								: "-"}
						</div>
					);
				},
			},
			{
				field: "created_at",
				headerName: `${t("customer.createdAt")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
			},
			{
				field: "actions",
				type: "actions",
				headerName: `${t("table.actions")}`,
				width: 100,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
				getActions: (params) => [
                    <GridActionsCellItem
                        icon={<MdModeEdit size={25} />}
                        label={t("button.edit")}
                        onClick={() => navigate(`/staff-profile/${params.id}`)}
                    />,
                    <GridActionsCellItem
                        icon={<MdPassword size={25} />}
                        label={t("button.edit")}
                        onClick={() => navigate(`/staff-password/${params.id}`)}
                    />
				],
			},
		],
		[t]
	);

	const [columnVisibilityModel, setColumnVisibilityModel] = useState({
		actions: permissions.includes("staff-edit"),
		bank_name: false,
		bank_account: false,
		ic: false,
	});

	return (
		<div>
			<div
				style={{ paddingBottom: 30, justifyContent: "space-between" }}
				className={styles.flexMiddle}
			>
				<div
					className={styles.flexMiddle}
					style={{ paddingBottom: 30 }}
				>
					<FiArrowLeft
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							marginRight: 10,
						}}
					/>
					<span
						onClick={() => navigate(-1)}
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							textTransform: "uppercase",
							cursor: "pointer",
						}}
					>
						{t("button.back")}
					</span>
				</div>

				<div>
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/dashboard"
						>
							{t("title.dashboard")}
						</Link>
						<Typography color="text.primary">
							{t("title.staffs")}
						</Typography>
					</Breadcrumbs>
				</div>
			</div>

			<div style={{ paddingBottom: 5 }}>
				<Box
					className={styles.flexMiddle}
					style={{
						paddingBottom: 5,
						justifyContent: "space-between",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							paddingBottom: 15,
						}}
					>
						<Typography
							style={{
								fontSize: 21,
								marginRight: 15,
								color: theme.palette.gray.main,
							}}
						>
							<b>{t("staff.staffList")}</b>
						</Typography>
						{_.includes(permissions, "staff-create") && (
							<Link
								underline="none"
								to={`/staff-add`}
								component={RouterLink}
							>
								<div
									style={{
										height: 30,
										width: 30,
										textAlign: "center",
										borderRadius: 5,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										border: theme.palette.button.borderMain,
									}}
								>
									<Typography
										style={{
											fontSize: 21,
											color: theme.palette.button.main,
										}}
									>
										+
									</Typography>
								</div>
							</Link>
						)}
					</div>
				</Box>
				<Grid container spacing={3}>
					<Grid item xs={9} sm={10} md={11}>
						<ThemeProvider theme={inputStyle}>
							<FormControl
								style={{ width: "100%", maxWidth: "100%" }}
							>
								<OutlinedInput
									placeholder={t("table.searchStaff")}
									onChange={({ target }) =>
										setKeyword(target.value)
									}
								/>
							</FormControl>
						</ThemeProvider>
					</Grid>
					<Grid item xs={3} sm={2} md={1}>
						<div
							style={{
								backgroundColor: theme.palette.button.main,
								borderRadius: 48,
								boxShadow:
									"2px 2px 10px 5px rgb(209 209 209 / 30%)",
								width: "100%",
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								color: "#fff",
								cursor: "pointer",
							}}
							onClick={() => searchData()}
						>
							<Typography style={{ paddingTop: 5 }}>
								<FiSearch />
							</Typography>
						</div>
					</Grid>
				</Grid>
			</div>

			<div>
				<div
					style={{
						height: 450,
						width: "100%",
						overflowX: "scroll",
						marginTop: 20,
						boxShadow: "0 0 20px 0 #dadada",
						borderRadius: "15px",
					}}
				>
					<div
						style={{ display: "flex", height: "100%", padding: 0 }}
					>
						<div style={{ flexGrow: 1 }}>
							<DataGrid
								{...data}
								rows={state}
								columns={columns}
								/* columnVisibilityModel={{
                                    actions: permissions.includes('staff-edit'),
                                    bank_name: false,
                                    bank_account: false,
                                    ic: false
                                }} */
								columnVisibilityModel={columnVisibilityModel}
								onColumnVisibilityModelChange={(newModel) =>
									setColumnVisibilityModel(newModel)
								}
								pageSize={pageSize}
								onPageSizeChange={(newPageSize) =>
									setPageSize(newPageSize)
								}
								rowsPerPageOptions={[5, 10, 20]}
								components={{
									Toolbar: CustomToolbar,
									LoadingOverlay: LinearProgress,
								}}
								loading={tableLoading ? true : false}
								disableSelectionOnClick
								sx={{
									marginTop: 0,
									borderRadius: "15px",
									paddingTop: "15px",
									border: "none",
									"& .super-app-theme--header": {
										// backgroundColor: theme.palette.primary.main,
										backgroundColor:
											theme.palette.gray.tableHeader,
										color: theme.palette.gray
											.tableHeaderText,
									},
									backgroundColor: "white",
									"& 	.MuiDataGrid-menuIconButton": {
										color: theme.palette.gray
											.tableHeaderText,
									},
									"& 	.MuiDataGrid-sortIcon": {
										color: theme.palette.gray
											.tableHeaderText,
									},
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	menuStyle: {
		background: "#fff",
		borderRadius: 15,
		boxShadow: "2px 2px 10px 5px rgb(209 209 209 / 30%)",
		padding: 10,
	},
	menuImg: {
		maxWidth: "100%",
		width: "100%",
	},
	menuText: {
		textAlign: "center",
		fontSize: "14px!important",
		paddingTop: 15,
	},
	flexMiddle: {
		display: "flex",
		alignItems: "center",
	},
}));

const inputStyle = createTheme({
	components: {
		// Name of the component
		MuiOutlinedInput: {
			styleOverrides: {
				// Name of the slot
				input: {
					// Some CSS
					padding: "28.5px 14px",
					boxSizing: "inherit",
					borderRadius: 48,
				},
				root: {
					borderRadius: 48,
					boxShadow: "3px 3px 8px 0 #ccc",
				},
			},
		},
	},
});
