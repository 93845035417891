import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@mui/styles";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { getUrl, postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import TradeInPrint from "./TradeInPrint";
import NumberFormat from 'react-number-format';

// MUI
import {
	Link,
	TextField,
	Box,
	Button,
	Tab,
	Tabs,
	Grid,
	Typography,
	FormControl,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	FormLabel,
	Checkbox,
	Breadcrumbs
} from "@mui/material/";
import Autocomplete from '@mui/material/Autocomplete';

// ReactIcon
import { IoAdd, IoTrashOutline } from "react-icons/io5";
import { FiArrowLeft } from "react-icons/fi";
import { MdBadge, MdMailOutline } from "react-icons/md";

// Tabs
import PropTypes from "prop-types";

//Tabs
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<div>{children}</div>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

// ImageUploaded
function srcset(image, width, height, rows = 1, cols = 1) {
	return {
		// src: `${image}?w=${width * cols}&h=${
		// 	height * rows
		// }&fit=crop&auto=format`,
		// srcSet: `${image}?w=${width * cols}&h=${
		// 	height * rows
		// }&fit=crop&auto=format&dpr=2 2x`,
		src: `${image}`,
		srcSet: `${image}`,
	};
}

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
	const { onChange, ...other } = props;
  
	return (
	  <NumberFormat
		{...other}
		getInputRef={ref}
		onValueChange={(values) => {
		  onChange({
			target: {
			  name: props.name,
			  value: values.value,
			},
		  });
		}}
		thousandSeparator
		isNumericString
	  />
	);
  });
  
  NumberFormatCustom.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
  };

export default function ProductAdd() {
	const styles = useStyles();
	const { t, i18n } = useTranslation();
	const isMountedRef = useRef(null);
	const theme = useTheme();
	const { addAlert, setLoading } = useNotificationLoading();
	let navigate = useNavigate();
	const location = useLocation();
    const productType = location.pathname === "/product-add" ? "new" : location.pathname === "/consignment-add" ? "consignment":"trade-in";
    const userId = useSelector(state => state.user ? state.user.id : 0);

	const [tabValue, setTabValue] = useState(0);
	const [brands, setBrands] = useState([]);
	const [suppliers, setSuppliers] = useState([]);
	const [customers, setCustomers] = useState([]);
    const [collectors, setCollectors] = useState([]);
	const [uploadImages, setUploadImages] = useState([]);
	const [state, setState] = useState({
		supplier_id: 0,
		customer_id: 0,
		type: productType,
		name: '',
		brand_id: '',
		selectedBrand: null,
		serial_no: '',
		model: '',
		ref_no: '',
		diameter: '',
        whats_in_box: '',
		description: '',
		warranty_period: { factory: { sdate: "", edate: "" }, shop: { period: "365"}},
		retail_price: '',
		sell_price: '',
		min_sell_price: '',
		cost_price: '',
		cost_price2: '',
		quantity: '',
		bonus_setting: { personal: "", group: "", purchaser: "" },
		keywords: '',
		extra: { can_accept_tradein: true, can_temp_sell: true },
		// trade_price: '',
		images: [],
		warranty_images: [],
        collector_id: userId,
        selectedCollector: null,
	});
	const [inputErrors, setInputErrors] = useState({});
	const [displayImages, setDisplayImages] = useState([]);

	// const handleTabChange = (event, newValue) => {
	// 	setTabValue(newValue);
	// };

	const handleNext = () => {
		setTabValue((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setTabValue((prevActiveStep) => prevActiveStep - 1);
	};

	useEffect(() => {
        isMountedRef.current = true;
		setLoading(true);

		getDropdownOptions();

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, setLoading])

	useEffect(() => {
        if(uploadImages){
			if (FileReader) {
				_.map(uploadImages, (img, index) => {
					var fr = new FileReader();
					fr.onload = function () {
						setDisplayImages(prevState => (
							[...prevState, fr.result]
						))
					}
					fr.readAsDataURL(img);
					setState(prevState => ({ ...prevState,
						images: [...prevState.images, img]
					}))
				})
			}
		}
        // eslint-disable-next-line
    }, [uploadImages])

	const removeImage = (index) => {
		let copyImages = [...state.images];
		let duplicateDisplayImages = [...displayImages];
		copyImages.splice(index, 1);
		duplicateDisplayImages.splice(index, 1);
		setState(prevState => ({ ...prevState,
			images: copyImages
		}))
		setDisplayImages(duplicateDisplayImages)
	};

    useEffect(() => {
        let tempCollector = state.selectedCollector;
        if(state.collector_id && _.size(collectors) > 0 && !_.size(state.selectedCollector)){
            tempCollector = _.find(collectors, {id: state.collector_id});
		}
        setState({...state, selectedCollector: tempCollector});
        // eslint-disable-next-line
    }, [state.collector_id, collectors])

	const getDropdownOptions = () => {
		getUrl(`/products/create`).then(response => {
			setLoading(false);
			if (isMountedRef.current) {
				if (response.status) {
					setBrands(response.data.brand_list);
					setSuppliers(response.data.supplier_list);
					setCustomers(response.data.customer_list);
					setCollectors(response.data.collector_list);
				}
			}
		}).catch(error => {
			setLoading(false);
			addAlert('', error.message || t('error.contactSupport'), 'error', '');
		});
	};

	const submitData = (e) => {
        e.preventDefault();
		handleNext();
	}

	const submitData2 = (e) => {
        e.preventDefault();
		setLoading(true);

		const formData = new FormData();
        if(state.warranty_images){
			for (let i = 0; i < state.warranty_images.length; i++) {
				formData.append('warranty_images[]', state.warranty_images[i]);
			}
        }
        if(state.images){
			for (let i = 0; i < state.images.length; i++) {
				formData.append('images[]', state.images[i]);
			}
        }
        formData.append("name", state.name);
        formData.append("type", state.type);
		if(productType === "new"){
			formData.append("supplier_id", state.supplier_id);
		}else if(productType === "consignment"){
			formData.append("supplier_id", state.supplier_id);
			formData.append("collector_id", state.collector_id);
		}else{
			formData.append("customer_id", state.customer_id);
			formData.append("collector_id", state.collector_id);
			formData.append("trade_price", state.trade_price);
		}
		formData.append("brand_id", state.brand_id);
		formData.append("serial_no", state.serial_no);
		formData.append("model", state.model);
		formData.append("ref_no", state.ref_no);
		formData.append("diameter", state.diameter);
		formData.append("whats_in_box", state.whats_in_box);
		formData.append("description", state.description);
		_.each( state.warranty_period, ( val, key ) => { 
			_.each( val, ( val2, key2 ) => { 
				formData.append(`warranty_period[${key}][${key2}]`, val2 || "");
			})
		});
		formData.append("retail_price", state.retail_price);
		formData.append("sell_price", parseFloat(state.sell_price));
		formData.append("min_sell_price", parseFloat(state.min_sell_price));
		formData.append("cost_price",  parseFloat(state.cost_price));
		formData.append("cost_price2", parseFloat(state.cost_price2));
		formData.append("quantity", 1);
		_.each( state.bonus_setting, ( val, key ) => { 
			formData.append(`bonus_setting[${key}]`, val || 0);
		});
		formData.append("keywords", state.keywords);
		_.each( state.extra, ( val, key ) => { 
			formData.append(`extra[${key}]`, val);
		});
        setInputErrors();
		// console.log(...formData)

        postUrl(`/products`, formData).then(response => {
            setLoading(false);
            let { status, errors, message, data } = response;
            if (status) {
                addAlert('', message, 'success', '');
				navigate(`${productType === 'trade-in' ? `/tradein-edit/${data.id}` : productType === 'consignment' ? `/consignment-edit/${data.id}`:`/product-edit/${data.id}`}`, { replace: true });
            } else {
                setInputErrors(errors);
                addAlert('', message, 'error', '');
				const product_errors=['name','brand_id','serial_no','description','model','ref_no','diameter','whats_in_box','keywords','images'];
				if(_.size(state.images)>0){
					_.map(Object.keys(state.images),(key)=>{
						product_errors.push(`images.${key}`);
						// console.log("errors",product_errors);
					})
				}
				if(_.size(errors) > 0 && (Object.keys(errors).some(key => product_errors.includes(key)))){
					handleBack();
				}
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
	}

	return (
		<div>
<div className={styles.flexMiddle} style={{ justifyContent: 'space-between', paddingBottom: 30, }}>	
			
				<Link underline="none" to={`/products`} component={RouterLink}>
					<div
						className={styles.flexMiddle}
						style={{ paddingBottom: 30 }}
					>
						<FiArrowLeft style={{ marginRight: 15 }} />
						<Typography className={styles.subtitle}>
							{t("title.products")}
						</Typography>
					</div>
				</Link>

				<div>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                color="inherit"
                                href="/dashboard"
                            >
                                {t("title.dashboard")}
                            </Link>
							<Link
                                underline="hover"
                                color="inherit"
                                href="/products"
                            >
                                {t("title.products")}
                            </Link>
                            <Typography color="text.primary">
                                {t("product.addNewProduct")}
                            </Typography>
                        </Breadcrumbs>
                    </div>
					</div>
                    
		
				<Typography style={{ paddingBottom: 15, fontSize: 18 }}>
					<b>{t("product.addNewProduct")}</b>
				</Typography>

				<Box sx={{ width: "100%" }}>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<Tabs
							value={tabValue}
							// onChange={handleTabChange}
							aria-label="basic tabs example"
						>
							<Tab
								label={t("product.generalInfo")}
								{...a11yProps(0)}
							/>
							<Tab
								label={t("product.productDetails")}
								{...a11yProps(1)}
							/>
							<Tab
								label={t("product.warranty&Price")}
								{...a11yProps(2)}
							/>
						</Tabs>
					</Box>
					<TabPanel value={tabValue} index={0}>
						{/* ------------ Only show for Add New Product -------------- */}
						{(productType === "new" || productType === "consignment") && (
							<SectionSupplier
								state={state}
								setState={setState}
								suppliers={suppliers}
								addAlert={addAlert}
								setLoading={setLoading}
								handleNext={handleNext}
								getDropdownOptions={getDropdownOptions}
							/>
						)}

						{/* ------------ Only show for Trade In -------------- */}
						{productType === "trade-in" && (
							<SectionCustomer
								state={state}
								setState={setState}
								customers={customers}
								addAlert={addAlert}
								setLoading={setLoading}
								handleNext={handleNext}
								getDropdownOptions={getDropdownOptions}
							/>
						)}
					</TabPanel>

					<TabPanel value={tabValue} index={1}>
						<Box
							component="form"
							autoComplete="on"
							sx={{
								"& .MuiTextField-root": { m: 1, width: "100%" },
							}}
							onSubmit={submitData}
						>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6} md={4}>
									{
										_.size(inputErrors) > 0 && inputErrors.images && <Typography style={{ color: 'red' }} variant="subtitle1" gutterBottom component="div">{inputErrors.images}</Typography>
									}
									{
										_.size(inputErrors) > 0 && (Object.keys(state.images).some(key => inputErrors[`images.${key}`])) && <Typography style={{ color: 'red' }} variant="subtitle1" gutterBottom component="div">{t('error.uploadImageError')}</Typography>
									}
									<Typography>
										{t("general.uploadImgMinQty")}
									</Typography>
									{/* <Box
										className={styles.uploadMedia}
										style={{
											color: theme.palette.gray.main,
										}}
									> */}
									<label 
										htmlFor="product-images-upload" 
										className={styles.uploadMedia}
										style={{
											color: theme.palette.gray.main,
											cursor: 'pointer'
										}}>
										<input accept="image/*" id="product-images-upload" multiple type="file" style={{ display: "none"}} onChange={({target}) => setUploadImages(target.files)}/>
										<IoAdd style={{ fontSize: "3rem" }} />
										<Typography style={{ fontSize: 14 }}>
											{t("general.uploadImgText")}
										</Typography>
									</label>
									<Typography style={{ fontSize: 14 }}>
										{t("product.recommendedSize", { width: '800px', height: '870px' })}
									</Typography>
									{/* </Box> */}
									{
										_.size(displayImages) > 0 ?
										<ImageList
											sx={{
												width: "100%",
												height: 300,
												// Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
												transform: "translateZ(0)",
											}}
											sm={{
												overflow: "visible",
											}}
											rowHeight={200}
											gap={1}
										>
											{ _.map(displayImages, (item, index) => {
												const cols = 1;//item.featured ? 2 : 1;
												const rows = 1;//item.featured ? 2 : 1;

												return (
													<ImageListItem
														key={index}
														cols={cols}
														rows={rows}
														style={{position: "relative" }}
													>
														<IoTrashOutline 
															style={{
																position: "absolute",
																right: 0,
																color: "white",
																top: 0,
																zIndex: 1,
																background: "black",
																cursor: 'pointer'
															}} 
															size="1.4em"
															onClick={() => removeImage(index)}
														/>
														<img
															{...srcset(
																item,
																250,
																200,
																rows,
																cols
															)}
															alt={`uploaded_${index}`}
															loading="lazy"
														/>
														<ImageListItemBar
															sx={{
																background:
																	"linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
																	"rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
															}}
															// title={item.title}
															position="top"
															actionPosition="left"
														/>
													</ImageListItem>
												);
											}) }
										</ImageList>
										: null 
									}
								</Grid>
								<Grid item xs={12} sm={6} md={8}>
									<Grid container spacing={3}>
										<Grid item xs={12} sm={12}>
											<TextField
												id="Name"
												label={t("product.name")}
												variant="outlined"
												required
												value={state.name}
												helperText={
													inputErrors &&
													inputErrors.name
														? inputErrors.name
														: ""
												}
												error={
													inputErrors &&
													inputErrors.name
														? true
														: false
												}
												onChange={({ target }) =>
													setState({
														...state,
														name: target.value,
													})
												}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Autocomplete
												disablePortal
												id="brand-input"
												options={brands}
												getOptionLabel={(option) =>
													option.name
												}
												fullWidth
												renderInput={(params) => (
													<TextField
														{...params}
														label={t(
															"product.brand"
														)}
														helperText={
															inputErrors &&
															inputErrors.brand_id
																? inputErrors.brand_id
																: ""
														}
														error={
															inputErrors &&
															inputErrors.brand_id
																? true
																: false
														}
														required
													/>
												)}
												onChange={(event, newValue) => {
													setState({
														...state,
														selectedBrand: newValue,
														brand_id:
															newValue?.id ?? 0,
													});
												}}
												value={state.selectedBrand}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												id="serialNo"
												label={t("product.serialNo")}
												variant="outlined"
												required
												value={state.serial_no}
												helperText={
													inputErrors &&
													inputErrors.serial_no
														? inputErrors.serial_no
														: ""
												}
												error={
													inputErrors &&
													inputErrors.serial_no
														? true
														: false
												}
												onChange={({ target }) =>
													setState({
														...state,
														serial_no: target.value,
													})
												}
											/>
										</Grid>
										<Grid item xs={12} sm={12}>
											<TextField
												id="productDescription"
												label={t("product.description")}
												variant="outlined"
												value={state.description}
												helperText={
													inputErrors &&
													inputErrors.description
														? inputErrors.description
														: ""
												}
												error={
													inputErrors &&
													inputErrors.description
														? true
														: false
												}
												onChange={({ target }) =>
													setState({
														...state,
														description:
															target.value,
													})
												}
												multiline
												maxRows={4}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												id="model"
												label={t("product.model")}
												variant="outlined"
												value={state.model}
												helperText={
													inputErrors &&
													inputErrors.model
														? inputErrors.model
														: ""
												}
												error={
													inputErrors &&
													inputErrors.model
														? true
														: false
												}
												onChange={({ target }) =>
													setState({
														...state,
														model: target.value,
													})
												}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												id="refNo"
												label={t("product.refNo")}
												variant="outlined"
												value={state.ref_no}
												helperText={
													inputErrors &&
													inputErrors.ref_no
														? inputErrors.ref_no
														: ""
												}
												error={
													inputErrors &&
													inputErrors.ref_no
														? true
														: false
												}
												onChange={({ target }) =>
													setState({
														...state,
														ref_no: target.value,
													})
												}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												id="diameterSize"
												label={t(
													"product.diameterSize"
												)}
												variant="outlined"
												value={state.diameter}
												helperText={
													inputErrors &&
													inputErrors.diameter
														? inputErrors.diameter
														: ""
												}
												error={
													inputErrors &&
													inputErrors.diameter
														? true
														: false
												}
												onChange={({ target }) =>
													setState({
														...state,
														diameter: target.value,
													})
												}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												id="whats_in_box"
												label={t("product.whatsInBox")}
												variant="outlined"
												value={state.whats_in_box}
												helperText={
													inputErrors &&
													inputErrors.whats_in_box
														? inputErrors.whats_in_box
														: ""
												}
												error={
													inputErrors &&
													inputErrors.whats_in_box
														? true
														: false
												}
												onChange={({ target }) =>
													setState({
														...state,
														whats_in_box: target.value,
													})
												}
											/>
										</Grid>
										<Grid item xs={12} sm={12}>
											<TextField
												id="tag"
												label={t("product.tag")}
												variant="outlined"
												value={state.keywords}
												helperText={
													inputErrors &&
													inputErrors.keywords
														? inputErrors.keywords
														: ""
												}
												error={
													inputErrors &&
													inputErrors.keywords
														? true
														: false
												}
												onChange={({ target }) =>
													setState({
														...state,
														keywords: target.value,
													})
												}
											/>
										</Grid>
                                        {(productType === "trade-in" || productType === "consignment") && (
                                            <Grid item xs={12} sm={6}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="collertor-input"
                                                    options={collectors}
                                                    getOptionLabel={(option) =>
                                                        option.name
                                                    }
                                                    fullWidth
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t(
                                                                "product.collector"
                                                            )}
                                                            helperText={
                                                                inputErrors &&
                                                                    inputErrors.collector_id
                                                                    ? inputErrors.collector_id
                                                                    : ""
                                                            }
                                                            error={
                                                                inputErrors &&
                                                                    inputErrors.collector_id
                                                                    ? true
                                                                    : false
                                                            }
                                                            required
                                                        />
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setState({
                                                            ...state,
                                                            selectedCollector: newValue,
                                                            collector_id:
                                                                newValue?.id ?? 0,
                                                        });
                                                    }}
                                                    value={state.selectedCollector}
                                                />
                                            </Grid>
                                        )}
										<Grid item xs={12}>
											<FormControl>
												<FormLabel
													id="demo-row-radio-buttons-group-label"
													style={{
														paddingBottom: 15,
													}}
												>
													{t(
														"product.purchaseOption"
													)}
												</FormLabel>
												<div
													className={
														styles.purchaseOption
													}
												>
													<div
														style={{
															display: "flex",
															alignItems:
																"center",
															marginRight: 15,
															marginBottom: 10,
														}}
													>
														<Checkbox
															checked={
																state.extra
																	.can_accept_tradein
															}
															onChange={(
																event,
																checked
															) =>
																setState({
																	...state,
																	extra: {
																		...state.extra,
																		can_accept_tradein:
																			checked,
																	},
																})
															}
															value="can_accept_tradein"
															name="radio-buttons"
															inputProps={{
																"aria-label":
																	"can_accept_tradein",
															}}
														></Checkbox>
														<img
															src={`/images/product/tradein-${i18n.language}.png`}
															style={{
																width: 200,
																maxWidth:
																	"40vw",
																borderTopLeftRadius: 48,
															}}
															alt="trade-in"
														/>
													</div>

													<div
														style={{
															display: "flex",
															alignItems:
																"center",
															marginRight: 15,
															marginBottom: 10,
														}}
													>
														<Checkbox
															checked={
																state.extra
																	.can_temp_sell
															}
															onChange={(
																event,
																checked
															) =>
																setState({
																	...state,
																	extra: {
																		...state.extra,
																		can_temp_sell:
																			checked,
																	},
																})
															}
															value="can_temp_sell"
															name="radio-buttons"
															inputProps={{
																"aria-label":
																	"can_temp_sell",
															}}
														></Checkbox>
														<img
															src={`/images/product/tempsell-${i18n.language}.png`}
															style={{
																width: 200,
																maxWidth:
																	"40vw",
																borderTopLeftRadius: 48,
															}}
															alt="temporary-sell"
														/>
													</div>
												</div>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Box
								style={{
									paddingTop: "5%",
									display: "flex",
									justifyContent: "flex-end",
									alignItems: "center",
								}}
							>
								<Button
									size="large"
									variant="outlined"
									className={styles.buttonStyle}
									style={{ marginRight: 15 }}
									onClick={() => handleBack()}
								>
									{t("button.backStep")}
								</Button>

								<Button
									type="submit"
									size="large"
									variant="contained"
									className={styles.buttonStyle}
								>
									{t("button.nextStep")}
								</Button>
							</Box>
						</Box>
					</TabPanel>

					<TabPanel value={tabValue} index={2}>
						<Box
							component="form"
							autoComplete="on"
							sx={{
								"& .MuiTextField-root": { m: 1, width: "100%" },
							}}
							onSubmit={submitData2}
						>
							<Typography
								style={{
									color: theme.palette.secondary.secondary,
									fontSize: 16,
								}}
							>
								<b>{t("title.warranty")}</b>
							</Typography>
							<Grid container spacing={3}>
								<Grid item xs={12} md={4} lg={3}>
									<label
										htmlFor="warranty-images-upload" 
										className={styles.uploadMedia}
										style={{
											color: theme.palette.gray.secondary,
											height: 250,
											minHeight: 150,
											cursor: 'pointer'
										}}
									>
										<input accept="image/*" id="warranty-images-upload" multiple type="file" style={{ display: "none"}} onChange={({target}) => setState({ ...state, warranty_images: target.files })}/>
										<IoAdd
											style={{
												fontSize: 73,
												marginBottom: 15,
											}}
										/>
										<Typography
											style={{ textAlign: "center" }}
										>
											{t("general.uploadImgText")}
										</Typography>
										{
											_.size(state.warranty_images) >0 && 
											<Typography
												style={{ textAlign: "center" }}
											>
												{_.size(state.warranty_images)} file(s) selected.
											</Typography>
										}
									</label>
								</Grid>
								<Grid item xs={12} md={8} lg={9}>
									<div>
										<Typography>
											{t("product.factoryWarranty")}
										</Typography>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={6}>
												<TextField
													id="facStartDate"
													label={t(
														"product.startDate"
													)}
													variant="outlined"
													type="date"
													InputLabelProps={{
														shrink: true,
													}}
													value={
														state.warranty_period
															.factory.sdate
													}
													helperText={
														inputErrors &&
														inputErrors
															.warranty_period
															?.factory?.sdate
															? inputErrors
																	.warranty_period
																	.factory
																	.sdate
															: ""
													}
													error={
														inputErrors &&
														inputErrors
															.warranty_period
															?.factory?.sdate
															? true
															: false
													}
													onChange={({ target }) =>
														setState({
															...state,
															warranty_period: {
																...state.warranty_period,
																factory: {
																	...state
																		.warranty_period
																		.factory,
																	sdate: target.value,
																},
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<TextField
													id="facEndDdate"
													label={t("product.endDate")}
													variant="outlined"
													type="date"
													InputLabelProps={{
														shrink: true,
													}}
													value={
														state.warranty_period
															.factory.edate
													}
													helperText={
														inputErrors &&
														inputErrors
															.warranty_period
															?.factory?.edate
															? inputErrors
																	.warranty_period
																	.factory
																	.edate
															: ""
													}
													error={
														inputErrors &&
														inputErrors
															.warranty_period
															?.factory?.edate
															? true
															: false
													}
													onChange={({ target }) =>
														setState({
															...state,
															warranty_period: {
																...state.warranty_period,
																factory: {
																	...state
																		.warranty_period
																		.factory,
																	edate: target.value,
																},
															},
														})
													}
												/>
											</Grid>
										</Grid>
									</div>
									<div style={{ paddingTop: 30 }}>
										<Typography>
											{t("product.shopWarranty")}
										</Typography>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={6}>
												<TextField
													id="productStartDate"
													type="number"
													label={t(
														"product.period"
													)}
													variant="outlined"
													// type="date"
													InputLabelProps={{
														shrink: true,
													}}
													value={
														state.warranty_period
															.shop.period
													}
													helperText={
														inputErrors &&
														inputErrors
															.warranty_period
															?.shop?.period
															? inputErrors
																	.warranty_period
																	.shop.period
															: ""
													}
													error={
														inputErrors &&
														inputErrors
															.warranty_period
															?.shop?.period
															? true
															: false
													}
													onChange={({ target }) =>
														setState({
															...state,
															warranty_period: {
																...state.warranty_period,
																shop: {
																	...state
																		.warranty_period
																		.shop,
																	period: target.value,
																},
															},
														})
													}
												/>
											</Grid>
											{/* <Grid item xs={12} sm={6}>
												<TextField
													id="productEndDate"
													label={t("product.endDate")}
													variant="outlined"
													type="date"
													InputLabelProps={{
														shrink: true,
													}}
													value={
														state.warranty_period
															.shop.edate
													}
													helperText={
														inputErrors &&
														inputErrors
															.warranty_period
															?.shop?.edate
															? inputErrors
																	.warranty_period
																	.shop.edate
															: ""
													}
													error={
														inputErrors &&
														inputErrors
															.warranty_period
															?.shop?.edate
															? true
															: false
													}
													onChange={({ target }) =>
														setState({
															...state,
															warranty_period: {
																...state.warranty_period,
																shop: {
																	...state
																		.warranty_period
																		.shop,
																	edate: target.value,
																},
															},
														})
													}
												/>
											</Grid> */}
										</Grid>
									</div>
								</Grid>
							</Grid>

							<Typography
								style={{
									color: theme.palette.secondary.secondary,
									fontSize: 16,
									paddingTop: 30,
									paddingBottom: 10,
								}}
							>
								<b>{t("product.price")}</b>
							</Typography>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<TextField
										id="costPrice1"
										// type="number"
										label={t("product.costPrice1")}
										variant="outlined"
                                        required
										value={state.cost_price}
										helperText={
											inputErrors &&
											inputErrors.cost_price
												? inputErrors.cost_price
												: ""
										}
										error={
											inputErrors &&
											inputErrors.cost_price
												? true
												: false
										}
										onChange={({ target }) =>
											setState({
												...state,
												cost_price: target.value,
											})
										}
										InputProps={{
											inputComponent: NumberFormatCustom,
										  }}
										name="cost_price" 
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="costPrice2"
										// type="number"
										label={t("product.costPrice2")}
										variant="outlined"
										required
										value={state.cost_price2}
										helperText={
											inputErrors &&
											inputErrors.cost_price2
												? inputErrors.cost_price2
												: ""
										}
										error={
											inputErrors &&
											inputErrors.cost_price2
												? true
												: false
										}
										onChange={({ target }) =>
											setState({
												...state,
												cost_price2: target.value,
											})
										}
										InputProps={{
											inputComponent: NumberFormatCustom,
										  }}
										name="cost_price2" 
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="sellPrice"
										// type="number"
										label={t("product.sellPrice")}
										variant="outlined"
										required
										value={state.sell_price}
										helperText={
											inputErrors &&
											inputErrors.sell_price
												? inputErrors.sell_price
												: ""
										}
										error={
											inputErrors &&
											inputErrors.sell_price
												? true
												: false
										}
										onChange={({ target }) =>
											setState({
												...state,
												sell_price: target.value,
											})
										}
										InputProps={{
											inputComponent: NumberFormatCustom,
										  }}
										name="sell_price" 
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="minPrice"
										// type="number"
										label={t("product.minPrice")}
										variant="outlined"
										required
										value={state.min_sell_price}
										helperText={
											inputErrors &&
											inputErrors.min_sell_price
												? inputErrors.min_sell_price
												: ""
										}
										error={
											inputErrors &&
											inputErrors.min_sell_price
												? true
												: false
										}
										onChange={({ target }) =>
											setState({
												...state,
												min_sell_price: target.value,
											})
										}
										InputProps={{
											inputComponent: NumberFormatCustom,
										  }}
										name="min_sell_price" 
									/>
								</Grid>
								{/* <Grid item xs={12} sm={6}>
									<TextField
										id="retailPrice"
										type="number"
										label={t("product.retailPrice")}
										variant="outlined"
										value={state.retail_price}
										helperText={
											inputErrors &&
											inputErrors.retail_price
												? inputErrors.retail_price
												: ""
										}
										error={
											inputErrors &&
											inputErrors.retail_price
												? true
												: false
										}
										onChange={({ target }) =>
											setState({
												...state,
												retail_price: target.value,
											})
										}
									/>
								</Grid>
								{/* {productType === "trade-in" && (
									<Grid item xs={12} sm={6}>
										<TextField
											id="collectPrice"
											label={t("product.collectPrice")}
											variant="outlined"
											value={state.trade_price}
											helperText={
												inputErrors &&
												inputErrors.trade_price
													? inputErrors.trade_price
													: ""
											}
											error={
												inputErrors &&
												inputErrors.trade_price
													? true
													: false
											}
											onChange={({ target }) =>
												setState({
													...state,
													trade_price: target.value,
												})
											}
											InputProps={{
												inputComponent: NumberFormatCustom,
											  }}
											name="collect_price" 
										/>
									</Grid>
								)} */}
							</Grid>

							<Typography
								style={{
									color: theme.palette.secondary.secondary,
									fontSize: 16,
									paddingTop: 30,
									paddingBottom: 10,
								}}
							>
								<b>{t("product.salesComission")}</b>
							</Typography>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<TextField
										id="personalcomm"
										type="number"
										label={t("product.personalCommission")}
										variant="outlined"
										value={state.bonus_setting.personal}
										helperText={
											inputErrors &&
											inputErrors.bonus_setting?.personal
												? inputErrors.bonus_setting
														.personal
												: ""
										}
										error={
											inputErrors &&
											inputErrors.bonus_setting?.personal
												? true
												: false
										}
										onChange={({ target }) =>
											setState({
												...state,
												bonus_setting: {
													...state.bonus_setting,
													personal: target.value,
												},
											})
										}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="groupcomm"
										type="number"
										label={t("product.groupCommission")}
										variant="outlined"
										value={state.bonus_setting.group}
										helperText={
											inputErrors &&
											inputErrors.bonus_setting?.group
												? inputErrors.bonus_setting
														.group
												: ""
										}
										error={
											inputErrors &&
											inputErrors.bonus_setting?.group
												? true
												: false
										}
										onChange={({ target }) =>
											setState({
												...state,
												bonus_setting: {
													...state.bonus_setting,
													group: target.value,
												},
											})
										}
									/>
								</Grid>
								{(productType === "trade-in" || productType === "consignment") && (
									<Grid item xs={12} sm={6}>
										<TextField
											id="purchasercomm"
											type="number"
											label={t(
												"product.purchaserCommission"
											)}
											variant="outlined"
											value={
												state.bonus_setting.purchaser
											}
											helperText={
												inputErrors &&
												inputErrors.bonus_setting
													?.purchaser
													? inputErrors.bonus_setting
															.purchaser
													: ""
											}
											error={
												inputErrors &&
												inputErrors.bonus_setting
													?.purchaser
													? true
													: false
											}
											onChange={({ target }) =>
												setState({
													...state,
													bonus_setting: {
														...state.bonus_setting,
														purchaser: target.value,
													},
												})
											}
										/>
									</Grid>
								)}
							</Grid>

							<Box
								style={{
									paddingTop: "5%",
									display: "flex",
									justifyContent: "flex-end",
									alignItems: "center",
								}}
							>
								<Button
									size="large"
									variant="outlined"
									className={styles.buttonStyle}
									style={{ marginRight: 15 }}
									onClick={() => handleBack()}
								>
									{t("button.backStep")}
								</Button>
								{
									productType === "trade-in" && <TradeInPrint customers={customers} type={productType} listing={state} label={t('button.agreement')}/>
								}
								<Button
									type="submit"
									size="large"
									variant="contained"
									className={styles.buttonStyle}
								>
									{t("button.save")}
								</Button>
							</Box>
						</Box>
					</TabPanel>
				</Box>
			</div>
	
	);
}

function SectionSupplier(props){
	const styles = useStyles();
	const { t } = useTranslation();
	const isMountedRef = useRef(null);
	
	const [state, setState] = useState({
		name: '',
		email: '',
		mobile: '',
		ic: '',
		country: '',
		zip: '',
		state: '',
		city: '',
		address: '',
		selectedName: null
	});
	const [inputErrors, setInputErrors] = useState({});

	useEffect(() => {
		isMountedRef.current = true;
		if(isMountedRef.current){
			if (props.state.supplier_id > 0 && !_.size(state.selectedName) && _.size(props.suppliers)){
				let selectedName = _.find(props.suppliers, {
					id: props.state.supplier_id
				});
				setState({
					...state,
					selectedName: selectedName,
					email: selectedName?.email ?? "",
					mobile: selectedName?.mobile ?? "",
					ic: selectedName?.ic ?? "",
					country: selectedName?.addresses?.[0].country ?? "",
					zip: selectedName?.addresses?.[0].zip ?? "",
					state: selectedName?.addresses?.[0].state ?? "",
					city: selectedName?.addresses?.[0].city ?? "",
					address: selectedName?.addresses?.[0].address1 ?? ""
				});
			}else if (_.size(state.selectedName)){
				setState({
					...state,
					email: state.selectedName.email,
					mobile: state.selectedName.mobile ?? "",
					ic: state.selectedName.ic,
					country: state.selectedName.addresses?.[0].country,
					zip: state.selectedName.addresses?.[0].zip,
					state: state.selectedName.addresses?.[0].state,
					city: state.selectedName.addresses?.[0].city,
					address: state.selectedName.addresses?.[0].address1,
				});
			}else{
				setState({
					...state,
					email: '',
					mobile: '',
					ic: '',
					country: '',
					zip: '',
					state: '',
					city: '',
					address: '',
				});
			}
		}
		return () => { isMountedRef.current = false };
		// eslint-disable-next-line
	}, [props.state.supplier_id, props.suppliers, state.selectedName]);

	const submitData = (e) => {
        e.preventDefault();
		if(state.selectedName){
			props.setState({ ...props.state, supplier_id: state.selectedName.id })
			props.handleNext();
		}else{
			const postData = {
				name: state.name,
				email: state.email,
				mobile: state.mobile,
				ic: state.ic,
				country: state.country,
				zip: state.zip,
				state: state.state,
				city: state.city,
				address: state.address
			}

			props.setLoading(true);
			setInputErrors();

			postUrl(`/suppliers`, postData).then(response => {
				props.setLoading(false);
				let { status , data, errors, message } = response;
				if (status) {
					props.addAlert('', 'Supplier Created Successfully', 'success', '');
					props.setState({
						...props.state,
						supplier_id: data.id,
					});
					props.getDropdownOptions();
					props.handleNext();
				} else {
					setInputErrors(errors);
					props.addAlert('', message, 'error', '');
				}
			}).catch(error => {
				props.setLoading(false);
				props.addAlert('', error.message || t('error.contactSupport'), 'error', '');
			});
		}
    }

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Typography
						style={{ padding: "40px 0 10px 0" }}
						className={styles.titleText}
					>
						{t("product.supplierDetails")}
					</Typography>
				</Grid>
			</Grid>
			<Box
				component="form"
				autoComplete="on"
				sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }}}
				onSubmit={submitData}
			>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12}>
						<Autocomplete
							disablePortal
							id="name-input"
							options={props.suppliers}
							getOptionLabel={(option) => option.name}
							renderOption={(prop, option, state) => (
								<div {...prop}>{option.name} [<MdBadge />: {option.ic}] [<MdMailOutline />: {option.email}]</div>
							)}
							filterOptions={(options, state) =>(
								options.filter(({ email, name}) => [email, name].some(v => v.toLowerCase().includes(state.inputValue.toLowerCase())))
							)}
							fullWidth
							freeSolo
							renderInput={(params) => <TextField {...params} label={t("supplier.supplierName")} helperText={inputErrors && inputErrors.name ? inputErrors.name : ''} error={inputErrors && inputErrors.name ? true : false} />}
							onChange={(event, newValue) => {
								setState({...state, name: '', selectedName: newValue})
								props.setState({ ...props.state, supplier_id: newValue?.id })
							}}
							onInputChange={(event, newValue) => {
								setState({...state, selectedName: null, name: newValue ? newValue : '' })
								props.setState({ ...props.state, supplier_id: 0 })
							}}
							value={state.selectedName}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="nric"
							label={t("supplier.nric_regNo")}
							variant="outlined"
							required
							value={state.ic}
							helperText={inputErrors && inputErrors.ic ? inputErrors.ic : ''}
							error={inputErrors && inputErrors.ic ? true : false}
							onChange={({ target }) => setState({ ...state, ic: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="mobile"
							label={t("user.mobile")}
							variant="outlined"
							value={state.mobile}
							helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
							error={inputErrors && inputErrors.mobile ? true : false}
							onChange={({ target }) => setState({ ...state, mobile: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="email"
							label={t("user.email")}
							variant="outlined"
							required
							type="email"
							value={state.email}
							helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
							error={inputErrors && inputErrors.email ? true : false}
							onChange={({ target }) => setState({ ...state, email: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={8}>
						<TextField
							id="address"
							label={t("user.address")}
							variant="outlined"
							required
							value={state.address}
							helperText={inputErrors && inputErrors.address ? inputErrors.address : ''}
							error={inputErrors && inputErrors.address ? true : false}
							onChange={({ target }) => setState({ ...state, address: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="city"
							label={t("user.city")}
							variant="outlined"
							required
							value={state.city}
							helperText={inputErrors && inputErrors.city ? inputErrors.city : ''}
							error={inputErrors && inputErrors.city ? true : false}
							onChange={({ target }) => setState({ ...state, city: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="state"
							label={t("user.state")}
							variant="outlined"
							required
							value={state.state}
							helperText={inputErrors && inputErrors.state ? inputErrors.state : ''}
							error={inputErrors && inputErrors.state ? true : false}
							onChange={({ target }) => setState({ ...state, state: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="zip"
							label={t("user.zip")}
							variant="outlined"
							required
							value={state.zip}
							helperText={inputErrors && inputErrors.zip ? inputErrors.zip : ''}
							error={inputErrors && inputErrors.zip ? true : false}
							onChange={({ target }) => setState({ ...state, zip: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="country"
							label={t("user.country")}
							variant="outlined"
							required
							value={state.country}
							helperText={inputErrors && inputErrors.country ? inputErrors.country : ''}
							error={inputErrors && inputErrors.country ? true : false}
							onChange={({ target }) => setState({ ...state, country: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
				</Grid>
				<Box
					style={{
						paddingTop: "5%",
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<Button
						type="submit"
						size="large"
						variant="contained"
						className={styles.buttonStyle}
					>
						{t("button.nextStep")}
					</Button>
				</Box>
			</Box>
		</>
	)
}

function SectionCustomer(props){
	const styles = useStyles();
	const { t } = useTranslation();
	const isMountedRef = useRef(null);
	
	const [state, setState] = useState({
		name: '',
		gender:'-',
		email: '',
		mobile: '',
		ic: '',
		country: '',
		zip: '',
		state: '',
		city: '',
		address: '',
		selectedName: null
	});
	const [inputErrors, setInputErrors] = useState({});

	useEffect(() => {
		isMountedRef.current = true;
		if(isMountedRef.current){
			if (props.state.customer_id > 0 && !_.size(state.selectedName) && _.size(props.customers)){
				let selectedName = _.find(props.customers, {
					id: props.state.customer_id
				});
				setState({
					...state,
					selectedName: selectedName,
					gender: selectedName?.gender ?? '-',
					email: selectedName?.email ?? "",
					mobile: selectedName?.mobile ?? "",
					ic: selectedName?.ic ?? "",
					country: selectedName && _.size(selectedName.addresses) >0?selectedName?.addresses?.[0].country ?? "":'',
					zip: selectedName &&  _.size(selectedName.addresses) >0?selectedName?.addresses?.[0].zip ?? "":'',
					state: selectedName &&  _.size(selectedName.addresses) >0?selectedName?.addresses?.[0].state ?? "":'',
					city: selectedName &&  _.size(selectedName.addresses) >0?selectedName?.addresses?.[0].city ?? "":'',
					address: selectedName && _.size(selectedName.addresses) >0?selectedName?.addresses?.[0].address1 ?? "":''
				});
			}else if (_.size(state.selectedName)){
				setState({
					...state,
					gender: state.selectedName.gender,
					email: state.selectedName.email,
					mobile: state.selectedName.mobile,
					ic: state.selectedName.ic,
					country: _.size(state.selectedName.addresses) >0 ? state.selectedName.addresses?.[0].country:'',
					zip:_.size(state.selectedName.addresses) >0 ? state.selectedName.addresses?.[0].zip:'',
					state:_.size(state.selectedName.addresses) >0 ? state.selectedName.addresses?.[0].state:'',
					city: _.size(state.selectedName.addresses) >0 ?state.selectedName.addresses?.[0].city:'',
					address:_.size(state.selectedName.addresses) >0 ? state.selectedName.addresses?.[0].address1:'',
				});
			}else{
				setState({
					...state,
					gender: '-',
					email: '',
					mobile: '',
					ic: '',
					country: '',
					zip: '',
					state: '',
					city: '',
					address: '',
				});
			}
		}
		return () => { isMountedRef.current = false };
		// eslint-disable-next-line
	}, [props.state.customer_id, props.customers, state.selectedName]);

	const submitData = (e) => {
        e.preventDefault();
		if(state.selectedName){
			props.setState({ ...props.state, customer_id: state.selectedName.id })
			props.handleNext();
		}else{
			const postData = {
				name: state.name,
				gender: state.gender,
				email: state.email,
				mobile: state.mobile,
				ic: state.ic,
				country: state.country,
				zip: state.zip,
				state: state.state,
				city: state.city,
				address: state.address ? state.address:'',
			}

			props.setLoading(true);
			setInputErrors();

			postUrl(`/customers`, postData).then(response => {
				props.setLoading(false);
				let { status , data, errors, message } = response;
				if (status) {
					props.addAlert('', 'Customer Created Successfully', 'success', '');
					props.setState({
						...props.state,
						customer_id: data.id,
					});
					props.getDropdownOptions();
					props.handleNext();
				} else {
					setInputErrors(errors);
					props.addAlert('', message, 'error', '');
				}
			}).catch(error => {
				props.setLoading(false);
				props.addAlert('', error.message || t('error.contactSupport'), 'error', '');
			});
		}
    }

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Typography
						style={{ padding: "40px 0 10px 0" }}
						className={styles.titleText}
					>
						{t("product.customerDetails")}
					</Typography>
				</Grid>
			</Grid>
			<Box
				component="form"
				autoComplete="on"
				sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }}}
				onSubmit={submitData}
			>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={8}>
						<Autocomplete
							disablePortal
							id="name-input"
							options={props.customers}
							getOptionLabel={(option) => option.name}
							renderOption={(prop, option, state) => (
								<div {...prop}>{option.name} [<MdBadge />: {option.ic}] [<MdMailOutline />: {option.email}]</div>
							)}
							filterOptions={(options, state) =>(
								options.filter(({ email, name }) => [email, name].some(v => v.toLowerCase().includes(state.inputValue.toLowerCase())))
							)}
							fullWidth
							freeSolo
							renderInput={(params) => <TextField {...params} label={t("user.name")} helperText={inputErrors && inputErrors.name ? inputErrors.name : ''} error={inputErrors && inputErrors.name ? true : false} />}
							onChange={(event, newValue) => {
								setState({...state, name: '', selectedName: newValue})
								props.setState({ ...props.state, customer_id: newValue?.id })
							}}
							onInputChange={(event, newValue) => {
								setState({...state, selectedName: null, name: newValue ? newValue : '' })
								props.setState({ ...props.state, customer_id: 0 })
							}}
							value={state.selectedName}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="standard-basic"
							label={t('user.gender.title')}
							variant="outlined"
							select
							value={state.gender}
							InputLabelProps={{ shrink: true }}
							helperText={inputErrors && inputErrors.gender ? inputErrors.gender : ''}
							error={inputErrors && inputErrors.gender ? true : false}
							onChange={({ target }) => setState({ ...state, gender: target.value })}
							SelectProps={{ native: true }}
							disabled={_.size(state.selectedName)? true:false}
						>
							{['-', 'male', 'female'].map((option) => (
								<option key={option} value={option} disabled={_.size(state.selectedName)? true:false}>
									{t('user.gender.' + option)}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="nric"
							label={t("user.nric")}
							variant="outlined"
							required
							value={state.ic}
							helperText={inputErrors && inputErrors.ic ? inputErrors.ic : ''}
							error={inputErrors && inputErrors.ic ? true : false}
							onChange={({ target }) => setState({ ...state, ic: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="mobile"
							label={t("user.mobile")}
							variant="outlined"
							required
							value={state.mobile}
							helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
							error={inputErrors && inputErrors.mobile ? true : false}
							onChange={({ target }) => setState({ ...state, mobile: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="email"
							label={t("user.email")}
							variant="outlined"
							required
							type="email"
							value={state.email}
							helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
							error={inputErrors && inputErrors.email ? true : false}
							onChange={({ target }) => setState({ ...state, email: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={8}>
						<TextField
							id="address"
							label={t("user.address")}
							variant="outlined"
							value={state.address}
							helperText={inputErrors && inputErrors.address ? inputErrors.address : ''}
							error={inputErrors && inputErrors.address ? true : false}
							onChange={({ target }) => setState({ ...state, address: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="city"
							label={t("user.city")}
							variant="outlined"
							value={state.city}
							helperText={inputErrors && inputErrors.city ? inputErrors.city : ''}
							error={inputErrors && inputErrors.city ? true : false}
							onChange={({ target }) => setState({ ...state, city: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="state"
							label={t("user.state")}
							variant="outlined"
							value={state.state}
							helperText={inputErrors && inputErrors.state ? inputErrors.state : ''}
							error={inputErrors && inputErrors.state ? true : false}
							onChange={({ target }) => setState({ ...state, state: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="zip"
							label={t("user.zip")}
							variant="outlined"
							value={state.zip}
							helperText={inputErrors && inputErrors.zip ? inputErrors.zip : ''}
							error={inputErrors && inputErrors.zip ? true : false}
							onChange={({ target }) => setState({ ...state, zip: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="country"
							label={t("user.country")}
							variant="outlined"
							value={state.country}
							helperText={inputErrors && inputErrors.country ? inputErrors.country : ''}
							error={inputErrors && inputErrors.country ? true : false}
							onChange={({ target }) => setState({ ...state, country: target.value })}
							inputProps={{readOnly: _.size(state.selectedName) }}
						/>
					</Grid>
					<Typography style={{ padding: "20px 0 0 2rem " }}>
						* {t('product.newCustomerAutoCreate')}
					</Typography>
				</Grid>
				<Box
					style={{
						paddingTop: "5%",
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<Button
						type="submit"
						size="large"
						variant="contained"
						className={styles.buttonStyle}
					>
						{t("button.nextStep")}
					</Button>
				</Box>
			</Box>
		</>
	)
}

const useStyles = makeStyles((theme) => ({
	buttonStyle: {
		minWidth: 150,
	},
	purchaseOption: {
		display: "flex",
		alignItems: "center",

		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
		},
		[theme.breakpoints.up("sm")]: {
			flexDirection: "column",
			alignItems: "center",
		},
		[theme.breakpoints.up("md")]: {
			flexDirection: "row",
			alignItems: "center",
		},
	},
	uploadMedia: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		backgroundColor: "#fff",
		boxShadow: "3px 3px 20px 0 #d8d8d8",
		borderRadius: 15,
		border: "2px dashed #aeaeae",
		width: "100%",
		height: 100,
		marginTop: 15,
		padding: 20,
	},
	titleText: {
		textTransform: "uppercase",
		color: "#d8a76c",
		fontWeight: "bold!important",
	},
	flexMiddle: {
		display: "flex",
		alignItems: "center",
	},
}));
