import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeLanguage as reduxChangeLanguage, storeProfile } from '../../actions';
import { getUrl, removeLoginAccess } from '../../helpers/ApiAction';
import _ from 'lodash';

import { styled, useTheme } from '@mui/material/styles';
import { Box, Toolbar, /* List, */ Typography, /* Divider, */ IconButton, /* ListItemButton, ListItemIcon, ListItemText, */ /* Collapse, */ Link, Menu, MenuItem, Grid, Avatar, Button } from '@mui/material';
// import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';


import { FiShoppingCart } from "react-icons/fi";


// Icon
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { /* ExpandLessOutlined, ExpandMoreOutlined, */ MoreVertOutlined, TranslateOutlined, Logout } from '@mui/icons-material';
// import { API } from '../../configs/AxiosConfig';
import { DEFAULT_LANGUAGE } from '../../configs/Config';
import useNotificationLoading from '../../helpers/useNotificationLoading';


const drawerWidth = 240;
const DEFAULT_STATE = {
    languageAnchorEl: null,
    mobileAnchorEl: null,
};

// const openedMixin = (theme) => ({
//     width: drawerWidth,
//     transition: theme.transitions.create('width', {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.enteringScreen,
//     }),
//     overflowX: 'hidden',
// });

// const closedMixin = (theme) => ({
//     transition: theme.transitions.create('width', {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//     }),
//     overflowX: 'hidden',
//     width: `calc(${theme.spacing(7)} + 1px)`,
//     [theme.breakpoints.up('sm')]: {
//         width: `calc(${theme.spacing(8)} + 1px)`,
//     },
// });

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//     ({ theme, open }) => ({
//         width: drawerWidth,
//         flexShrink: 0,
//         whiteSpace: 'nowrap',
//         boxSizing: 'border-box',
//         ...(open && {
//             ...openedMixin(theme),
//             '& .MuiDrawer-paper': openedMixin(theme),
//         }),
//         ...(!open && {
//             ...closedMixin(theme),
//             '& .MuiDrawer-paper': closedMixin(theme),
//         }),
//     }),
// );

export default function MiniDrawer({ routeProps, content }) {
    const theme = useTheme();
    const [currentPath, setCurrentPath] = useState();
    const [showDrawer, setShowDrawer] = useState(false);
    const [state, setState] = useState(DEFAULT_STATE);
    const [levelOpen, setLevelOpen] = useState({});
    const { accessToken/* , permissions */ } = useSelector(state => state.general);
    const { name ,profile_picture } = useSelector(state => state.user);
    const { addAlert } = useNotificationLoading();

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    let location = useLocation();
    let page = location.pathname;
    let navigate = useNavigate();

    useEffect(() => {
        let active = true;
        if (active) {
            const path = _.split(location.pathname, '/');
            if (path) {
                setCurrentPath(path[1].toLowerCase());
            }
        }
        return () => { active = false };
    }, [location.pathname]);

    useEffect(() => {
        setShowDrawer(false);
    }, [currentPath])

    // selected page in category expand it
    useEffect(() => {
        let categoryList = [];
        _.map(routeProps, category => {
            categoryList = categoryList.concat({ [category.name]: false });
            if (category.category) {
                _.map(category.children, route => {
                    if (page === route.path) {
                        handleOpenSecondLevel(category.name);
                    }
                })
            }
        })
        setLevelOpen(categoryList);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (_.includes(['en', 'cn'], i18n.language) === false) {
            changeLanguage(DEFAULT_LANGUAGE || 'en');
        }
        // eslint-disable-next-line
    }, [i18n.language, t]);

    useEffect(() => {
        if (accessToken) {
            getUrl('/user').then(response => {
                if (response.status) {
                    dispatch(storeProfile(response.data));
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                removeLoginAccess();
                navigate('/login');
            });
        }
        // eslint-disable-next-line
    }, [dispatch, addAlert]);

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
        handleMenuClose();
    };

    const handleMenuClose = () => {
        setState(DEFAULT_STATE);
    };

    const handleOpenSecondLevel = type => {
        setShowDrawer(true);
        setLevelOpen({ ...levelOpen, [type]: !levelOpen[type] });
    }

    // const toggleDrawer = () => {
    //     setShowDrawer(!showDrawer);
    // };

    const logoutUser = () => {
        // localStorage.removeItem('accessToken');
        removeLoginAccess();
        dispatch(reduxChangeLanguage(i18n.language));
        navigate('/login');
    }

    // const sidebarListUrl = () => {
    //     return (
    //         <>
    //             {
    //                 _.map(routeProps, category => {
    //                     /* check permission here, have either one permission in categoryPermission */
    //                     if (category.category) {
    //                         // LinkGroup
    //                         if (category.categorySidebar) {
    //                             if (!_.size(category.categoryPermission) || _.size(_.intersection(permissions, category.categoryPermission)) > 0) {
    //                                 return (
    //                                     <div key={category.categoryName}>
    //                                         <ListItemButton
    //                                             sx={{
    //                                                 minHeight: 48,
    //                                                 justifyContent: showDrawer ? 'initial' : 'center',
    //                                                 px: 2.5,
    //                                             }}
    //                                             onClick={() => handleOpenSecondLevel(category.categoryName)}
    //                                         >
    //                                             <ListItemIcon
    //                                                 sx={{
    //                                                     minWidth: 0,
    //                                                     mr: showDrawer ? 3 : 'auto',
    //                                                     justifyContent: 'center',
    //                                                     color: theme.palette.secondary.main,
    //                                                 }}
    //                                             >
    //                                                 {category.categoryIcon}
    //                                             </ListItemIcon>
    //                                             <ListItemText primary={t(category.categoryLabel)} sx={{ opacity: showDrawer ? 1 : 0, color: theme.palette.secondary.main }} />
    //                                             {levelOpen[category.categoryName] ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
    //                                         </ListItemButton>
    //                                         <Collapse in={levelOpen[category.categoryName]} timeout="auto" >
    //                                             {
    //                                                 _.map(category.children, list => {
    //                                                     return sidebarLinkContent(list, true);
    //                                                 })
    //                                             }
    //                                         </Collapse>
    //                                     </div>
    //                                 )
    //                             } else {
    //                                 return null;
    //                             }
    //                         }
    //                     } else {
    //                         // Link
    //                         return (
    //                             <div key={category.categoryName}>
    //                                 {
    //                                     _.map(category.children, list => {
    //                                         return sidebarLinkContent(list, false);
    //                                     })
    //                                 }
    //                             </div>
    //                         )
    //                     }
    //                 })
    //             }
    //         </>
    //     )
    // }



    // const sidebarLinkContent = (content, sub) => {
    //     let fulfilledAllPermission = true;
    //     if (content.sidebar /* check permission here */) {
    //         if (_.size(content.permission) > 0) {
    //             _.map(content.permission, (contentPermission) => {
    //                 if (!_.includes(permissions, contentPermission)) {
    //                     fulfilledAllPermission = false;
    //                 }
    //             })
    //         }
    //         if (fulfilledAllPermission) {
    //             return (
    //                 <Link key={content.path} underline='none' to={content.path} component={RouterLink}>
    //                     <ListItemButton
    //                         key={content.path}
    //                         sx={{
    //                             minHeight: 48,
    //                             justifyContent: showDrawer ? 'initial' : 'center',
    //                             px: 2.5,
    //                             borderLeft: page === content.path ? 'solid' : 'none',
    //                             borderLeftColor: theme.palette.secondary.main,
    //                             borderLeftWidth: 5,
    //                         }}
    //                         selected={page === content.path ? true : false}
    //                     >
    //                         <ListItemIcon
    //                             sx={{
    //                                 minWidth: 0,
    //                                 mr: showDrawer ? 3 : 'auto',
    //                                 justifyContent: 'center',
    //                                 color: theme.palette.secondary.main
    //                             }}
    //                         >
    //                             {sub ? '' : content.icon}
    //                         </ListItemIcon>
    //                         <ListItemText
    //                             primary={t(content.title)}
    //                             sx={{ opacity: showDrawer ? 1 : 0, color: theme.palette.secondary.main }}
    //                         />
    //                     </ListItemButton>
    //                 </Link>
    //             )
    //         }
    //     } else {
    //         return null;
    //     }
    // }

    return (
        <Box sx={{ display: 'flex' }}>
            {/* Header */}
            <AppBar position="fixed" open={showDrawer} color='inherit' style={{ zIndex: 1200, width: '100%', boxShadow: '0 6px 20px 0 #c4c4c463' }}>
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <Link underline='none' component={RouterLink} to={`/dashboard`}>
                        <img src="/images/logo2.png" alt="logo" style={{ height: 50 }} />
                    </Link>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {
                            accessToken ?
                                <Link underline='none' component={RouterLink} to={`/myAccount`}>
                                    <div style={{ display: 'flex', alignItems: 'center', paddingRight: 15 }} xs={2}>
                                        {profile_picture ?
                                            <Avatar sx={{ bgcolor: theme.palette.secondary.main }} src={profile_picture.file_name}/>
                                            :
                                            <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>{name[0]}</Avatar>
                                        }
                                        <Typography paddingLeft={2}>{name}</Typography>
                                    </div>
                                </Link>
                                :
                                <Grid container item direction="row" justifyContent="flex-end" alignItems="center" xs={2}>
                                    <Link underline='none' to={`/login`} component={RouterLink}>
                                        <Button size="medium" variant="contained" color="primary" >{t('button.login')}</Button>
                                    </Link>
                                </Grid>
                        }
                        <Link underline='none' component={RouterLink} to={`/checkout`} style={{ padding: '0 15px' }}>
                            <FiShoppingCart style={{ fontSize: 18 }} />
                        </Link>
                        <IconButton
                            aria-label="show more"
                            aria-haspopup="true"
                            onClick={(event) => setState({ ...state, mobileAnchorEl: event.currentTarget })}
                            color="inherit"
                        >
                            <MoreVertOutlined />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>


            {/* Menu Dropdown */}
            <Menu
                anchorEl={state.mobileAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(state.mobileAnchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={(event) => setState({ ...state, languageAnchorEl: event.currentTarget })} >
                    <IconButton
                        aria-controls="primary-search-language-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <TranslateOutlined />
                    </IconButton>
                    <p>{t('general.language')}</p>
                </MenuItem>
                {
                    accessToken &&
                    <MenuItem onClick={logoutUser} >
                        <IconButton
                            aria-controls="primary-search-logout-menu"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <Logout />
                        </IconButton>
                        <p>{t('button.logout')}</p>
                    </MenuItem>
                }
            </Menu>

            {/* Language Dropdown */}
            <Menu
                anchorEl={state.languageAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(state.languageAnchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => changeLanguage('en')}>{t('general.en')}</MenuItem>
                <MenuItem onClick={() => changeLanguage('cn')}>{t('general.cn')}</MenuItem>
            </Menu>


            <Box component="main" style={{ width: '100%', padding: '24px 0' }} sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {content}
            </Box>
        </Box >
    );
}
