import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { getUrl } from "../../helpers/ApiAction";
import useNotificationLoading from "../../helpers/useNotificationLoading";

// MUI
import {
    Breadcrumbs, Grid, LinearProgress, Link, Typography
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import {
    DataGrid, GridActionsCellItem, gridClasses, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";

//ICON
import { FiArrowLeft } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";

function CustomToolbar() {
	return (
		<GridToolbarContainer
			className={gridClasses.toolbarContainer}
			style={{ paddingBottom: 10 }}
		>
			<GridToolbarExport
				style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
				csvOptions={{ allColumns: true, disableToolbarButton: true }}
				printOptions={{ disableToolbarButton: true }}
			/>
			<GridToolbarDensitySelector
				style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
			/>
			<GridToolbarFilterButton
				style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
			/>
			<GridToolbarColumnsButton
				style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
			/>
		</GridToolbarContainer>
	);
}

export default function Index() {
	const { t, i18n } = useTranslation();
	const [state, setState] = useState([]);
	const [pageSize, setPageSize] = useState(5);
	const [tableLoading, setTableLoading] = useState(false);
	const { addAlert } = useNotificationLoading();
	const { data } = useDemoData({
		dataSet: "role",
		rowLength: 5,
		maxColumns: 6,
	});
	const theme = useTheme();
	let navigate = useNavigate();
	const styles = useStyles();
	const { permissions } = useSelector((state) => state.general);

	useEffect(() => {
		setTableLoading(true);
		getUrl("/roles")
			.then((response) => {
				setTableLoading(false);
				if (response.status) {
					setState(response.data.listing);
				} else {
					addAlert("", t("error.contactSupport"), "error", "");
				}
			})
			.catch((error) => {
				setTableLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});
		// eslint-disable-next-line
	}, []);

	const editRole = React.useCallback(
		(id) => () => {
			navigate(`/role-edit/${id}`);
		},
		// eslint-disable-next-line
		[]
	);

	const columns = useMemo(
		() => [
			{
				field: "name",
				headerName: `${t("roles.roleName")}`,
				flex: 1,
				headerClassName: "super-app-theme--header",
				align: "center",
				headerAlign: "center",
			},
			{
				field: "display_name",
				headerName: `${t("roles.displayName")}`,
				flex: 1,
				headerClassName: "super-app-theme--header",
				align: "center",
				headerAlign: "center",
				valueGetter: (params) =>
					`${params.row.display_name[i18n.language] || "-"} `,
			},
			{
				field: "role_level",
				headerName: `${t("roles.roleLevel")}`,
				flex: 1,
				headerClassName: "super-app-theme--header",
				align: "center",
				headerAlign: "center",
			},
			{
				field: "created_at",
				headerName: `${t("roles.createdAt")}`,
				flex: 1,
				headerClassName: "super-app-theme--header",
				align: "center",
				headerAlign: "center",
			},
			{
				field: "actions",
				type: "actions",
				headerName: `${t("table.actions")}`,
				width: 70,
				headerClassName: "super-app-theme--header",
				align: "center",
				headerAlign: "center",
				getActions: (params) => [
					<GridActionsCellItem
						icon={
							<MdModeEdit
								style={{ color: theme.palette.button.main }}
								size={25}
							/>
						}
						label={t("button.edit")}
						onClick={editRole(params.id)}
					/>,
				],
			},
		],
		// eslint-disable-next-line
		[editRole, i18n, t]
	);

	return (
		<div>
			<div
				className={styles.flexMiddle}
				style={{ justifyContent: "space-between", paddingBottom: 30 }}
			>
				<div
					className={styles.flexMiddle}
					style={{ paddingBottom: 30 }}
				>
					<FiArrowLeft
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							marginRight: 10,
						}}
					/>
					<span
						onClick={() => navigate(-1)}
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							textTransform: "uppercase",
							cursor: "pointer",
						}}
					>
						{t("button.back")}
					</span>
				</div>

				<div>
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/dashboard"
						>
							{t("title.dashboard")}
						</Link>
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/setting"
						>
							{t("title.setting")}
						</Link>
						<Typography color="text.primary">
							{t("title.roles")}
						</Typography>
					</Breadcrumbs>
				</div>
			</div>

			<div style={{ paddingBottom: 5 }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							paddingBottom: 15,
						}}
					>
						<Typography
							style={{
								fontSize: 21,
								marginRight: 15,
								color: theme.palette.gray.main,
							}}
						>
							<b>{t("title.roles")}</b>
						</Typography>
						{_.includes(permissions, "role-create") && (
							<Link
								underline="none"
								to={`/role-add`}
								component={RouterLink}
							>
								<div
									style={{
										height: 30,
										width: 30,
										textAlign: "center",
										borderRadius: 5,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										border: theme.palette.button.borderMain,
									}}
								>
									<Typography
										style={{
											fontSize: 21,
											color: theme.palette.button.main,
										}}
									>
										+
									</Typography>
								</div>
							</Link>
						)}
					</div>
				</Grid>
			</div>

			<div>
				<div
					style={{
						height: 450,
						width: "100%",
						overflowX: "scroll",
						marginTop: 20,
						boxShadow: "0 0 20px 0 #dadada",
						borderRadius: "15px",
					}}
				>
					<div
						style={{ display: "flex", height: "100%", padding: 0 }}
					>
						<div style={{ flexGrow: 1 }}>
							<DataGrid
								{...data}
								rows={state}
								columns={columns}
								pageSize={pageSize}
								onPageSizeChange={(newPageSize) =>
									setPageSize(newPageSize)
								}
								rowsPerPageOptions={[5, 10, 20]}
								components={{
									Toolbar: CustomToolbar,
									LoadingOverlay: LinearProgress,
								}}
								loading={tableLoading ? true : false}
								disableSelectionOnClick
								sx={{
									marginTop: 0,
									borderRadius: "15px",
									paddingTop: "15px",
									border: "none",
									"& .super-app-theme--header": {
										// backgroundColor: theme.palette.primary.main,
										backgroundColor:
											theme.palette.gray.tableHeader,
										color: theme.palette.gray
											.tableHeaderText,
									},
									backgroundColor: "white",
									"& 	.MuiDataGrid-menuIconButton": {
										color: theme.palette.gray
											.tableHeaderText,
									},
									"& 	.MuiDataGrid-sortIcon": {
										color: theme.palette.gray
											.tableHeaderText,
									},
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	flexMiddle: {
		display: "flex",
		alignItems: "center",
	},
}));
