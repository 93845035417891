import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

// MUI
import { Link, Typography, Hidden } from '@mui/material';

export default function ProfileTradeIn({ permissions, tradeInList }) {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <div style={{ padding: '30px 0' }}>
            <Typography className={styles.subText} style={{ paddingBottom: 8 }}>
                <b>{t('customer.tradeInRecord')}</b>
            </Typography>
            {
                _.size(tradeInList) > 0
                    ?
                    _.map(tradeInList, tradeIn => {
                        return (
                            <div className={`head ${styles.flexMiddle} ${styles.spaceBetween} ${styles.tradeInBox}`} key={tradeIn.id}>
                                <div className={styles.flexTtopMiddle}>
                                    <img src={_.size(tradeIn.product.images) ? tradeIn.product.images[0].file_name : "/images/emptyStatus/noproductimg.jpg"} className={styles.productImg} alt="product" />
                                    <div style={{ color: theme.palette.gray.dtext, paddingLeft: 15 }}>
                                        <Typography style={{ fontSize: 21 }}><b>{tradeIn.product.name}</b></Typography>
                                        <Typography style={{ padding: 5 }}>{t('menu.serialNo') + ': ' + tradeIn.product.serial_no}</Typography>
                                        <Typography style={{ padding: 5 }}>{t('customer.tradeInNo') + ' # ' + tradeIn.id}</Typography>
                                        <Hidden smUp>
                                            <div>
                                                {/* <Typography style={{ fontSize: 20, color: theme.palette.gray.dtext, textTransform: 'uppercase' }}>In Store</Typography> */}
                                                {
                                                    _.includes(permissions, 'tradein-edit')
                                                        ?
                                                        <Link underline='none' to={`/tradein-edit/${tradeIn.product.id}`} component={RouterLink}>
                                                            <Typography style={{ color: theme.palette.secondary.main, fontSize: 20 }}>
                                                                {t('general.checkDetails')}
                                                            </Typography>
                                                        </Link>
                                                        : null
                                                }
                                            </div>
                                        </Hidden>
                                    </div>
                                </div>
                                <Hidden only={'xs'}>
                                    <div style={{ textAlign: 'right' }}>
                                        {/* <Typography style={{ fontSize: 20, color: theme.palette.gray.dtext, textTransform: 'uppercase' }}>In Store</Typography> */}
                                        {
                                            _.includes(permissions, 'tradein-edit')
                                                ?
                                                <Link underline='none' to={`/tradein-edit/${tradeIn.product.id}`} component={RouterLink}>
                                                    <Typography style={{ color: theme.palette.secondary.main, fontSize: 20 }}>
                                                        {t('general.checkDetails')}
                                                    </Typography>
                                                </Link>
                                                : null
                                        }
                                    </div>
                                </Hidden>
                            </div>
                        )
                    })
                    :
                    <div className={styles.flexCenterMiddle} style={{ flexDirection: 'column' }}>
                        <img src="/images/emptyStatus/nolist.png" alt="nolist" style={{ width: 180, margin: '0 auto' }} />
                        <Typography style={{ color: theme.palette.gray.main }}>
                            {t('emptyStatus.noRecord')}
                        </Typography>
                    </div>
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    tradeInBox: {
        // boxShadow:'rgb(240 240 240) -5px -5px 8px 0px, rgb(221 221 221) 5px 5px 8px 0px, rgb(241 241 241) 0 0 2px 0px inset, rgb(215 215 215) 0 0 2px 0 inset',
        boxShadow: '0 0 18px 0 #dbdbdb',
        borderRadius: 15,
        padding: 20,
        marginBottom: 20
    },
    productImg: {
        [theme.breakpoints.down('sm')]: {
            width: 50,
            borderRadius: 0
        },
        [theme.breakpoints.up('sm')]: {
            width: 150,
            borderRadius: 15
        },
    },
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    spaceBetween: {
        justifyContent: 'space-between'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    flexTtopMiddle: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    flexCenterMiddle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));
