import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@mui/styles";
import { Link as RouterLink, useLocation } from "react-router-dom";
import useNotificationLoading from "../../helpers/useNotificationLoading";
import { getUrl } from "../../helpers/ApiAction";
import { useSelector } from "react-redux";
import _ from "lodash";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { currencyFormat, useQuery } from '../../helpers/Tools';
// import { GENTING_URL } from '../../configs/Config';
// MUI
import {
    Grid,
    Typography,
    Link,
    LinearProgress,
    Breadcrumbs,
    Button, 
    TextField,
    OutlinedInput,
    FormControl,
    Box,
    Table,
    TableCell,
    TableRow,
    TableBody,
    InputLabel,
    NativeSelect 
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    gridClasses,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    // GridActionsCellItem,
} from "@mui/x-data-grid";

import { useDemoData } from "@mui/x-data-grid-generator";

import { FiArrowLeft } from "react-icons/fi";

function CustomToolbar() {
    return (
        <GridToolbarContainer
            className={gridClasses.toolbarContainer}
            style={{ paddingBottom: 10 }}
        >
            <GridToolbarExport
                style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
                csvOptions={{
                    allColumns: true,
                    fileName: `Products_${new Date()
                        .toISOString()
                        .slice(0, 10)}`,
                }}
                printOptions={{ disableToolbarButton: true }}
            />
            <GridToolbarDensitySelector
                style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
            />
            <GridToolbarFilterButton
                style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
            />
            <GridToolbarColumnsButton
                style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
            />
        </GridToolbarContainer>
    );
}

export default function ProductStockReportListing() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    let query = useQuery();
    const location = useLocation();
    // const productType = location.pathname === "/products" ? "new" : "trade-in";
    const { permissions } = useSelector((state) => state.general);

    const [pageSize, setPageSize] = useState(20);
    // const [tableLoading, setTableLoading] = useState(false);
    const [state, setState] = useState([]);
    const { addAlert, setLoading } = useNotificationLoading();
    const { data } = useDemoData({
        dataSet: "product",
        rowLength: 5,
        maxColumns: 10,
    });

    const [searchText, setSearchText] = useState(query.get('search') || "");
    const [dateFilter, setDateFilter] = useState({
        from_date: query.get('from_date') || null, // moment().format('L hh:mm A'),
        to_date: query.get('to_date') || null, // moment().format('L hh:mm A'),
    });
    const [searchOrderNo, setSearchOrderNo] = useState(query.get('order_no') || "");
    const [searchProductName, setSearchProductName] = useState(query.get('product') || "");
    const [searchSerialNo, setSearchSerialNo] = useState(query.get('serial_no') || "");
    const [searchStock, setSearchStock] = useState(query.get('stock') || "all");

    const [total, setTotal] = useState([]);
    // const BRANCH = window.location.href.indexOf(GENTING_URL) > -1 ? 'genting' : 'others';

    //table
    const columns = useMemo(
        () => [
            {
                field: "name",
                headerName: `${t("product.name")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
            },
            {
                field: "type",
                headerName: `${t("product.type")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
            },
            {
                field: "brand",
                headerName: `${t("product.brand")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
            },
            {
                field: "serial_no",
                headerName: `${t("product.serialNo")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
            },
            {
                field: "cost_price2",
                headerName: `${t("product.assetValue")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
            },
            {
                field: "stock_in_date",
                headerName: `${t("report.stockInDate")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
            },
            {
                field: "stock_out_date",
                headerName: `${t("report.stockOutDate")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
            },
            {
                field: "total_profit2",
                headerName: `${t("report.profit")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
            },
            {
                field: "order_no",
                headerName: `${t("report.orderNo")}`,
                flex: 1,
                align: "center",
                headerAlign: "center",
                headerClassName: "super-app-theme--header",
                renderCell: (params) => { return <Link underline='none' to={`/orders/${params.row.order_id}`} component={RouterLink}><div key={params.row.order_id}>{params.value ? params.value : "-"}</div></Link> }
 
            },
            
        ],
        [t]
    );
   

    const callApi = ()=>{
        const from_date = dateFilter.from_date ? moment(dateFilter.from_date).format('YYYY-MM-DD HH:mm:ss'): '';
        const to_date = dateFilter.to_date ? moment(dateFilter.to_date).format('YYYY-MM-DD HH:mm:ss'): '';
        setLoading(true);
        getUrl('/report/product-stock', { search: searchText, searchOrderNo: searchOrderNo, searchProductName: searchProductName, searchStock: searchStock, searchSerialNo: searchSerialNo, sdate: from_date, edate: to_date })
            .then((response) => {
                setLoading(false);
                if (response.status) {
                    setState(response.data.listing);
                    setTotal(response.data.total);
                } else {
                    addAlert("", t("error.contactSupport"), "error", "");
                }
            })
            .catch((error) => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        // eslint-disable-next-line
    }

    useEffect(() => {
        setLoading(true);
        callApi();
        // eslint-disable-next-line
    }, []);

    const handleDateFilterChange = (newValue, type) => {
        setDateFilter({ ...dateFilter, [type]: newValue });
    }

    const clearFilter = useCallback(() => {
        // setDateFilter({ from_date: null, to_date: null });
        // setSearchText('');
        // callApi();
        window.location.href = location.pathname;
    }, []);

    return (
        <div>
            <div className={styles.flexMiddle} style={{ justifyContent: 'space-between', paddingBottom: 30, }}>
                <Link underline="none" component={RouterLink} to={-1} >
                    <div
                        className={styles.flexMiddle}
                        style={{ paddingBottom: 30 }}
                    >
                        <FiArrowLeft
                            style={{
                                color: theme.palette.gray.main,
                                fontSize: 16,
                                marginRight: 10,
                            }}
                        />
                        <Typography
                            style={{
                                color: theme.palette.gray.main,
                                fontSize: 16,
                                textTransform: "uppercase",
                            }}
                        >
                            {t("button.back")}
                        
                        </Typography>
                    </div>
                </Link>

                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            color="inherit"
                            href="/dashboard"
                        >
                            {t("title.dashboard")}
                        </Link>
                        <Typography color="text.primary">
                            {t("title.stock")}
                        </Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <Box component="form" method="get" action={location.pathname} display="flex" flexDirection="row" padding="10px">
                <div style={{ width: '100%', maxWidth: '100%', marginRight:10 }}>
                    {/* <ThemeProvider theme={inputStyle}>
                        <FormControl style={{ width: '100%', maxWidth: '100%' }}>
                            <OutlinedInput placeholder={t('product.searchProductStock')} name="search" value={searchText} onChange={({ target }) => setSearchText(target.value)} />
                        </FormControl>
                    </ThemeProvider> */}
                    <ThemeProvider theme={inputStyle}>
                        <FormControl style={{ width: '25%', padding:'0px 5px' }}>
                            <OutlinedInput placeholder={t('report.productNameSearch')} name="product" value={searchProductName} onChange={({ target }) => setSearchProductName(target.value)} /* onKeyDown={searchOrder} */ />
                        </FormControl>
                    </ThemeProvider>
                    <ThemeProvider theme={inputStyle}>
                        <FormControl style={{ width: '25%', padding:'0px 5px' }}>
                            <OutlinedInput placeholder={t('report.serialNoSearch')} name="serial_no" value={searchSerialNo} onChange={({ target }) => setSearchSerialNo(target.value)} /* onKeyDown={searchOrder} */ />
                        </FormControl>
                    </ThemeProvider>
                    <ThemeProvider theme={inputStyle}>
                        <FormControl style={{ width: '25%', padding:'0px 5px' }}>
                            <OutlinedInput placeholder={t('report.orderNoSearch')} name="order_no" value={searchOrderNo} onChange={({ target }) => setSearchOrderNo(target.value)} /* onKeyDown={searchOrder} */ />
                        </FormControl>
                    </ThemeProvider>
                    <ThemeProvider theme={inputStyle}>
                    <FormControl style={{ width: '25%', padding:'0px 5px' }}>
                        <InputLabel  variant="standard" htmlFor="uncontrolled-native">
                            Stock In/Out
                        </InputLabel >
                        <NativeSelect
                            defaultValue='all'
                            inputProps={{
                            name: 'stock',
                            id: 'stock',
                            }}
                            value={searchStock}
                            onChange={({ target }) => setSearchStock(target.value)}
                        >
                            <option value='all'>Both</option>
                            <option value='in'>In Stock</option>
                            <option value='out'>Stocked Out</option>
                        </NativeSelect>
                    </FormControl></ThemeProvider>
                </div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label={t('report.fromDate')}
                        mask='__/__/____ __:__ _M'
                        value={dateFilter.from_date}
                        onChange={(value) => handleDateFilterChange(value, 'from_date')}
                        renderInput={(params) => <TextField name="from_date" style={{ marginRight: 10 }} {...params} />}
                    />
                    <DatePicker
                        label={t('report.toDate')}
                        mask='__/__/____ __:__ _M'
                        value={dateFilter.to_date}
                        onChange={(value) => handleDateFilterChange(value, 'to_date')}
                        renderInput={(params) => <TextField name="to_date" style={{ marginRight: 10 }} {...params} />}
                    />
                </LocalizationProvider>
                <Button variant="outlined" type="submit"/* onClick={callApi} */ style={{ marginRight: 10 }}>
                    <Typography>{t('button.search')}</Typography>
                </Button>
                <Button variant="outlined" onClick={clearFilter}>
                    <Typography>{t('general.clear')}</Typography>
                </Button>
            </Box>
            <div style={{ paddingBottom: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ paddingBottom: 5 }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            paddingBottom: 15,
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: 21,
                                marginRight: 15,
                                color: theme.palette.gray.main,
                            }}
                        >
                            <b>{t("product.stockList")}</b>
                        </Typography>
                    </div>
                  
                </Grid>
            </div>
            <div
                style={{
                    height: 450,
                    width: "100%",
                    overflowX: "scroll",
                    boxShadow: "0 0 20px 0 #dadada",
                    borderRadius: "15px 0 15px 15px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        height: "100%",
                        padding: 0,
                    }}
                >
                    <div style={{ flexGrow: 1 }}>
                        {
                            _.size(state) > 0 ? (
                                <DataGrid
                                    {...data}
                                    rows={state}
                                    columns={columns}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) =>
                                        setPageSize(newPageSize)
                                    }
                                    rowsPerPageOptions={[5, 10, 20]}
                                    components={{
                                        Toolbar: CustomToolbar,
                                        LoadingOverlay: LinearProgress,
                                    }}
                                    // loading={
                                    //     tableLoading ? true : false
                                    // }
                                    sx={{
                                        marginTop: 0,
                                        borderRadius:
                                            "15px 0 15px 15px",
                                        paddingTop: "15px",
                                        border: "none",
                                        "& .super-app-theme--header": {
                                            backgroundColor:
                                                theme.palette.gray
                                                    .tableHeader,
                                            color: theme.palette.gray
                                                .tableHeaderText,
                                        },
                                        backgroundColor: "white",
                                        "& 	.MuiDataGrid-menuIconButton":
                                        {
                                            color: theme.palette
                                                .gray
                                                .tableHeaderText,
                                        },
                                        "& 	.MuiDataGrid-sortIcon": {
                                            color: theme.palette.gray
                                                .tableHeaderText,
                                        },
                                    }}
                                />
                            ) : (
                                <div
                                    className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
                                    style={{
                                        flexDirection: "column",
                                        backgroundColor: "#fff",
                                        height: "100%",
                                    }}
                                >
                                    <img
                                        src="images/emptyStatus/emptycart.png"
                                        alt="empty"
                                        style={{ width: 180 }}
                                    />
                                    <Typography
                                        style={{
                                            margin: 10,
                                            textAlign: "center",
                                        }}
                                    >
                                        {t("emptyStatus.noProduct")}
                                    </Typography>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <Table>
            <TableBody>
                <TableRow>
                    <TableCell rowSpan={5} />
                    <TableCell colSpan={2}>{t('report.totalSales')}</TableCell>
                    <TableCell align="right">{currencyFormat(parseFloat(total.total_sell)) || "0.00"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}>{t('report.totalAssetValue')}</TableCell>
                    <TableCell align="right">{currencyFormat(parseFloat(total.total_cost2)) || "0.00"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}>{t('report.totalAddOns')}</TableCell>
                    <TableCell align="right">{currencyFormat(parseFloat(total.total_add_ons)) || "0.00"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}>{t('report.totalCommission')}</TableCell>
                    <TableCell align="right">{currencyFormat(parseFloat(total.total_comm)) || "0.00"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}>{t('report.totalProfit')}</TableCell>
                    <TableCell align="right">{currencyFormat(parseFloat(total.total_profit2)) || "0.00"}</TableCell>
                </TableRow>
            </TableBody>
            </Table>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    flexMiddle: {
        display: "flex",
        alignItems: "center",
    },
    justifyRight: {
        justifyContent: "flex-end",
    },
    justifyCenter: {
        justifyContent: "center",
    },
    tabStyle: {
        boxShadow:
            "rgb(209 209 209 / 35%) 2px 2px 10px 5px, inset 1px -7px 5px 0px #0000000a",
        borderRadius: "11px 11px 0 0",
        padding: "8px 15px",
        marginRight: 15,
        minWidth: 110,
        justifyContent: "center",
        textAlign: "center",
        border: "none",
        backgroundColor: "#fff",
    }
}));

const inputStyle = createTheme({
    components: {
      // Name of the component
      MuiOutlinedInput: {
        styleOverrides: {
          // Name of the slot
          input: {
            // Some CSS
            padding:'28.5px 14px',
            boxSizing:'inherit',
            borderRadius:48
          },
          root:{
              borderRadius:48,
              boxShadow:'3px 3px 8px 0 #ccc'
          },
        },
      },
    },
  })