import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

// MUI
import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

export default function ProfilePurchase({ permissions, orderList }) {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <div style={{ padding: '30px 0' }}>
            <Typography className={styles.subText} style={{ paddingBottom: 8 }}>
                <b>{t('customer.orderRecord')}</b>
            </Typography>
            {
                _.size(orderList) > 0
                    ?
                    <TableContainer component={Paper} style={{ boxShadow: '1px 1px 20px 0px #0000001f' }}>
                        <Table sx={{ minWidth: 650, }} stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: 150, color: theme.palette.gray.ltext }}>{t('order.date')}</TableCell>
                                    <TableCell style={{ color: theme.palette.gray.ltext }}>{t('order.receiptNo')}</TableCell>
                                    <TableCell style={{ width: 180, color: theme.palette.gray.ltext }} align="right">{t('order.totalAmount')}&nbsp;(RM)</TableCell>
                                    {
                                        _.includes(permissions, 'order')
                                            ?
                                            <TableCell style={{ width: 180, color: theme.palette.gray.ltext }} align="right">{t('general.checkDetails')}</TableCell>
                                            : null
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orderList.map((order) => (
                                    <TableRow
                                        key={order.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell style={{ width: 150 }}>{order.created_at}</TableCell>
                                        <TableCell component="th" scope="row">{order.order_no}</TableCell>
                                        <TableCell align="right" style={{ width: 180 }}>{order.total_price}</TableCell>
                                        {
                                            _.includes(permissions, 'order')
                                                ?
                                                <TableCell align="right" style={{ width: 180 }}>
                                                    {/* //show product receipt */}
                                                    <Link underline='none' to={`/orders/${order.id}`} component={RouterLink}>
                                                        {t('general.checkDetails')}
                                                    </Link>
                                                </TableCell>
                                                : null
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <div className={styles.flexCenterMiddle} style={{ flexDirection: 'column' }}>
                        <img src="/images/emptyStatus/nolist.png" alt="nolist" style={{ width: 180, margin: '0 auto' }} />
                        <Typography style={{ color: theme.palette.gray.main }}>
                            {t('emptyStatus.noRecord')}
                        </Typography>
                    </div>
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    flexCenterMiddle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));