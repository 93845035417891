import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import _ from "lodash";
import { useSelector } from "react-redux";

// MUI
import {
    AddBoxOutlined, FileDownloadOutlined, FirstPageOutlined,
    KeyboardArrowLeftOutlined,
    KeyboardArrowRightOutlined,
    LastPageOutlined
} from "@mui/icons-material";
import {
    Box, Breadcrumbs, createTheme, IconButton, Link, Paper, Table,
    TableBody,
    TableCell,
    TableContainer, TableFooter, TableHead, TablePagination, TableRow, ThemeProvider, Tooltip, Typography
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { FiArrowLeft, FiPlus } from "react-icons/fi";

import { getUrl, postUrl } from "../../helpers/ApiAction";
import { currencyFormat } from "../../helpers/Tools";
import useNotificationLoading from "../../helpers/useNotificationLoading";

export default function DailySettlements() {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [data, setData] = useState([]);

	const navigate = useNavigate();
	const styles = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const { addAlert, setLoading } = useNotificationLoading();
	const { role } = useSelector((state) => state.general);

	const isMountedRef = useRef();

	useEffect(() => {
		setLoading(true);
		isMountedRef.current = true;

		getUrl(`daily-settlements`)
			.then((response) => {
				console.log("response", response);
				setLoading(false);
				const { status, data } = response;
				if (status && isMountedRef.current) {
					setData(data.listing);
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});

		return () => (isMountedRef.current = false);
	}, []);

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const createReport = (date) => {
		postUrl(`daily-settlements-report/genting/${date}`)
			.then((response) => {
				const { status } = response;
				if (status) {
					addAlert(
						"",
						t("accounting.reportCreatedMessage"),
						"success",
						""
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});
	};

	const downloadReport = (date) => {
		getUrl(`daily-settlements-report/genting/${date}`)
			.then((response) => {
				const { status, data, filename } = response;
				if (status && data) {
					const element = document.createElement("a");
					const file = new Blob([data], {
						type: "text/plain",
					});
					element.href = URL.createObjectURL(file);
					element.download = filename;
					document.body.appendChild(element);
					element.click();
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});
	};

	return (
		<div>
			<div
				style={{ paddingBottom: 30, justifyContent: "space-between" }}
				className={styles.flexMiddle}
			>
				<div
					className={styles.flexMiddle}
					style={{ paddingBottom: 30 }}
				>
					<FiArrowLeft
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							marginRight: 10,
						}}
					/>
					<span
						onClick={() => navigate(-1)}
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							textTransform: "uppercase",
							cursor: "pointer",
						}}
					>
						{t("button.back")}
					</span>
				</div>
				<div>
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/dashboard"
						>
							{t("title.dashboard")}
						</Link>
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/accounting"
						>
							{t("title.accounting")}
						</Link>
						<Typography color="text.primary">
							{t("title.dailyClosing")}
						</Typography>
					</Breadcrumbs>
				</div>
			</div>

			<div style={{ padding: "20px 0" }}>
				<div className={styles.flexMiddle}>
					<Typography
						style={{
							color: theme.palette.gray.dtext,
							fontSize: 18,
							paddingRight: 15,
						}}
					>
						<b>{t("accounting.dailyClosingReport")}</b>
					</Typography>
					<Link
						underline="none"
						component={RouterLink}
						to={`/create-daily-settlement`}
					>
						<div
							className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
							style={{
								border: "2px solid #222",
								borderColor: theme.palette.secondary.main,
								padding: 5,
								borderRadius: 5,
								width: 40,
								height: 40,
							}}
						>
							<FiPlus
								style={{
									fontSize: 25,
									color: theme.palette.secondary.main,
								}}
							/>
						</div>
					</Link>
				</div>

				<div style={{ paddingTop: 30 }}>
					<div
						className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}
					></div>
					<ThemeProvider theme={tableStyle}>
						<TableContainer
							component={Paper}
							style={{
								marginTop: 15,
								boxShadow: "0 0 20px 0 #e0e0e0",
								borderRadius: 15,
							}}
						>
							<Table
								stickyHeader
								sx={{ minWidth: 650 }}
								size="small"
								aria-label="sticky table pagination table"
							>
								<TableHead>
									<TableRow>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
										>
											{t("textField.date")}
										</TableCell>
										{/* <TableCell style={{color:theme.palette.gray.ltext}} align="right">Report ID</TableCell> */}
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
											align="right"
										>{`${t(
											"accounting.cashIn"
										)} (RM)`}</TableCell>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
											align="right"
										>{`${t(
											"accounting.cashOut"
										)} (RM)`}</TableCell>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
											align="right"
										>{`${t(
											"accounting.totalAmount"
										)} (RM)`}</TableCell>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
											align="right"
										>
											{t("accounting.action")}
										</TableCell>
										<TableCell
											style={{
												color: theme.palette.gray.ltext,
											}}
											align="right"
										>
											{t("accounting.reports")}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{(rowsPerPage && _.size(data)
										? data.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
										  )
										: data
									).map((rowItem) => (
										<TableRow key={rowItem.settlement_date}>
											<TableCell
												component="th"
												scope="row"
											>
												{rowItem.settlement_date}
											</TableCell>
											{/* <TableCell align="right">{row.reportid}</TableCell> */}
											<TableCell align="right">
												{currencyFormat(
													parseFloat(
														rowItem.total_cash_in
													)
												)}
											</TableCell>
											<TableCell align="right">
												{currencyFormat(
													parseFloat(
														rowItem.total_cash_out
													)
												)}
											</TableCell>
											<TableCell align="right">
												{currencyFormat(
													parseFloat(
														rowItem.total_cash_in
													) -
														parseFloat(
															rowItem.total_cash_out
														)
												)}
											</TableCell>
											<TableCell align="right">
												<Link
													underline="none"
													component={RouterLink}
													to={`/daily-settlement/${rowItem.settlement_date}`}
												>
													{t("button.view")}
												</Link>
											</TableCell>
											<TableCell align="right">
												<Box
													display={"flex"}
													flexDirection="row"
													alignItems={"flex-end"}
													justifyContent="flex-end"
												>
													<Tooltip
														title={t(
															"button.download"
														)}
													>
														<IconButton
															onClick={() =>
																downloadReport(
																	rowItem.settlement_date
																)
															}
															aria-label="download"
														>
															<FileDownloadOutlined />
														</IconButton>
													</Tooltip>
													{_.size(
														_.intersection(role, [
															"dev",
															"super-admin",
														])
													) > 0 ? (
														<Tooltip
															title={t(
																"button.create"
															)}
														>
															<IconButton
																onClick={() =>
																	createReport(
																		rowItem.settlement_date
																	)
																}
																aria-label="create report"
															>
																<AddBoxOutlined />
															</IconButton>
														</Tooltip>
													) : null}
													{/* <Link
                                                        href={rowItem.genting_report_url}
                                                        target="_blank"
                                                    >
                                                        <IconButton
                                                            aria-label="preview"
                                                        >
                                                            <PreviewOutlined />
                                                        </IconButton>
                                                    </Link> */}
												</Box>
											</TableCell>
										</TableRow>
									))}

									{emptyRows > 0 && (
										<TableRow
											style={{ height: 53 * emptyRows }}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
								<TableFooter>
									<TableRow>
										<TablePagination
											rowsPerPageOptions={[
												5,
												10,
												25,
												{ label: "All", value: -1 },
											]}
											colSpan={6}
											count={data.length}
											rowsPerPage={rowsPerPage}
											page={page}
											SelectProps={{
												inputProps: {
													"aria-label":
														"rows per page",
												},
												native: true,
											}}
											onPageChange={handleChangePage}
											onRowsPerPageChange={
												handleChangeRowsPerPage
											}
											ActionsComponent={
												TablePaginationActions
											}
										/>
									</TableRow>
								</TableFooter>
							</Table>
						</TableContainer>
					</ThemeProvider>
				</div>
			</div>
		</div>
	);
}

//Table
function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? (
					<LastPageOutlined />
				) : (
					<FirstPageOutlined />
				)}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRightOutlined />
				) : (
					<KeyboardArrowLeftOutlined />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeftOutlined />
				) : (
					<KeyboardArrowRightOutlined />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? (
					<FirstPageOutlined />
				) : (
					<LastPageOutlined />
				)}
			</IconButton>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	justifyCenter: {
		justifyContent: "center",
	},
	spaceBetween: {
		justifyContent: "space-between",
	},
	flexMiddle: {
		display: "flex",
		alignItems: "center",
		width: "100%",
	},
	flexTtop: {
		display: "flex",
		alignItems: "flex-start",
	},
}));

const tableStyle = createTheme({
	components: {
		// Name of the component
		MuiTableCell: {
			styleOverrides: {
				// Name of the slot
				root: {
					// Some CSS
					lineHeight: "2.5rem",
				},
			},
		},
	},
});
