import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import _ from 'lodash';
// import { ThemeProvider, createTheme } from '@mui/material/styles';

// MUI
import { Link, TextField, Box, Button, Breadcrumbs, Grid, Typography, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material/';
import { makeStyles, useTheme } from '@mui/styles';

import { postUrl, getUrl } from '../../helpers/ApiAction';

import { FiArrowLeft } from "react-icons/fi";


export default function Create() {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [state, setState] = useState({
        name: '',
        parent_id: 0,
        assignRole: [],
    });
    const [mainPermission, setMainPermission] = useState({});
    const [role, setRole] = useState({});
    const [inputErrors, setInputErrors] = useState({});
    const isMountedRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl('/permissions').then(permissionList => {
            setLoading(false);
            if (isMountedRef.current) {
                if (permissionList.status) {
                    setMainPermission(permissionList.data.listing);
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl('/roles').then(roleList => {
            setLoading(false);
            if (isMountedRef.current) {
                setRole(roleList.data.listing);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert])


    const submitData = () => {
        // console.log("state", state);
        setLoading(true);
        setInputErrors();
        postUrl('/permissions', state).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.createSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const authorizeRole = roleName => {
        let roleAuthorize = state.assignRole;

        // const index = roleAuthorize.indexOf(roleAuthorize);
        if(_.includes(roleAuthorize, roleName)) {
            // roleAuthorize.splice(index, 1);
            // _.remove(roleAuthorize, function(name) {
            //     return 
            // });

            roleAuthorize = _.difference(roleAuthorize, [roleName]);
        } else {
            roleAuthorize.push(roleName);
        }

        setState({ ...state, assignRole: roleAuthorize });
    }

    return (
        <div>
            <Link underline='none' component={RouterLink} to={`/permissions`}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.back')}</Typography>
                </div>
            </Link>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18 }}><b>{t('title.permissionAdd')}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to="/permissions">
                            {t('title.permissions')}
                        </Link>
                        <Typography color="text.primary">{t('title.permissionAdd')}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <div style={{ paddingBottom: '5%' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{t('permissions.categoryBelongTo')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.parent_id}
                                    label={t('permissions.categoryBelongTo')}
                                    onChange={({ target }) => setState({ ...state, parent_id: target.value })}
                                >
                                    <MenuItem key={0} value={0}>--- { t('permissions.mainPermission') } --- </MenuItem>
                                    {
                                        _.map(mainPermission, permissionType => {
                                            if(permissionType.parent_id === "0"){
                                                return <MenuItem key={permissionType.id} value={permissionType.id}>{permissionType.name}</MenuItem>
                                            }
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('permissions.name')}
                                variant="outlined"
                                value={state.name}
                                InputLabelProps={{ shrink: true }} 
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <Typography>{t('permissions.assignPermission')}</Typography>
                            {
                                _.map(role, roleItem => {
                                    return (
                                        <Grid item xs={4} key={roleItem.id}>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={_.includes(state.assignRole, roleItem.name)} onChange={() => authorizeRole(roleItem.name)} />}
                                                label={roleItem.display_name[i18n.language]}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>

                <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link underline='none' to={`/permissions`} component={RouterLink}>
                        <Button size="large" variant="outlined" className={styles.buttonStyle} style={{border:theme.palette.button.borderMain, color:theme.palette.button.main,  marginRight: 15}}>{t('button.cancel')}</Button>
                    </Link>
                    <Button size="large" variant="contained" style={{backgroundColor:theme.palette.button.main}} className={styles.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
                </Box>
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle:{
        display:'flex',
        alignItems:'center'
    }
}));


