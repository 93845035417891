import axios from 'axios';
import { API_URL } from '../configs/Config';

const API = axios.create({
    baseURL: API_URL,
    timeout: 30000,
    headers: {
        'Accept': 'application/json',
    },
});

export { API };