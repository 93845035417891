import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@mui/styles";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useNotificationLoading from "../../helpers/useNotificationLoading";
import { getUrl } from "../../helpers/ApiAction";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import _ from "lodash";
import CartPendingList from "../Pos/CartList";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useQuery } from "../../helpers/Tools";

// MUI
import {
  Grid,
  Typography,
  Box,
  Link,
  Tab,
  Tabs,
  Hidden,
  Button,
  TextField,
  OutlinedInput,
  FormControl,
  Breadcrumbs,
} from "@mui/material";

// ICONS
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

export default function Index() {
  const navigate = useNavigate();
  const styles = useStyles();
  const theme = useTheme();
  let query = useQuery();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { addAlert, setLoading } = useNotificationLoading();
  const [orders, setOrders] = useState({});
  const [searchText, setSearchText] = useState(query.get("search") || "");
  const [statusList, setStatusList] = useState({});
  const [tabStatus, setTabStatus] = useState(
    query.get("tab") ? parseInt(query.get("tab")) : 0
  );
  const { permissions } = useSelector((state) => state.general);
  const [tableLoading, setTableLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    from_date: query.get("from_date") || null, // moment().format('L hh:mm A'),
    to_date: query.get("to_date") || null, // moment().format('L hh:mm A'),
  });

  const handleTabChange = (event, newValue) => {
    setTabStatus(newValue);
  };

  const callApi = () => {
    const from_date = dateFilter.from_date
      ? moment(dateFilter.from_date).format("YYYY-MM-DD HH:mm:ss")
      : "";
    const to_date = dateFilter.to_date
      ? moment(dateFilter.to_date).format("YYYY-MM-DD HH:mm:ss")
      : "";

    setLoading(true);

    let extraQuery = from_date ? `?sdate=${from_date}` : "";
    extraQuery += `${extraQuery ? "&" : "?"}${
      to_date ? `edate=${to_date}` : ""
    }`;

    let apiUrl = `/orders`;
    if (extraQuery) {
      apiUrl = `/orders${extraQuery}`;
    }

    if (tabStatus !== "cart") {
      getUrl(apiUrl, { status: tabStatus, search: searchText })
        .then((response) => {
          setLoading(false);
          if (response.status) {
            setOrders(response.data.listing);
            setStatusList(response.data.status_list);
          }
        })
        .catch((error) => {
          setLoading(false);
          addAlert("", error.message || t("error.contactSupport"), "error", "");
        });
    } else {
      setLoading(false);
      setOrders({});
    }
  };
  useEffect(() => {
    setLoading(true);
    callApi();
    // eslint-disable-next-line
  }, [tabStatus]);

  const handleDateFilterChange = (newValue, type) => {
    setDateFilter({ ...dateFilter, [type]: newValue });
  };

  const clearFilter = useCallback(() => {
    // setDateFilter({ from_date: null, to_date: null });
    // setSearchText('');
    // callApi();
    window.location.href = location.pathname;
  }, []);

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <div
          style={{ paddingBottom: 30, justifyContent: "space-between" }}
          className={styles.flexMiddle}
        >
          <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
            <FiArrowLeft
              style={{
                color: theme.palette.gray.main,
                fontSize: 16,
                marginRight: 10,
              }}
            />
            <span
              onClick={() => navigate(-1)}
              style={{
                color: theme.palette.gray.main,
                fontSize: 16,
                textTransform: "uppercase",
                cursor: "pointer",
              }}
            >
              {t("button.back")}
            </span>
          </div>
          <div>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/dashboard"
              >
                {t("title.dashboard")}
              </Link>
              <Typography color="text.primary">{t("title.orders")}</Typography>
            </Breadcrumbs>
          </div>
        </div>

        <Box
          component="form"
          method="get"
          action={location.pathname}
          display="flex"
          flexDirection="row"
          padding="10px"
        >
          <input name="tab" value={tabStatus} type="hidden" />
          <div style={{ width: "100%", maxWidth: "100%", marginRight: 10 }}>
            <ThemeProvider theme={inputStyle}>
              <FormControl style={{ width: "100%", maxWidth: "100%" }}>
                <OutlinedInput
                  placeholder={t("order.searchOrder")}
                  name="search"
                  value={searchText}
                  onChange={({ target }) =>
                    setSearchText(target.value)
                  } /* onKeyDown={searchOrder} */
                />
              </FormControl>
            </ThemeProvider>
          </div>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={t("report.fromDate")}
              mask="__/__/____ __:__ _M"
              value={dateFilter.from_date}
              onChange={(value) => handleDateFilterChange(value, "from_date")}
              renderInput={(params) => (
                <TextField
                  name="from_date"
                  style={{ marginRight: 10 }}
                  {...params}
                />
              )}
            />
            <DatePicker
              label={t("report.toDate")}
              mask="__/__/____ __:__ _M"
              value={dateFilter.to_date}
              onChange={(value) => handleDateFilterChange(value, "to_date")}
              renderInput={(params) => (
                <TextField
                  name="to_date"
                  style={{ marginRight: 10 }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <Button
            variant="outlined"
            type="submit"
            /* onClick={callApi} */ style={{ marginRight: 10 }}
          >
            <Typography>{t("general.filter")}</Typography>
          </Button>
          <Button variant="outlined" onClick={clearFilter}>
            <Typography>{t("general.clear")}</Typography>
          </Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={_.size(statusList) ? tabStatus : 0}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label={t("order.all")} value={0} />
            {_.size(statusList) &&
              _.map(statusList, (status) => {
                return (
                  <Tab
                    label={status[i18n.language]}
                    value={status.code}
                    key={status.code}
                  />
                );
              })}
            {permissions.includes("cart-approve") ? (
              <Tab label={t("cart.list")} value={"cart"} key={"cart"} />
            ) : null}
          </Tabs>
        </Box>
        {tabStatus !== "cart" ? (
          <div style={{ padding: "24px 0" }}>
            {_.size(orders) > 0 ? (
              <>
                {_.map(orders, (order) => {
                  return (
                    <div key={order.id}>
                      <Typography
                        style={{
                          color: theme.palette.gray.main,
                          paddingBottom: 1,
                          marginTop: 30,
                        }}
                      >
                        {t("order.lastUpdate") + ": " + order.updated_at}
                      </Typography>
                      <Typography
                        style={{
                          color: theme.palette.gray.main,
                          paddingBottom: 8,
                          marginTop: 1,
                        }}
                      >
                        {t("order.date") + ": " + order.created_at}
                      </Typography>
                      <Grid
                        container
                        style={{
                          boxShadow: "0 0 15px 0 #0000002e",
                          borderRadius: 15,
                          padding: 15,
                          marginBottom: 15,
                        }}
                      >
                        <Grid item xs={12} sm={9} md={9}>
                          <div
                            className={styles.flexMiddle}
                            style={{ width: "100%" }}
                          >
                            <img
                              src={
                                _.size(order.order_details[0].product.images)
                                  ? order.order_details[0].product.images[0]
                                      .file_name
                                  : "/images/emptyStatus/noproductimg.jpg"
                              }
                              className={styles.productImg}
                              alt="product"
                            />
                            <div style={{ paddingLeft: 15 }}>
                              <Typography
                                className={styles.textLine2}
                                style={{
                                  fontSize: 21,
                                  fontWeight: "bold",
                                  color: theme.palette.gray.dtext,
                                  lineHeight: 1.2,
                                }}
                              >
                                {order.customer.name}
                              </Typography>
                              <div
                                style={{
                                  paddingTop: 15,
                                  width: "100%",
                                  color: theme.palette.gray.main,
                                }}
                              >
                                <Typography
                                  fontSize={{ xs: 14, sm: 15, md: 16 }}
                                >
                                  {t("order.receiptNo") + ": " + order.order_no}
                                </Typography>
                                <Typography
                                  fontSize={{ xs: 14, sm: 15, md: 16 }}
                                >
                                  {t("order.totalAmount") + ": "}
                                  <NumberFormat
                                    thousandSeparator={true}
                                    prefix="RM "
                                    value={order.total_price}
                                    displayType="text"
                                  />
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <div
                            className={styles.flexMiddle}
                            style={{
                              justifyContent: "space-between",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Hidden smUp>
                              <Typography
                                style={{
                                  color: theme.palette.gray.main,
                                  width: "100%",
                                }}
                              >
                                <i>
                                  {t("order.totalItems", {
                                    total: _.size(order.order_details),
                                  })}
                                </i>
                              </Typography>
                            </Hidden>
                            <div style={{ width: "100%" }}>
                              <Link
                                underline="none"
                                component={RouterLink}
                                to={`/orders/` + order.id}
                              >
                                <div
                                  className={styles.flexMiddle}
                                  style={{
                                    color: theme.palette.button.main,
                                    height: "100%",
                                    padding: "0 15px",
                                    justifyContent: "flex-end",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      textAlign: "right",
                                      fontSize: 16,
                                      paddingRight: 10,
                                    }}
                                  >
                                    {t("general.checkDetails")}
                                  </Typography>
                                  <FiArrowRight />
                                </div>
                              </Link>
                              <Hidden smDown>
                                <Typography
                                  style={{
                                    color: theme.palette.gray.ltext,
                                    textAlign: "right",
                                    paddingRight: 15,
                                  }}
                                >
                                  <i>
                                    {t("order.totalItems", {
                                      total: _.size(order.order_details),
                                    })}
                                  </i>
                                </Typography>
                              </Hidden>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
              </>
            ) : (
              <div
                className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
                style={{
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  height: "100%",
                }}
              >
                <img
                  src="images/emptyStatus/nolist.png"
                  alt="empty"
                  style={{ width: 180 }}
                />
                <Typography style={{ margin: 10, textAlign: "center" }}>
                  {t("emptyStatus.noOrder")}
                </Typography>
              </div>
            )}
          </div>
        ) : (
          <CartPendingList />
        )}
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  productImg: {
    maxWidth: "100%",
    objectFit: "cover",
    borderRadius: 8,
    [theme.breakpoints.down("md")]: {
      width: 80,
      height: 150,
    },
    [theme.breakpoints.up("md")]: {
      width: 120,
      height: 120,
    },
    [theme.breakpoints.up("lg")]: {
      width: 150,
      height: 150,
    },
  },
  flexMiddle: {
    display: "flex",
    alignItems: "center",
  },
  textLine2: {
    display: "-webkit-box",
    maxWidth: "100%",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
}));

const inputStyle = createTheme({
  components: {
    // Name of the component
    MuiOutlinedInput: {
      styleOverrides: {
        // Name of the slot
        input: {
          // Some CSS
          padding: "28.5px 14px",
          boxSizing: "inherit",
          borderRadius: 48,
        },
        root: {
          borderRadius: 48,
          boxShadow: "3px 3px 8px 0 #ccc",
        },
      },
    },
  },
});
