import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';

import { Provider } from "react-redux";
import { store, persistor } from './configs/Store';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress } from '@mui/material';

import { ENV } from './configs/Config';
import theme from './Theme';

import './i18n';

// if (ENV !== "dev") {
//     console.log = () => { };
// }

const Loading = () => {
  return (
      <div style={{ position: "fixed", top: '50%', left: '50%' }}>
          <CircularProgress disableShrink color='primary' />
      </div>
  );
};

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <React.StrictMode>
                <ThemeProvider theme={theme}>
                    <Suspense fallback={<Loading />}>
                        <BrowserRouter>
                            <CssBaseline />
                            <App />
                        </BrowserRouter>
                    </Suspense>
                </ThemeProvider>
            </React.StrictMode>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
