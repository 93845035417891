import { makeStyles, useTheme } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { format } from "date-fns";
// MUI
import {
    Box, Breadcrumbs, Button, FormControl, InputLabel, Link, MenuItem, Modal, Select,
    TextField, Typography
} from "@mui/material";

import _ from "lodash";
import PropTypes from "prop-types";
import { FiArrowLeft, FiMinus, FiPlus } from "react-icons/fi";
import { TiTick } from "react-icons/ti";
import NumberFormat from "react-number-format";
import { getUrl, postUrl } from "../../helpers/ApiAction";
import { currencyFormat } from "../../helpers/Tools";
import useNotificationLoading from "../../helpers/useNotificationLoading";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
	props,
	ref
) {
	const { onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
		/>
	);
});

NumberFormatCustom.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default function DrawerAdjustment() {
	const [summaryOpen, setSummaryOpen] = useState(false);
	const [walletTypeList, setWalletTypeList] = useState(null);
	const [refTypeList, setRefTypeList] = useState(null);
	const [state, setState] = useState({
		wallet: "",
		amount: "",
		ref_type: "",
		remark: "",
		password: "",
	});
	const [inputErrors, setInputErrors] = useState({
		wallet: null,
		amount: null,
		ref_type: null,
		remark: null,
		password: null,
	});
	const [drawerSummary, setDrawerSummary] = useState(null);
	const navigate = useNavigate();

	const styles = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const { addAlert, setLoading } = useNotificationLoading();

	const isMountedRef = useRef();

	useEffect(() => {
		setLoading(true);
		isMountedRef.current = true;

		getUrl(`drawer-logs-info`)
			.then((response) => {
				setLoading(false);
				const { status, data } = response;
				if (status && isMountedRef.current) {
					setWalletTypeList(data.wallet_type);
					setRefTypeList(data.ref_type);
					setState({
						...state,
						wallet: data.wallet_type[0].code,
						ref_type: data.ref_type[0],
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});

		return () => (isMountedRef.current = false);
		// eslint-disable-next-line
	}, []);

	const adjustAmount = (method) => {
		let newAmount = _.isNaN(parseFloat(state.amount))
			? 0
			: parseFloat(state.amount);
		if (method === "add") {
			newAmount += 10;
		} else {
			newAmount -= 10;
		}

		newAmount = newAmount < 0 ? 0 : newAmount;
		setState({ ...state, amount: newAmount });
	};

	const handleChange = ({ target }) => {
		let { name, value } = target;
		if (name === "amount") {
			value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
		}
		setState({ ...state, [name]: value });
	};

	const cashInCashOut = () => {
		setLoading(true);
		let apiData = {
			wallet: state.wallet,
			amount: parseFloat(state.amount),
			ref_type: state.ref_type,
			remark: state.remark,
			password: state.password,
		};
		postUrl(`drawer-logs`, apiData)
			.then((response) => {
				setLoading(false);
				const { status, data, errors, message } = response;
				if (status) {
					addAlert("", t("success.createSuccess"), "success", "");
					setDrawerSummary(data);
					setState({
						...state,
						amount: "",
						remark: "",
						password: "",
					});
					setInputErrors({
						wallet: null,
						amount: null,
						ref_type: null,
						remark: null,
						password: null,
					});
					setSummaryOpen(true);
				} else {
					if (errors) {
						setInputErrors(errors);
					}
					addAlert(
						"",
						message ? message : t("error.contactSupport"),
						"error",
						""
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});
	};

	const closeDrawerSummary = () => {
		setSummaryOpen(false);
		setDrawerSummary(null);
	};

	return (
		<div>
			<div
				style={{ paddingBottom: 30, justifyContent: "space-between" }}
				className={styles.flexMiddle}
			>
				<div
					className={styles.flexMiddle}
					style={{ paddingBottom: 30 }}
				>
					<FiArrowLeft
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							marginRight: 10,
						}}
					/>
					<span
						onClick={() => navigate(-1)}
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							textTransform: "uppercase",
							cursor: "pointer",
						}}
					>
						{t("button.back")}
					</span>
				</div>
				<div>
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/dashboard"
						>
							{t("title.dashboard")}
						</Link>
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/accounting"
						>
							{t("title.accounting")}
						</Link>
						<Typography color="text.primary">
							{t("accounting.cashInOut")}
						</Typography>
					</Breadcrumbs>
				</div>
			</div>

			<div>
				<Box className={styles.BoxStyle}>
					{/* <Typography style={{color:theme.palette.gray.dtext, fontSize:18, textAlign:'center'}}><b>Need Think a Title Here</b></Typography> */}
					<Typography
						style={{
							color: theme.palette.gray.dtext,
							textAlign: "center",
						}}
					>
						{t("general.date", {
							date: format(new Date(), "yyyy-MM-dd"),
						})}
					</Typography>
					<Typography
						style={{
							color: theme.palette.gray.ltext,
							padding: "25px 0 10px",
							textAlign: "center",
						}}
					>
						{t("menu.amount") + " (RM)"}
					</Typography>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							width: 350,
							maxWidth: "100%",
							margin: "0 auto",
						}}
					>
						<div
							onClick={() => adjustAmount("deduct")}
							style={{
								backgroundColor: theme.palette.secondary.main,
								width: 30,
								height: 30,
								borderRadius: 3,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								boxShadow: "2px 2px 6px 0 #8f72547a",
								cursor: "pointer",
							}}
						>
							<FiMinus style={{ fontSize: 20, color: "#fff" }} />
						</div>
						{/* <Typography style={{ color: theme.palette.secondary.main, fontSize: 25, fontWeight: "bold", textAlign: "center" }}>
                            45781054
                        </Typography> */}
						<TextField
							fullWidth
							required
							variant="standard"
							value={state.amount}
							error={inputErrors.amount ? true : false}
							InputLabelProps={{ shrink: true }}
							helperText={
								inputErrors && inputErrors.amount
									? inputErrors.amount
									: ""
							}
							style={{ paddingLeft: 20, paddingRight: 20 }}
							inputProps={{ style: { textAlign: "center" } }}
							onChange={handleChange}
							name="amount"
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
						/>
						<div
							onClick={() => adjustAmount("add")}
							style={{
								backgroundColor: theme.palette.secondary.main,
								width: 30,
								height: 30,
								borderRadius: 3,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								boxShadow: "2px 2px 6px 0 #8f72547a",
								cursor: "pointer",
							}}
						>
							<FiPlus style={{ fontSize: 20, color: "#fff" }} />
						</div>
					</div>
					{inputErrors && inputErrors.amount ? (
						<Typography variant="caption" style={{ color: "red" }}>
							{inputErrors.amount[0]}
						</Typography>
					) : null}
					{/* <div className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} style={{paddingTop:25, maxWidth: '100%'}}>
                        <div style={{width:150, padding:'8px 15px', backgroundColor:theme.palette.secondary.secondary, borderRadius:5, marginRight:15}}>
                            <Typography style={{textAlign:'center', color:'#fff'}}>Money IN</Typography>
                        </div>
                        <div style={{width:150, padding:'8px 15px', backgroundColor:theme.palette.secondary.main, borderRadius:5, }}>
                            <Typography style={{textAlign:'center', color:'#fff'}}>Money OUT</Typography>
                        </div>
                    </div> */}

					<div style={{ paddingTop: 50 }}>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">
								{t("accounting.transactionType")}
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={state.ref_type}
								defaultValue=""
								label="Trasaction Type"
								onChange={handleChange}
								name="ref_type"
							>
								{_.map(refTypeList, (refTypeItem) => {
									return (
										<MenuItem
											key={refTypeItem}
											value={refTypeItem}
										>
											{t(
												`accounting.refTypeList.${refTypeItem}`
											)}
										</MenuItem>
									);
								})}
							</Select>
							{inputErrors && inputErrors.ref_type ? (
								<Typography
									variant="caption"
									style={{ color: "red" }}
								>
									{inputErrors.ref_type[0]}
								</Typography>
							) : null}
						</FormControl>
					</div>

					<div style={{ paddingTop: 25 }}>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">
								{t("accounting.walletType")}
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={state.wallet}
								defaultValue=""
								label="Wallet Type"
								onChange={handleChange}
								name="wallet"
							>
								{_.map(walletTypeList, (walletItem) => {
									return (
										<MenuItem
											key={walletItem.code}
											value={walletItem.code}
										>
											{walletItem[i18n.language]}
										</MenuItem>
									);
								})}
							</Select>
							{inputErrors && inputErrors.wallet ? (
								<Typography
									variant="caption"
									style={{ color: "red" }}
								>
									{inputErrors.wallet[0]}
								</Typography>
							) : null}
						</FormControl>
					</div>
					<div style={{ paddingTop: 25 }}>
						<TextField
							fullWidth
							required
							label={t("accounting.remark")}
							variant="outlined"
							value={state.remark}
							name="remark"
							onChange={handleChange}
							error={inputErrors.remark ? true : false}
							InputLabelProps={{ shrink: true }}
							helperText={
								inputErrors && inputErrors.remark
									? inputErrors.remark[0]
									: ""
							}
						/>
					</div>
					<div style={{ paddingTop: 25 }}>
						<TextField
							fullWidth
							required
							label={t("accounting.password")}
							type={"password"}
							variant="outlined"
							value={state.password}
							name="password"
							onChange={handleChange}
							error={inputErrors.password ? true : false}
							InputLabelProps={{ shrink: true }}
							helperText={
								inputErrors && inputErrors.password
									? inputErrors.password[0]
									: ""
							}
						/>
					</div>

					<div style={{ padding: "8% 0 10px" }}>
						<Button
							onClick={cashInCashOut}
							style={{
								backgroundColor: theme.palette.button.main,
								width: "100%",
								padding: "10px 15px",
								borderRadius: 5,
								boxShadow: "2px 2px 6px 0 #8f72547a",
							}}
						>
							<Typography style={{ color: "#fff" }}>
								{t("button.proceed")}
							</Typography>
						</Button>
					</div>
					<Modal
						open={summaryOpen}
						onClose={closeDrawerSummary}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={style}>
							<div
								className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
								style={{
									height: 60,
									width: 60,
									margin: "0 auto",
									borderRadius: 18,
									backgroundColor:
										theme.palette.indicator.green,
									boxShadow: "2px 1px 11px 0 #cccccc",
								}}
							>
								<TiTick
									style={{ color: "#fff", fontSize: 50 }}
								/>
							</div>
							<Typography
								id="modal-modal-title"
								variant="h6"
								component="h2"
								style={{
									textAlign: "center",
									color: theme.palette.indicator.green,
									paddingTop: 10,
								}}
							>
								{t("accounting.successfully")}
							</Typography>

							<div style={{ paddingBottom: 25 }}>
								<Typography
									style={{
										color: theme.palette.gray.ltext,
										paddingTop: 25,
										textAlign: "center",
									}}
								>
									{t(
										`accounting.${
											drawerSummary &&
											drawerSummary.type === "in"
												? "moneyIn"
												: "moneyOut"
										}`
									)}{" "}
									{t("menu.amount")}
								</Typography>
								<Typography
									style={{
										fontSize: 25,
										fontWeight: "bold",
										textAlign: "center",
										color: theme.palette.indicator.red,
									}}
								>
									{`${
										drawerSummary &&
										drawerSummary.type === "in"
											? "+"
											: "-"
									} ${
										drawerSummary
											? currencyFormat(
													drawerSummary.amount
											  )
											: "-"
									}`}
								</Typography>
							</div>
							<div>
								<Typography
									style={{
										color: theme.palette.gray.dtext,
										marginTop: 10,
									}}
									id="modal-modal-description"
								>
									<span
										style={{
											paddingRight: 8,
											color: theme.palette.gray.ltext,
										}}
									>
										Wallet Type:{" "}
									</span>
									<br />
									{drawerSummary
										? drawerSummary.wallet_display
										: "-"}
								</Typography>
								<Typography
									style={{
										color: theme.palette.gray.dtext,
										marginTop: 10,
									}}
								>
									<span
										style={{
											paddingRight: 8,
											color: theme.palette.gray.ltext,
										}}
									>
										Date:{" "}
									</span>
									<br />
									{drawerSummary
										? drawerSummary.created_at
										: "-"}
								</Typography>
								<Typography
									style={{
										color: theme.palette.gray.dtext,
										marginTop: 10,
									}}
								>
									<span
										style={{
											paddingRight: 8,
											color: theme.palette.gray.ltext,
										}}
									>
										Remark:{" "}
									</span>
									<br />
									{drawerSummary ? drawerSummary.remark : "-"}
								</Typography>
							</div>

							<div style={{ paddingTop: 30 }}>
								<Button
									onClick={closeDrawerSummary}
									style={{
										backgroundColor:
											theme.palette.secondary.main,
										width: "100%",
										padding: "8px 15px",
										borderRadius: 5,
										boxShadow: "2px 2px 6px 0 #8f72547a",
									}}
								>
									<Typography style={{ color: "#fff" }}>
										{t("button.close")}
									</Typography>
								</Button>
							</div>
						</Box>
					</Modal>
				</Box>
			</div>
		</div>
	);
}

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 350,
	maxWidth: "90%",
	borderRadius: "15px",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const useStyles = makeStyles((theme) => ({
	BoxStyle: {
		background: "#fff",
		borderRadius: 15,
		boxShadow: "2px 2px 10px 5px rgb(209 209 209 / 30%)",
		padding: "40px 20px",
		width: 700,
		maxWidth: "100%",
		margin: "0 auto",
	},
	menuText: {
		textAlign: "center",
		fontSize: "14px!important",
		paddingTop: 15,
	},
	justifyCenter: {
		justifyContent: "center",
	},
	flexMiddle: {
		display: "flex",
		alignItems: "center",
	},
	flexTtop: {
		display: "flex",
		alignItems: "flex-start",
	},
}));
