import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl, postUrl } from '../../helpers/ApiAction';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';



//MUI
import { Link, TextField, Box, Button, FormGroup, FormControlLabel, Checkbox, Grid, Typography, Breadcrumbs, FormControl, Select, InputLabel, MenuItem, FormHelperText, Switch } from '@mui/material/';

import { FiArrowLeft } from "react-icons/fi";

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

export default function StaffProfile() {
    const styles = useStyles();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const [state, setState] = useState({
        staff_no: '',
        name: '',
        email: '',
        mobile: '',
        ic: '',
        bank_name: '',
        bank_account: '',
        status: 0,
        role_type: [],
        profile_picture: '',
        existing_image: '',
        bonus_setting: { personal: -1, group: -1, purchaser: -1 },
    });
    const [staffAddress, setStaffAddress] = useState({
        country: '',
        zip: '',
        state: '',
        city: '',
        address: '',
        address2: ''
    });
    const [roles, setRoles] = useState({});
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    const isMountedRef = useRef(null);
    let { id } = useParams();
    const { role } = useSelector(state => state.general);
    const [permissions, setPermissions] = useState([]);
    const [allPermissions, setAllPermissions] = useState([]);
    const [rolePermission, setRolePermission] = useState([]);
    const [staffPermission, setStaffPermission] = useState([]);

    /* useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl('/roles-for-staff').then(roleList => {
            setLoading(false);
            if (isMountedRef.current) {
                setRoles(roleList.data.listing);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]) */


    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl('/permissions-for-staff').then(response => {
            setLoading(false);
            if (isMountedRef.current && response.status) {
                setRoles(response.data.roles);
                setPermissions(response.data.permissions_by_role);
                setAllPermissions(response.data.all_permissions);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getUrl(`/users/${id}`).then(response => {
            setLoading(false);
            if (isMountedRef.current) {
                if (response.status) {
                    let listing = [];
                    setStaffPermission(response.data.directPermissions);
                    if (_.size(response.data.roles) > 0) {
                        _.map(response.data.roles, (roleList, index) => {
                            listing[index] = roleList.name;
                        })
                    }
                    setState({
                        ...state,
                        name: response.data.name,
                        email: response.data.email,
                        mobile: response.data.mobile,
                        ic: response.data.ic,
                        status: response.data.status,
                        bank_account: response.data.bank_account,
                        bank_name: response.data.bank_name,
                        staff_no: response.data.staff_no,
                        existing_image: response.data.profile_picture,
                        role_type: listing,
                        bonus_setting:response.data.bonus_setting === null ? { personal: -1, group: -1, purchaser: -1 }:response.data.bonus_setting,
                    });
                    if (_.size(response.data.addresses) > 0) {
                        _.map(response.data.addresses, listing => {
                            setStaffAddress({
                                ...staffAddress,
                                address: listing.address1,
                                address2: listing.address2 ? listing.address2 : '',
                                country: listing.country,
                                zip: listing.zip,
                                state: listing.state,
                                city: listing.city,
                            });
                        })
                    }
                } else {
                    addAlert('', t('error.contactSupport') + response.message, 'error', '');
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, id])

    useEffect(() => {
        let permissionList = [];

        if (_.size(state.role_type) > 0) {
            _.map(state.role_type, (roleList) => {
                let permissionDetail = permissions[roleList];
                if (_.size(permissionDetail) > 0) {
                    _.map(permissionDetail, detail => {
                        permissionList.push(detail);
                    })
                }
                // const mergedPermission = permissionList.concat(permissionDetail);
                // setRolePermission(mergedPermission);
                setRolePermission(permissionList);
            });
        } else {
            setRolePermission([]);
        }
        // eslint-disable-next-line
    }, [state.role_type, permissions]);

    const authorizeRole = roleName => {
        let roleAuthorize = state.role_type;
        let permissionList = [];

        if (_.includes(roleAuthorize, roleName)) {

            roleAuthorize = _.difference(roleAuthorize, [roleName]);
        } else {
            roleAuthorize.push(roleName);
        }

        if (_.size(roleAuthorize) > 0) {
            _.map(roleAuthorize, (roleList) => {
                let permissionDetail = permissions[roleList];
                if (_.size(permissionDetail) > 0) {
                    _.map(permissionDetail, detail => {
                        permissionList.push(detail);
                    })
                }
                // const mergedPermission = permissionList.concat(permissionDetail);
                // setRolePermission(mergedPermission);
                setRolePermission(permissionList);
            });
        } else {
            setRolePermission([]);
        }

        setState({ ...state, role_type: roleAuthorize });
    }

    const submitData = (e) => {
        e.preventDefault();
        setLoading(true);
        setInputErrors();
        const formData = new FormData();
        formData.append("_method", "put");
        if (state.profile_picture) {
            formData.append("profile_picture", state.profile_picture);
        }
        formData.append("name", state.name);
        formData.append("email", state.email);
        formData.append("mobile", state.mobile || "");
        formData.append("ic", state.ic);
        formData.append("status", state.status);
        formData.append("staff_no", state.staff_no);
        formData.append("bank_name", state.bank_name || "");
        formData.append("bank_account", state.bank_account || "");
        formData.append("country", staffAddress.country);
        formData.append("zip", staffAddress.zip);
        formData.append("state", staffAddress.state);
        formData.append("city", staffAddress.city);
        formData.append("address", staffAddress.address);
        formData.append("address2", staffAddress.address2 || "");
        _.each(state.bonus_setting, (val, key) => {
			formData.append(`bonus_setting[${key}]`, val);
		});
        _.each(state.role_type, (val, key) => {
            formData.append(`role_type[${key}]`, val);
        });
        _.each(staffPermission, (val, key) => {
            formData.append(`permissions[${key}]`, val);
        });
        // let postData = {
        //     name: state.name,
        //     email: state.email,
        //     mobile: state.mobile,
        //     ic: state.ic,
        //     status: state.status,
        //     staff_no: state.staff_no,
        //     bank_name: state.bank_name,
        //     bank_account: state.bank_account,
        //     country: staffAddress.country,
        //     zip: staffAddress.zip,
        //     state: staffAddress.state,
        //     city: staffAddress.city,
        //     address: staffAddress.address,
        //     address2: staffAddress.address2,
        //     role_type: state.role_type,
        //     permissions: staffPermission,
        //     profile_picture:state.profile_picture,
        // }
        postUrl(`/users/${id}`, formData).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.editSuccess'), 'success', '');
                setState({
                    ...state,
                    existing_image: response.data.profile_picture,
                });
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const changeAuthorize = name => {
        let permissionList = staffPermission;
        const checkpermission = permissionList.indexOf(name);

        // console.log("check",checkpermission);

        if (checkpermission !== -1) {
            permissionList.splice(checkpermission, 1);
        } else {
            permissionList.push(name);
        }

        setStaffPermission(permissionList => ([...permissionList]));


    }

    const handleBonusChange = (event) => {
        let name = event.target.name;

        if (state.bonus_setting[name] >= 0) {
            setState({ ...state, bonus_setting: { ...state.bonus_setting, [name]: -1 } })
        } else {
            setState({ ...state, bonus_setting: { ...state.bonus_setting, [name]: 0 } })
        }
    }

    const handleBonusValueChange = (event) => {
        let name = event.target.name;
        setState({ ...state, bonus_setting: { ...state.bonus_setting, [name]: event.target.value } })
    }

    return (
        <div>

            <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                <span onClick={() => navigate(-1)} style={{
                    color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase',
                    cursor: 'pointer'
                }}>{t('button.back')}</span>
            </div>

            <div style={{ paddingBottom: '5%' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ paddingBottom: 15, fontSize: 18 }}><b>{t('title.staffProfile')}</b></Typography>
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to={`/dashboard`}>
                                {t('title.dashboard')}
                            </Link>
                            <Link underline="hover" color="inherit" component={RouterLink} to={`/staffs`}>
                                {t('title.staffs')}
                            </Link>
                            <Typography color="text.primary">{t('title.staffProfile')}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
                <Box component="form" autoComplete="on" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }} onSubmit={submitData}>
                    <Grid item xs={12} container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <TextField
                                label={t('user.name')}
                                variant="outlined"
                                type="text"
                                autoComplete="name"
                                value={state.name || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value })}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <TextField
                                label={t('user.staffNo')}
                                variant="outlined"
                                type="text"
                                autoComplete="staff_no"
                                value={state.staff_no || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.staff_no ? inputErrors.staff_no : ''}
                                error={inputErrors && inputErrors.staff_no ? true : false}
                                onChange={({ target }) => setState({ ...state, staff_no: target.value })}
                                disabled={_.size(_.intersection(['dev', 'super-admin'], role)) > 0 ? false : true}
                            />
                            <Typography variant="caption" display="block" gutterBottom style={{ paddingLeft: 5 }}>
                                {t('general.staffNoHeplerText')}
                            </Typography>
                            {/* </Box> */}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <TextField
                                label={t('user.nric')}
                                variant="outlined"
                                autoComplete="ic"
                                value={state.ic || ''}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.ic ? inputErrors.ic : ''}
                                error={inputErrors && inputErrors.ic ? true : false}
                                onChange={({ target }) => setState({ ...state, ic: target.value })}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <TextField
                                label={t('user.email')}
                                variant="outlined"
                                type="email"
                                autoComplete="email"
                                value={state.email || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                error={inputErrors && inputErrors.email ? true : false}
                                onChange={({ target }) => setState({ ...state, email: target.value })}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <TextField
                                label={t('user.mobile')}
                                variant="outlined"
                                autoComplete="mobile"
                                value={state.mobile || ''}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                error={inputErrors && inputErrors.mobile ? true : false}
                                onChange={({ target }) => setState({ ...state, mobile: target.value })}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <TextField
                                label={t('user.bankName')}
                                variant="outlined"
                                autoComplete="bank_name"
                                value={state.bank_name || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.bank_name ? inputErrors.bank_name : ''}
                                error={inputErrors && inputErrors.bank_name ? true : false}
                                onChange={({ target }) => setState({ ...state, bank_name: target.value })}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <TextField
                                label={t('user.bankAccount')}
                                variant="outlined"
                                autoComplete="bank_account"
                                value={state.bank_account || ''}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.bank_account ? inputErrors.bank_account : ''}
                                error={inputErrors && inputErrors.bank_account ? true : false}
                                onChange={({ target }) => setState({ ...state, bank_account: target.value })}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <TextField
                                label={t('user.address')}
                                variant="outlined"
                                type="text"
                                autoComplete="address"
                                value={staffAddress.address || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.address ? inputErrors.address : ''}
                                error={inputErrors && inputErrors.address ? true : false}
                                onChange={({ target }) => setStaffAddress({ ...staffAddress, address: target.value })}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <TextField
                                label={t('user.address2')}
                                variant="outlined"
                                type="text"
                                autoComplete="address2"
                                value={staffAddress.address2 || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.address2 ? inputErrors.address2 : ''}
                                error={inputErrors && inputErrors.address2 ? true : false}
                                onChange={({ target }) => setStaffAddress({ ...staffAddress, address2: target.value })}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <TextField
                                label={t('user.country')}
                                variant="outlined"
                                type="text"
                                autoComplete="country"
                                value={staffAddress.country || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.country ? inputErrors.country : ''}
                                error={inputErrors && inputErrors.country ? true : false}
                                onChange={({ target }) => setStaffAddress({ ...staffAddress, country: target.value })}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <TextField
                                label={t('user.zip')}
                                variant="outlined"
                                autoComplete="zip"
                                value={staffAddress.zip || ''}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.zip ? inputErrors.zip : ''}
                                error={inputErrors && inputErrors.zip ? true : false}
                                onChange={({ target }) => setStaffAddress({ ...staffAddress, zip: target.value })}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <TextField
                                label={t('user.city')}
                                type="text"
                                autoComplete="city"
                                value={staffAddress.city || ''}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" helperText={inputErrors && inputErrors.city ? inputErrors.city : ''}
                                error={inputErrors && inputErrors.city ? true : false}
                                onChange={({ target }) => setStaffAddress({ ...staffAddress, city: target.value })}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <TextField
                                label={t('user.state')}
                                type="text"
                                autoComplete="state"
                                value={staffAddress.state || ''}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" helperText={inputErrors && inputErrors.state ? inputErrors.state : ''}
                                error={inputErrors && inputErrors.state ? true : false}
                                onChange={({ target }) => setStaffAddress({ ...staffAddress, state: target.value })}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ marginTop: 8 }}>
                            {/* <Box component="form" noValidate
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{t('staff.status.title')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.status}
                                    label={t('staff.status.title')}
                                    error={inputErrors && inputErrors.status ? true : false}
                                    onChange={({ target }) => setState({ ...state, status: target.value })}
                                >
                                    {
                                        _.map([0, 1], statusType => {
                                            return <MenuItem key={statusType} value={statusType}>{t(`staff.status.${statusType}`)}</MenuItem>
                                        })
                                    }
                                </Select>
                                <FormHelperText style={{ color: 'red' }}>{inputErrors && inputErrors.status ? inputErrors.status : ''}</FormHelperText>
                            </FormControl>
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <Box component="form" noValidate autoComplete="on"
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} > */}
                            {state.existing_image && <img src={state.existing_image.file_name} alt="profile img" style={{ width: 'auto', height: 150 }} />}
                            <TextField
                                label={t('staff.profilePicture')}
                                type="file"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" helperText={inputErrors && inputErrors.profile_picture ? inputErrors.profile_picture : ''}
                                error={inputErrors && inputErrors.profile_picture ? true : false}
                                onChange={({ target }) => setState({ ...state, profile_picture: target.files[0] })}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ paddingBottom: 15 }}>
                                <Typography
                                    className={styles.subText}
                                    style={{ paddingBottom: 6 }}
                                >
                                    <b>{t("staff.bonusSetting")}</b>
                                </Typography>
                                <Typography>
                                    {t('staff.personalCommission')}
                                </Typography>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                    <Grid item sm={3} xs={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={state.bonus_setting?.personal >= 0 ? true : false} onChange={handleBonusChange} name="personal" />} color="primary" label="" />
                                        </FormGroup>
                                    </Grid>
                                    {
                                        state.bonus_setting?.personal >= 0 &&
                                        <Grid item sm={3} xs={6}>
                                            <TextField
                                                name="personal"
                                                value={state.bonus_setting?.personal}
                                                onChange={handleBonusValueChange}
                                                helperText="Set value as 0 if want to use product's bonus setting"
                                            />
                                        </Grid>
                                    }
                                </Grid>
                                <Typography>
                                    {t('staff.groupCommission')}
                                </Typography>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                    <Grid item sm={3} xs={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={state.bonus_setting?.group >= 0 ? true : false} onChange={handleBonusChange} name="group" />} color="primary" label="" />
                                        </FormGroup>
                                    </Grid>
                                    {/* {
                                        state.bonus_setting?.group >= 0 &&
                                        <Grid item sm={3} xs={6}>
                                            <TextField
                                                name="group"
                                                value={state.bonus_setting?.group}
                                                onChange={handleBonusValueChange}
                                                helperText="Set value as 0 if want to use product's bonus setting"
                                            />
                                        </Grid>
                                    } */}
                                </Grid>
                                <Typography>
                                    {t('staff.purchaserCommission')}
                                </Typography>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                    <Grid item sm={3} xs={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={state.bonus_setting?.purchaser >= 0 ? true : false} onChange={handleBonusChange} name="purchaser" />} color="primary" label="" />
                                        </FormGroup>
                                    </Grid>
                                    {
                                        state.bonus_setting?.purchaser >= 0 &&
                                        <Grid item sm={3} xs={6}>
                                            <TextField
                                                name="purchaser"
                                                value={state.bonus_setting?.purchaser}
                                                onChange={handleBonusValueChange}
                                                helperText="Set value as 0 if want to use product's bonus setting"
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} >
                            <div style={{ paddingBottom: 15 }}>
                                <Typography className={styles.subText} style={{ paddingBottom: 6 }}><b>{t('staff.roles')}</b></Typography>
                                <FormGroup>
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        {Array.from(roles).map((list) => (
                                            <Grid item xs={2} sm={4} md={4} key={list.name}>
                                                <FormControlLabel control={<Checkbox />} label={list.display_name[i18n.language]} onChange={() => authorizeRole(list.name)} checked={_.includes(state.role_type, list.name)} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                    {inputErrors && inputErrors.role_type && <FormHelperText style={{ color: 'red' }}>{inputErrors.role_type}</FormHelperText>}
                                </FormGroup>
                            </div>
                        </Grid>
                        {
                            _.size(state.role_type) > 0 &&
                            <Grid item xs={12} >
                                <div style={{ paddingBottom: 15 }}>
                                    <Typography className={styles.subText} style={{ paddingBottom: 6 }}><b>{t('staff.permission')}</b></Typography>
                                    <Box sx={{ width: '100%' }}>
                                        <Grid container>
                                            {
                                                _.size(allPermissions) > 0 &&
                                                _.map(allPermissions, listing => {
                                                    let selectedPermission = false;
                                                    let staffSelectedPermission = false;
                                                    if (_.size(rolePermission) > 0) {
                                                        _.map(rolePermission, list => {
                                                            if (listing.name === list) {
                                                                selectedPermission = true;
                                                            }
                                                        });
                                                    }
                                                    if (_.size(staffPermission) > 0) {
                                                        _.map(staffPermission, staff => {
                                                            if (listing.name === staff) {
                                                                staffSelectedPermission = true;
                                                            }
                                                        });
                                                    }
                                                    if (listing.authorize) {
                                                        return (
                                                            <Grid item md={6} key={listing.id}>
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={selectedPermission ? true : (staffSelectedPermission ? true : false)} color="primary" onChange={() => changeAuthorize(listing.name)} disabled={selectedPermission} />}
                                                                    label={listing.name}
                                                                />
                                                                {
                                                                    _.size(listing.children) > 0 && _.map(listing.children, child => {
                                                                        let selectedChildPermission = false;
                                                                        let staffSelectedChildPermission = false;
                                                                        if (_.size(rolePermission) > 0) {
                                                                            _.map(rolePermission, list => {
                                                                                if (child.name === list) {
                                                                                    selectedChildPermission = true;
                                                                                }
                                                                            });
                                                                        }
                                                                        if (_.size(staffPermission) > 0) {
                                                                            _.map(staffPermission, staffChild => {
                                                                                if (child.name === staffChild) {
                                                                                    staffSelectedChildPermission = true;
                                                                                }
                                                                            });
                                                                        }
                                                                        if (child.authorize) {
                                                                            return (
                                                                                <Box key={child.id} spacing={2} paddingLeft={5}>
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox checked={selectedChildPermission ? true : (staffSelectedChildPermission ? true : false)} color="primary" onChange={() => changeAuthorize(child.name)} disabled={selectedChildPermission} />}
                                                                                        label={child.name}
                                                                                    />
                                                                                </Box>
                                                                            )
                                                                        } else { return null; }
                                                                    })
                                                                }
                                                            </Grid>
                                                        )
                                                    } else { return null; }
                                                })
                                            }
                                        </Grid>
                                    </Box>
                                </div>
                            </Grid>
                        }
                    </Grid>
                    <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link underline='none' to={`/staffs`} component={RouterLink}>
                            <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                        </Link>
                        <Button size="large" variant="contained" className={styles.buttonStyle} type="submit" >{t('button.save')}</Button>
                    </Box>
                </Box>
            </div>
        </div >

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
}));