import React, { useEffect, useState, useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl, putUrl } from '../../helpers/ApiAction';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// MUI
import { Grid, Typography, Link, LinearProgress, Breadcrumbs, IconButton, Modal, Box, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarColumnsButton, GridActionsCellItem } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';

import { FiArrowLeft } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import { BsSortNumericDown } from "react-icons/bs";


function CustomToolbar() {
    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer} style={{paddingBottom:10}}>
            <GridToolbarExport style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} csvOptions={{ allColumns: true, fileName: `Brands_${new Date().toISOString().slice(0, 10)}` }} printOptions={{ disableToolbarButton: true }} />
            <GridToolbarDensitySelector style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
            <GridToolbarFilterButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
            <GridToolbarColumnsButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
        </GridToolbarContainer>
    );
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '80%',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '90%',
    display: 'flex',
    flexDirection: 'column'
};

export default function Index() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const [pageSize, setPageSize] = useState(5);
    const [tableLoading, setTableLoading] = useState(false);
    const [state, setState] = useState([]);
    const [openPriorityModal, setOpenPriorityModal] = useState(false);
    const { addAlert } = useNotificationLoading();
    const { data } = useDemoData({
        dataSet: 'brand',
        rowLength: 5,
        maxColumns: 10,
    });
    let navigate = useNavigate();
    const { permissions } = useSelector(state => state.general);

    const editDetails = React.useCallback(
        (id) => () => {
            navigate(`/brand-edit/${id}`);
        },
        // eslint-disable-next-line
        [],
    );

    //table
    const columns = useMemo(() => [
        {
            field: 'name',
            headerName: `${t('brand.name')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'created_at',
            headerName: `${t('customer.createdAt')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: `${t('table.actions')}`,
            width: 70,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<MdModeEdit style={{color:theme.palette.button.main}} size={25} />}
                    label={t('button.edit')}
                    onClick={editDetails(params.id)}
                />
            ],
        },
    ],
        // eslint-disable-next-line
        [editDetails, t],
    );

    useEffect(() => {
        setTableLoading(true);
        getUrl('/brands').then(response => {
            setTableLoading(false);
            if (response.status) {
                setState(response.data.listing);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            
            <div style={{ paddingBottom: 30, justifyContent:'space-between' }} className={styles.flexMiddle}>
            <Link underline='none' component={RouterLink} to={`/setting`}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.back')}</Typography>
                </div>
            </Link>

            <div>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Link underline="hover" color="inherit" component={RouterLink} to="/setting">
                        {t('title.setting')}
                    </Link>
                    <Typography color="text.primary">{t('title.brands')}</Typography>
                </Breadcrumbs>
            </div>

            </div>
            <div style={{ paddingBottom: 5 }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t('brand.brandList')}</b></Typography>
                        {
                            _.includes(permissions, "brand-create") &&
                            <Link underline='none' to={`/brand-add`} component={RouterLink}>
                                <div style={{ height: 30, width: 30, textAlign: 'center', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', border: theme.palette.button.borderMain }}>
                                    <Typography style={{ fontSize: 21, color: theme.palette.button.main }}>
                                        +
                                    </Typography>
                                </div>
                            </Link>
                        }
                        {
                            _.includes(permissions, "brand-edit") && _.size(state) > 0 ?
                            <IconButton 
                                variant="outlined"
                                style={{ height: 30, marginLeft: 10, textAlign: 'center', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', border: theme.palette.button.borderMain, color: theme.palette.button.main }}
                                onClick={() => setOpenPriorityModal(true)}    
                            >
                                <BsSortNumericDown style={{color:theme.palette.button.main}} size={20} />
                            </IconButton>
                            : null
                        }
                    </div>
            
                </Grid>
            </div>
            <div style={{ height: 450, width: '100%', overflowX: 'scroll', marginTop: 20, boxShadow: '0 0 20px 0 #dadada', borderRadius: '15px' }}>
                <div style={{ display: 'flex', height: '100%', padding: 0 }}>
                    <div style={{ flexGrow: 1 }}>
                        {
                            _.size(state) > 0
                                ?
                                <DataGrid
                                    {...data}
                                    rows={state}
                                    columns={columns}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    components={{
                                        Toolbar: CustomToolbar,
                                        LoadingOverlay: LinearProgress,
                                    }}
                                    loading={tableLoading ? true : false}
                                    sx={{
                                        marginTop: 0,
                                        borderRadius: '15px',
                                        paddingTop: '15px',
                                        border: 'none',
                                        '& .super-app-theme--header': {
                                            // backgroundColor: theme.palette.primary.main,
                                            backgroundColor: theme.palette.gray.tableHeader,
                                            color: theme.palette.gray.tableHeaderText,
                                        },
                                        backgroundColor: 'white',
                                        '& 	.MuiDataGrid-menuIconButton': {
                                            color: theme.palette.gray.tableHeaderText,
                                        },
                                        '& 	.MuiDataGrid-sortIcon': {
                                            color: theme.palette.gray.tableHeaderText,
                                        },
                                    }}
                                />
                                :
                                <div className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} style={{ flexDirection: 'column', backgroundColor: '#fff', height: '100%', }}>
                                    <img src="images/emptyStatus/noBrand.png" alt="empty" style={{ width: 180 }} />
                                    <Typography style={{ margin: 10, textAlign: "center" }}>{t('emptyStatus.noBrand')}</Typography>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {_.size(state) > 0 &&
                <PriorityModal data={state} openModal={openPriorityModal} setOpenModal={setOpenPriorityModal}/>
            }
        </div>

    );
}

const PriorityModal = memo((props) => {
    const theme = useTheme();
    const { t } = useTranslation();
	const [state, setState] = useState(props.data);
    const { addAlert, setLoading } = useNotificationLoading();
    
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

	function onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const list = reorder(
			state,
			result.source.index,
			result.destination.index
		);

		setState(list);
	}

    function Item({ item, index }) {
		return (
			<Draggable key={`listitem-${item.id}`} draggableId={`listitem-${item.id}`} index={parseInt(index)}>
				{(provided) => (
                    <ListItem 
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{top: 'auto !important', left: 'auto !important'}}
                    >
                        <ListItemAvatar>
                            <Avatar alt={item.name} src={item.logo ? item.logo.file_name : "/images/emptyStatus/nobrandimg.png"} variant="square"></Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.name} />
                        <Typography>{index+1}</Typography>
                    </ListItem>
				)}
			</Draggable>
		);
	}

    const DataList = React.memo(function DataList({data}) {
        return _.map(data, (item, index) => (
            <Item item={item} index={index} key={item.id} />
        ));
    });

    const updatePriority = () => {
        setLoading(true);
        putUrl('/brands/priority', {ids: _.map(state, 'id')}).then(response => {
            setLoading(false);
            addAlert("", response.message, response.status ? 'success' : "error", '');
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

	return (
        <Modal
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <center>{t('brand.dragDropToReorder')}</center>
                <div style={{ textAlign: 'center', paddingTop: 15, color: theme.palette.gray.dtext, position: "relative", overflow: 'auto' }}>
                    {_.size(state) > 0 &&
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="list">
                            {provided => (
                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} ref={provided.innerRef} {...provided.droppableProps}>
                                    <DataList data={state} />
                                    {provided.placeholder}
                                </List>
                            )}
                        </Droppable>
                    </DragDropContext>
                    }
                </div>
                <div style={{ paddingTop: 25, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <Button style={{ border: theme.palette.button.borderMain, width: 'fit-content', minWidth: 150, padding: '8px 15px', borderRadius: 5, boxShadow: '2px 2px 6px 0 #8f72547a' }} onClick={() => props.setOpenModal(false)}>
                        <Typography style={{ color: theme.palette.button.main }}>{t('button.cancel')}</Typography>
                    </Button>
                    <Button style={{ backgroundColor: theme.palette.button.main, width: 'fit-content', minWidth: 150, padding: '8px 15px', borderRadius: 5, boxShadow: '2px 2px 6px 0 #8f72547a' }} onClick={updatePriority}>
                        <Typography style={{ color: '#fff' }}>{t('button.save')}</Typography>
                    </Button>
                </div>
            </Box>
        </Modal>
	);
});

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    justifyCenter:{
        justifyContent:'center'
    }
}));
