import React, { Fragment } from "react";
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { APP_NAME } from '../configs/Config';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// MUI
import { Typography, Link, Container, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

//NOTIFICATON AND LOADING
import NotificationLoadingProvider from "../providers/NotificationLoadingProvider";
import NotificationLoading from "./Layout/NotificationLoading";

// PAGES
import Sidebar from './Framework/Sidebar';
import Dashboard from './Dashboard';

import Accounting from './Accounting/Index';
import DrawerSummary from './Accounting/DrawerSummary';
// import DrawerDetails from './Accounting/DrawerWalletSummary';
import DailySettlements from './Accounting/DailySettlements';
import DailySettlementAdd from './Accounting/DailySettlementAdd';
import DrawerAdjustment from './Accounting/DrawerAdjustment';

import Customer from './Customer/Index';
import CustomerAdd from './Customer/CustomerAdd';
import CustomerProfile from './Customer/CustomerProfile';
import CustomerEdit from './Customer/CustomerEdit';

import Role from "./Role/Index";
import RoleCreate from "./Role/Create";
import RoleEdit from "./Role/Edit";
import Permission from "./Permission/Index";
import PermissionCreate from "./Permission/Create";
import PermissionEdit from "./Permission/Edit";

import Setting from "./Setting/Index";
import MyAccount from "./Setting/MyAccount";

import Checkout from './Pos/Index';
// import CheckoutSample from './Pos/Index_bk';

import Menu from './Menu/Index';
import MenuProduct from './Menu/MenuProduct';
import MenuProductDetails from './Menu/MenuProductDetails';

import Order from './Order/Index';
import OrderDetails from './Order/Detail';

import Product from './Product/Index';
import ProductListing from './Product/ProductListing';
import ProductAdd from './Product/ProductAdd';
import ProductEdit from './Product/ProductEdit';
import ProductStockReport from './Product/ProductStockReport';

import Staff from './Staff/Index';
import StaffAdd from './Staff/StaffAdd';
import StaffProfile from './Staff/StaffProfile';

import Supplier from './Supplier/Index';
import SupplierAdd from './Supplier/SupplierAdd';
import SupplierProfile from './Supplier/SupplierProfile';
import SupplierEdit from './Supplier/SupplierEdit';

import SalesReport from './SalesReport/Index';
import SalesIndividial from './SalesReport/Individual';
import SalesProfit from './SalesReport/SalesProfit';

import Warranty from './Warranty/Index';
import WarrantyCheck from './Warranty/WarrantyCheck';
import WarrantyReport from './Warranty/WarrantyReport';
import WarrantyReportDetail from './Warranty/WarrantyReportDetail';
import CreateWarrantyReport from './Warranty/CreateWarrantyReport';

import Login from "./Auth/Login";
import ForgetPassword from "./Auth/ForgetPassword";
import ResetPassword from "./Auth/ResetPassword";

import Commission from './Commission/Index';

import Brand from './Brand/Index';
import BrandDetail from "./Brand/Detail";
import DailySettlementDetails from "./Accounting/DailySettlementDetails";
import DrawerWalletSummary from "./Accounting/DrawerWalletSummary";
import StaffPassword from "./Staff/StaffPassword";

import CreditNote from './CreditNote/Index';
import CreditNoteDetail from './CreditNote/Detail';

import ExpenseTitle from './ExpenseTitle';
import ExpenseTitleDetail from "./ExpenseTitle/Detail";
import Expense from './Expense';
import ExpenseDetail from "./Expense/Detail";

const routes = [
    // category: true; if show as category, fill in category info, and state all category children
    // name in english, label in translate, children icon wont show if category
    // category: false; if show as self, put details at children

    //dashboard
    {
        category: false,
        categoryName: '',
        categoryLabel: '',
        categoryIcon: null,
        categoryPermission: [],
        categorySidebar: true,
        children: [
            {
                path: "/dashboard",
                component: <Dashboard />,
                permission: [],
                sidebar: true,
                icon: <DashboardIcon />,
                title: 'title.dashboard',
            },
        ]
    },
    //customer
    // {
    //     category: true,
    //     categoryName: 'customer',
    //     categoryLabel: 'title.customer',
    //     categoryIcon: <GroupIcon />,
    //     categoryPermission: [],
    //     categorySidebar: true,
    //     children: [
    //         {
    //             path: "/customers",
    //             component: <Customer />,
    //             permission: ['customer'],
    //             sidebar: true,
    //             icon: null,
    //             title: 'title.customers',
    //         },
    //         {
    //             path: "/customer-add",
    //             component: <CustomerAdd />,
    //             permission: ['customer-create'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.customerCreate',
    //         },
    //         {
    //             path: "/customer-profile/:id",
    //             component: <CustomerProfile />,
    //             permission: ['customer'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.customerProfile',
    //         },
    //         {
    //             path: "/customer-edit/:id",
    //             component: <CustomerEdit />,
    //             permission: ['customer-edit'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.customerEdit',
    //         },
    //     ]
    // },
    //Order
    {
        category: true,
        categoryName: 'order',
        categoryLabel: 'title.orders',
        categoryIcon: <GroupIcon />,
        categoryPermission: [],
        categorySidebar: false,
        children: [
            {
                path: "/orders",
                component: <Order />,
                permission: ['order'],
                sidebar: false,
                icon: null,
                title: 'title.orders',
            },
            {
                path: "/orders/:id",
                component: <OrderDetails />,
                permission: ['order'],
                sidebar: false,
                icon: null,
                title: '',
            },
        ]
    },

    //product
    {
        category: true,
        categoryName: 'product',
        categoryLabel: 'title.products',
        categoryIcon: <GroupIcon />,
        categoryPermission: [],
        categorySidebar: false,
        children: [
            {
                path: "/product",
                component: <Product />,
                permission: ['product'],
                sidebar: false,
                icon: null,
                title: 'title.products',
            },
            {
                path: "/products",
                component: <ProductListing />,
                permission: ['product'],
                sidebar: false,
                icon: null,
                title: 'title.products',
            },
            // {
            //     path: "/tradeins",
            //     component: <ProductListing />,
            //     permission: ['tradein'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.products',
            // },
            {
                path: "/product-add",
                component: <ProductAdd />,
                permission: ['product-create'],
                sidebar: false,
                icon: null,
                title: 'title.products',
            },
            {
                path: "/tradein-add",
                component: <ProductAdd />,
                permission: ['tradein-create'],
                sidebar: false,
                icon: null,
                title: 'title.products',
            },
            {
                path: "/consignment-add",
                component: <ProductAdd />,
                permission: ['consignment-create'],
                sidebar: false,
                icon: null,
                title: 'title.products',
            },
            {
                path: "/product-edit/:id",
                component: <ProductEdit />,
                permission: ['product-edit'],
                sidebar: false,
                icon: null,
                title: 'title.products',
            },
            {
                path: "/tradein-edit/:id",
                component: <ProductEdit />,
                permission: ['tradein-edit'],
                sidebar: false,
                icon: null,
                title: 'title.products',
            },
            {
                path: "/consignment-edit/:id",
                component: <ProductEdit />,
                permission: ['consignment-edit'],
                sidebar: false,
                icon: null,
                title: 'title.products',
            },
            {
                path: "/product-stock-report",
                component: <ProductStockReport />,
                permission: [],
                sidebar: false,
                icon: null,
                title: 'title.products',
            },
        ]
    },
    //Pos
    {
        category: true,
        categoryName: 'pos',
        categoryLabel: 'title.checkout',
        categoryIcon: <GroupIcon />,
        categoryPermission: [],
        categorySidebar: false,
        children: [
            {
                path: "/checkout",
                component: <Checkout />,
                permission: [],
                sidebar: false,
                icon: null,
                title: 'title.checkout',
            },
            // {
            //     path: "/checkout-sample",
            //     component: <CheckoutSample />,
            //     permission: [],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.checkout',
            // },
        ],
    },
    //Menu
    {
        category: true,
        categoryName: 'menu',
        categoryLabel: 'title.menu',
        categoryIcon: <GroupIcon />,
        categoryPermission: [],
        categorySidebar: false,
        children: [
            {
                path: "/menu",
                component: <Menu />,
                permission: ['menu'],
                sidebar: false,
                icon: null,
                title: 'title.menu',
            },
            {
                path: "/menu/brand/:brand_id",
                component: <MenuProduct/>,
                permission: ['menu'],
                sidebar: true,
                icon: null,
                title: 'title.menu',
            },
            {
                path: "/menu/product/:id",
                component: <MenuProductDetails/>,
                permission: ['menu'],
                sidebar: true,
                icon: null,
                title: 'title.menu',
            },
        ]
    },
    //Warranty
    // {
    //     category: true,
    //     categoryName: 'warranty',
    //     categoryLabel: 'title.warranty',
    //     categoryIcon: <GroupIcon />,
    //     categoryPermission: [],
    //     categorySidebar: false,
    //     children: [
    //         {
    //             path: "/warranty",
    //             component: <Warranty/>,
    //             permission: ['warranty'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.warranty',
    //         },
    //         {
    //             path: "/warranty-check",
    //             component: <WarrantyCheck/>,
    //             permission: ['warranty'],
    //             sidebar: true,
    //             icon: null,
    //             title: 'title.warranty',
    //         },
    //         {
    //             path: "/warranty-report/:id",
    //             component: <WarrantyReportDetail/>,
    //             permission: ['warranty'],
    //             sidebar: true,
    //             icon: null,
    //             title: 'title.warranty',
    //         },
    //         {
    //             path: "/warranty-reports",
    //             component: <WarrantyReport/>,
    //             permission: ['warranty'],
    //             sidebar: true,
    //             icon: null,
    //             title: 'title.warranty',
    //         },
    //         {
    //             path: "/warranty-report-add/:id",
    //             component: <CreateWarrantyReport/>,
    //             permission: ['warranty-create'],
    //             sidebar: true,
    //             icon: null,
    //             title: 'title.warranty',
    //         },
    //     ]
    // },

    //staff
    // {
    //     category: true,
    //     categoryName: 'staff',
    //     categoryLabel: 'title.staff',
    //     categoryIcon: <GroupIcon />,
    //     categoryPermission: [],
    //     categorySidebar: false,
    //     children: [
    //         {
    //             path: "/staffs",
    //             component: <Staff />,
    //             permission: ['staff'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.staff',
    //         },
    //         {
    //             path: "/staff-add",
    //             component: <StaffAdd />,
    //             permission: ['staff-create'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.staffAdd',
    //         },
    //         {
    //             path: "/staff-profile/:id",
    //             component: <StaffProfile />,
    //             permission: ['staff-edit'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.staffProfile',
    //         },
    //         {
    //             path: "/staff-password/:id",
    //             component: <StaffPassword />,
    //             permission: ['staff-edit'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.staffPassword',
    //         },
    //     ]
    // },
    // //supplier
    // {
    //     category: true,
    //     categoryName: 'supplier',
    //     categoryLabel: 'title.supplier',
    //     categoryIcon: <GroupIcon />,
    //     categoryPermission: [],
    //     categorySidebar: true,
    //     children: [
    //         {
    //             path: "/suppliers",
    //             component: <Supplier />,
    //             permission: ['supplier'],
    //             sidebar: true,
    //             icon: null,
    //             title: 'title.supplier',
    //         },
    //         {
    //             path: "/supplier-add",
    //             component: <SupplierAdd />,
    //             permission: ['supplier-create'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.supplierCreate',
    //         },
    //         {
    //             path: "/supplier-profile/:id",
    //             component: <SupplierProfile />,
    //             permission: ['supplier'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.supplierProfile',
    //         },
    //         {
    //             path: "/supplier-edit/:id",
    //             component: <SupplierEdit />,
    //             permission: ['supplier-edit'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.supplierEdit',
    //         },

    //     ]
    // },

    //supplier
    {
        category: true,
        categoryName: 'supplier',
        categoryLabel: 'title.salesReport',
        categoryIcon: <GroupIcon />,
        categoryPermission: [],
        categorySidebar: true,
        children: [
            {
                path: "/salesReport",
                component: <SalesReport />,
                permission: ['report'],
                sidebar: true,
                icon: null,
                title: 'title.salesReport',
            },
            {
                path: "/salesReport/:id",
                component: <SalesIndividial />,
                permission: [],
                sidebar: true,
                icon: null,
                title: 'title.salesReport',
            },
            {
                path: "/salesProfitReport",
                component: <SalesProfit />,
                permission: ['report'],
                sidebar: true,
                icon: null,
                title: 'title.salesProfitReport',
            },
        ]
    },
    
    //setting
    {
        category: true,
        categoryName: 'setting',
        categoryLabel: 'title.setting',
        categoryIcon: <SettingsIcon />,
        categoryPermission: ['role', 'permission', 'brand', 'expense-title'],
        categorySidebar: true,
        children: [
            {
                path: "/setting",
                component: <Setting />,
                permission: [],
                sidebar: true,
                icon: <AccountTreeIcon />,
                title: 'title.setting',
            },
            {
                path: "/myAccount",
                component: <MyAccount />,
                permission: [],
                sidebar: true,
                icon: <AccountTreeIcon />,
                title: 'title.myAccount',
            },
            // {
            //     path: "/roles",
            //     component: <Role />,
            //     permission: ['role'],
            //     sidebar: true,
            //     icon: <AccountTreeIcon />,
            //     title: 'title.roles',
            // },
            // {
            //     path: "/role-add",
            //     component: <RoleCreate />,
            //     permission: ['role-create'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.roleCreate',
            // },
            // {
            //     path: "/role-edit/:id",
            //     component: <RoleEdit />,
            //     permission: ['role-edit'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.roleEdit',
            // },
            // {
            //     path: "/permissions",
            //     component: <Permission />,
            //     permission: ['permission'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.permissions',
            // },
            // {
            //     path: "/permission-add",
            //     component: <PermissionCreate />,
            //     permission: ['permission-create'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.permissionCreate',
            // },
            // {
            //     path: "/permission-edit/:id",
            //     component: <PermissionEdit />,
            //     permission: ['permission-edit'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.permissionEdit',
            // },
            // {
            //     path: "/brands",
            //     component: <Brand />,
            //     permission: ['brand'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.brands',
            // },
            // {
            //     path: "/brand-add",
            //     component: <BrandDetail />,
            //     permission: ['brand-create'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.brandCreate',
            // },
            // {
            //     path: "/brand-edit/:id",
            //     component: <BrandDetail />,
            //     permission: ['brand-edit'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.brandEdit',
            // },
            {
                path: "/expense-titles",
                component: <ExpenseTitle />,
                permission: ['expense-title'],
                sidebar: true,
                icon: null,
                title: 'title.expenseTitles',
            },
            {
                path: "/expense-title-add",
                component: <ExpenseTitleDetail />,
                permission: ['expense-title-create'],
                sidebar: false,
                icon: null,
                title: 'title.expenseTitleCreate',
            },
            {
                path: "/expense-title-edit/:id",
                component: <ExpenseTitleDetail />,
                permission: ['expense-title-edit'],
                sidebar: false,
                icon: null,
                title: 'title.expenseTitleEdit',
            },
        ]
    },
    

    //accounting
    // {
    //     category: false,
    //     categoryName: 'accounting',
    //     categoryLabel: 'title.accounting',
    //     categoryIcon: <SettingsIcon />,
    //     categoryPermission: [],
    //     categorySidebar: true,
    //     children: [
    //         {
    //             path: "/accounting",
    //             component: <Accounting />,
    //             permission: [],
    //             sidebar: true,
    //             icon: <GroupIcon />,
    //             title: 'title.accounting',
    //         },
    //         {
    //             path: "/drawer-summary",
    //             component: <DrawerSummary />,
    //             permission: ['drawer-log'],
    //             sidebar: true,
    //             icon: <AccountTreeIcon />,
    //             title: 'title.drawer',
    //         },  
    //         {
    //             path: "/drawer-wallet-summary",
    //             component: <DrawerWalletSummary />,
    //             permission: ['drawer-log'],
    //             sidebar: true,
    //             icon: <AccountTreeIcon />,
    //             title: 'title.drawer',
    //         }, 
    //         {
    //             path: "/drawer-adjustment",
    //             component: <DrawerAdjustment />,
    //             permission: ['drawer-log-create'],
    //             sidebar: true,
    //             icon: <AccountTreeIcon />,
    //             title: 'title.cashInOut',
    //         },  
    //         {
    //             path: "/daily-settlements",
    //             component: <DailySettlements />,
    //             permission: ['daily-settlement'],
    //             sidebar: true,
    //             icon: <AccountTreeIcon />,
    //             title: 'title.dailyClosing',
    //         },
    //         {
    //             path: "/daily-settlement/:date",
    //             component: <DailySettlementDetails />,
    //             permission: ['daily-settlement'],
    //             sidebar: false,
    //             icon: <AccountTreeIcon />,
    //             title: 'title.dailyClosing',
    //         },
    //         {
    //             path: "/create-daily-settlement",
    //             component: <DailySettlementAdd />,
    //             permission: ['daily-settlement-create'],
    //             sidebar: true,
    //             icon: <AccountTreeIcon />,
    //             title: 'title.dailyClosing',
    //         },
    //     ]
    // },
    //commission
    {
        category: false,
        categoryName: 'commission',
        categoryLabel: 'title.commission',
        categoryIcon: <SettingsIcon />,
        categoryPermission: [],
        categorySidebar: true,
        children: [
            {
                path: "/commissions",
                component: <Commission />,
                permission: ['commission'],
                sidebar: true,
                icon: <GroupIcon />,
                title: 'title.commission',
            },        
        ]
    },
    //credit note
    {
        category: false,
        categoryName: 'credit_note',
        categoryLabel: 'title.creditNote',
        categoryIcon: <SettingsIcon />,
        categoryPermission: [],
        categorySidebar: true,
        children: [
            {
                path: "/credit-notes",
                component: <CreditNote />,
                permission: ['credit-note'],
                sidebar: true,
                icon: <GroupIcon />,
                title: 'title.creditNotes',
            }, 
            {
                path: "/create-credit-note",
                component: <CreditNoteDetail />,
                permission: ['credit-note-create'],
                sidebar: false,
                icon: <GroupIcon />,
                title: 'title.creditNoteAdd',
            },   
            {
                path: "/credit-note/:id",
                component: <CreditNoteDetail />,
                permission: ['credit-note-edit'],
                sidebar: false,
                icon: <GroupIcon />,
                title: 'title.creditNoteEdit',
            },          
        ]
    },

    //expense
    {
        category: true,
        categoryName: 'expense',
        categoryLabel: 'title.expense',
        categoryIcon: <GroupIcon />,
        categoryPermission: [],
        categorySidebar: true,
        children: [
            {
                path: "/expenses",
                component: <Expense />,
                permission: ['expense'],
                sidebar: true,
                icon: null,
                title: 'title.expense',
            },
            {
                path: "/expense-add",
                component: <ExpenseDetail />,
                permission: ['expense-create'],
                sidebar: false,
                icon: null,
                title: 'title.expenseCreate',
            },
            {
                path: "/expense-edit/:id",
                component: <ExpenseDetail />,
                permission: ['expense-edit'],
                sidebar: false,
                icon: null,
                title: 'title.expenseEdit',
            },
        ]
    },
];

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/">
                {APP_NAME}
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

function PrivateRoute({ children, ...rest }) {
    let location = useLocation();
    const { accessToken } = useSelector(state => state.general);
    return (
        accessToken ? (
            children
        ) : (
            <Navigate to="/login" state={{ from: location }} replace />
        )
    );
}


export default function App(props) {
    const { accessToken, permissions } = useSelector(state => state.general);

    const Wrapper = (children, sidebar) => (
        <NotificationLoadingProvider>
            <div>
                {
                    sidebar ?
                        <Sidebar routeProps={routes} content={
                            <>
                            <Container  maxWidth="xl">
                            {children}
                            < Box pt={4}>
                                    <Copyright />
                                </Box>
                            </Container>
                                
                                
                            </>
                        } />
                        :
                        <>
                            {children}
                            < Box pt={4}>
                                <Copyright />
                            </Box>
                        </>
                }
            </div>
            <NotificationLoading />
        </NotificationLoadingProvider>
    );

    return (
        <Fragment>
            <div>
                <Routes>
                    <Route path="/login" element={accessToken ? <Navigate replace to="/dashboard" /> : Wrapper(<Login />, false)} />
                    <Route path="/forget-password" element={Wrapper(<ForgetPassword />, false)} />
                    <Route path="/reset-password" element={Wrapper(<ResetPassword />, false)} />
                    {
                        routes.map(category => {
                            return (
                                _.map(category.children, (route) => {
                                    let fulfilledAllPermission = true;
                                    if (_.size(route.permission) > 0) {
                                        _.map(route.permission, (contentPermission) => {
                                            if (!_.includes(permissions, contentPermission)) {
                                                fulfilledAllPermission = false;
                                            }
                                        })
                                    }
                                    return <Route
                                        key={route.path}
                                        path={route.path}
                                        element={fulfilledAllPermission? <PrivateRoute>
                                            {Wrapper(route.component, true)}
                                        </PrivateRoute>:<Navigate replace to="/dashboard" />} />
                                })
                            )
                        })
                    }
                    <Route path="*" element={accessToken ? <Navigate replace to="/dashboard" /> : <Navigate replace to="/login" />} />
                </Routes>
            </div>
        </Fragment>
    );
};