export const useQuery = () => {
    return new URLSearchParams(window.location.search);
}

export const currencyFormat = amount => {
    if (typeof amount === 'string') {
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
    } else if(typeof amount === 'decimal' || typeof amount === 'number') {
        return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    return amount;
}

export const buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        
        formData.append(parentKey, value);
    }
}