import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink , useParams } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import _ from 'lodash';
// import { ThemeProvider, createTheme } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';



// MUI
import { Link, TextField, Box, Button, Breadcrumbs, Grid, Typography, FormControl, InputLabel, Select, MenuItem, InputAdornment, Chip, FormGroup ,FormControlLabel, Checkbox  } from '@mui/material/';
import { makeStyles, useTheme } from '@mui/styles';

import { putUrl, getUrl } from '../../helpers/ApiAction';

import { FiArrowLeft } from "react-icons/fi";


export default function Edit() {
    const navigate = useNavigate();
    const styles = useStyles();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [state, setState] = useState({
        name: '',
        display_name: '',
        role_level: '',
        type: 'admins',
    });
    const [fieldLang, setFieldLang] = useState({ title: 'en' });
    const [inputErrors, setInputErrors] = useState({});
    const [permissions, setPermissions]=useState({});
    const theme = useTheme();
    let { id } = useParams();


    useEffect(() => {
        getUrl(`/roles/${id}`).then(response => {
            if (response.status) {
                setState(response.data.role);
                setPermissions(response.data.permissions_by_role);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let postPermission = [];
        _.map(permissions, (permission) => {
            if(permission.authorize) {
                postPermission.push(permission.name);
            }
        })
        const updateData = {
            name : state.name,
            display_name : state.display_name,
            type : state.type,
            role_level : state.role_level,
            permissions: postPermission,
        };

        console.log("state",updateData);
        putUrl(`/roles/${id}`, updateData).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const changeAuthorize = id => {
        let newPermissions = permissions;
        _.map(newPermissions, (permission, permissionIndex) => {
            if(permission.id === id) {
                newPermissions[permissionIndex]['authorize'] = newPermissions[permissionIndex]['authorize'] ? false : true;
            }
        })
        console.log("newPermissions", newPermissions);

        setPermissions(newPermissions => ({ ...newPermissions }));
    }

    return (
        <div>
           
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <span onClick={() => navigate(-1)} style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase',
cursor:'pointer' }}>{t('button.back')}</span>
                </div>
          

            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18 }}><b>{t('title.roleEdit')}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to="/roles">
                            {t('title.roles')}
                        </Link>
                        <Typography color="text.primary">{t('title.roleEdit')}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <div style={{ paddingBottom: '5%' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roles.roleName')}
                                variant="outlined"
                                value={state.name}
                                InputLabelProps={{ shrink: true }} 
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roles.displayName')}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        {
                                            _.map(['en', 'cn'], (value) => (
                                                <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, title: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.title === value ? theme.palette.primary.main : '#efefef', color: fieldLang.title === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                            ))
                                        }
                                    </InputAdornment>,
                                }}
                                onChange={({ target }) => setState({ ...state, display_name: { ...state.display_name, [fieldLang.title]: target.value } })}
                                value={state.display_name && state.display_name[fieldLang.title] ? state.display_name[fieldLang.title] : ""}
                                helperText={inputErrors && (inputErrors['display_name'] || inputErrors['display_name.en'] || inputErrors['display_name.cn'])}
                                error={inputErrors && (inputErrors['display_name'] || inputErrors['display_name.en'] || inputErrors['display_name.cn']) ? true : false}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roles.roleLevel')}
                                variant="outlined"
                                value={state.role_level}
                                InputLabelProps={{ shrink: true }} 
                                helperText={inputErrors && inputErrors.role_level ? inputErrors.role_level : ''}
                                error={inputErrors && inputErrors.role_level ? true : false}
                                onChange={({ target }) => setState({ ...state, role_level: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{t('roles.roleType')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.type}
                                    label={t('roles.roleType')}
                                    onChange={({ target }) => setState({ ...state, type: target.value })}
                                >
                                    {
                                        _.map(['admins', 'staffs'], rolesType => {
                                            return <MenuItem key={rolesType} value={rolesType}>{t(`roles.types.${rolesType}`)}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <Grid container>
                                {
                                    _.size(permissions) > 0 ? _.map(permissions, permission => {
                                        return (
                                            <Grid item md={6} key={permission.id}>
                                                {
                                                    permission.parent_id === 0 ?
                                                        <>
                                                            <FormControlLabel
                                                                control={<Checkbox color="primary" checked={permission.authorize} onChange={() => changeAuthorize(permission.id)} />}
                                                                label={permission.name}
                                                            />
                                                            {
                                                                _.map(permissions, secondaryPermission => {
                                                                    if (parseInt(secondaryPermission.parent_id) === permission.id) {
                                                                        return (
                                                                            <Box key={secondaryPermission.id} spacing={2} paddingLeft={5}>
                                                                                <FormControlLabel
                                                                                    control={<Checkbox color="primary" checked={secondaryPermission.authorize} onChange={() => changeAuthorize(secondaryPermission.id)} />}
                                                                                    label={secondaryPermission.name}
                                                                                />
                                                                            </Box>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </>
                                                        : null
                                                }
                                            </Grid>
                                        )
                                    })
                                        : null
                                }
                            </Grid>
                        </FormGroup>
                    </Grid>
                </Grid>

                <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link underline='none' to={`/roles`} component={RouterLink}>
                        <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                    </Link>
                    <Button size="large" variant="contained" className={styles.buttonStyle} onClick={() => submitData()}>{t('button.edit')}</Button>
                </Box>
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle:{
        display:'flex',
        alignItems:'center'
    }
}));


