import React ,{useState , useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import _ from 'lodash';
import NumberFormat from 'react-number-format';

// MUI
import { Typography,Button } from '@mui/material';

export default function TradeInPrint({ customers, listing, label, type }) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const printInfo = () => {
        var content = document.getElementById("ifmcontentstoprint");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    const isMountedRef = useRef(null);
	
	const [state, setState] = useState({
		name: '',
        gender:'-',
		email: '',
		mobile: '',
		ic: '',
		country: '',
		zip: '',
		state: '',
		city: '',
		address: '',
		selectedName: null,
        full_address:''
	});

	useEffect(() => {
		isMountedRef.current = true;
		if(isMountedRef.current){
			if ((listing.customer_id>0 || listing.supplier_id>0) && !_.size(state.selectedName) && _.size(customers)){
				let selectedName = _.find(customers, {
					id: type==='trade-in'? listing.customer_id : listing.supplier_id
				});
				setState({
					...state,
					selectedName: selectedName,
                    gender: selectedName?.gender ?? '-',
					email: selectedName?.email ?? "",
					mobile: selectedName?.mobile ?? "",
					ic: selectedName?.ic ?? "",
					country:_.size(selectedName.addresses) >0?selectedName?.addresses?.[0].country ?? "":'',
					zip: _.size(selectedName.addresses) >0?selectedName?.addresses?.[0].zip ?? "":'',
					state: _.size(selectedName.addresses) >0?selectedName?.addresses?.[0].state ?? "":'',
					city: _.size(selectedName.addresses) >0?selectedName?.addresses?.[0].city ?? "":'',
					address: _.size(selectedName.addresses) >0?selectedName?.addresses?.[0].address1 ?? "":'',
                    full_address:_.size(selectedName.addresses) >0?selectedName?.addresses?.[0].full_address ?? "":'',
				});
			}else if (_.size(state.selectedName)){
				setState({
					...state,
                    gender: state.selectedName.gender,
					email: state.selectedName.email,
					mobile: state.selectedName.mobile ?? "",
					ic: state.selectedName.ic,
					country:  _.size(state.selectedName.addresses) >0 ?state.selectedName.addresses?.[0].country:'',
					zip:  _.size(state.selectedName.addresses) >0 ?state.selectedName.addresses?.[0].zip:'',
					state: _.size(state.selectedName.addresses) >0 ?state.selectedName.addresses?.[0].state:'',
					city: _.size(state.selectedName.addresses) >0 ?state.selectedName.addresses?.[0].city:'',
					address: _.size(state.selectedName.addresses) >0 ?state.selectedName.addresses?.[0].address1:'',
                    full_address: _.size(state.selectedName.addresses) >0 ?state.selectedName.addresses?.[0].full_address:'',
				});
			}else{
				setState({
					...state,
                    gender:'-',
					email: '',
					mobile: '',
					ic: '',
					country: '',
					zip: '',
					state: '',
					city: '',
					address: '',
				});
			}
		}
		return () => { isMountedRef.current = false };
		// eslint-disable-next-line
	}, [listing.customer_id, listing.supplier_id, customers, state.selectedName]);

    return (
        <div>
            {/* <div style={{ padding: '8px 15px', minWidth: 130, maxWidth: '100%', borderRadius: 8, background: theme.palette.secondary.main, boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center', cursor: 'pointer' }} onClick={printInfo}>
                <Typography style={{ color: '#fff', fontSize: 15, textAlign: 'center', textTransform: 'uppercase' }}>{label}</Typography>
            </div> */}
            <Button size="large" variant="contained"  color="secondary" style={{ marginRight: 15}} onClick={printInfo}>{label}</Button>
            <iframe id="ifmcontentstoprint" style={{ height: 0, width: 0, position: "absolute", display: "none" }} title="order-detail">
                {
                    _.size(state) > 0 ?
                        <div style={{ margin: 20 }}>
                            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <h1>{t('product.tradeInAgreement')}</h1>
                            </div>
                            {/* ACKNOWLEDGEMENT */}
                            <div style={{ lineHeight: 1 }}>
                                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <u><b>PERAKUAN</b></u>
                                </div>
                                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <div style={{ fontSize:12, lineHeight:1.6, wordBreak:'break-word', textAlign:'justify', textJustify:'inter-word'}}>Saya dengan ini memperakui bahawa saya adalah pemilik yang sebenarnya bagi barang-barang yang tersebut di bawah dan saya ingin menawarkannya untuk jualan kepada tuan. Jika terdapat sebarang keraguan mengenai pemilikan barang tersebut, saya akan bertanggungjawab sepenuhnya terhadap masalah dialami oleh tuan. Saya juga mengaku janji akan membayar gantirugi kepada tuan ke atas sebarang aduan dan kerugian berkenaan dengan barang-barang tersebut. Saya mengesahkan bahawa barang-barang tersebut bukan barang-barang yang repika ataupun barang-barang kecurian.</div>
                                </div>
                                <br/>
                                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <u><b>ACKNOWLEDGEMENT</b></u>
                                </div>
                                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <div style={{ fontSize:12, lineHeight:1.6, wordBreak:'break-word', textAlign:'justify', textJustify:'inter-word'}}>I hereby acknowledge that I am the true owner of the following items, and I intend to sell them to your company. If there is any doubt about the ownership of the items, I promise to be fully responsible for the company's problems. All complaints and losses will be compensated to your company. The following objects are not thieves and do not belong to replicas.</div>
                                </div>
                                <br/>
                                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <u><b>承认</b></u>
                                </div>
                                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <div style={{ fontSize:12, lineHeight:1.6, wordBreak:'break-word', textAlign:'justify', textJustify:'inter-word'}}>本人在此承认本人乃是以下物品的真正物主，本人有意出售给贵公司。如对以下物品的所有权有任何疑问，本人承诺将会对贵公司的问题负全部的责任，以及将会赔偿所有的损失。以下的物品，不是贼赃恕不属于复制品。</div>
                                    
                                </div>
                            </div>
                            <hr style={{ borderTop: 'dotted 1px' }} />
                            {/*CUSTOMER INFO */}
                            <div>
                                <div style={{ fontSize: 15, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <b>{type==='trade-in'? t('product.customerInfo') : t('product.supplierInfo')}</b>
                                </div>
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div style={{ lineHeight: 1 }}>{t('user.name')} : {state.selectedName? state.selectedName.name :"-"}</div>
                                </div>
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div style={{ lineHeight: 1 }}>{t('user.gender.title')} : {state.gender ? t('user.gender.' + state.gender) : "-"}</div>
                                </div>
                                <section style={{ display: 'grid' }}>
                                    <div style={{ gridColumn: 1 }}>
                                        <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                            <div>{t('user.nric')} : {state.ic ? state.ic : "-"}</div>
                                        </div>
                                    </div>
                                    <div style={{ gridColumn: 2 }}>
                                        <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                            <div>{t('user.mobile')} : {state.mobile ? state.mobile : "-"}</div>
                                        </div>
                                    </div>
                                </section>
                                <section style={{ display: 'grid' }}>
                                    <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                        <div>{t('user.email')} : {state.email ? state.email : "-"}</div>
                                    </div>
                                    <div style={{ gridColumn: 2 }}>
                                        <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                            <div>{t('product.date')} : {listing.created_at ? listing.created_at : "-"}</div>
                                        </div>
                                    </div>
                                </section>
                                {/* <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div>{t('user.nric')} : {state.ic? state.ic :"-"}</div>
                                </div>
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div>{t('user.mobile')} : {state.mobile? state.mobile :"-"}</div>
                                </div> */}
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div style={{ lineHeight:'normal' }}>{t('user.address')} : {state.full_address? state.full_address:'-'}</div>
                                </div>
                                {/* <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div>{t('product.date')} : {listing.created_at? listing.created_at :"-"}</div>
                                </div> */}
                            </div>
                            <hr style={{ borderTop: 'dotted 1px' }} />
                             {/*PRODUCT INFO */}
                             <div>
                                <div style={{ fontSize: 15, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <b>{t('product.productInfo')}</b>
                                </div>
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div>{t('product.name')} : {listing.name? listing.name :"-"}</div>
                                </div>
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div>{t('product.brand')} : {listing.selectedBrand? listing.selectedBrand.name :"-"}</div>
                                </div>
                                <section style={{ display: 'grid' }}>
                                    <div style={{ gridColumn: 1 }}>
                                        <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                            <div>{t('product.model')} : {listing.model? listing.model :"-"}</div>
                                        </div>
                                    </div>
                                    <div style={{ gridColumn: 2 }}>
                                        <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                            <div>{t('product.serialNo')} : {listing.serial_no? listing.serial_no :"-"}</div>
                                        </div>
                                    </div>
                                </section>
                                <section style={{ display: 'grid' }}>
                                    <div style={{ gridColumn: 1 }}>
                                        <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                            <div>{t('product.refNo')} : {listing.ref_no? listing.ref_no :"-"}</div>
                                        </div>
                                    </div>
                                    <div style={{ gridColumn: 2 }}>
                                        <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                            <div>{t('product.diameterSize')} : {listing.diameter? listing.diameter :"-"}</div>
                                        </div>
                                    </div>
                                </section>
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div>{t('product.whatsInBox')} : {listing.whats_in_box? listing.whats_in_box :"-"}</div>
                                    <br/>
                                    <br/>
                                </div>
                                <div style={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                    <div style={{ lineHeight:'normal' }}>{t('product.description')} : {listing.description? listing.description :null}</div>
                                </div>
                                <br/>
                                <hr style={{ borderTop: 'solid 1px' }} />
                                <br/>
                                <hr style={{ borderTop: 'solid 1px' }} />
                            </div>
                            {/* <hr style={{ borderTop: 'dotted 1px' }} /> */}
                            {/* FOOTER INFO */}
                            <div style={{ lineHeight: '20%' }}>
                                <div style={{ justifyContent: 'left', alignItems: 'center' }}>
                                    <h4>Saya dengan ini memperakui bahawa segala kenyataan di atas benar.</h4>
                                    <h4>I hereby acknowledge making the above statement is true.</h4>
                                    <h4>本人在此承认作出以上真实的陈述。</h4>
                                </div>
                                <br/>
                                <section style={{ lineHeight:2, display: 'grid' }}>
                                    <div style={{ gridColumn: 1 }}>
                                        <div>
                                            Tandatangan atau cap jari kanan penjual
                                        </div>
                                    </div>
                                    <div style={{ gridColumn: 2 }}>
                                        <div style={{ float: 'right', textAlign: 'right' }}>
                                            Saya akui menerima harga tawaran ini: -
                                        </div>
                                    </div>
                                </section>
                                <br/><br/>
                                <section style={{ lineHeight:2, display: 'grid' }}>
                                    <div style={{ gridColumn: 1 }}>
                                        <div>
                                            签名 : ...................................
                                        </div>
                                    </div>
                                    <div style={{ gridColumn: 2 }}>
                                        <div style={{ float: 'right', textAlign: 'right' }}>
                                            估价 : RM {listing.trade_price? <NumberFormat thousandSeparator={true}  value={listing.trade_price} displayType="text" /> :'_____________'}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        :
                        <div style={{ margin: 20 }}>
                            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <h4>{t('emptyStatus.noProduct')}</h4>
                            </div>
                        </div>
                }
            </iframe>
        </div>
    )
}