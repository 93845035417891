import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';

// MUI
import { Grid, Typography, Link, Hidden, OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { makeStyles, useTheme } from '@mui/styles';
import { FiArrowLeft } from "react-icons/fi";

import { getUrl, postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';

export default function CreateWarrantyReport() {
    const [data, setData] = useState(null);
    const [state, setState] = useState({ description: "" });
    const [inputErrors, setInputErrors] = useState({
        order_detail_id: null,
        description: null,
    })

    let { id } = useParams();
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    let navigate = useNavigate();

    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`order-details/${id}`).then(response => {
            setLoading(false);
            if(response.status && isMountedRef.current) {
                setData(response.data);
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [id]);
    
    const handleInputChange = ({ target }) => {
        setState({ ...state, [target.name]: target.value });
    }

    const createWarranty = () => {
        if(id) {
            setLoading(true);
            postUrl(`services`, { order_detail_id: id, description: state.description }).then(response => {
                setLoading(false);
                if(response.status) {
                    addAlert('', t('success.createSuccess'), 'success', '');
                    if(response.data && _.has(response.data, 'id')) {
                        navigate(`/warranty-report/${response.data.id}`)
                    }
                } else {
                    const errorMessage = response.message ? response.message : t('error.contactSupport');
                    addAlert('', errorMessage, 'error', '');
                    if(response.errors) {
                        setInputErrors(response.errors);
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            })
        }
    }

    return(
        <div>
            <Link underline='none' component={RouterLink} to={`/warranty`}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.backTo')}{t('title.warranty')}</Typography>
                </div>
            </Link>
            
            <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{paddingBottom:20, borderBottom:'1px dashed #777'}}>
                <div>
                <Typography style={{fontSize:18, fontWeight: 'bold'}}>{`${t('warranty.orderNo')} ${data ? data.order.order_no : '-'}`}</Typography>
                <Hidden smUp><Typography>{`${t('textField.date')}: ${data ? data.created_at : '-'}`}</Typography></Hidden>
                </div>
                <Hidden only={'xs'}>
                    <Typography>{`${t('textField.date')}: ${data ? data.created_at : '-'}`}</Typography>
                </Hidden>
            </div>

            <div style={{padding:'20px 0'}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Typography style={{ fontSize: 28, fontWeight: 'bold' }}>{ data ? data.order.customer.name : '-' }</Typography>
                        <Typography>{ data ? data.order.customer.mobile : '-' }<span style={{ margin: '0 15px' }}>|</span> { data ? data.order.customer.email : '-' }</Typography>
                    </Grid>
                </Grid>
            </div>

            <div className={styles.flexMiddle}>
                <div>
                    <img src={ data ? (_.size(data.product.images) ? data.product.images[0]['file_name'] : "/images/emptyStatus/noproductimg.jpg") : null } style={{ width: 120, borderRadius: 8 }} alt={ data ? data.product.name : '-' } />
                </div>
                <div style={{color:theme.palette.gray.dtext, padding:'0 20px'}}>
                    <Typography className={styles.productTitle}>{ data ? data.product.name : '-' }</Typography>
                    <Typography className={styles.productContentText}>{`${t('warranty.serialNo')}: ${data ? data.product.serial_no : '-'}`}</Typography>
                    <Hidden only={'xs'}>
                        <Typography className={styles.productContentText} style={{ paddingTop: 15 }}>{`${t('warranty.dateOfPurchase')}: ${data ? data.created_at : '-'}`}</Typography>
                        <Typography className={styles.productContentText} style={{ paddingTop: 15 }}>{`${t('warranty.endDate')}: ${data ? data.warranty_end_date : '-'}`}</Typography>
                    </Hidden>
                    <Hidden smUp>
                        <Typography className={styles.productContentText} style={{ paddingTop: 15 }}>{`${t('warranty.dateOfPurchase')}: ${data ? data.created_at : '-'}`}</Typography>
                        <Typography className={styles.productContentText} style={{ paddingTop: 15 }}>{`${t('warranty.endDate')}: ${data ? data.warranty_end_date : '-'}`}</Typography>
                    </Hidden>
                </div>
            </div>

            <div>
                <Typography variant="caption" style={{ color: 'red' }}>{inputErrors.order_detail_id}</Typography>
            </div>

            <div style={{padding:'25px 0 20px', borderBottom:'1px dashed #777'}}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 8 }}>
                    <Typography style={{ color: theme.palette.gray.ltext, fontWeight: 'bold' }}>{ t('warranty.description') }</Typography>
                </div>
                <FormControl style={{ width: '100%', maxWidth: '100%' }}>
                    <OutlinedInput 
                        name="description" 
                        value={state.description} 
                        onChange={handleInputChange} 
                        error={inputErrors.description}
                    />
                </FormControl>
            </div>
            
            <div className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} style={{ paddingTop: 25 }} >
                <Link underline='none' component={RouterLink} to={`/warranty`}>
                    <div style={{ padding: '8px 15px', width: 180, marginRight: 15, maxWidth: '100%', borderRadius: 48, border: '2px solid #cdac89', boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center' }}>
                    <Typography style={{ color: theme.palette.secondary.main, fontSize: 18, textAlign: 'center', textTransform: 'uppercase' }}>{t('button.cancel')}</Typography>
                </div>
                </Link>
                <div onClick={createWarranty} style={{ padding: '8px 15px', width: 180, maxWidth: '100%', borderRadius: 48, background: theme.palette.secondary.main, boxShadow: '2px 2px 20px 0 #efefef', textAlign: 'center' }}>
                    <Typography style={{ color: '#fff', fontSize: 18, textAlign: 'center', textTransform: 'uppercase', cursor: 'pointer' }}>{t('warranty.createWarranty')}</Typography>
                </div>
            </div>
        </div>
        
    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle:{
        display:'flex',
        alignItems:'center',
    },
    spaceBetween:{
        justifyContent:'space-between',
    },
    justifyCenter:{
        justifyContent:'center'
    },
    directionColumn:{
        flexDirection:'column'
    },
    textLine2: {
        display: '-webkit-box',
        maxWidth: '100%',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    },
    textLine1: {
        
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'noWrap'
    },
    productTitle:{
        lineHeight:1.2,
        paddingBottom:8,
        fontWeight: 'bold!important',
        [theme.breakpoints.down('sm')]: {
            fontSize:'16px!important'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize:'24px!important',
        },
    },
    productContentText:{
        [theme.breakpoints.down('sm')]: {
            fontSize:'14px!important'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize:'16px!important',
        },
    }
}));