import React, { memo, useEffect, useState } from 'react'
// import { useTranslation } from 'react-i18next';
import { addDays } from 'date-fns';
import _ from 'lodash';

import { /* VictoryTheme, VictoryLabel, VictoryBrushContainer, */ VictoryChart, VictoryLine, VictoryAxis, VictoryZoomContainer,  } from 'victory';

const SalesGraph = memo(({ data }) => {
    const [zoomDomain, setZoomDomain] = useState({ 
        x: [new Date(2022, 1, 1), new Date(2023, 1, 1)]
    });
    const [formattedData, setFormattedData] = useState([]);

    useEffect(() => {
        let formattedData = [];

        _.map(data, (dataItem, dataKey) => {
            const { sales_date, total_quantity, total_sales } = dataItem;
            let salesDate = new Date(sales_date);
            formattedData.push({ a: salesDate, b: parseFloat(total_sales), c: parseInt(total_quantity) });
        });
        
        if(_.size(data) === 1) {
            // if today equal to first day, add 1 day to show diff
            if(formattedData[0].a === new Date()) {
                formattedData.push({ a: addDays(new Date(), 1), b: 0, c: 0 });
            } else {
                formattedData.push({ a: new Date(), b: 0, c: 0 });
            }
        }

        const firstItem = _.first(data);
        let newZoomDomain = [
            firstItem ? new Date(firstItem.sales_date) : new Date(),
            new Date()
        ];

        setFormattedData(formattedData);
        setZoomDomain({ x: newZoomDomain });

    }, [data]);

    // const { t } = useTranslation();

    const handleZoom = domain => {
        setZoomDomain(domain);
    }

    return (
        <>
            <svg style={{ height: 0 }}>
                <defs>
                    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stopColor="#68421d" />
                        <stop offset="42%" stopColor="#d59647" />
                        <stop offset="66%" stopColor="#e8c190" />
                        <stop offset="72%" stopColor="#e0b580" />
                        <stop offset="91%" stopColor="#d59647" />
                        <stop offset="100%" stopColor="#68421d" />
                    </linearGradient>
                </defs>
            </svg>
            <VictoryChart
                // theme={VictoryTheme.material}
                // domainPadding={{ x: 15 }}
                // animate={{
                //     duration: 1000,
                //     onLoad: { duration: 1000 }
                // }}
                // height={250}
                width={1000} 
                height={250} 
                scale={{ x: "time" }}
                containerComponent={
                    <VictoryZoomContainer
                        zoomDimension="x"
                        zoomDomain={zoomDomain}
                        onZoomDomainChange={handleZoom}
                    />
                }
            >
                {/* <VictoryLabel text={ t('home.walletWeeklyBalance') } x={170} y={10} style={{ fontSize: 8 }} textAnchor="middle"/> */}
                <VictoryAxis fixLabelOverlap style={{ tickLabels: { fontSize: 9 } }} />
                <VictoryAxis dependentAxis style={{ tickLabels: { fontSize: 9 } }} />
                <VictoryLine
                    // barRatio={0.8}
                    // barWidth={10}
                    style={{
                        data: { stroke:"url(#linear)", strokeWidth:8, strokeLinecap:'round' },
                        labels: {
                            fontSize: 9,
                        },
                    }}
                    
                    data={formattedData}
                    x="a"
                    y="b"
                    labels={({ datum }) => datum.y_display }
                />
                
            </VictoryChart>
            {/* <VictoryChart
                padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
                width={600} 
                height={80} 
                scale={{ x: "time" }}
                containerComponent={
                    <VictoryBrushContainer
                        brushDimension="x"
                        brushDomain={zoomDomain}
                        onBrushDomainChange={handleZoom}
                    />
                }
            >
                <VictoryAxis tickFormat={(x) => new Date(x).getFullYear()} />
                <VictoryLine
                    style={{ data: { stroke: "#e2b478" } }}
                    data={formattedData}
                    x="a"
                    y="b"
                />
            </VictoryChart> */}
        </>
    )
})

export default SalesGraph