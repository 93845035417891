import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl, putUrl } from '../../helpers/ApiAction';
import _ from 'lodash';

// MUI
import { Box, Typography, Breadcrumbs, Link, LinearProgress, Modal, Button } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarColumnsButton, GridActionsCellItem } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';

import { FiArrowLeft } from "react-icons/fi";
import { MdCancel, MdCheckCircle } from "react-icons/md";

function CustomToolbar() {
    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer} style={{ paddingBottom: 10 }}>
            <GridToolbarExport style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} csvOptions={{ allColumns: true, fileName: `Customers_${new Date().toISOString().slice(0, 10)}` }} printOptions={{ disableToolbarButton: true }} />
            <GridToolbarDensitySelector style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
            <GridToolbarFilterButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
            <GridToolbarColumnsButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
        </GridToolbarContainer>
    );
}

// MODAL
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '80%',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
};

export default function CartList() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    // table
    const [pageSize, setPageSize] = useState(5);
    const [tableLoading, setTableLoading] = useState(false);
    const [state, setState] = useState([]);
    const { addAlert } = useNotificationLoading();
    const { data } = useDemoData({
        dataSet: 'cart',
        rowLength: 5,
        maxColumns: 10,
    });
    const [modal, setModal] = useState({
        open: false,
        id: 0,
        type: ''
    });

    useEffect(() => {
        setTableLoading(true);
        getUrl('/carts/list').then(response => {
            setTableLoading(false);
            if (response.status) {
                setState(response.data.listing);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const handleModalOpen = (id, type) => {
        setModal({
            open: true,
            id: id,
            type: type
        });
    }
    const handleModalClose = () => {
        setModal({
            open: false,
            id: 0,
            type: ''
        });
    }

    const approveRejectCartItem = () => {
        setTableLoading(true);
        setModal({ ...modal, open: false });
        putUrl(`/carts/${modal.id}/${modal.type}`).then(response => {
            setTableLoading(false);
            if (response.status) {
                addAlert("", response.message, 'success', '');
                // remove cart item
                let temp = state;
                temp = _.filter(temp, (cartItem) => { return cartItem.id !== modal.id; });
                setState(temp);
                handleModalClose();
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
                handleModalClose();
            }
        }).catch(error => {
            setTableLoading(false);
            handleModalClose();
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    //table
    const columns = useMemo(() => [
        {
            field: 'userName',
            headerName: `${t('cart.userName')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return params.row.user.name ? params.row.user.name : '-';
            }
        },
        {
            field: 'productName',
            headerName: `${t('cart.productName')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return params.row.product.name ? params.row.product.name : '-';
            }
        },
        {
            field: 'productOriSellPrice',
            headerName: `${t('cart.oriSellPrice')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return params.row.product.sell_price ? params.row.product.sell_price : '-';
            }
        },
        {
            field: 'productMinSellPrice',
            headerName: `${t('cart.minSellPrice')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return params.row.product.min_sell_price ? params.row.product.min_sell_price : '-';
            }
        },
        {
            field: 'sell_price',
            headerName: `${t('cart.sellPrice')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => { return <div key={params.value}>{params.value ? params.value : "-"}</div>; }
        },
        {
            field: 'created_at',
            headerName: `${t('cart.createdAt')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: `${t('table.actions')}`,
            width: 100,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<MdCheckCircle size={25} />}
                    label={t('cart.approve')}
                    onClick={() => handleModalOpen(params.id, 'approve')}
                />,
                <GridActionsCellItem
                    icon={<MdCancel size={25} />}
                    label={t('cart.reject')}
                    onClick={() => handleModalOpen(params.id, 'reject')}
                />,
            ],
        },
    ],
        [handleModalOpen, t],
    );

    return (
        <div style={{ padding: '24px 0' }}>
            {/* <Link underline='none' component={RouterLink} to={`/dashboard`}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.backTo')}{t('title.dashboard')}</Typography>
                </div>
            </Link> */}
            <div style={{ paddingBottom: 5 }}>
                <Box className={styles.flexMiddle} style={{ paddingBottom: 5, justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t('cart.list')}</b></Typography>
                    </div>
                    {/* <div>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" href="/dashboard">
                                {t('title.dashboard')}
                            </Link>
                            <Typography color="text.primary">{t('title.carts')}</Typography>
                        </Breadcrumbs>
                    </div> */}
                </Box>
            </div>
            {/* TABLE */}
            <div style={{ height: 450, width: '100%', overflowX: 'scroll', marginTop: 20, boxShadow: '0 0 20px 0 #dadada', borderRadius: '15px' }}>
                <div style={{ display: 'flex', height: '100%', padding: 0 }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            {...data}
                            rows={state}
                            columns={columns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10, 20]}
                            components={{
                                Toolbar: CustomToolbar,
                                LoadingOverlay: LinearProgress,
                            }}
                            loading={tableLoading ? true : false}
                            disableSelectionOnClick
                            sx={{
                                marginTop: 0,
                                borderRadius: '15px',
                                paddingTop: '15px',
                                border: 'none',
                                '& .super-app-theme--header': {
                                    // backgroundColor: theme.palette.primary.main,
                                    backgroundColor: theme.palette.gray.tableHeader,
                                    color: theme.palette.gray.tableHeaderText,
                                },
                                backgroundColor: 'white',
                                '& 	.MuiDataGrid-menuIconButton': {
                                    color: theme.palette.gray.tableHeaderText,
                                },
                                '& 	.MuiDataGrid-sortIcon': {
                                    color: theme.palette.gray.tableHeaderText,
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* MODAL */}
            <Modal
                open={modal.open}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                        {t('cart.' + modal.type)}
                    </Typography>
                    <Typography >{t('cart.modalNote', { action: t('cart.' + modal.type) })}</Typography>
                    <div style={{ paddingTop: 30, justifyContent: 'space-between' }} >
                        <Button onClick={handleModalClose} style={{ backgroundColor: '#fff', width: '40%', padding: '8px 15px', borderRadius: 5, boxShadow: '2px 2px 6px 0 #8f72547a', margin: 5 }}>
                            <Typography style={{ color: theme.palette.secondary.main }}>{t('button.close')}</Typography>
                        </Button>
                        <Button onClick={approveRejectCartItem} style={{ backgroundColor: theme.palette.secondary.main, width: '40%', padding: '8px 15px', borderRadius: 5, boxShadow: '2px 2px 6px 0 #8f72547a', margin: 5 }}>
                            <Typography style={{ color: '#fff' }}>{t('cart.' + modal.type)}</Typography>
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    justifyCenter: {
        justifyContent: 'center'
    },
}));