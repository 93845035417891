import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl } from '../../helpers/ApiAction';
import theme from '../../Theme';
import { useSelector } from 'react-redux';

// MUI
import { makeStyles } from '@mui/styles';
import { Grid, Typography, LinearProgress, Link, Avatar, Box, Button, Paper,Breadcrumbs } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarColumnsButton, GridActionsCellItem } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { FiUser, FiPhone, FiMapPin, FiArrowLeft } from "react-icons/fi";
import { MdVisibility } from "react-icons/md";


function CustomToolbar() {
    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer} style={{ paddingBottom: 10 }}>
            <GridToolbarExport style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} csvOptions={{ allColumns: true, fileName: `Products_${new Date().toISOString().slice(0, 10)}` }} printOptions={{ disableToolbarButton: true }} />
            <GridToolbarDensitySelector style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
            <GridToolbarFilterButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
            <GridToolbarColumnsButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
        </GridToolbarContainer>
    );
}

export default function SupplierProfile() {
    const styles = useStyles();
    const { t } = useTranslation();
    const [state, setState] = useState({});
    const [product, setProduct] = useState([]);
    const { addAlert, setLoading } = useNotificationLoading();
    let { id } = useParams();
    const isMountedRef = useRef(null);
    const { permissions } = useSelector(state => state.general);

    const [tableLoading, setTableLoading] = useState(false);
    const [pageSize, setPageSize] = useState(5);
    const { data } = useDemoData({
        dataSet: 'product',
        rowLength: 5,
        maxColumns: 10,
    });

    let navigate = useNavigate();

    const editDetails = React.useCallback(
        (id,type) => () => {
            console.log("type",type)
            navigate(`/${type === "consignment"  ? "consignment-edit" :"product-edit" }/${id}`);
        },
        // eslint-disable-next-line
        [],
    );

    const columns = useMemo(() => [
        {
            field: 'name',
            headerName: `${t('product.name')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'brand',
            headerName: `${t('product.brand')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({value}) => value?.name
        },
        {
            field: 'serial_no',
            headerName: `${t('product.serialNo')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'cost_price',
            headerName: `${t('product.costPrice1')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueFormatter: ({value}) =>  parseFloat(value).toFixed(2)
        },
        {
            field: 'cost_price2',
            headerName: `${t('product.costPrice2')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueFormatter: ({value}) =>  parseFloat(value).toFixed(2)
        },
        {
            field: 'created_at',
            headerName: `${t('customer.createdAt')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: `${t('table.actions')}`,
            width: 70,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<MdVisibility size={25} />}
                    label={t('button.view')}
                    onClick={editDetails(params.id, params.row.type)}
                />
            ],
        },
    ],
        [editDetails, t],
    );

    useEffect(() => {
        setLoading(true);
        setTableLoading(true);
        isMountedRef.current = true;

        getUrl(`/suppliers/${id}`).then(response => {
            setLoading(false);
            if (isMountedRef.current) {
                if (response.status) {
                    setState(response.data);
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });


        getUrl(`/supplier-products/${id}`).then(response => {
            setTableLoading(false);
            if (isMountedRef.current) {
                if (response.status) {
                    setProduct(response.data.listing);
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert])

    return (
        <div>
            <div style={{ paddingBottom: '5%' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Link underline='none' to={`/suppliers`} component={RouterLink}>
                        <div className={styles.flexMiddle}>
                            <FiArrowLeft style={{ marginRight: 15 }} />
                            <Typography className={styles.subtitle}>{t('supplier.supplierList')}</Typography>
                        </div>
                    </Link>
                    <div>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                                {t('title.dashboard')}
                            </Link>
                            <Link underline="hover" color="inherit" component={RouterLink} to="/suppliers">
                                {t('title.suppliers')}
                            </Link>
                            <Typography color="text.primary">{t('title.supplierProfile')}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
                <Grid container spacing={3} style={{ padding: '3% 0 5% 0' }}>
                    <Grid item xs={12} sm={4} className={styles.flexCenterMiddle} >
                        <Avatar className={styles.avatarStyle}>
                            <Typography fontSize={{ xs: 33, sm: '4rem' }} style={{textTransform:'uppercase'}} >{state && state.name ? (state.name[0] + state.name[1]) : "-"}</Typography>
                        </Avatar>
                    </Grid>
                    <Grid item xs={12} sm={4} className={styles.flexCenterMiddle}>
                        <div style={{width:'100%'}}>
                            <Box style={{ paddingBottom: 15 }} className={styles.flexTtopMiddle} >
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={2}>
                                        <FiUser className={styles.icon} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <div style={{ paddingLeft: 13 }}>
                                            <Typography className={styles.titleText}><b>{state && state.name ? state.name : "-"}</b></Typography>
                                            <Typography className={styles.contentText}>{state && state.ic ? state.ic : "-"}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={styles.flexTtopMiddle}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={2}>
                                        <FiPhone className={styles.icon} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <div style={{ paddingLeft: 13 }}>
                                            <Typography className={styles.titleText}><b>{state && state.mobile ? state.mobile : "-"}</b></Typography>
                                            <Typography className={styles.contentText}>{state && state.email ? state.email : "-"}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} className={styles.flexCenterMiddle}>
                    <div style={{width:'100%'}}>
                            <Box style={{ paddingBottom: 15 }} className={styles.flexTtopMiddle} >
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={2}>
                                        <FiMapPin className={styles.icon} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <div style={{ paddingLeft: 13 }}>
                                            <Typography className={styles.contentText}>{state && state.addresses ? state.addresses[0]['full_address'] : "-"}</Typography>
                                        </div>
                                        { 
                                            permissions.includes('supplier-edit') &&
                                            <Link underline='none' to={`/supplier-edit/${id}`} component={RouterLink}>
                                                <Button size="large" variant="contained" style={{ width: '100%', maxWidth: 230, marginTop:15, borderRadius: '30px 0', backgroundColor: theme.palette.button.main }}>
                                                    {t('button.editProfile')}
                                                </Button>
                                            </Link>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{ height: 450, width: '100%', overflowX: 'scroll', marginTop: 20, boxShadow: '0 0 20px 0 #dadada', borderRadius: '15px' }}>
                <div style={{ display: 'flex', height: '100%', padding: 0 }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                {...data}
                                rows={product}
                                columns={columns}
                                columnVisibilityModel={{
                                    actions: permissions.includes('product-edit'),
                                }}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowsPerPageOptions={[5, 10, 20]}
                                components={{
                                    Toolbar: CustomToolbar,
                                    LoadingOverlay: LinearProgress,
                                }}
                                loading={tableLoading ? true : false}
                                sx={{
                                    marginTop: 0,
                                    borderRadius: '15px',
                                    paddingTop: '15px',
                                    border: 'none',
                                    '& .super-app-theme--header': {
                                        backgroundColor: theme.palette.gray.tableHeader,
                                        color: theme.palette.gray.tableHeaderText,
                                    },
                                    backgroundColor: 'white',
                                    '& 	.MuiDataGrid-menuIconButton': {
                                        color: theme.palette.gray.tableHeaderText,
                                    },
                                    '& 	.MuiDataGrid-sortIcon': {
                                        color: theme.palette.gray.tableHeaderText,
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Paper>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    icon: {
        fontSize: 27,
        marginTop: 2,
        color: theme.palette.gray.ltext
    },
    titleText: {
        fontSize: '24px!important',
        color: theme.palette.gray.main,
    },
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    contentText: {
        fontSize: '18px!important',
        color: theme.palette.gray.main,
    },
    flexCenterMiddle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    flexTtopMiddle: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    avatarStyle:{
        padding: 10,
       [theme.breakpoints.down('sm')]: {
           width: 80,
           height: 80,
       },
       [theme.breakpoints.up('sm')]: {
           width: 140,
           height: 140,
       },
   },
}));
