import FormControl from "@mui/material/FormControl";
import { makeStyles, useTheme } from "@mui/styles";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { getUrl } from "../../helpers/ApiAction";
import useNotificationLoading from "../../helpers/useNotificationLoading";

// MUI
import {
    Breadcrumbs, Grid, LinearProgress, Link,
    OutlinedInput, Typography
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    DataGrid, GridActionsCellItem, gridClasses, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";

import { FiArrowLeft } from "react-icons/fi";
import { MdVisibility } from "react-icons/md";

function CustomToolbar() {
	return (
		<GridToolbarContainer
			className={gridClasses.toolbarContainer}
			style={{ paddingBottom: 10 }}
		>
			<GridToolbarExport
				style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
				csvOptions={{
					allColumns: true,
					fileName: `Customers_${new Date()
						.toISOString()
						.slice(0, 10)}`,
				}}
				printOptions={{ disableToolbarButton: true }}
			/>
			<GridToolbarDensitySelector
				style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
			/>
			<GridToolbarFilterButton
				style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
			/>
			<GridToolbarColumnsButton
				style={{ color: "#CDAC89", width: 150, maxWidth: "100%" }}
			/>
		</GridToolbarContainer>
	);
}

export default function Dashboard() {
	const styles = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();

	const [pageSize, setPageSize] = useState(5);
	const [tableLoading, setTableLoading] = useState(false);
	const [state, setState] = useState([]);
	const [keyword, setKeyword] = useState({});
	const { addAlert } = useNotificationLoading();
	const { data } = useDemoData({
		dataSet: "customer",
		rowLength: 5,
		maxColumns: 10,
	});

	const { permissions } = useSelector((state) => state.general);

	const viewCustomer = React.useCallback(
		(id) => () => {
			navigate(`/customer-profile/${id}`);
		},
		// eslint-disable-next-line
		[]
	);

	//table
	const columns = useMemo(
		() => [
			{
				field: "name",
				headerName: `${t("user.name")}`,
				flex: 2,
				align: "left",
				headerAlign: "left",
				headerClassName: "super-app-theme--header",
				renderCell: (params) => {
					return (
						<div key={params}>
							{params.value ? (
								<Link
									underline="none"
									to={`/customer-profile/${params.row.id}`}
									component={RouterLink}
								>
									{params.value}
								</Link>
							) : (
								"-"
							)}
						</div>
					);
				},
			},
			{
				field: "gender",
				headerName: `${t("user.gender.title")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
				renderCell: (params) => {
					return (
						<div key={params}>
							{params.value != null
								? t("user.gender." + params.value)
								: "-"}
						</div>
					);
				},
			},
			{
				field: "ic",
				headerName: `${t("user.nric")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
			},
			{
				field: "email",
				headerName: `${t("user.email")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
			},
			{
				field: "mobile",
				headerName: `${t("user.mobile")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
			},
			{
				field: "mobile",
				headerName: `${t("user.mobile")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
			},
			{
				field: "status",
				headerName: `${t("customer.status.title")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
				renderCell: (params) => {
					return (
						<div key={params}>
							{params.value != null
								? t("customer.status." + params.value)
								: "-"}
						</div>
					);
				},
			},
			{
				field: "created_at",
				headerName: `${t("customer.createdAt")}`,
				flex: 1,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
			},
			{
				field: "actions",
				type: "actions",
				headerName: `${t("table.actions")}`,
				width: 70,
				align: "center",
				headerAlign: "center",
				headerClassName: "super-app-theme--header",
				getActions: (params) => [
					<GridActionsCellItem
						icon={
							<MdVisibility
								style={{ color: theme.palette.button.main }}
								size={25}
							/>
						}
						label={t("button.view")}
						onClick={viewCustomer(params.id)}
					/>,
				],
			},
		],
		// eslint-disable-next-line
		[viewCustomer, t]
	);

	useEffect(() => {
		setTableLoading(true);
		getUrl("/customers")
			.then((response) => {
				setTableLoading(false);
				if (response.status) {
					setState(response.data.listing);
				} else {
					addAlert("", t("error.contactSupport"), "error", "");
				}
			})
			.catch((error) => {
				setTableLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});
		// eslint-disable-next-line
	}, []);

	const searchData = () => {
		let param = {};
		if (keyword.length > 0) {
			param = {
				q: keyword,
			};
		}
		getUrl("/customers", param)
			.then((response) => {
				setTableLoading(false);
				if (response.status) {
					setState(response.data.listing);
				} else {
					addAlert("", t("error.contactSupport"), "error", "");
				}
			})
			.catch((error) => {
				setTableLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});
	};

	return (
		<div>
			<div
				className={styles.flexMiddle}
				style={{ justifyContent: "space-between", paddingBottom: 30 }}
			>
				<div
					className={styles.flexMiddle}
					style={{ color: theme.palette.gray.main }}
				>
					<FiArrowLeft style={{ fontSize: 16, marginRight: 10 }} />
					<span
						onClick={() => navigate(-1)}
						style={{
							color: theme.palette.gray.main,
							fontSize: 16,
							textTransform: "uppercase",
							cursor: "pointer",
						}}
					>
						{t("button.back")}
					</span>
				</div>

				<div>
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/dashboard"
						>
							{t("title.dashboard")}
						</Link>
						<Typography color="text.primary">
							{t("title.customers")}
						</Typography>
					</Breadcrumbs>
				</div>
			</div>
			<div style={{ paddingBottom: 5 }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					style={{ paddingBottom: 5 }}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							paddingBottom: 15,
						}}
					>
						<Typography
							style={{
								fontSize: 21,
								marginRight: 15,
								color: theme.palette.gray.main,
							}}
						>
							<b>{t("customer.customerList")}</b>
						</Typography>
						{_.includes(permissions, "customer-create") && (
							<Link
								underline="none"
								to={`/customer-add`}
								component={RouterLink}
							>
								<div
									style={{
										height: 30,
										width: 30,
										textAlign: "center",
										borderRadius: 5,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										border: theme.palette.button.borderMain,
									}}
								>
									<Typography
										style={{
											fontSize: 21,
											color: theme.palette.button.main,
										}}
									>
										+
									</Typography>
								</div>
							</Link>
						)}
					</div>
				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={8} sm={10} md={11}>
						<ThemeProvider theme={inputStyle}>
							<FormControl
								style={{ width: "100%", maxWidth: "100%" }}
							>
								<OutlinedInput
									placeholder={t("table.searchCustomer")}
									onChange={({ target }) =>
										setKeyword(target.value)
									}
								/>
							</FormControl>
						</ThemeProvider>
					</Grid>
					<Grid item xs={4} sm={2} md={1}>
						<div
							style={{
								backgroundColor: theme.palette.button.main,
								borderRadius: 48,
								boxShadow:
									"2px 2px 10px 5px rgb(209 209 209 / 30%)",
								width: "100%",
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								color: "#fff",
								cursor: "pointer",
							}}
							onClick={() => searchData()}
						>
							<Typography>{t("button.search")}</Typography>
						</div>
					</Grid>
				</Grid>
			</div>

			<div
				style={{
					height: 450,
					width: "100%",
					overflowX: "scroll",
					marginTop: 20,
					boxShadow: "0 0 20px 0 #dadada",
					borderRadius: "15px",
				}}
			>
				<div style={{ display: "flex", height: "100%", padding: 0 }}>
					<div style={{ flexGrow: 1 }}>
						{_.size(state) > 0 ? (
							<DataGrid
								{...data}
								rows={state}
								columns={columns}
								pageSize={pageSize}
								onPageSizeChange={(newPageSize) =>
									setPageSize(newPageSize)
								}
								rowsPerPageOptions={[5, 10, 20]}
								components={{
									Toolbar: CustomToolbar,
									LoadingOverlay: LinearProgress,
								}}
								loading={tableLoading ? true : false}
								disableSelectionOnClick
								sx={{
									marginTop: 0,
									borderRadius: "15px",
									paddingTop: "15px",
									border: "none",
									"& .super-app-theme--header": {
										backgroundColor:
											theme.palette.gray.tableHeader,
										color: theme.palette.gray
											.tableHeaderText,
									},
									backgroundColor: "white",
									"& 	.MuiDataGrid-menuIconButton": {
										color: theme.palette.gray
											.tableHeaderText,
									},
									"& 	.MuiDataGrid-sortIcon": {
										color: theme.palette.gray
											.tableHeaderText,
									},
								}}
							/>
						) : (
							<div
								className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
								style={{
									flexDirection: "column",
									backgroundColor: "#fff",
									height: "100%",
								}}
							>
								<img
									src="images/emptyStatus/nocustomer.png"
									alt="empty"
									style={{ width: 180 }}
								/>
								<Typography
									style={{ margin: 10, textAlign: "center" }}
								>
									{t("emptyStatus.noCustomer")}
								</Typography>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	menuStyle: {
		background: "#fff",
		borderRadius: 15,
		boxShadow: "2px 2px 10px 5px rgb(209 209 209 / 30%)",
		padding: 10,
	},
	menuImg: {
		maxWidth: "100%",
		width: "100%",
	},
	menuText: {
		textAlign: "center",
		fontSize: "14px!important",
		paddingTop: 15,
	},
	flexMiddle: {
		display: "flex",
		alignItems: "center",
	},
	justifyCenter: {
		justifyContent: "center",
	},
}));

const inputStyle = createTheme({
	components: {
		// Name of the component
		MuiOutlinedInput: {
			styleOverrides: {
				// Name of the slot
				input: {
					// Some CSS
					padding: "28.5px 14px",
					boxSizing: "inherit",
					borderRadius: 48,
				},
				root: {
					borderRadius: 48,
					boxShadow: "3px 3px 8px 0 #ccc",
				},
			},
		},
	},
});
