import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useNotificationLoading from "../../helpers/useNotificationLoading";
import { currencyFormat } from "../../helpers/Tools";
import { getUrl } from "../../helpers/ApiAction";
import _ from 'lodash';
import { useSelector } from 'react-redux';

// MUI
import { Grid, Typography, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Hidden, /* Box, Button, */TextField  } from '@mui/material';

// import DateRangePicker from '@mui/lab/DateRangePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { FiArrowLeft/* , FiSearch */ } from "react-icons/fi";
import { VscTriangleUp, VscTriangleDown } from "react-icons/vsc";
import SalesGraph from './SalesGraph';

export default function SalesIndividual() {
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const { addAlert } = useNotificationLoading();
    let { id } = useParams();
    const { permissions } = useSelector(state => state.general);

    const [summary, setSummary] = useState([]);
    const [summaryYearMonth, setSummaryYearMonth] = useState(new Date());

    //date picker
    // const [value, setValue] = React.useState([null, null]);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`/report/sales/${id}`)
			.then((response) => {
                if(isMountedRef.current){
                    if (response.status) {
                        setSummary(response.data);
                    } else {
                        addAlert("", response.message || t("error.contactSupport"), "error", "");
                    }
                }
			})
			.catch((error) => {
                if(isMountedRef.current){
				    addAlert("", error.message || t("error.contactSupport"), "error", "");
                }
			});
        
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
	}, []);

    const handleChangeSalesSummaryYearMonth = (value) => {
        setSummaryYearMonth(value);
        const selectedDate = new Date(value);

        getUrl(`/report/sales-summary/${id}`, {month: selectedDate.getMonth()+1, year: new Date(value).getFullYear()})
			.then((response) => {
                if(isMountedRef.current){
                    if (response.status) {
                        setSummary({...summary, sales_summary: response.data.sales_summary, commission: response.data.commission});
                    } else {
                        addAlert("", t("error.contactSupport"), "error", "");
                    }
                }
			})
			.catch((error) => {
                if(isMountedRef.current){
				    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                }
			});
    }

    const numberPercent = (percent) =>{
        return(
            <div style={{ color: `${theme.palette.indicator[percent > 0 ? "green" : (percent < 0 ? "red" : "yellow" )]}`, marginLeft:-15, paddingTop:8 }} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                {percent > 0 ? <VscTriangleUp style={{marginRight:15}}/> : (percent < 0 ? <VscTriangleDown style={{marginRight:15}}/> : null )}
                <Typography>{`${percent>0?'+':''}${currencyFormat(parseFloat(percent))}`} %</Typography>
            </div>
        )
    }

    return(
        <div>
            <Link underline='none' component={RouterLink} to={`/dashboard`}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.backTo')}{t('title.dashboard')}</Typography>
                </div>
            </Link>

            <div>
                <Typography style={{color:theme.palette.button.main, fontSize:25, fontWeight:'bold', paddingBottom:15}}>{summary?.user?.name}</Typography>

                {/* //Finish other part 1st, temporary hide */}
                <div>
                    {/* <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                        <div className={styles.flexMiddle} style={{ color: theme.palette.gray.ltext, }}>
                            <Typography style={{ fontSize: 18 }}><b>Monthly</b></Typography>
                            <VscTriangleDown style={{ marginLeft: 15 }} />
                        </div>
                        <div className={styles.flexMiddle} style={{zIndex:'-1'}}>
                            <div style={{ boxShadow: 'rgb(209 209 209 / 35%) 2px 2px 10px 5px', borderRadius: '11px 11px 0 0', padding: '8px 15px', marginRight: 15 }} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                                <Typography style={{ textAlign: 'center', color: theme.palette.gray.dtext }}>Quantity</Typography>
                            </div>
                            <div style={{ boxShadow: 'rgb(209 209 209 / 35%) 2px 2px 10px 5px', borderRadius: '11px 11px 0 0', padding: '8px 15px' }} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                                <Typography style={{ textAlign: 'center', color: theme.palette.secondary.secondary }}>Amount</Typography>
                            </div>
                        </div>
                    </div> */}
                    <div className={styles.boxStyle} style={{borderTopRightRadius:0, minHeight:'30vh'}}>
                        {/* <img src="images/example/chart.png" style={{width:'100%'}}/> */}
                        <SalesGraph data={_.has(summary, 'graph_summary') ? summary.graph_summary : null } />
                    </div>
                </div>
                {/* /////////////////*/}

            </div>

            {/* //only show in individual salesman */}
            <div style={{ padding: '40px 0' }}>
                <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{ width: '100%' }}>
                    <Typography style={{ color: theme.palette.gray.ltext, fontSize: 18 }}><b>{t('report.commission')} - {summary?.commission?.month_year}</b></Typography>
                    <div className={styles.flexMiddle} style={{ color: theme.palette.gray.ltext, }}>
                        {/* <Typography style={{ fontSize: 18 }}><b>{t('report.monthly')}</b></Typography> */}
                        {/* <VscTriangleDown style={{ marginLeft: 15 }} /> */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                views={['year', 'month']}
                                label="Year and Month"
                                minDate={new Date('2022-05-01')}
                                maxDate={new Date()}
                                value={summaryYearMonth}
                                onChange={handleChangeSalesSummaryYearMonth}
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                            />
                        </LocalizationProvider> 
                    </div>
                </div>
                <Grid container spacing={3} style={{ marginTop: 0 }}>
                    <Grid item xs={12} sm={6} md={6} style={{paddingTop:15}}>
                        <div style={{ textAlign: 'center' }} className={styles.boxStyle}>
                            { 
                                permissions.includes('commission') ?
                                <Link underline='none' to={`/commissions`} component={RouterLink}><Typography style={{ color: theme.palette.secondary.secondary, textAlign: 'right', paddingBottom:10 }}>{t('report.details')}</Typography></Link>
                                : <Typography style={{ color: theme.palette.secondary.secondary, textAlign: 'right', paddingBottom:10 }}>{t('report.details')}</Typography>
                            }
                            <Grid container>
                                <Hidden >{/* mdUp */}
                                    <Grid item xs={4} sm={3} md={4} style={{ position: 'relative' }} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                                        <div style={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
                                            <div style={{ height: '90%', backgroundColor: theme.palette.primary.secondary, width: 12, borderRadius: 48, boxShadow: '#d9d9d9 5px 2px 6px 0px', marginRight: 15 }} />
                                            <div style={{ height: '70%', backgroundColor: theme.palette.secondary.main, width: 12, borderRadius: 48, boxShadow: '#d9d9d9 5px 2px 6px 0px' }} />
                                        </div>
                                    </Grid>
                                </Hidden>
                                <Grid item xs={8} sm={9} md={6}>
                                    <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                                        <Typography  style={{ textAlign:'left'}}>{t('report.personal')}</Typography>
                                        <Typography style={{color:theme.palette.primary.secondary}}>RM {currencyFormat(parseFloat(summary?.commission?.personal || 0))}</Typography>
                                    </div>
                                    <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                                        <Typography>{t('report.group')}</Typography>
                                        <Typography style={{color:theme.palette.secondary.main}}>RM {currencyFormat(parseFloat(summary?.commission?.group || 0))}</Typography>
                                    </div>
                                    <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                                        <Typography>{t('report.purchaser')}</Typography>
                                        <Typography style={{color:theme.palette.secondary.main}}>RM {currencyFormat(parseFloat(summary?.commission?.purchaser || 0))}</Typography>
                                    </div>
                                    <div style={{borderTop:'1px solid #b7b7b7', borderBottom:'1px solid #b7b7b7', padding:'4px 0', marginTop:5}} className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                                        <Typography>{t('report.total')}</Typography>
                                        <Typography>RM {currencyFormat(parseFloat(summary?.commission?.total || 0))}</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            
                            
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{paddingTop:15}}>
                        <div style={{ textAlign: 'center' }} className={styles.boxStyle}>
                            { 
                                permissions.includes('order') ?
                                <Link underline='none' to={`/orders`} component={RouterLink}><Typography style={{ color: theme.palette.secondary.secondary, textAlign: 'right', paddingBottom:10 }}>{t('report.details')}</Typography></Link>
                                : <Typography style={{ color: theme.palette.secondary.secondary, textAlign: 'right', paddingBottom:10 }}>{t('report.details')}</Typography>
                            }
                            <Typography style={{ color: theme.palette.gray.ltext }}>{t('report.totalSales')} (RM)</Typography>
                            <Typography className={styles.priceText}>{currencyFormat(summary?.sales_summary?.total_sales || 0)}</Typography>
                            {numberPercent(summary?.sales_summary?.total_sales_percent || 0.00)}
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div>
                <Grid container>
                    <Grid item xs={12} sm={6} md={4} className={styles.flexMiddle}>
                    <Typography style={{ color: theme.palette.gray.ltext, fontSize: 18 }}><b>{t('report.recentOrder')}</b></Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} style={{display:'flex', justifyContent:'flex-end'}}>
                    {/* <div className={styles.flexMiddle} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateRangePicker
                                startText="From Date"
                                endText="To Date"
                                value={value}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                }}
                                renderInput={(startProps, endProps) => (
                                    <React.Fragment>
                                        <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }}> to </Box>
                                        <TextField {...endProps} />
                                    </React.Fragment>
                                )}
                            />
                        </LocalizationProvider>
                        <Button style={{ width: 40, height: 40, minWidth: 'unset', marginLeft: 15, borderRadius: 48, backgroundColor: theme.palette.secondary.main }} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                            <FiSearch style={{ color: '#fff', fontSize: 21 }} />
                        </Button>
                    </div> */}
                    </Grid>
                </Grid>

                <TableContainer component={Paper} style={{    boxShadow: '0 0 20px 0 #e3e3e3', marginTop: 15, borderRadius: 15}}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('report.date')}</TableCell>
                                <TableCell>{t('report.customer')}</TableCell>
                                <TableCell>{t('order.receiptNo')}</TableCell>
                                <TableCell>{t('report.orderStatus')}</TableCell>
                                <TableCell align="right">{t('report.price')} (RM)</TableCell>
                                <TableCell align="right">{t('report.action')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.size(summary.orders) > 0 && summary.orders.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{width:200}} component="th" scope="row">
                                        {row.created_at}
                                    </TableCell>
                                    <TableCell >{row.customer.name}</TableCell>
                                    <TableCell style={{width:200}}>{row.order_no}</TableCell>
                                    <TableCell style={{width:200}}>{row.status_name[i18n.language]?row.status_name[i18n.language]:'-'}</TableCell>
                                    <TableCell style={{width:200}} align="right">{currencyFormat(row.total_price)}</TableCell>
                                    <TableCell style={{width:150}} align="right">
                                        <Link underline='none' to={`/orders/${row.id}`} component={RouterLink}>{t('button.view')}</Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
        
    );
}

const useStyles = makeStyles(theme => ({
    boxStyle: {
        background: '#fff',
        borderRadius: 15,
        boxShadow: '2px 2px 10px 5px rgb(209 209 209 / 30%)',
        padding: 15,
        height:'100%'
    },
    priceText:{
        color:theme.palette.secondary.main,
        fontSize:'25px!important',
        fontWeight:'bold!important'
    },
    justifyCenter:{
        justifyContent:'center'
    },
    spaceBetween:{
        justifyContent:'space-between'
    },
    flexMiddle:{
        display:'flex',
        alignItems:'center',
    },
  }));

