import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl } from '../../helpers/ApiAction';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// MUI
import { Grid, Typography, Link, LinearProgress, Breadcrumbs } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarColumnsButton, GridActionsCellItem } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';

import { FiArrowLeft } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";


function CustomToolbar() {
    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer} style={{paddingBottom:10}}>
            <GridToolbarExport style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} csvOptions={{ allColumns: true, fileName: `Brands_${new Date().toISOString().slice(0, 10)}` }} printOptions={{ disableToolbarButton: true }} />
            <GridToolbarDensitySelector style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
            <GridToolbarFilterButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
            <GridToolbarColumnsButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
        </GridToolbarContainer>
    );
}

export default function Index() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const [pageSize, setPageSize] = useState(20);
    const [tableLoading, setTableLoading] = useState(false);
    const [state, setState] = useState([]);
    const { addAlert } = useNotificationLoading();
    const { data } = useDemoData({
        dataSet: 'expense',
        rowLength: 5,
        maxColumns: 10,
    });
    let navigate = useNavigate();
    const { permissions } = useSelector(state => state.general);

    const editDetails = React.useCallback(
        (id) => () => {
            navigate(`/expense-edit/${id}`);
        },
        // eslint-disable-next-line
        [],
    );

    //table
    const columns = useMemo(() => [
        {
            field: 'expense_title',
            headerName: `${t('expense.type')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value?.name,
        },
        {
            field: 'amount',
            headerName: `${t('expense.amount')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'created_at',
            headerName: `${t('general.createdAt')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'updated_at',
            headerName: `${t('general.updatedAt')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: `${t('table.actions')}`,
            width: 70,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            hide: true,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<MdModeEdit style={{color:theme.palette.button.main}} size={25} />}
                    label={t('button.edit')}
                    onClick={editDetails(params.id)}
                />
            ],
        },
    ],
        // eslint-disable-next-line
        [editDetails, t],
    );

    useEffect(() => {
        setTableLoading(true);
        getUrl('/expenses').then(response => {
            setTableLoading(false);
            if (response.status) {
                setState(response.data.listing);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Link underline="none" component={RouterLink} to={`/dashboard`}>
                <div
                    className={styles.flexMiddle}
                    style={{ paddingBottom: 30 }}
                >
                    <FiArrowLeft
                        style={{
                            color: theme.palette.gray.main,
                            fontSize: 16,
                            marginRight: 10,
                        }}
                    />
                    <Typography
                        style={{
                            color: theme.palette.gray.main,
                            fontSize: 16,
                            textTransform: "uppercase",
                        }}
                    >
                        {t("button.backTo")}
                        {t("title.dashboard")}
                    </Typography>
                </div>
            </Link>
            <div style={{ paddingBottom: 5 }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t('expense.expenseList')}</b></Typography>
                        {
                            _.includes(permissions, "expense-create") &&
                            <Link underline='none' to={`/expense-add`} component={RouterLink}>
                                <div style={{ height: 30, width: 30, textAlign: 'center', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', border: theme.palette.button.borderMain }}>
                                    <Typography style={{ fontSize: 21, color: theme.palette.button.main }}>
                                        +
                                    </Typography>
                                </div>
                            </Link>
                        }
                    </div>
                    <div>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                                {t('title.dashboard')}
                            </Link>
                            <Typography color="text.primary">{t('title.expenses')}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
            </div>
            <div style={{ height: 450, width: '100%', overflowX: 'scroll', marginTop: 20, boxShadow: '0 0 20px 0 #dadada', borderRadius: '15px' }}>
                <div style={{ display: 'flex', height: '100%', padding: 0 }}>
                    <div style={{ flexGrow: 1 }}>
                        {
                            _.size(state) > 0
                                ?
                                <DataGrid
                                    {...data}
                                    rows={state}
                                    columns={columns}
                                    columnVisibilityModel={{actions: permissions.includes("expense-title-edit")}}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    components={{
                                        Toolbar: CustomToolbar,
                                        LoadingOverlay: LinearProgress,
                                    }}
                                    loading={tableLoading ? true : false}
                                    sx={{
                                        marginTop: 0,
                                        borderRadius: '15px',
                                        paddingTop: '15px',
                                        border: 'none',
                                        '& .super-app-theme--header': {
                                            // backgroundColor: theme.palette.primary.main,
                                            backgroundColor: theme.palette.gray.tableHeader,
                                            color: theme.palette.gray.tableHeaderText,
                                        },
                                        backgroundColor: 'white',
                                        '& 	.MuiDataGrid-menuIconButton': {
                                            color: theme.palette.gray.tableHeaderText,
                                        },
                                        '& 	.MuiDataGrid-sortIcon': {
                                            color: theme.palette.gray.tableHeaderText,
                                        },
                                    }}
                                />
                                :
                                <div className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} style={{ flexDirection: 'column', backgroundColor: '#fff', height: '100%', }}>
                                    <img src="images/emptyStatus/nolist.png" alt="empty" style={{ width: 180 }} />
                                    <Typography style={{ margin: 10, textAlign: "center" }}>{t('emptyStatus.noRecord')}</Typography>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    justifyCenter:{
        justifyContent:'center'
    }
}));
