import { makeStyles, useTheme } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
	changeLanguage as reduxChangeLanguage,
	storeProfile
} from "../../actions";
import { getUrl, postUrl, putUrl } from "../../helpers/ApiAction";
import useNotificationLoading from "../../helpers/useNotificationLoading";
// MUI
import {
	Avatar, Badge, Box, Breadcrumbs, Button, Chip, FormHelperText, Hidden, IconButton,
	InputAdornment, Link, Tab, Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow, Tabs, TextField, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { FiArrowLeft /* , FiEdit2 */ } from "react-icons/fi";
import {
	MdCheck, MdClose, MdModeEdit, MdRemove, MdVisibility,
	MdVisibilityOff
} from "react-icons/md";

import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

//Tabs
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<div>{children}</div>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
	width: 22,
	height: 22,
	border: `2px solid ${theme.palette.background.paper}`,
}));

export default function MyAccount() {
	const navigate = useNavigate();
	const styles = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const {
		name,
		staff_no,
		email,
		mobile,
		ic,
		bank_name,
		bank_account,
		addresses,
		id,
		profile_picture,
	} = useSelector((state) => state.user);
	const { role, permissions, language } = useSelector(
		(state) => state.general
	);
	const [value, setValue] = useState(0);
	const [state, setState] = useState({
		staff_no,
		name,
		email,
		mobile,
		ic,
		bank_name,
		bank_account,
		password: "",
		password_confirmation: "",
		showPassword: {
			password: false,
			password_confirmation: false,
		},
	});
	const [staffAddress, setStaffAddress] = useState({
		country: "",
		zip: "",
		state: "",
		city: "",
		address: "",
		address2: "",
	});
	const [roles, setRoles] = useState({});
	// const [permission, setPermission] = useState({});
	const [parentPermissions, setParentPermissions] = useState([]);
	const [childPermissions, setChildPermissions] = useState([]);
	const [inputErrors, setInputErrors] = useState({});
	const isMountedRef = useRef(null);
	const { addAlert, setLoading } = useNotificationLoading();
	const dispatch = useDispatch();
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [profileImg, setProfileImg] = useState(profile_picture);

	useEffect(() => {
		if (_.size(addresses) > 0) {
			_.map(addresses, (listing) => {
				setStaffAddress({
					...staffAddress,
					address: listing.address1,
					address2: listing.address2 ? listing.address2 : "",
					country: listing.country,
					zip: listing.zip,
					state: listing.state,
					city: listing.city,
				});
			});
		}
		// eslint-disable-next-line
	}, [addresses]);

	useEffect(() => {
		setLoading(true);
		isMountedRef.current = true;
		getUrl("/permissions-for-staff")
			.then((response) => {
				setLoading(false);
				if (isMountedRef.current) {
					setRoles(response.data.all_roles);
					// setPermission(response.data.permissions_by_role);
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});

		getUrl(`parent-permissions`)
			.then((response) => {
				setLoading(false);
				if (isMountedRef.current) {
					setParentPermissions(response.data.parent_permissions);
					setChildPermissions(response.data.child_permissions);
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});

		return () => {
			isMountedRef.current = false;
		};
		// eslint-disable-next-line
	}, []);

	const submitData = () => {
		let postData = {
			name: state.name,
			email: state.email,
			mobile: state.mobile,
			ic: state.ic,
			status: state.status,
			country: staffAddress.country,
			zip: staffAddress.zip,
			state: staffAddress.state,
			city: staffAddress.city,
			address: staffAddress.address,
			address2: staffAddress.address2,
			bank_name: state.bank_name,
			bank_account: state.bank_account,
		};
		setLoading(true);
		setInputErrors();
		putUrl(`/user`, postData)
			.then((response) => {
				setLoading(false);
				if (response.status) {
					dispatch(storeProfile(response.data));
					addAlert("", t("success.editSuccess"), "success", "");
				} else {
					setInputErrors(response.errors);
					addAlert("", t("error.editError"), "error", "");
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});
	};

	const submitPassword = () => {
		let postData = {
			password: state.password,
			password_confirmation: state.password_confirmation,
		};
		setLoading(true);
		setInputErrors();
		putUrl(`/users/${id}/password`, postData)
			.then((response) => {
				setLoading(false);
				if (response.status) {
					addAlert("", t("success.editSuccess"), "success", "");
				} else {
					setInputErrors(response.errors);
					addAlert("", t("error.editError"), "error", "");
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});
	};

	const handleClickShowPassword = (value) => {
		setState({
			...state,
			showPassword: {
				...state.showPassword,
				[value]: !state.showPassword[value],
			},
		});
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
		dispatch(reduxChangeLanguage(lang));
	};

	const uploadIcon = (e) => {
		setLoading(true);
		const putData = new FormData();
		if (e.target && e.target.files[0]) {
			putData.append("profile_picture", e.target.files[0]);
			putData.append("_method", "put");
		}

		postUrl(`/users/${id}/profilePicture`, putData)
			.then((response) => {
				setLoading(false);
				if (response.status) {
					setProfileImg(response.data.profile_picture);
					dispatch(storeProfile(response.data));
					addAlert("", t("success.editSuccess"), "success", "");
				} else {
					setInputErrors(response.errors);
					addAlert("", t("error.editError"), "error", "");
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error.message || t("error.contactSupport"),
					"error",
					""
				);
			});
	};

	return (
		<div>
			<div
				className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}
				style={{ paddingBottom: 30 }}
			>
				<Link underline="none" component={RouterLink} to={`/setting`}>
					<div className={styles.flexMiddle}>
						<FiArrowLeft
							style={{
								color: theme.palette.gray.main,
								fontSize: 16,
								marginRight: 10,
							}}
						/>
						<span
							onClick={() => navigate(-1)}
							style={{
								color: theme.palette.gray.main,
								fontSize: 16,
								textTransform: "uppercase",
								cursor: "pointer",
							}}
						>
							{t("button.back")}
						</span>
					</div>
				</Link>

				<div>
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/dashboard"
						>
							{t("title.dashboard")}
						</Link>
						<Link
							underline="hover"
							color="inherit"
							component={RouterLink}
							to="/setting"
						>
							{t("title.setting")}
						</Link>
						<Typography color="text.primary">
							{t("title.myAccount")}
						</Typography>
					</Breadcrumbs>
				</div>

				<Hidden smUp>
					<div
						style={{ color: theme.palette.button.main }}
						className={styles.flexMiddle}
					>
						<p style={{ paddingRight: 10 }}>{t("button.logout")}</p>
						<Logout />
					</div>
				</Hidden>
			</div>

			<div>
				<Hidden smDown>
					<img
						src="/images/setting/banner.png"
						style={{
							width: "100%",
							maxWidth: "100%",
							borderRadius: 15,
						}}
						alt="cover"
					/>
				</Hidden>
				<Hidden smUp>
					<img
						src="/images/setting/bannerMobile.png"
						style={{
							width: "100%",
							maxWidth: "100%",
							borderRadius: 15,
						}}
						alt="cover"
					/>
				</Hidden>
				<Box
					style={{ width: "100%", height: "100%", paddingTop: 0 }}
					padding={{ xs: "20px", sm: "40px" }}
					marginTop={{ xs: "-73px", sm: "-80px", md: "-150px" }}
					className={`head ${styles.flexTop} ${styles.justifyTop} ${styles.mobileColumn}`}
				>
					<Hidden smDown>
						{/* minHeight: 'calc(100vh - 250px)', */}
						<div
							style={{
								backgroundColor: "#fff",
								borderRadius: 15,
								boxShadow: "rgb(0 0 0 / 14%) 0px 0px 20px 0px",
								width: 400,
							}}
						>
							<div
								className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
								style={{
									margin: "25px auto",
								}}
							>
								{/* <form> */}
								<Badge
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right",
									}}
									badgeContent={
										<SmallAvatar alt="icon">
											<input
												accept="image/*"
												className={styles.inputFile}
												id="icon-button-file"
												type="file"
												onChange={uploadIcon}
											/>
											<label htmlFor="icon-button-file">
												<IconButton
													component="span"
													style={{
														backgroundColor:
															theme.palette
																.secondary.main,
													}}
												>
													<MdModeEdit
														style={{
															fontSize: "small",
														}}
													/>
												</IconButton>
											</label>
										</SmallAvatar>
									}
								>
									{profileImg ? (
										<Avatar
											alt="profile_picture"
											variant="square"
											sx={{
												height: 80,
												width: 80,
												borderRadius: 2,
												boxShadow:
													"rgb(0 0 0 / 14%) 0px 0px 20px 0px",
												color: "#fff",
												backgroundColor:
													theme.palette.secondary
														.main,
											}}
											src={profileImg.file_name}
										/>
									) : (
										<Avatar
											alt="profile_picture"
											variant="square"
											sx={{
												height: 80,
												width: 80,
												borderRadius: 2,
												boxShadow:
													"rgb(0 0 0 / 14%) 0px 0px 20px 0px",
												color: "#fff",
												backgroundColor:
													theme.palette.secondary
														.main,
											}}
										>
											<Typography
												style={{
													textTransform: "uppercase",
													fontWeight: "bold",
												}}
												fontSize={{ xs: 14, sm: 36 }}
											>
												{state && state.name
													? state.name[0] +
													  state.name[1]
													: "-"}
											</Typography>
										</Avatar>
									)}
								</Badge>
								{/* </form> */}
							</div>
							<div style={{ textAlign: "center" }}>
								<FormHelperText style={{ textAlign: "center" }}>
									{t("general.recommendedProfileImageSize")}
								</FormHelperText>
							</div>
							<div style={{ textAlign: "center" }}>
								<Typography
									style={{
										textTransform: "uppercase",
										fontWeight: "bold",
										color: theme.palette.secondary.main,
									}}
									fontSize={{ xs: 14, sm: 24 }}
								>
									{state.name ? state.name : "-"}
								</Typography>
								<Typography
									style={{
										textTransform: "uppercase",
										color: theme.palette.gray.ltext,
									}}
									fontSize={{ xs: 14, sm: 18 }}
								>
									ID: {state.staff_no ? state.staff_no : "-"}
								</Typography>
							</div>
							<div style={{ padding: "15px 0" }}>
								<Tabs
									orientation="vertical"
									variant="scrollable"
									value={value}
									onChange={handleChange}
									aria-label="Vertical tabs example"
									sx={{
										borderRight: 0,
										borderColor: "divider",
									}}
								>
									<Tab
										label={t("setting.basicInfo")}
										{...a11yProps(0)}
									/>
									<Tab
										label={t("setting.address")}
										{...a11yProps(1)}
									/>
									<Tab
										label={t("setting.password")}
										{...a11yProps(2)}
									/>
									<Tab
										label={t("setting.bankDetail")}
										{...a11yProps(3)}
									/>
									<Tab
										label={t("setting.rolePermission")}
										{...a11yProps(4)}
									/>
									<Tab
										label={t("setting.language")}
										{...a11yProps(5)}
									/>
								</Tabs>
							</div>
						</div>
					</Hidden>
					<Hidden smUp>
						<div
							className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
							style={{
								margin: "25px auto",
							}}
						>
							<Badge
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								badgeContent={
									<SmallAvatar alt="icon">
										<input
											accept="image/*"
											className={styles.inputFile}
											id="icon-button-file"
											type="file"
											onChange={uploadIcon}
										/>
										<label htmlFor="icon-button-file">
											<IconButton
												component="span"
												style={{
													backgroundColor:
														theme.palette.secondary
															.main,
												}}
											>
												<MdModeEdit
													style={{
														fontSize: "small",
													}}
												/>
											</IconButton>
										</label>
									</SmallAvatar>
								}
							>
								{profileImg ? (
									<Avatar
										alt="profile_picture"
										variant="square"
										sx={{
											height: 80,
											width: 80,
											borderRadius: 2,
											boxShadow:
												"rgb(0 0 0 / 14%) 0px 0px 20px 0px",
											color: "#fff",
											backgroundColor:
												theme.palette.secondary.main,
										}}
										src={profileImg.file_name}
									/>
								) : (
									<Avatar
										alt="profile_picture"
										variant="square"
										sx={{
											height: 80,
											width: 80,
											borderRadius: 2,
											boxShadow:
												"rgb(0 0 0 / 14%) 0px 0px 20px 0px",
											color: "#fff",
											backgroundColor:
												theme.palette.secondary.main,
										}}
									>
										<Typography
											style={{
												textTransform: "uppercase",
												fontWeight: "bold",
											}}
											fontSize={{ xs: 36, sm: 36 }}
										>
											{state && state.name
												? state.name[0] + state.name[1]
												: "-"}
										</Typography>
									</Avatar>
								)}
							</Badge>
						</div>
						{/* <div
							className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
							style={{
								height: 80,
								width: 80,
								borderRadius: 5,
								boxShadow: "rgb(0 0 0 / 14%) 0px 0px 20px 0px",
								color: "#fff",
								backgroundColor: theme.palette.secondary.main,
								margin: "13px auto",
							}}
						>
							<Typography
								style={{
									textTransform: "uppercase",
									fontWeight: "bold",
								}}
								fontSize={{ xs: 36, sm: 36 }}
							>
								{state && state.name
									? state.name[0] + state.name[1]
									: "-"}
							</Typography>
						</div> */}
						<div style={{ textAlign: "center" }}>
							<FormHelperText style={{ textAlign: "center" }}>
								{t("general.recommendedProfileImageSize")}
							</FormHelperText>
						</div>
						<div style={{ textAlign: "center", paddingBottom: 20 }}>
							<Typography
								style={{
									textTransform: "uppercase",
									fontWeight: "bold",
									color: theme.palette.secondary.main,
								}}
								fontSize={{ xs: 18, sm: 24 }}
							>
								{state.name ? state.name : "-"}
							</Typography>
							<Typography
								style={{
									textTransform: "uppercase",
									color: theme.palette.gray.ltext,
								}}
								fontSize={{ xs: 14, sm: 18 }}
							>
								ID: {state.staff_no ? state.staff_no : "-"}
							</Typography>
						</div>
						<div style={{ width: "100%" }}>
							<div style={{ padding: "15px 0" }}>
								<Tabs
									orientation="horizontal"
									variant="scrollable"
									value={value}
									onChange={handleChange}
									aria-label="Vertical tabs example"
									sx={{
										borderRight: 0,
										borderColor: "divider",
									}}
								>
									<Tab
										label={t("setting.basicInfo")}
										{...a11yProps(0)}
									/>
									<Tab
										label={t("setting.address")}
										{...a11yProps(1)}
									/>
									<Tab
										label={t("setting.password")}
										{...a11yProps(2)}
									/>
									<Tab
										label={t("setting.bankDetail")}
										{...a11yProps(3)}
									/>
									<Tab
										label={t("setting.rolePermission")}
										{...a11yProps(4)}
									/>
									<Tab
										label={t("setting.language")}
										{...a11yProps(5)}
									/>
								</Tabs>
							</div>
						</div>
					</Hidden>
					<Box
						style={{ width: "100%" }}
						paddingLeft={{ xs: 0, sm: "30px" }}
					>
						<div
							style={{
								backgroundColor: "#fff",
								borderRadius: 15,
								boxShadow: "rgb(0 0 0 / 14%) 0px 0px 20px 0px",
								width: "100%",
								height: "100%",
							}}
						>
							<TabPanel value={value} index={0}>
								<div
									className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}
								>
									<Typography
										style={{
											color: theme.palette.gray.main,
											fontSize: 21,
										}}
									>
										<b>{t("setting.profile")}</b>
									</Typography>
									{/* <div
										className={styles.flexMiddle}
										style={{
											color: theme.palette.button.main,
										}}
									>
										<Typography
											style={{
												fontSize: 15,
												textAlign: "right",
											}}
										>
											<b>
												<FiEdit2 />
											</b>
										</Typography>
										<Typography
											style={{
												fontSize: 17,
												paddingLeft: 8,
											}}
										>
											<b>{t("button.edit")}</b>
										</Typography>
									</div> */}
								</div>
								<div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="name"
											fullWidth
											label={t("user.name")}
											variant="outlined"
											type="text"
											autoComplete="name"
											value={state.name || ""}
											InputLabelProps={{ shrink: true }}
											helperText={
												inputErrors && inputErrors.name
													? inputErrors.name
													: ""
											}
											error={
												inputErrors && inputErrors.name
													? true
													: false
											}
											onChange={({ target }) =>
												setState({
													...state,
													name: target.value,
												})
											}
										/>
									</div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="staffNo"
											fullWidth
											label={t("user.staffNo")}
											variant="outlined"
											type="text"
											autoComplete="staff_no"
											value={state.staff_no || ""}
											InputLabelProps={{ shrink: true }}
											disabled={true}
										/>
									</div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="nric"
											fullWidth
											label={t("user.nric")}
											variant="outlined"
											autoComplete="ic"
											value={state.ic || ""}
											InputLabelProps={{ shrink: true }}
											inputProps={{
												inputMode: "numeric",
												pattern: "[0-9]*",
											}}
											helperText={
												inputErrors && inputErrors.ic
													? inputErrors.ic
													: ""
											}
											error={
												inputErrors && inputErrors.ic
													? true
													: false
											}
											onChange={({ target }) =>
												setState({
													...state,
													ic: target.value,
												})
											}
										/>
									</div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="email"
											fullWidth
											label={t("user.email")}
											variant="outlined"
											type="email"
											autoComplete="email"
											value={state.email || ""}
											InputLabelProps={{ shrink: true }}
											disabled={true}
										/>
									</div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="mobile"
											fullWidth
											label={t("user.mobile")}
											variant="outlined"
											autoComplete="mobile"
											value={state.mobile || ""}
											InputLabelProps={{ shrink: true }}
											inputProps={{
												inputMode: "numeric",
												pattern: "[0-9]*",
											}}
											helperText={
												inputErrors &&
												inputErrors.mobile
													? inputErrors.mobile
													: ""
											}
											error={
												inputErrors &&
												inputErrors.mobile
													? true
													: false
											}
											onChange={({ target }) =>
												setState({
													...state,
													mobile: target.value,
												})
											}
										/>
									</div>
								</div>
								<div>
									<Box
										style={{
											paddingTop: "5%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Link
											underline="none"
											to={`/customer`}
											component={RouterLink}
										>
											<Button
												size="large"
												variant="outlined"
												className={styles.buttonStyle}
												style={{
													marginRight: 15,
													color: theme.palette.button
														.main,
													border: theme.palette.button
														.borderMain,
												}}
											>
												{t("button.cancel")}
											</Button>
										</Link>
										<Button
											size="large"
											variant="contained"
											style={{
												backgroundColor:
													theme.palette.button.main,
											}}
											className={styles.buttonStyle}
											onClick={() => submitData()}
										>
											{t("button.save")}
										</Button>
									</Box>
								</div>
							</TabPanel>
							<TabPanel value={value} index={1}>
								<div
									className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}
								>
									<Typography
										style={{
											color: theme.palette.gray.main,
											fontSize: 21,
										}}
									>
										<b>{t("setting.address")}</b>
									</Typography>
									{/* <div
										className={styles.flexMiddle}
										style={{
											color: theme.palette.button.main,
										}}
									>
										<Typography
											style={{
												fontSize: 15,
												textAlign: "right",
											}}
										>
											<b>
												<FiEdit2 />
											</b>
										</Typography>
										<Typography
											style={{
												fontSize: 17,
												paddingLeft: 8,
											}}
										>
											<b>{t("button.edit")}</b>
										</Typography>
									</div> */}
								</div>
								<div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="address"
											fullWidth
											label={t("user.address")}
											variant="outlined"
											type="text"
											autoComplete="address"
											value={staffAddress.address || ""}
											InputLabelProps={{ shrink: true }}
											helperText={
												inputErrors &&
												inputErrors.address
													? inputErrors.address
													: ""
											}
											error={
												inputErrors && inputErrors.name
													? true
													: false
											}
											onChange={({ target }) =>
												setStaffAddress({
													...staffAddress,
													address: target.value,
												})
											}
										/>
									</div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="address2"
											fullWidth
											label={t("user.address2")}
											variant="outlined"
											type="text"
											autoComplete="address2"
											value={staffAddress.address2 || ""}
											InputLabelProps={{ shrink: true }}
											helperText={
												inputErrors &&
												inputErrors.address2
													? inputErrors.address2
													: ""
											}
											error={
												inputErrors &&
												inputErrors.address2
													? true
													: false
											}
											onChange={({ target }) =>
												setStaffAddress({
													...staffAddress,
													address2: target.value,
												})
											}
										/>
									</div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="country"
											fullWidth
											label={t("user.country")}
											variant="outlined"
											type="text"
											autoComplete="country"
											value={staffAddress.country || ""}
											InputLabelProps={{ shrink: true }}
											helperText={
												inputErrors &&
												inputErrors.country
													? inputErrors.country
													: ""
											}
											error={
												inputErrors &&
												inputErrors.country
													? true
													: false
											}
											onChange={({ target }) =>
												setStaffAddress({
													...staffAddress,
													country: target.value,
												})
											}
										/>
									</div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="zip"
											fullWidth
											label={t("user.zip")}
											variant="outlined"
											autoComplete="zip"
											value={staffAddress.zip || ""}
											InputLabelProps={{ shrink: true }}
											inputProps={{
												inputMode: "numeric",
												pattern: "[0-9]*",
											}}
											helperText={
												inputErrors && inputErrors.zip
													? inputErrors.zip
													: ""
											}
											error={
												inputErrors && inputErrors.zip
													? true
													: false
											}
											onChange={({ target }) =>
												setStaffAddress({
													...staffAddress,
													zip: target.value,
												})
											}
										/>
									</div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="city"
											fullWidth
											label={t("user.city")}
											type="text"
											autoComplete="city"
											value={staffAddress.city || ""}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											helperText={
												inputErrors && inputErrors.city
													? inputErrors.city
													: ""
											}
											error={
												inputErrors && inputErrors.city
													? true
													: false
											}
											onChange={({ target }) =>
												setStaffAddress({
													...staffAddress,
													city: target.value,
												})
											}
										/>
									</div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="state"
											fullWidth
											label={t("user.state")}
											type="text"
											autoComplete="state"
											value={staffAddress.state || ""}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											helperText={
												inputErrors && inputErrors.state
													? inputErrors.name
													: ""
											}
											error={
												inputErrors && inputErrors.state
													? true
													: false
											}
											onChange={({ target }) =>
												setStaffAddress({
													...staffAddress,
													state: target.value,
												})
											}
										/>
									</div>
								</div>
								<div>
									<Box
										style={{
											paddingTop: "5%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Link
											underline="none"
											to={`/customer`}
											component={RouterLink}
										>
											<Button
												size="large"
												variant="outlined"
												className={styles.buttonStyle}
												style={{
													marginRight: 15,
													color: theme.palette.button
														.main,
													border: theme.palette.button
														.borderMain,
												}}
											>
												{t("button.cancel")}
											</Button>
										</Link>
										<Button
											size="large"
											variant="contained"
											style={{
												backgroundColor:
													theme.palette.button.main,
											}}
											className={styles.buttonStyle}
											onClick={() => submitData()}
										>
											{t("button.save")}
										</Button>
									</Box>
								</div>
							</TabPanel>
							<TabPanel value={value} index={2}>
								<div
									className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}
								>
									<Typography
										style={{
											color: theme.palette.gray.main,
											fontSize: 21,
										}}
									>
										<b>{t("setting.changePassword")}</b>
									</Typography>
									{/* <div
										className={styles.flexMiddle}
										style={{
											color: theme.palette.button.main,
										}}
									>
										<Typography
											style={{
												fontSize: 15,
												textAlign: "right",
											}}
										>
											<b>
												<FiEdit2 />
											</b>
										</Typography>
										<Typography
											style={{
												fontSize: 17,
												paddingLeft: 8,
											}}
										>
											<b>{t("button.edit")}</b>
										</Typography>
									</div> */}
								</div>
								<div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="password"
											fullWidth
											label={t("user.password")}
											type={
												state.showPassword.password
													? "text"
													: "password"
											}
											value={state.password}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											helperText={
												inputErrors &&
												inputErrors.password
													? inputErrors.password[0]
													: ""
											}
											error={
												inputErrors &&
												inputErrors.password
													? true
													: false
											}
											onChange={({ target }) =>
												setState({
													...state,
													password: target.value,
												})
											}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() =>
																handleClickShowPassword(
																	"password"
																)
															}
															onMouseDown={
																handleMouseDownPassword
															}
															edge="end"
														>
															{state.showPassword
																.password ? (
																<MdVisibility />
															) : (
																<MdVisibilityOff />
															)}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="confirmPassword"
											fullWidth
											label={t("user.confirmPassword")}
											type={
												state.showPassword
													.password_confirmation
													? "text"
													: "password"
											}
											value={state.password_confirmation}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											helperText={
												inputErrors &&
												inputErrors.password_confirmation
													? inputErrors.name
													: ""
											}
											error={
												inputErrors &&
												inputErrors.password_confirmation
													? true
													: false
											}
											onChange={({ target }) =>
												setState({
													...state,
													password_confirmation:
														target.value,
												})
											}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() =>
																handleClickShowPassword(
																	"password_confirmation"
																)
															}
															onMouseDown={
																handleMouseDownPassword
															}
															edge="end"
														>
															{state.showPassword
																.password_confirmation ? (
																<MdVisibility />
															) : (
																<MdVisibilityOff />
															)}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								<div>
									<Box
										style={{
											paddingTop: "5%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Link
											underline="none"
											to={`/customer`}
											component={RouterLink}
										>
											<Button
												size="large"
												variant="outlined"
												className={styles.buttonStyle}
												style={{
													marginRight: 15,
													color: theme.palette.button
														.main,
													border: theme.palette.button
														.borderMain,
												}}
											>
												{t("button.cancel")}
											</Button>
										</Link>
										<Button
											size="large"
											variant="contained"
											style={{
												backgroundColor:
													theme.palette.button.main,
											}}
											className={styles.buttonStyle}
											onClick={() => submitPassword()}
										>
											{t("button.save")}
										</Button>
									</Box>
								</div>
							</TabPanel>
							<TabPanel value={value} index={3}>
								<div
									className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}
								>
									<Typography
										style={{
											color: theme.palette.gray.main,
											fontSize: 21,
										}}
									>
										<b>{t("setting.bankDetail")}</b>
									</Typography>
									{/* <div
										className={styles.flexMiddle}
										style={{
											color: theme.palette.button.main,
										}}
									>
										<Typography
											style={{
												fontSize: 15,
												textAlign: "right",
											}}
										>
											<b>
												<FiEdit2 />
											</b>
										</Typography>
										<Typography
											style={{
												fontSize: 17,
												paddingLeft: 8,
											}}
										>
											<b>{t("button.edit")}</b>
										</Typography>
									</div> */}
								</div>
								<div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="bankName"
											fullWidth
											label={t("user.bankName")}
											variant="outlined"
											autoComplete="bank_name"
											value={state.bank_name || ""}
											InputLabelProps={{ shrink: true }}
											helperText={
												inputErrors &&
												inputErrors.bank_name
													? inputErrors.bank_name
													: ""
											}
											error={
												inputErrors &&
												inputErrors.bank_name
													? true
													: false
											}
											onChange={({ target }) =>
												setState({
													...state,
													bank_name: target.value,
												})
											}
										/>
									</div>
									<div style={{ paddingTop: 25 }}>
										<TextField
											id="bankAccount"
											fullWidth
											label={t("user.bankAccount")}
											variant="outlined"
											autoComplete="bank_account"
											value={state.bank_account || ""}
											InputLabelProps={{ shrink: true }}
											inputProps={{
												inputMode: "numeric",
												pattern: "[0-9]*",
											}}
											helperText={
												inputErrors &&
												inputErrors.bank_account
													? inputErrors.bank_account
													: ""
											}
											error={
												inputErrors &&
												inputErrors.bank_account
													? true
													: false
											}
											onChange={({ target }) =>
												setState({
													...state,
													bank_account: target.value,
												})
											}
										/>
									</div>
								</div>
								<div>
									<Box
										style={{
											paddingTop: "5%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Link
											underline="none"
											to={`/customer`}
											component={RouterLink}
										>
											<Button
												size="large"
												variant="outlined"
												className={styles.buttonStyle}
												style={{
													marginRight: 15,
													color: theme.palette.button
														.main,
													border: theme.palette.button
														.borderMain,
												}}
											>
												{t("button.cancel")}
											</Button>
										</Link>
										<Button
											size="large"
											variant="contained"
											style={{
												backgroundColor:
													theme.palette.button.main,
											}}
											className={styles.buttonStyle}
											onClick={() => submitData()}
										>
											{t("button.save")}
										</Button>
									</Box>
								</div>
							</TabPanel>
							<TabPanel value={value} index={4}>
								<div>
									<div
										style={{
											paddingTop: 25,
											color: theme.palette.gray.dtext,
										}}
									>
										<Typography
											style={{
												color: theme.palette.gray.main,
												fontSize: 21,
											}}
										>
											<b>{t("setting.roles")}</b>
										</Typography>
										{_.size(role) > 0 && (
											<>
												{_.map(
													role,
													(roleList, index) => {
														let showPermission = false;
														let roleDetail = _.find(
															roles,
															{ name: roleList }
														);
														if (roleDetail) {
															showPermission = true;
														}
														if (showPermission) {
															return (
																<Chip
																	color="primary"
																	label={
																		roleDetail
																			? roleDetail
																					.display_name[
																					i18n
																						.language
																			  ]
																			: "-"
																	}
																	key={index}
																	style={{
																		marginLeft: 5,
																		marginRight: 5,
																		marginBottom: 5,
																	}}
																/>
															);
														} else {
															return null;
														}
													}
												)}
											</>
										)}
									</div>
									<div
										style={{
											paddingTop: 25,
											color: theme.palette.gray.dtext,
										}}
									>
										<Typography
											style={{
												color: theme.palette.gray.main,
												fontSize: 21,
											}}
										>
											<b>{t("setting.permission")}</b>
										</Typography>
										{/* {
                                            _.size(permissions) > 0 &&
                                            <>
                                                {
                                                    _.map(permissions, (permissionsList, index) => {
                                                        return (
                                                            <Chip color="primary" label={permissionsList} key={index} style={{ marginLeft: 5, marginRight: 5, marginBottom: 5 }} />
                                                        )
                                                    })
                                                }
                                            </>
                                        } */}

										{_.size(parentPermissions) > 0 && (
											<Table
												sx={{
													"& .MuiTableCell-root": {
														borderBottom: "none",
													},
												}}
												aria-label="permission table"
												size="small"
											>
												<TableHead>
													<TableRow>
														<TableCell></TableCell>
														<TableCell align="center">
															View
														</TableCell>
														<TableCell align="center">
															Create
														</TableCell>
														<TableCell align="center">
															Edit
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{_.map(
														parentPermissions,
														(parent, index) => (
															<TableRow
																key={index}
																sx={{
																	"&:last-child td, &:last-child th":
																		{
																			border: 0,
																		},
																}}
															>
																<TableCell
																	component="th"
																	scope="row"
																	style={{
																		textTransform:
																			"capitalize",
																	}}
																>
																	{parent}
																</TableCell>
																<TableCell align="center">
																	{permissions.includes(
																		`${parent}`
																	) ? (
																		<MdCheck
																			color="green"
																			size="1.8em"
																		/>
																	) : (
																		<MdClose
																			color="red"
																			size="1.8em"
																		/>
																	)}
																</TableCell>
																<TableCell align="center">
																	{childPermissions.includes(
																		`${parent}-create`
																	) ? (
																		permissions.includes(
																			`${parent}-create`
																		) ? (
																			<MdCheck
																				color="green"
																				size="1.8em"
																			/>
																		) : (
																			<MdClose
																				color="red"
																				size="1.8em"
																			/>
																		)
																	) : (
																		<MdRemove
																			color="gray"
																			size="1.8em"
																		/>
																	)}
																</TableCell>
																<TableCell align="center">
																	{childPermissions.includes(
																		`${parent}-edit`
																	) ? (
																		permissions.includes(
																			`${parent}-edit`
																		) ? (
																			<MdCheck
																				color="green"
																				size="1.8em"
																			/>
																		) : (
																			<MdClose
																				color="red"
																				size="1.8em"
																			/>
																		)
																	) : (
																		<MdRemove
																			color="gray"
																			size="1.8em"
																		/>
																	)}
																</TableCell>
															</TableRow>
														)
													)}
												</TableBody>
											</Table>
										)}
									</div>
								</div>
							</TabPanel>
							<TabPanel value={value} index={5}>
								<div>
									<Box
										style={{
											paddingTop: "5%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Button
											size="large"
											variant="contained"
											style={{
												backgroundColor:
													language === "en"
														? theme.palette.button
																.main
														: "white",
												marginRight: 15,
												color:
													language === "en"
														? "white"
														: "black",
											}}
											className={styles.buttonStyle}
											onClick={() => changeLanguage("en")}
										>
											{t("general.en")}
										</Button>
										<Button
											size="large"
											variant="contained"
											style={{
												backgroundColor:
													language === "cn"
														? theme.palette.button
																.main
														: "white",
												color:
													language === "cn"
														? "white"
														: "black",
											}}
											className={styles.buttonStyle}
											onClick={() => changeLanguage("cn")}
										>
											{t("general.cn")}
										</Button>
									</Box>
								</div>
							</TabPanel>
						</div>
					</Box>
				</Box>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	menuStyle: {
		background: "#fff",
		borderRadius: 15,
		boxShadow: "2px 2px 10px 5px rgb(209 209 209 / 30%)",
		padding: 10,
		width: 250,
		height: 250,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	justifyTop: {
		justifyContent: "flex-start",
	},
	justifyCenter: {
		justifyContent: "center",
	},
	spaceBetween: {
		justifyContent: "space-between",
	},
	mobileColumn: {
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
		[theme.breakpoints.up("sm")]: {
			flexDirection: "row",
		},
	},
	flexMiddle: {
		display: "flex",
		alignItems: "center",
	},
	flexTop: {
		display: "flex",
		alignContent: "flex-start",
	},
	buttonStyle: {
		minWidth: 120,
	},
	inputFile: {
		display: "none",
	},
	large: {
		width: theme.spacing(10),
		height: theme.spacing(10),
	},
}));
