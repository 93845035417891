import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// MUI
import { Link, Avatar, Box, Button, Grid, Typography, Breadcrumbs, Hidden } from '@mui/material';
import { FiUser, FiPhone, FiMapPin, FiArrowLeft } from "react-icons/fi";
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';

import ProfileWarranty from './ProfileWarranty';
import ProfileTradeIn from './ProfileTradeIn';
import ProfilePurchase from './ProfilePurchase';


export default function CustomerProfile() {
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [state, setState] = useState({});
    const [serviceList, setServiceList] = useState({});
    const [tradeInList, setTradeInList] = useState({});
    const [orderList, setOrderList] = useState({});
    let { id } = useParams();
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`/customers/${id}`).then(response => {
            setLoading(false);
            if (isMountedRef.current) {
                if (response.status) {
                    setState(response.data);
                    setServiceList(response.data.services);
                    setTradeInList(response.data.trade_ins);
                    setOrderList(response.data.orders);
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, id])

    return (
        <div>
            <div style={{ paddingBottom: '5%' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Link underline='none' to={`/customers`} component={RouterLink}>
                            <div className={styles.flexMiddle} style={{color:theme.palette.gray.main}}>
                                <FiArrowLeft style={{ marginRight: 15 }} />
                                <Typography className={styles.subtitle}>{t('customer.customerList')}</Typography>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                                {t('title.dashboard')}
                            </Link>
                            <Link underline="hover" color="inherit" component={RouterLink} to="/customers">
                                {t('title.customers')}
                            </Link>
                            <Typography color="text.primary">{t('title.customerProfile')}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
                <Grid container spacing={3} style={{ padding: '3% 0 5% 0' }}>
                    <Grid item xs={12} sm={4} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} >
                        <Avatar className={styles.avatarStyle}>
                            <Typography fontSize={{ xs: 33, sm: '4rem' }} >{state && state.name ? (state.name[0] + state.name[1]) : "-"}</Typography>
                        </Avatar>
                    </Grid>
                    <Grid item xs={12} sm={4} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                        <div style={{width:'100%'}}>
                            <Box style={{ paddingBottom: 15 }} className={styles.flexTtopMiddle} >
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={2}>
                                        <FiUser className={styles.icon} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <div style={{ paddingLeft: 13 }}>
                                            <Typography className={styles.titleText}><b>{state && state.name ? state.name : "-"}</b></Typography>
                                            <Typography className={styles.contentText}>{state && state.ic ? state.ic : "-"}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={styles.flexTtopMiddle}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={2}>
                                        <FiPhone className={styles.icon} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <div style={{ paddingLeft: 13 }}>
                                            <Typography className={styles.contentText}>{state && state.mobile ? state.mobile : "-"}</Typography>
                                            <Typography className={styles.contentText}>{state && state.email ? state.email : "-"}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                        <div style={{width:'100%'}}>
                            <Box style={{ paddingBottom: 15 }} className={styles.flexTtopMiddle} >
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={2}>
                                        <FiMapPin className={styles.icon} />
                                    </Grid>
                                    <Grid item xs={10} sm={8}>
                                        <div style={{ paddingLeft: 13 }}>
                                            <Typography className={styles.contentText}>{state && _.size(state.addresses) >0 ? state.addresses[0]['full_address'] : "-"}</Typography>
                                        </div>
                                        { 
                                            permissions.includes('customer-edit') &&
                                            <Link underline='none' to={`/customer-edit/${id}`} component={RouterLink}>
                                                <Button size="large" variant="contained" style={{ width: '100%', maxWidth: 230, marginTop:15, borderRadius: '30px 0' }}>
                                                    {t('button.editCustomerProfile')}
                                                </Button>
                                            </Link>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
                <TabsUnstyled defaultValue={0}>
                    <TabsListUnstyled className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} >
                        <TabUnstyled className={styles.mainButtonWrap}>
                            <div style={{ margin:'0 auto', backgroundColor: theme.palette.primary.main }} className={styles.mainButtonStyle}>
                                <div className={`head ${styles.flexMiddle} ${styles.justifyCenter} `}>
                                    <img src="/images/customer/warranty.png" className={styles.iconImg} alt="warranty"/>
                                    <Hidden smDown>
                                        <Typography style={{ fontSize: 18, }}>
                                            {t('customer.warrantyRecord')}
                                        </Typography>
                                    </Hidden>
                                </div>
                            </div>
                            <Hidden smUp>
                                <Typography style={{ fontSize: 18, textAlign: 'center', color: theme.palette.primary.main, paddingTop: 15 }}>
                                    {t('customer.warrantyRecord')}
                                </Typography>
                            </Hidden>
                        </TabUnstyled>
                        <TabUnstyled className={styles.mainButtonWrap}>
                            <div style={{ margin:'0 auto', backgroundColor: theme.palette.secondary.main, }} className={styles.mainButtonStyle}>
                                <div className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                                    <img src="/images/customer/trade.png" className={styles.iconImg} alt="trade"/>
                                    <Hidden smDown>
                                        <Typography style={{ fontSize: 18, }}>
                                        {t('customer.tradeInRecord')}
                                        </Typography>
                                    </Hidden>
                                </div>
                            </div>
                            <Hidden smUp>
                                <Typography style={{ fontSize: 18, textAlign: 'center', color: theme.palette.secondary.main, paddingTop: 15 }}>
                                    {t('customer.tradeInRecord')}
                                </Typography>
                            </Hidden>
                        </TabUnstyled>
                        <TabUnstyled className={styles.mainButtonWrap}>
                            <div style={{ margin:'0 auto', backgroundColor: theme.palette.gray.main, }} className={styles.mainButtonStyle}>
                                <div className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                                    <img src="/images/customer/order.png" className={styles.iconImg} alt="order"/>
                                    <Hidden smDown>
                                        <Typography style={{ fontSize: 18, }}>
                                            {t('customer.orderRecord')}
                                        </Typography>
                                    </Hidden>
                                </div>
                            </div>
                            <Hidden smUp>
                                <Typography style={{ fontSize: 18, textAlign: 'center', color: theme.palette.gray.main, paddingTop: 15 }}>
                                    {t('customer.orderRecord')}
                                </Typography>
                            </Hidden>
                        </TabUnstyled>
                    </TabsListUnstyled>
                    <TabPanelUnstyled value={0}>
                        <ProfileWarranty serviceList={serviceList} permissions={permissions} />
                    </TabPanelUnstyled>
                    <TabPanelUnstyled value={1}>
                        <ProfileTradeIn tradeInList={tradeInList} permissions={permissions} />
                    </TabPanelUnstyled>
                    <TabPanelUnstyled value={2}>
                        <ProfilePurchase orderList={orderList} permissions={permissions} />
                    </TabPanelUnstyled>
                </TabsUnstyled>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    mainButtonStyle: {
        color: '#fff',
        borderRadius: 15,
        minHeight: 90,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: 80,
            height: 80,
            borderRadius:15,
            minHeight:'unset'
        },
    },
    mainButtonWrap: {
        background: 'transparent',
        border: 'none',
        padding: 0,
        cursor:'pointer',
        marginBottom:15,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '30%'
        },
    },
    icon: {
        fontSize: 27,
        marginTop: 2,
        color: theme.palette.gray.ltext
    },
    avatarStyle:{
         padding: 10,
        [theme.breakpoints.down('sm')]: {
            width: 80,
            height: 80,
        },
        [theme.breakpoints.up('sm')]: {
            width: 140,
            height: 140,
        },
    },
    iconImg: {
        width: 70,
        paddingRight: 15,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
            width: 60,
        },
    },
    titleText: {
        fontSize: '24px!important',
        color: theme.palette.gray.main,
        wordBreak:'break-all !important',
    },
    contentText: {
        fontSize: '18px!important',
        color: theme.palette.gray.main,
        wordBreak:'break-all !important',
    },
    justifyCenter:{
        justifyContent: 'center'
    },
    spaceBetween:{
        justifyContent:'space-between'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    flexTtopMiddle: {
        display: 'flex',
        alignItems: 'flex-start',
    },
}));