import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useNotificationLoading from "../../helpers/useNotificationLoading";
import { currencyFormat } from "../../helpers/Tools";
import { getUrl } from "../../helpers/ApiAction";
import { GENTING_URL } from '../../configs/Config';
import _ from 'lodash';

// MUI
// import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Grid, Typography, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper/*, Box, Hidden, Button*/,TextField ,Breadcrumbs} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import DateRangePicker from '@mui/lab/DateRangePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { FiArrowLeft/* , FiSearch */ } from "react-icons/fi";
import { VscTriangleUp, VscTriangleDown } from "react-icons/vsc";
import SalesGraph from './SalesGraph';


export default function Dashboard() {
    const navigate = useNavigate();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const { addAlert } = useNotificationLoading();

    //date picker
    // const [value, setValue] = React.useState([null, null]);

    const [summary, setSummary] = useState([]);
    const [summaryYearMonth, setSummaryYearMonth] = useState(new Date());
    const [recentOrder, setRecentOrder] = useState([]);
    const [individualSales, setIndividualSales] = useState([]);
    const [individualSalesYearMonth, setIndividualSalesYearMonth] = useState(new Date());

    const BRANCH = window.location.href.indexOf(GENTING_URL) > -1 ? 'genting' : 'others';

    useEffect(() => {
        isMountedRef.current = true;
        getUrl("/report/sales")
			.then((response) => {
                if(isMountedRef.current){
                    if (response.status) {
                        setSummary(response.data);
                    } else {
                        addAlert("", t("error.contactSupport"), "error", "");
                    }
                }
			})
			.catch((error) => {
                if(isMountedRef.current){
				    addAlert("", t("error.contactSupport"), "error", "");
                }
			});

        getRecentOrder();
        getIndividualSales();

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
	}, []);

    const handleChangeSalesSummaryYearMonth = (value) => {
        setSummaryYearMonth(value);
        const selectedDate = new Date(value);

        getUrl("/report/sales-summary", {month: selectedDate.getMonth()+1, year: new Date(value).getFullYear()})
			.then((response) => {
                if(isMountedRef.current){
                    if (response.status) {
                        setSummary({...summary, sales_summary: response.data.sales_summary});
                    } else {
                        addAlert("", t("error.contactSupport"), "error", "");
                    }
                }
			})
			.catch((error) => {
                if(isMountedRef.current){
				    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                }
			});
    }

    const handleChangeIndividualSalesYearMonth = (value) => {
        setIndividualSalesYearMonth(value);
        const selectedDate = new Date(value);

        getUrl("/report/individual-sales", {month: selectedDate.getMonth()+1, year: new Date(value).getFullYear()})
			.then((response) => {
                if(isMountedRef.current){
                    if (response.status) {
                        setIndividualSales(response.data.listing);
                    } else {
                        addAlert("", t("error.contactSupport"), "error", "");
                    }
                }
			})
			.catch((error) => {
                if(isMountedRef.current){
				    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                }
			});
    }

    const getRecentOrder = () => {
        getUrl("/report/recent-order")
			.then((response) => {
                if(isMountedRef.current){
                    if (response.status) {
                        setRecentOrder(response.data.listing);
                      
                    } else {
                        addAlert("", t("error.contactSupport"), "error", "");
                    }
                }
			})
			.catch((error) => {
                if(isMountedRef.current){
				    addAlert("", t("error.contactSupport"), "error", "");
                }
			});
    }

    const getIndividualSales = () => {
        getUrl("/report/individual-sales")
			.then((response) => {
                if(isMountedRef.current){
                    if (response.status) {
                        setIndividualSales(response.data.listing);
                    } else {
                        addAlert("", t("error.contactSupport"), "error", "");
                    }
                }
			})
			.catch((error) => {
                if(isMountedRef.current){
				    addAlert("", t("error.contactSupport"), "error", "");
                }
			});
    }

   
    const numberPercent = (percent) =>{
        return(
            <div style={{ color: `${theme.palette.indicator[percent > 0 ? "green" : (percent < 0 ? "red" : "yellow" )]}`, marginLeft:-15, paddingTop:8 }} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                {percent > 0 ? <VscTriangleUp style={{marginRight:15}}/> : (percent < 0 ? <VscTriangleDown style={{marginRight:15}}/> : null )}
                <Typography>{`${percent>0?'+':''}${currencyFormat(parseFloat(percent))}`} %</Typography>
            </div>
        )
    }

    return(
        <div>
            
            <div style={{ paddingBottom: 30, justifyContent:'space-between' }} className={styles.flexMiddle}>
          
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <span onClick={() => navigate(-1)} style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase', cursor:'pointer' }}>{t('button.back')}</span>
                </div>
        
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Typography color="text.primary">{t('title.salesReport')}</Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <div>

                {/* //Finish other part 1st, temporary hide */}
                <div>
                    {/* <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                        <div className={styles.flexMiddle} style={{ color: theme.palette.gray.ltext, }}>
                            <Typography style={{ fontSize: 18 }}><b>Monthly</b></Typography>
                            <VscTriangleDown style={{ marginLeft: 15 }} />
                        </div>
                        <div className={styles.flexMiddle} style={{zIndex:'-1'}}>
                            <div style={{ boxShadow: 'rgb(209 209 209 / 35%) 2px 2px 10px 5px', borderRadius: '11px 11px 0 0', padding: '8px 15px', marginRight: 15 }} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                                <Typography style={{ textAlign: 'center', color: theme.palette.gray.dtext }}>Quantity</Typography>
                            </div>
                            <div style={{ boxShadow: 'rgb(209 209 209 / 35%) 2px 2px 10px 5px', borderRadius: '11px 11px 0 0', padding: '8px 15px' }} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                                <Typography style={{ textAlign: 'center', color: theme.palette.secondary.secondary }}>Amount</Typography>
                            </div>
                        </div>
                    </div> */}
                    <div className={styles.boxStyle} style={{borderTopRightRadius:0, minHeight:'30vh'}}>
                        {/* <img src="images/example/chart.png" style={{width:'100%'}}/> */}
                        <SalesGraph data={_.has(summary, 'graph_summary') ? summary.graph_summary : null } />
                    </div>
                </div>
                {/* /////////////////*/}


                {/* //only Master Admin can show this  */}
                <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{width:'100%', paddingTop:40}}>
                    <Typography style={{ color: theme.palette.gray.ltext, fontSize: 18 }}><b>{t('report.salesSummary')}</b></Typography>
                    <div className={styles.flexMiddle} style={{color: theme.palette.gray.ltext, }}>
                        {/* <Typography style={{ fontSize: 18 }}><b>{t('report.monthly')}</b></Typography> */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                views={['year', 'month']}
                                label="Year and Month"
                                minDate={new Date('2022-05-01')}
                                maxDate={new Date()}
                                value={summaryYearMonth}
                                onChange={handleChangeSalesSummaryYearMonth}
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                            />
                        </LocalizationProvider> 
                        {/* <VscTriangleDown style={{ marginLeft: 15 }} /> */}
                    </div>
                </div>
                <Grid container spacing={3} style={{marginTop:0}}>
                    <Grid item xs={12} sm={4} style={{paddingTop:15}}>
                        <div style={{textAlign:'center'}} className={styles.boxStyle}>
                            <Typography style={{color:theme.palette.gray.ltext}}>{t('report.totalSales')} (RM)</Typography>
                            <Typography className={styles.priceText}>{currencyFormat(parseFloat(summary?.sales_summary?.total_sales|| 0.00))}</Typography>
                            {/* {numberPercent(summary?.sales_summary?.total_sales_percent || 0.00)} */}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{paddingTop:15}}>
                        <div style={{textAlign:'center'}} className={styles.boxStyle}>
                            <Typography style={{color:theme.palette.gray.ltext}}>{t('report.totalDiscountGiven')} (RM)</Typography>
                            <Typography className={styles.priceText}>{currencyFormat(parseFloat(summary?.sales_summary?.total_discount || 0.00))}</Typography>
                            {/* {numberPercent(summary?.sales_summary?.total_discount_percent || 0.00)} */}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{paddingTop:15}}>
                        <div style={{textAlign:'center'}} className={styles.boxStyle}>
                            <Typography style={{color:theme.palette.gray.ltext}}>{t('report.totalProfit')} (RM)</Typography>
                            <Typography className={styles.priceText}>{currencyFormat(parseFloat((BRANCH === 'others' ? summary?.sales_summary?.total_profit2 : summary?.sales_summary?.total_profit1) || 0.00))}</Typography>
                            {/* {numberPercent((BRANCH === 'others' ? summary?.sales_summary?.total_profit2_percent : summary?.sales_summary?.total_profit1_percent) || 0.00)} */}
                        </div>
                    </Grid>
                </Grid>
                <div style={{ paddingTop: 15, paddingRight: 15, textAlign: 'right' }}>
                    <Link underline='none' component={RouterLink} to={`/salesProfitReport`}>
                        <Typography style={{ color: theme.palette.secondary.main, fontSize: 16, textDecoration: 'underline' }}>{t('report.details')}</Typography>
                    </Link>
                </div>
                {/* //////////////////////////////////*/}
            </div>

           

            <div style={{paddingTop:50}}>
                <Grid container>
                    <Grid item xs={12} sm={6} md={4} className={styles.flexMiddle}>
                        <Typography style={{ color: theme.palette.gray.ltext, fontSize: 18 }}><b>{t('report.recentOrder')}</b></Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} style={{display:'flex', justifyContent:'flex-end'}}>
                    {/* <div className={styles.flexMiddle} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateRangePicker
                                startText={t('report.fromDate')}
                                endText={t('report.toDate')}
                                value={value}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                }}
                                renderInput={(startProps, endProps) => (
                                    <React.Fragment>
                                        <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }}> {t('report.to')} </Box>
                                        <TextField {...endProps} />
                                    </React.Fragment>
                                )}
                            />
                        </LocalizationProvider>
                        <Button style={{ width: 40, height: 40, minWidth: 'unset', marginLeft: 15, borderRadius: 48, backgroundColor: theme.palette.secondary.main }} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                            <FiSearch style={{ color: '#fff', fontSize: 21 }} />
                        </Button>
                    </div> */}
                    </Grid>
                </Grid>

                <TableContainer component={Paper} style={{    boxShadow: '0 0 20px 0 #e3e3e3', marginTop: 15, borderRadius: 15}}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('report.date')}</TableCell>
                                <TableCell>{t('order.receiptNo')}</TableCell>
                                <TableCell>{t('report.salesman')}</TableCell>
                                <TableCell>{t('report.orderStatus')}</TableCell>
                                <TableCell align="right">{t('report.totalAmount')} (RM)</TableCell>
                                <TableCell align="right">{t('report.action')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.size(recentOrder) > 0 && recentOrder.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{width:200}} component="th" scope="row">
                                        {row.created_at}
                                    </TableCell>
                                    <TableCell style={{width:200}}>{row.order_no}</TableCell>
                                    <TableCell >{row.user.name}</TableCell>
                                    <TableCell style={{width:200}}>{row.status_name[i18n.language]?row.status_name[i18n.language]:'-'}</TableCell>
                                    <TableCell style={{width:200}} align="right">{currencyFormat(parseFloat(row.total_price))}</TableCell>
                                    <TableCell style={{width:150}} align="right">
                                        <Link underline='none' to={`/orders/${row.id}`} component={RouterLink}>{t('button.view')}</Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{paddingTop:50}}>
                    <Grid item className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{width:'100%', paddingTop:40}}>
                        <Typography style={{ color: theme.palette.gray.ltext, fontSize: 18 }}><b>{t('report.individualSales')}</b></Typography>
                        <div className={styles.flexMiddle} style={{color: theme.palette.gray.ltext, }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={['year', 'month']}
                                    label="Year and Month"
                                    minDate={new Date('2022-05-01')}
                                    maxDate={new Date()}
                                    value={individualSalesYearMonth}
                                    onChange={handleChangeIndividualSalesYearMonth}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                />
                            </LocalizationProvider> 
                        </div>
                    </Grid>
                    <TableContainer component={Paper} style={{    boxShadow: '0 0 20px 0 #e3e3e3', marginTop: 15, borderRadius: 15}}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>{t('report.date')}</TableCell> */}
                                    <TableCell>{t('report.salesman')}</TableCell>
                                    <TableCell align="right">{t('report.totalSales')} (RM)</TableCell>
                                    <TableCell align="right">{t('report.totalDiscount')} (RM)</TableCell>
                                    <TableCell align="right">{t('report.totalProfit')} (RM)</TableCell>
                                    <TableCell align="right">{t('report.totalCommission')} (RM)</TableCell>
                                    <TableCell align="right">{t('report.netProfit')} (RM)</TableCell>
                                    <TableCell align="right">{t('report.action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.size(individualSales) > 0 && individualSales.map((individual) => (
                                    <TableRow
                                        key={individual.salesperson} 
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {/* <TableCell style={{width:200}} component="th" scope="row">{individual.date}</TableCell> */}
                                        <TableCell >{individual.salesperson}</TableCell>
                                        <TableCell style={{width:200}} align="right">{individual.total_sales || "0.00"}</TableCell>
                                        <TableCell style={{width:200}} align="right">{individual.total_discount || "0.00"}</TableCell>
                                        <TableCell style={{width:200}} align="right">{currencyFormat(parseFloat(BRANCH === 'genting' ? individual.total_profit1 : individual.total_profit2)) || "0.00"}</TableCell>
                                        <TableCell style={{width:200}} align="right">{currencyFormat(parseFloat(individual.total_commission)) || "0.00"}</TableCell>
                                        <TableCell style={{width:200}} align="right">{currencyFormat(parseFloat(BRANCH === 'genting' ? individual.net_profit1 : individual.net_profit2)) || "0.00"}</TableCell>
                                        <TableCell style={{width:200}} align="right">
                                            <Link underline='none' to={`/salesReport/${individual.user_id}`} component={RouterLink}>{t('button.view')}</Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>


            </div>
        </div>
        
    );
}

const useStyles = makeStyles(theme => ({
    boxStyle: {
        background: '#fff',
        borderRadius: 15,
        boxShadow: '2px 2px 10px 5px rgb(209 209 209 / 30%)',
        padding: 15,
        height:'100%'
    },
    menuImg:{
        maxWidth:'100%',
        width:'100%'
    },
    menuText:{
        textAlign:'center',
        fontSize:'14px!important',
        paddingTop:15
    },
    priceText:{
        color:theme.palette.secondary.main,
        fontSize:'25px!important',
        fontWeight:'bold!important'
    },
    justifyCenter:{
        justifyContent:'center'
    },
    spaceBetween:{
        justifyContent:'space-between'
    },
    flexMiddle:{
        display:'flex',
        alignItems:'center',
    },
    flexTtopMiddle:{
        display:'flex',
        alignItems:'flex-start',
        justifyContent:'center'
    },
    
  }));
