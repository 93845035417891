import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl, postUrl } from '../../helpers/ApiAction';

// MUI
import { Grid, Typography, Link, TextField, Box, Button, Breadcrumbs } from '@mui/material';
import { FiArrowLeft } from "react-icons/fi";

export default function Detail() {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [state, setState] = useState({
        name: '',
        logo: '',
        image: '',
        existing_logo: '',
        existing_image: ''
    });
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    let { id } = useParams();
    const isMountedRef = useRef(null);
    const location = useLocation();
    const formType = location.pathname === "/brand-add" ? "add" : "edit";

    useEffect(() => {
        isMountedRef.current = true;

        if(formType === 'edit' && id){
            setLoading(true);
            getUrl(`/brands/${id}`).then(response => {
                setLoading(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        setState({
                            ...state,
                            name: response.data.name,
                            existing_image: response.data.image,
                            existing_logo: response.data.logo,
                        });
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, id])

    const submitData = (e) => {
        e.preventDefault();
        const formData = new FormData();
        if(state.logo){
            formData.append("logo",state.logo);
        }
        if(state.image){
            formData.append("image",state.image);
        }
        formData.append("name",state.name);
        formData.append("_method", formType === "edit" ? "put" : "post");

        setLoading(true);
        setInputErrors();

        postUrl(`/brands${formType === "edit" ? `/${id}` : ""}`, formData).then(response => {
            setLoading(false);
            let { status , data, errors, message } = response;
            if (status) {
                addAlert('', message, 'success', '');
                setState({
                    ...state,
                    existing_image: data.image,
                    existing_logo: data.logo,
                });

            } else {
                setInputErrors(errors);
                addAlert('', message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div>
            <Link underline='none' component={RouterLink} to={`/brands`}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.back')}</Typography>
                </div>
            </Link>
            <div style={{ paddingBottom: '5%' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, color:theme.palette.gray.dtext }}>
                        <Typography style={{ paddingBottom: 15, fontSize: 18 }}><b>{formType === "edit" ? t('title.brandEdit') : t('title.brandAdd')}</b></Typography>
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                                {t('title.dashboard')}
                            </Link>
                            <Link underline="hover" color="inherit" component={RouterLink} to="/brands">
                                {t('title.brands')}
                            </Link>
                            <Typography style={{color:theme.palette.button.main}}>{formType === "edit" ? t('title.brandEdit') : t('title.brandAdd')}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
                <Box component="form" autoComplete="on" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }}} onSubmit={submitData}>
                    <Grid item xs={12} container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="standard-basic"
                                label={t('brand.name')}
                                variant="outlined"
                                type="text"
                                autoComplete="name"
                                value={state.name}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value })}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
                            {state.existing_logo && <img src={state.existing_logo.file_name} alt="logo" style={{width:'auto', height:150}} />}
                            <TextField
                                id="standard-basic"
                                label={t('brand.logo')}
                                variant="outlined"
                                type="file"
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.logo ? inputErrors.logo : t('brand.recommendedSize', { width: '456px', height: '238px' })}
                                error={inputErrors && inputErrors.logo ? true : false}
                                onChange={({ target }) => setState({ ...state, logo: target.files[0] })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
                            {state.existing_image && <img src={state.existing_image.file_name} alt="brand img"  style={{width:'auto', height:150}}/>}
                            <TextField
                                id="standard-basic"
                                label={t('brand.image')}
                                variant="outlined"
                                type="file"
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.image ? inputErrors.image : t('brand.recommendedBrandCoverImageSize', { width: '800px', height: '870px' })}
                                error={inputErrors && inputErrors.image ? true : false}
                                onChange={({ target }) => setState({ ...state, image: target.files[0] })}
                            />
                        </Grid>
                    </Grid>
                    <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link underline='none' to={`/brands`} component={RouterLink}>
                            <Button type="button" size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15,color:theme.palette.button.main, border:theme.palette.button.borderMain, boxShadow:'2px 4px 8px 0 #9f9f9f45' }}>{t('button.cancel')}</Button>
                        </Link>
                        <Button type="submit" size="large" variant="contained" style={{backgroundColor:theme.palette.button.main, boxShadow:'2px 4px 8px 0 #00000045'}} className={styles.buttonStyle}>{t(`button.${formType==="edit"? "save":formType}`)}</Button>
                    </Box>
                </Box>
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150,
        borderRadius:8,
    }, 
    flexMiddle:{
        display:'flex',
        alignItems:'center'
    }

}));