import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl } from '../../helpers/ApiAction';
import NumberFormat from 'react-number-format';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

// Owl Carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// MUI
import { Typography, Link, OutlinedInput,Breadcrumbs } from '@mui/material';
import FormControl from '@mui/material/FormControl';

// ICONS
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";

export default function Index() {
    const navigate = useNavigate();
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [menuProduct, setMenuProduct] = useState({});
    const [brandList, setBrandList] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchToggle, setSearchToggle] = useState(false);

    useEffect(() => {
        setLoading(true);
        getUrl('/products/menu', { q: searchText }).then(response => {
            setLoading(false);
            if (response.status) {
                setMenuProduct(response.data.listing);
                setBrandList(response.data.brand_list);
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [searchToggle]);

    return (
        <div>
             <div style={{ paddingBottom: 30, justifyContent:'space-between' }} className={styles.flexMiddle}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <span onClick={() => navigate(-1)} style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase', cursor:'pointer' }}>{t('button.back')}</span>
                </div>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Typography color="text.primary">{t('title.menu')}</Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <div className={styles.flexCenterMiddle} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', maxWidth: '100%' }}>
                <div style={{ minWidth: 100 }}>
                    <Typography style={{ fontSize: 18, paddingRight: 15 }}><b>{t('menu.products')}</b></Typography>
                </div>
                <ThemeProvider theme={inputStyle}>
                    <FormControl style={{ width: 500, maxWidth: '100%' }}>
                        <OutlinedInput
                            placeholder={t('menu.searchPlaceholder')}
                            value={searchText}
                            onChange={(event) => setSearchText(event.target.value)}
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    setSearchToggle(!searchToggle);
                                }
                            }}
                        />
                    </FormControl>
                </ThemeProvider>
            </div>

            {/* BRAND */}
            <div style={{ paddingTop: 30 }}>
                <div style={{ paddingLeft: 10, borderLeft: '8px solid #c4a283' }}> {/* marginBottom:180  */}
                    <Typography style={{ color: theme.palette.secondary.main, fontSize: 18 }}>{t('menu.byBrand')}</Typography>
                </div>
                <div style={{ paddingBottom: 25 }}>
                    {
                        _.size(brandList) > 0
                            ?
                            <OwlCarousel className='owl-theme' items={5} autoWidth={true} margin={10} nav>
                                {
                                    _.map(brandList, (brand, key) => {
                                        let color = '#5e4536';
                                        switch(key % 4){
                                            case 0: color = '#5e4536'; break;
                                            case 1: color = '#c4a283'; break;
                                            case 2: color = '#969493'; break;
                                            case 3: color = '#475675'; break;
                                            default: color = '#5e4536';
                                        }
                                        return (
                                            <div className='item' key={brand.id}>
                                                <div style={{ width: 250, padding: 15 }}>
                                                    <div style={{ backgroundColor: color, marginTop: 150 }} className={styles.categoryBox}>
                                                        <div style={{ marginTop: '-150px' }}>
                                                            <img src={brand.image?brand.image.file_name:"/images/emptyStatus/noproductimg.jpg"} style={{ width: '100%', maxWidth: '100%', borderRadius: 15, height:224, objectFit:'cover' }} alt='brand_image' />
                                                            <Link underline='none' component={RouterLink} to={`/menu/brand/` + brand.id}>
                                                                <div className={styles.categoryText}>
                                                                    <Typography style={{ fontSize: 18 }}>
                                                                        {brand.name}
                                                                    </Typography>
                                                                    <Typography style={{ fontSize: 12 }}>
                                                                        {t('menu.brandTotal', { total: brand.brand_total ? brand.brand_total : 0 })}
                                                                    </Typography>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                            :
                            <div className={styles.flexCenterMiddle} style={{flexDirection:'column'}}>
                                <img src="/images/emptyStatus/nobrandimg.png" alt="noresult" style={{ width: 180, margin: '0 auto' }} />
                                <Typography style={{color:theme.palette.gray.main}}>
                                    {t('emptyStatus.noBrand')}
                                </Typography>
                            </div>
                    }
                </div>
            </div>

            {/* PRODUCT */}
            <div style={{ paddingTop: '5%' }}>
                <div style={{ paddingLeft: 10, borderLeft: '8px solid #c4a283', marginBottom: 30 }}>
                    <Typography style={{ color: theme.palette.secondary.main, fontSize: 18 }}>{t('menu.newProduct')}</Typography>
                </div>
                {
                    _.size(menuProduct) > 0
                        ?
                        <OwlCarousel className='owl-theme' items={5} autoWidth={true} loop margin={10} nav>
                            {
                                _.map(menuProduct, (product, key) => {
                                    if (key < 12) {
                                        return (
                                            <div className='item' key={product.id}>
                                                <div style={{ width: 350, padding: 15 }}>
                                                    <img src={_.size(product.images)?product.images[0].file_name:"/images/emptyStatus/noproductimg.jpg"} className={styles.productImg} alt="product"/>
                                                    <Link underline='none' component={RouterLink} to={`/menu/product/` + product.id}>
                                                        <div>
                                                            <Typography className={styles.productTitle}>
                                                                {product.name}
                                                            </Typography>
                                                        </div>
                                                        <div className={styles.flexSbM} style={{ paddingTop: 5 }}>
                                                            <Typography className={styles.priceText}>
                                                                <NumberFormat thousandSeparator={true} prefix="RM " value={product.sell_price} displayType="text" />
                                                            </Typography>
                                                            <FiArrowRight style={{ marginLeft: 15, fontSize: 17, color: theme.palette.gray.main }} />
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </OwlCarousel>
                        :
                        <div className={styles.flexCenterMiddle} style={{flexDirection:'column'}}>
                        <img src="/images/emptyStatus/noproductimg.jpg" alt="noresult" style={{ width: 180, margin: '0 auto' }} />
                        <Typography style={{color:theme.palette.gray.main}}>
                            {t('emptyStatus.noProduct')}
                        </Typography>
                    </div>
                }
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    categoryBox: {
        borderRadius: 18,
        padding: 15,
        boxShadow: '4px 4px 12px 0 #00000036'
    },
    categoryText: {
        textAlign: 'center',
        color: '#fff',
        padding: 15
    },
    productTitle: {
        color: theme.palette.gray.main,
        fontSize: '18px!important',
        paddingTop: 10,
        fontWeight: 'bold!important',
        minHeight:66
    },
    brandText: {
        color: theme.palette.gray.ltext,
        fontSize: '14px!important',
        textTransform: 'uppercase',
        width: 164,
        textAlign: 'center',
        paddingTop: 10
    },
    priceText: {
        color: theme.palette.gray.main,
        fontSize: '17px!important',
    },
    flexSbM: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    flexCenterMiddle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    productImg:{
        width: '100%',
         maxWidth: '100%',
         borderRadius: 15,
         objectFit:'cover',
         boxShadow:'0 0 16px 0 #e5e5e5',
         [theme.breakpoints.down('sm')]: {
            height:282,
          },
          [theme.breakpoints.up('sm')]: {
            height:300,
          },
          [theme.breakpoints.up('md')]: {
            height:350,
          },
    },
    

}));

const inputStyle = createTheme({
    components: {
        // Name of the component
        MuiOutlinedInput: {
            styleOverrides: {
                // Name of the slot
                input: {
                    // Some CSS
                    padding: '28.5px 14px',
                    boxSizing: 'inherit',
                    borderRadius: 48
                },
                root: {
                    borderRadius: 48,
                    boxShadow: '3px 3px 8px 0 #ccc'
                },
            },
        },
    },
});
