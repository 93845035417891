// import React, { useState, useEffect, useRef } from 'react';
// import { useTranslation } from 'react-i18next';
// import { makeStyles, useTheme } from '@mui/styles';
// import { Link as RouterLink } from 'react-router-dom';

// // MUI
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { Grid, Typography, Box, Link,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, Menu, Button, Modal, TextField, Backdrop, Fade } from '@mui/material';

// import DateRangePicker from '@mui/lab/DateRangePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';

// import { FiArrowLeft, FiChevronDown, FiSearch } from "react-icons/fi";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 800,
//     maxWidth:'90%',
//     bgcolor: 'background.paper',
//     // border: '2px solid #000',
//     borderRadius:3,
//     boxShadow: 24,
//     p: 2,
//   };

// //Table
// function createData(type,  moneyin, moneyout, balance, action) {
//     return { type, moneyin, moneyout, balance, action };
//   }
  
//   const rows = [
//     createData('2022-03-28', 2000000, 20000,180000,'Details'),
//     createData('2022-03-27', 2000000, 20000,180000,'Details'),
//     createData('2022-03-26', 2000000, 20000,180000,'Details'),
//     createData('2022-03-25', 2000000, 20000,180000,'Details'),
//     createData('2022-03-24', 2000000, 20000,180000,'Details'),
//     createData('2022-03-23', 2000000, 20000,180000,'Details'),
//   ];

// export default function DrawerWalletSummary() {
//     const [anchorE2, setAnchorE2] = useState(null);

//     const styles = useStyles();
//     const { t, i18n } = useTranslation();
//     const theme = useTheme();

//     useEffect(() => {

//     }, []);


//     const handleMenuType = (event) => {
//         setAnchorE2(event.currentTarget);
//     };

 
//     const handleCloseType = () => {
//         setAnchorE2(null);
//     };

//     const [open, setOpen] = React.useState(false);
//     const handleOpen = () => {
//         setOpen(true);
//     };
//     const handleClose = () => {
//         setOpen(false);
//     };

//     //date picker
//     const [value, setDateRange] = React.useState([null, null]);


//     return(
//         <div>
//             <Link underline='none' component={RouterLink} to={`/drawer-summary`}>
//                 <div className={styles.flexMiddle} style={{ paddingBottom: 30, width:'100%', }}>
//                     <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
//                     <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.backTo')}{t('title.drawer')}</Typography>
//                 </div>
//             </Link>

//             <div style={{ padding: '20px 0' }}>
//                 <Typography style={{ color: theme.palette.gray.dtext, }}>Total Cash Amount (RM)</Typography>
//                 <Typography style={{ color: theme.palette.secondary.secondary, fontSize: 40 }}><b>21,899,999.00</b></Typography>

//                 <div style={{ paddingTop: 30 }}>

//                     <div className={`head ${styles.flexMiddle} ${styles.spaceBetween} ${styles.mobileColumn}`} style={{width:'100%'}}>
//                         <div>
//                             <Button
//                                 size="large"
//                                 aria-label="account of current user"
//                                 aria-controls="menu-drawerType"
//                                 aria-haspopup="true"
//                                 onClick={handleMenuType}
//                                 color="inherit"
//                             >
//                                 <div style={{ color: theme.palette.gray.ltext, }} className={styles.flexMiddle}>
//                                     <Typography style={{ fontSize: 18, textAlign:'left' }}><b>Cash Drawer Report <span style={{paddingLeft:15}}><FiChevronDown /></span></b></Typography>
//                                 </div>
//                             </Button>
//                             <Menu
//                                 id="menu-drawerType"
//                                 anchorE2={anchorE2}
//                                 anchorOrigin={{
//                                     vertical: 'top',
//                                     horizontal: 'right',
//                                 }}
//                                 keepMounted
//                                 transformOrigin={{
//                                     vertical: 'top',
//                                     horizontal: 'right',
//                                 }}
//                                 open={Boolean(anchorE2)}
//                                 onClose={handleCloseType}
//                             >
//                                 <MenuItem onClick={handleCloseType}>USDT</MenuItem>
//                                 <MenuItem onClick={handleCloseType}>Online Transfer</MenuItem>
//                                 <MenuItem onClick={handleCloseType}>Chip</MenuItem>
//                             </Menu>
//                         </div>
//                         <div className={styles.flexMiddle} >
//                             <LocalizationProvider dateAdapter={AdapterDateFns}>
//                                 <DateRangePicker
//                                     startText="From Date"
//                                     endText="To Date"
//                                     value={value}
//                                     onChange={(newValue) => {
//                                         setDateRange(newValue);
//                                     }}
//                                     renderInput={(startProps, endProps) => (
//                                         <React.Fragment>
//                                             <TextField {...startProps} />
//                                             <Box sx={{ mx: 2 }}> to </Box>
//                                             <TextField {...endProps} />
//                                         </React.Fragment>
//                                     )}
//                                 />
//                             </LocalizationProvider>
//                             <Button style={{width:40, height:40, minWidth:'unset', marginLeft:15, borderRadius:48, backgroundColor:theme.palette.secondary.main}} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
//                                 <FiSearch style={{color:'#fff', fontSize:21}}/>
//                             </Button>
//                         </div>
//                     </div>

//                     <ThemeProvider theme={tableStyle}>
//                         <TableContainer component={Paper} style={{ marginTop: 15, boxShadow: '0 0 20px 0 #e0e0e0', borderRadius: 15 }}>
//                             <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
//                                 <TableHead>
//                                     <TableRow>
//                                         <TableCell style={{color:theme.palette.gray.ltext}}>Date</TableCell>
//                                         <TableCell style={{color:theme.palette.gray.ltext}} align="right">Cash In</TableCell>
//                                         <TableCell style={{color:theme.palette.gray.ltext}} align="right">Cash Out</TableCell>
//                                         <TableCell style={{color:theme.palette.gray.ltext}} align="right">Balance</TableCell>
//                                         <TableCell style={{color:theme.palette.gray.ltext}} align="right">Action</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {rows.map((row) => (
//                                         <TableRow
//                                         key={row.type}
//                                         sx={{ '&:last-child td, &:last-child th': { border: 0, color:theme.palette.gray.dtext } }}
//                                     >
//                                         <TableCell component="th" scope="row">
//                                             {row.type}
//                                         </TableCell>
//                                         <TableCell style={{ width: 160 }} align="right">{row.moneyin}</TableCell>
//                                         <TableCell  style={{ width: 160 }}align="right">{row.moneyout}</TableCell>
//                                         <TableCell style={{ width: 160 }} align="right">{row.balance}</TableCell>
//                                         <TableCell onClick={handleOpen} style={{ width: 120, color:theme.palette.secondary.main, cursor:'pointer' }} align="right">
//                                         {row.action}
//                                         </TableCell>
//                                     </TableRow>
                                    
//                                     ))}
//                                 </TableBody>
                                
//                             </Table>
//                         </TableContainer>
//                     </ThemeProvider>
//                     <Modal
//                         aria-labelledby="transition-modal-title"
//                         aria-describedby="transition-modal-description"
//                         open={open}
//                         onClose={handleClose}
//                         closeAfterTransition
//                         BackdropComponent={Backdrop}
//                         BackdropProps={{
//                             timeout: 500,
//                         }}
//                     >
//                         <Fade in={open}>
//                             <Box sx={style}>
//                                 <div style={{paddingBottom:10}}>
//                                     <Typography id="transition-modal-title" variant="h6" component="h2" style={{ color: theme.palette.gray.dtext }}>
//                                         Cash Drawer Report
//                                     </Typography>
//                                     <Typography style={{ fontSize: 16, color: theme.palette.gray.ltext }}>
//                                         28-03-2022
//                                     </Typography>
//                                 </div>
//                                 <Grid container style={{paddingTop:15, alignItems:'center'}}>
//                                     <Grid item xs={6} sm={8} md={8} style={{color:theme.palette.gray.dtext}}>
//                                         <Typography className={styles.textLine1}>INV 1287462155</Typography>
//                                     </Grid>
//                                     <Grid item xs={6} sm={4} md={4}>
//                                         <Typography style={{textAlign:'right', color:'#47b29e'}}>+ RM 1,546,840.00</Typography>
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container style={{paddingTop:15, alignItems:'center'}}>
//                                     <Grid item xs={6} sm={8} md={8} style={{color:theme.palette.gray.dtext}}>
//                                         <Typography className={styles.textLine1}>INV 1581234</Typography>
//                                     </Grid>
//                                     <Grid item xs={6} sm={4} md={4}>
//                                         <Typography style={{textAlign:'right', color:'#dd5858'}}>- RM 546,840.00</Typography>
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container style={{paddingTop:15, alignItems:'center'}}>
//                                     <Grid item xs={6} sm={8} md={8} style={{color:theme.palette.gray.dtext}}>
//                                         <Typography className={styles.textLine1}>INV 1287462155</Typography>
//                                     </Grid>
//                                     <Grid item xs={6} sm={4} md={4}>
//                                         <Typography style={{textAlign:'right', color:'#47b29e'}}>+ RM 1,546,840.00</Typography>
//                                     </Grid>
//                                 </Grid>
//                             </Box>
//                         </Fade>
//                     </Modal>
//                 </div>
//             </div>
//         </div>
        
//     );
// }

// const useStyles = makeStyles(theme => ({ 
//     justifyCenter:{
//         justifyContent:'center'
//     },
//     flexMiddle:{
//         display:'flex',
//         alignItems:'center',
//     },
//     mobileColumn:{
//         [theme.breakpoints.down('sm')]: {
//             flexDirection:'column',
//             alignItems:'flex-start'
//           },
//           [theme.breakpoints.up('sm')]: {
//             flexDirection:'row',
//             alignItems:'center'
//           },
//     },
//     textLine1:{
//         whiteSpace:'nowrap', 
//         width:'100%',
//         textOverflow:'ellipsis',
//         overflow:'hidden'
//     },
//     spaceBetween:{
//         justifyContent:'space-between'
//     },
//     flexTtop:{
//         display:'flex',
//         alignItems:'flex-start',
//     },
        
    //   }));

//   const tableStyle = createTheme({
//     components: {
//         // Name of the component
//         MuiTableCell: {
//             styleOverrides: {
//                 // Name of the slot
//                 root: {
//                     // Some CSS
//                     lineHeight:'2.5rem'
//                 },
//             },
//         },
//     },
// });

import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

// MUI
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { TableFooter, TablePagination, IconButton, TextField, Typography, Box, Link,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, Menu, Button, Modal, Backdrop, Fade, Grid,Breadcrumbs } from '@mui/material';
import { FiArrowLeft, FiChevronDown, FiSearch } from "react-icons/fi";
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { currencyFormat, useQuery } from '../../helpers/Tools';

export default function DrawerWalletSummary() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [wallet, setWallet] = useState(null);
    const [walletList, setWalletList] = useState(null);
    const [data, setData] = useState([]);
    const [state, setState] = useState({ from_date: null, to_date: null });
    const [totalAmount, setTotalAmount] = useState(0);
    const [dateRange, setDateRange] = useState([null, null]);
    const [page, setPage] = useState(0);
  	const [rowsPerPage, setRowsPerPage] = useState(5);

    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();
    let query = useQuery();
    const queryWallet = query.get('wallet');

    const isMountedRef = useRef();

    useLayoutEffect(() => {
        if(queryWallet) {
            setWallet(queryWallet);
        } else {
            setWallet("all");
        }
    }, [queryWallet]);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        console.log('state', state);
        if(wallet) {
            getUrl(`/drawer-logs-wallet-summary/${wallet}?from_date=${state.from_date}&to_date=${state.to_date}`).then(response => {
                setLoading(false);
                if(response.status && isMountedRef.current) {
                    if(!walletList) {
                        setWalletList(response.data.wallet_type);
                    }
                    setData(response.data.listing);
                    setTotalAmount(response.data.total_balance);
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }

        return () => isMountedRef.current = false;
    }, [wallet, state]);

    const filterDate = () => {
        if(dateRange) {
            let fromDate = null;
            let toDate = null;

            if(dateRange[0]) {
                fromDate = formatDate(dateRange[0]); // new Date(dateRange[0]).toLocaleDateString('YYYY-MM-DD');
            }

            if(dateRange[1]) {
                toDate = formatDate(dateRange[1]); // new Date(dateRange[1]).toLocaleDateString('YYYY-MM-DD');
            }
            
            setState({ from_date: fromDate, to_date: toDate });
        }
    }

    const clearFilter = () => {
        setDateRange([null, null]);
        setState({ from_date: null, to_date: null });
    }

    //DropDown Menu
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const selectType = selectedWallet => {
        // setWallet(selectedWallet);
        navigate(`/drawer-wallet-summary?wallet=${selectedWallet}`);
        setAnchorEl(null);
    }

    const selectedWalletName = useMemo(() => {
        let name = "";
        if(wallet && walletList) {
            const selectedWallet = _.find(walletList, { code: wallet });
            name = selectedWallet ? selectedWallet[i18n.language] : t('accounting.all');
        }

        return name;
    }, [i18n.language, wallet, walletList])

    // Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

    return(
        <div>
            <div style={{ paddingBottom: 30, justifyContent:'space-between' }} className={styles.flexMiddle}>
            <Link underline='none' component={RouterLink} to={`/drawer-summary`}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.back')}</Typography>
                </div>
            </Link>

            <div>
                <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Link underline="hover" color="inherit" component={RouterLink} to="/accounting">
                        {t('title.accounting')}
                    </Link>
                    <Link underline="hover" color="inherit" component={RouterLink} to="/drawer-summary">
                        {t('accounting.drawerSummary')}
                    </Link>
                    <Typography color="text.primary">{t('title.drawer')}</Typography>
                </Breadcrumbs>
            </div>
            </div>


           
            <div style={{ padding: '20px 0' }}>
                <Typography style={{ color: theme.palette.gray.dtext }}>{t('accounting.totalAmount')} (RM)</Typography>
                <Typography style={{ color: theme.palette.secondary.secondary, fontSize: 40 }}><b>{ currencyFormat(totalAmount) }</b></Typography>

                <div style={{ paddingTop: 30 }}>
                    <div className={`head ${styles.flexMiddle} ${styles.spaceBetween} ${styles.mobileColumn}`} style={{width:'100%'}}>
                        <div style={{ width: '100%' }}>
                            <Button size="large" onClick={handleMenu} color="inherit">
                                <div style={{ color: theme.palette.gray.ltext, }} className={styles.flexMiddle}>
                                    <Typography style={{ fontSize: 18, textAlign:'left' }}>
                                        <b>{ selectedWalletName }</b>
                                    </Typography>
                                    <FiChevronDown />
                                </div>
                            </Button>
                            <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(anchorEl)}  onClose={() => setAnchorEl(null)}>
                            <MenuItem onClick={() => selectType('all') }>{ t('accounting.all') }</MenuItem>
                                { _.map(walletList, walletItem => {
                                    return <MenuItem key={walletItem.code} onClick={() => selectType(walletItem.code) }>{walletItem[i18n.language]}</MenuItem>
                                })}
                            </Menu>
                        </div>
                        <div className={styles.flexMiddle}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateRangePicker
                                    startText="From Date"
                                    endText="To Date"
                                    value={dateRange}
                                    onChange={(newValue) => {
                                        setDateRange(newValue);
                                    }}
                                    mask="____-__-__"
                                    inputFormat="yyyy-MM-dd"
                                    toolbarFormat="yyyy-MM-dd"
                                    renderInput={(startProps, endProps) => (
                                        <>
                                            <TextField {...startProps} />
                                                <Box sx={{ mx: 2 }}> {t('general.to')} </Box>
                                            <TextField {...endProps} />
                                        </>
                                    )}
                                />
                            </LocalizationProvider>
                            <Button onClick={filterDate} style={{width:40, height:40, minWidth:'unset', marginLeft:15, borderRadius:48, backgroundColor:theme.palette.secondary.main}} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}>
                                <FiSearch style={{color:'#fff', fontSize:21}}/>
                            </Button>
                            <Button onClick={clearFilter} style={{ marginLeft: 20, marginRight: 20 }}>
                                <Typography>{ t('general.clear') }</Typography>
                            </Button>
                        </div>
                    </div>
                    <ThemeProvider theme={tableStyle}>
                        <TableContainer component={Paper} style={{ marginTop: 15, boxShadow: '0 0 20px 0 #e0e0e0', borderRadius: 15 }}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{color:theme.palette.gray.ltext}}>{ t('textField.date') }</TableCell>
                                        <TableCell style={{color:theme.palette.gray.ltext}}>{ t('accounting.transactionType') }</TableCell>
                                        <TableCell style={{color:theme.palette.gray.ltext}}>{ t('accounting.remark') }</TableCell>
                                        <TableCell style={{color:theme.palette.gray.ltext}} align="right">{ t('accounting.cashIn') }</TableCell>
                                        <TableCell style={{color:theme.palette.gray.ltext}} align="right">{ t('accounting.cashOut') }</TableCell>
                                        {/* <TableCell style={{color:theme.palette.gray.ltext}} align="right">{ t('accounting.balance') }</TableCell> */}
                                        {/* <TableCell style={{color:theme.palette.gray.ltext}} align="right">{ t('accounting.action') }</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { _.map(data, dataItem => {
                                        return (
                                            <TableRow
                                                key={dataItem.id}
                                            >
                                                <TableCell component="th" scope="row">{ dataItem.drawer_date }</TableCell>
                                                <TableCell>{ t(`accounting.refTypeList.${dataItem.ref_type}`) }</TableCell>
                                                <TableCell>{ (Array.isArray(dataItem.remark) && dataItem.remark.length===0) ? (dataItem.order_no!==null? `[OrderNo-${dataItem.order_no}]`:"") : dataItem.remark }</TableCell>
                                                <TableCell style={{ width: 160, color: dataItem.type === 'in' ? 'green' : 'black' }} align="right">{ dataItem.type === 'in' ? `+${currencyFormat(parseFloat(dataItem.amount))}` : '-' }</TableCell>
                                                <TableCell  style={{ width: 160, color: dataItem.type === 'out' ? 'red' : 'black' }}align="right">{ dataItem.type === 'out' ? `-${currencyFormat(parseFloat(dataItem.amount))}` : '-' }</TableCell>
                                                
                                            </TableRow>
                                        )
                                    })}

                                    {emptyRows > 0 && (
										<TableRow style={{ height: 53 * emptyRows }}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            colSpan={6}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    );
}

//Table
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};
  
    return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
    );
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    maxWidth:'90%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius:3,
    boxShadow: 24,
    p: 2,
  };

const useStyles = makeStyles(theme => ({ 
    justifyCenter:{
        justifyContent:'center'
    },
    spaceBetween:{
        justifyContent:'space-between'
    },
    flexMiddle:{
        display:'flex',
        alignItems:'center',
        width:'100%',
    },
    flexTtop:{
        display:'flex',
        alignItems:'flex-start',
    },
    
  }));

  const tableStyle = createTheme({
    components: {
        // Name of the component
        MuiTableCell: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    lineHeight:'2.5rem'
                },
            },
        },
    },
});

