import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postUrl} from '../../helpers/ApiAction';
import { useDispatch } from 'react-redux';
import { authSuccess, authFail } from '../../actions';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { useNavigate, useLocation } from 'react-router-dom';

// MUI
import { TextField, Typography, InputAdornment, IconButton, Box, Card, CardContent, Button, Grid, Link } from '@mui/material';
import { MdPerson, MdLock, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { makeStyles, useTheme } from '@mui/styles';

export default function Login() {
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const [state, setState] = useState({
        email: '',
        password: '',
        showPassword: false,
        type: 'web'
    });
    const [inputErrors, setInputErrors] = useState();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    let navigate = useNavigate();
    let location = useLocation();

    let from = location.state?.from?.pathname || "/dashboard";

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const loginUser = () => {
        setLoading(true);
        postUrl('/login', state).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(authSuccess(response.data));
                navigate(from, { replace: true });
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            dispatch(authFail());
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleKeyPress = (event) => {
        if(event.charCode==13){
          loginUser();
        } 
    }

    return (
        <Box style={{height:'100%',}} >
            <Card style={{ boxShadow: 'none', border: 'none', display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'90vh', padding:20, }} className={styles.loginBkg}>
                <CardContent className={styles.outerBox}>
                    <center><img src="images/logo2.png" style={{height:100, }} alt="logo"/></center>
                <Typography style={{ fontSize: 21, padding: '15px 0px 30px', textTransform:'capitalize', fontWeight: 'bold',color: theme.palette.gray.ltext }}>
                {/* textTransform: 'uppercase', textAlign:'center , */}
                        {t('login.title')}
                    </Typography>
                    <TextField
                        variant="outlined"
                        label={t('user.email')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdPerson />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        type="email"
                        autoComplete="email"
                        color='primary'
                        value={state.email}
                        onChange={({ target }) => setState({ ...state, email: target.value })}
                        className={styles.marginTop30}
                        helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                        error={inputErrors && inputErrors.email ? true : false}
                        onKeyPress={handleKeyPress}
                    />
                    <TextField
                        variant="outlined"
                        label={t('user.password')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdLock />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {state.showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        type={state.showPassword ? 'text' : 'password'}
                        color='primary'
                        value={state.password}
                        onChange={({ target }) => setState({ ...state, password: target.value })}
                        className={styles.marginTop30}
                        helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                        error={inputErrors && inputErrors.password ? true : false}
                        onKeyPress={handleKeyPress}
                    />
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={styles.marginTop30}>
                        <Link href="/forget-password" color="secondary" underline="none">{t('login.forgetPassword')}</Link>
                    </Grid>
                    <Box style={{padding:'40px 0 15px'}} display="flex" justifyContent="center">
                        <Button className={styles.buttonStyle} variant="contained" color="secondary" size="large" onClick={loginUser} >{t('button.login')}</Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    marginTop30: {
        marginBottom:'30px !important'
    },
    outerBox:{
        background: '#fff', 
        width: 600,
        padding:'35px 20px',
        maxWidth:'100%',
        borderRadius:15,
        // height: '100%',
        boxShadow:'rgb(244 244 244) -10px -10px 16px 0px, rgb(215 215 215) 10px 10px 16px 0px, rgb(244 244 244) 2px 2px 4px 0px inset, rgb(215 215 215) -2px -2px 4px 0px inset',
        // boxShadow:'6px 5px 20px 0 #00000026, -11px -7px 12px 0 #0000000f'
    },
    buttonStyle:{
        width: '100%',
        boxShadow: '3px 3px 8px 0 #cbc2b7, -3px -3px 4px 0 #fefefe, inset 0 -3px 4px 0 #b99877, inset 0 5px 6px 0 #e3c7ab',
        color: '#fff',
        borderRadius: 9,
    },
    // loginBkg:{
        
    //     backgroundSize:'cover',
    //     backgroundRepeat:'no-repeat',
    //     backgroundPosition:'center',
    //     [theme.breakpoints.down('sm')]: {
    //         backgroundImage:`url('images/login.png')`,
    //       },
    //       [theme.breakpoints.down('lg')]: {
    //         backgroundImage:`url('images/login.jpg')`,
    //       },
    //       [theme.breakpoints.up('lg')]: {
    //         backgroundImage:`url('images/login.jpg')`,
    //         backgroundPosition:'bottom',
    //       },
    // }

}));