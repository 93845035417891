import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';


// MUI
import { Button, Grid, Box, Typography, Link, LinearProgress, Breadcrumbs, /* FormGroup, */ FormControl, InputLabel, TextField,OutlinedInput, Select, MenuItem} from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarColumnsButton } from '@mui/x-data-grid';
import { makeStyles, useTheme } from '@mui/styles';
import { useDemoData } from '@mui/x-data-grid-generator';
import { FiArrowLeft } from "react-icons/fi";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { getUrl, postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { useQuery } from '../../helpers/Tools';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function CustomToolbar() {
    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer} style={{ paddingBottom: 10 }}>
            <GridToolbarExport style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} csvOptions={{ allColumns: true, fileName: `Commissions_${new Date().toISOString().slice(0, 10)}` }} printOptions={{ disableToolbarButton: true }} />
            <GridToolbarDensitySelector style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
            <GridToolbarFilterButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
            <GridToolbarColumnsButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
        </GridToolbarContainer>
    );
}

export default function Index() {
    const navigate = useNavigate();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    let query = useQuery();
    const location = useLocation();

    const [searchType, setSearchType] = useState(query.get('type') || "0");
    const [searchOrderNo, setSearchOrderNo] = useState(query.get('order_no') || "");
    const [searchName, setSearchName] = useState(query.get('name') || "");
    const [bonusList, setBonusList] = useState({});
    const { permissions, role } = useSelector(state => state.general);

    const [pageSize, setPageSize] = useState(20);
    const [tableLoading, setTableLoading] = useState(false);
    const [state, setState] = useState([]);
    const { addAlert, setLoading } = useNotificationLoading();
    const { data } = useDemoData({
        dataSet: 'commission',
        rowLength: 5,
        maxColumns: 10,
    });
    const [dateFilter, setDateFilter] = useState({
        from_date: query.get('from_date') || null, // moment().format('L hh:mm A'),
        to_date: query.get('to_date') || null, // moment().format('L hh:mm A'),
    });

    const [commissionDate, setCommissionDate] = useState({
        start_date: null,
        end_date: null,
    });
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //table
    const columns = useMemo(() => [
        {
            field: `bonus`,
            headerName: `${t('commission.type')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({value}) => value?.name?.[i18n.language],
            type: "singleSelect",
            valueOptions: ['Purchaser', 'Group Sale', 'Personal Sale']
        },
        {
            field: 'amount',
            headerName: `${t('commission.amount')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueFormatter: ({value}) =>  parseFloat(value).toFixed(2)
        },
        {
            field: 'order_detail',
            headerName: `${t('commission.orderNo')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
           // valueGetter: ({value}) => value?.order?.order_no

            renderCell: (params) => { return <Link underline='none' to={`/orders/${params.value.order_id}`} component={RouterLink}><div key={params.value.order_id}>{params.value.order.order_no? params.value.order.order_no : "-"}</div></Link> }
        },
        {
            field: 'user',
            headerName: `${t('user.name')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({value}) => value?.name
        },
       // {
       //     field: 'user_ic',
      //      headerName: `${t('user.nric')}`,
       //     flex: 1,
       //     align: 'center',
        //    headerAlign: 'center',
       //     headerClassName: 'super-app-theme--header',
       //     valueGetter: ({row}) => row?.user?.ic
      //  },
        // {
        //     field: 'status',
        //     headerName: `${t('commission.status.title')}`,
        //     flex: 1,
        //     align: 'center',
        //     headerAlign: 'center',
        //     headerClassName: 'super-app-theme--header',
        // },
        {
            field: 'bonus_date',
            headerName: `${t('customer.createdAt')}`,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            // type: 'date'
            renderCell: (params) => {  return moment(params.value).format('YYYY-MM-DD') }
        },
    ],
        // eslint-disable-next-line
        [t],
    );

    const handleDateFilterChange = (newValue, type) => {
        setDateFilter({ ...dateFilter, [type]: newValue });
    }

    const clearFilter = useCallback(() => {
        // setDateFilter({ from_date: null, to_date: null });
        // setSearchType('0');
        // setSearchOrderNo('');
        // setSearchName('');
        // callApi();
        window.location.href = location.pathname;
    }, []);

    // const searchOrder = (e) => {
    //     // setTableLoading(true);
    //     if (e.key === 'Enter') {
    //         callApi();
    //     }
    // }

    const handleCommissionDateChange = (newValue, type) => {
        setCommissionDate({ ...commissionDate, [type]: newValue });
    }

    const calculateCommission = () => {
        const start_date = commissionDate.start_date ? moment(commissionDate.start_date).format('YYYY-MM-DD'): '';
        const end_date = commissionDate.end_date ? moment(commissionDate.end_date).format('YYYY-MM-DD'): '';
        setLoading(true);
        postUrl(`/commissions/calculate-commission`, {start_date, end_date}).then(response => {
            setLoading(false);
            let message = response.message;
            if(response.errors){
                _.map(response.errors, msg => {
                    message += (" "+msg);
                })
            }
            addAlert("", message || t('error.contactSupport'), response.status ? "success" : "error", '');
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const callApi = ()=>{
        const from_date = dateFilter.from_date ? moment(dateFilter.from_date).format('YYYY-MM-DD HH:mm:ss'): '';
        const to_date = dateFilter.to_date ? moment(dateFilter.to_date).format('YYYY-MM-DD HH:mm:ss'): '';
        setLoading(true);
        let apiUrl=`/commissions`;
        getUrl(apiUrl, { search: searchType, search3: searchOrderNo ,search4: searchName, sdate: from_date, edate: to_date })
            .then((response) => {
                setLoading(false);
                if (response.status) {
                    setState(response.data.listing);
                    setBonusList(response.data.bonus_list);
                } else {
                    addAlert("", t("error.contactSupport"), "error", "");
                }
            })
            .catch((error) => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        // eslint-disable-next-line
    }

    useEffect(() => {
        setLoading(true);
        callApi();
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <div style={{ paddingBottom: 30, justifyContent:'space-between' }} className={styles.flexMiddle}>
            
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <span onClick={() => navigate(-1)} style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase', cursor:'pointer' }}>{t('button.back')}</span>
                </div>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Typography color="text.primary">{t('title.commission')}</Typography>
                    </Breadcrumbs>
                </div>

            </div>
            <div style={{ paddingBottom: 5 }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t('commission.commissionList')}</b></Typography>
                    </div>
 
                </Grid>
            </div>
            <Box display="flex" flexDirection="row" padding="10px">
                {
                    _.includes(permissions, "commission-all") && _.size(_.intersection(role, ['dev', 'super-admin', 'admin'])) > 0 && 
                        <Button variant="outlined" onClick={handleClickOpen}>
                            { t('commission.calculateCommission') }
                        </Button>
                }
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{t('commission.calculateCommission')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{paddingBottom: 10 }}>
                            Warning: Proceed only if you know what you are doing and understand clearly on the consequences of doing so.
                        </DialogContentText>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label={ t('commission.startDate') }
                                value={commissionDate.start_date}
                                onChange={(value) => handleCommissionDateChange(value, 'start_date') }
                                renderInput={(params) => <TextField style={{ marginRight: 10 }} {...params} />}
                            />
                            <DatePicker
                                label={ t('commission.endDate') }
                                value={commissionDate.end_date}
                                onChange={(value) => handleCommissionDateChange(value, 'end_date')}
                                renderInput={(params) => <TextField style={{ marginRight: 10 }} {...params} />}
                            />
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{t('button.cancel')}</Button>
                        <Button onClick={calculateCommission}>{t('button.proceed')}</Button>
                    </DialogActions>
                </Dialog>
            </Box> 
            <Box component="form" method="get" action={location.pathname} display="flex" flexDirection="row" padding="10px">

                <div style={{ width: '100%', maxWidth: '100%', marginRight:10 }}>
                    <ThemeProvider theme={selectStyle}>
                        <FormControl style={{ width: '20%', maxWidth: '20%' }}>
                            {/* <OutlinedInput placeholder={t('commission.typeSearch')} value={searchType} onChange={({ target }) => setSearchType(target.value)} onKeyDown={searchOrder} /> */}
                            <InputLabel id="type-search-label">{t('commission.typeSearch')}</InputLabel>
                            <Select
                                labelId="type-search-label"
                                value={searchType}
                                onChange={({ target }) => setSearchType(target.value)}
                                input={<OutlinedInput name="type" label={t('commission.typeSearch')} />}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: (48 * 4.5) + 8,
                                            width: 250,
                                        },
                                    },
                                }}
                            >
                                <MenuItem key={0} value={0} >
                                        All
                                    </MenuItem>
                                {_.map(bonusList, bonus => (
                                    <MenuItem key={bonus.id} value={bonus.id} >
                                        {bonus.name[i18n.language]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ThemeProvider>

                    <ThemeProvider theme={inputStyle}>
                        <FormControl style={{ width: '20%', maxWidth: '20%' }}>
                            <OutlinedInput placeholder={t('commission.orderNoSearch')} name="order_no" value={searchOrderNo} onChange={({ target }) => setSearchOrderNo(target.value)} />
                        </FormControl>
                    </ThemeProvider>

                    <ThemeProvider theme={inputStyle}>
                        <FormControl style={{ width: '20%', maxWidth: '20%' }}>
                            <OutlinedInput placeholder={t('commission.nameSearch')} name="name" value={searchName} onChange={({ target }) => setSearchName(target.value)}/>
                        </FormControl>
                    </ThemeProvider>
                </div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                        label={ t('report.fromDate') }
                        mask='__/__/____ __:__ _M'
                        value={dateFilter.from_date}
                        onChange={(value) => handleDateFilterChange(value, 'from_date') }
                        renderInput={(params) => <TextField name="from_date" style={{ marginRight: 10 }} {...params} />}
                    />
                    <DateTimePicker
                        label={ t('report.toDate') }
                        mask='__/__/____ __:__ _M'
                        value={dateFilter.to_date}
                        onChange={(value) => handleDateFilterChange(value, 'to_date')}
                        renderInput={(params) => <TextField name="to_date" style={{ marginRight: 10 }} {...params} />}
                    />
                </LocalizationProvider>
                <Button variant="outlined" type="submit" /* onClick={callApi} */ style={{ marginRight: 10 }}>
                    <Typography>{ t('general.filter') }</Typography>
                </Button>
                <Button variant="outlined" onClick={clearFilter}>
                    <Typography>{ t('general.clear') }</Typography>
                </Button>
            </Box>            
            <div style={{ height: 450, width: '100%', overflowX: 'scroll', marginTop: 20, boxShadow: '0 0 20px 0 #dadada', borderRadius: '15px' }}>
                <div style={{ display: 'flex', height: '100%', padding: 0 }}>
                    <div style={{ flexGrow: 1 }}>
                    {
                            _.size(state) > 0
                                ?
                        <DataGrid
                            {...data}
                            rows={state}
                            columns={columns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10, 20]}
                            components={{
                                Toolbar: CustomToolbar,
                                LoadingOverlay: LinearProgress,
                            }}
                            loading={tableLoading ? true : false}
                            sx={{
                                marginTop: 0,
                                borderRadius: '15px',
                                paddingTop: '15px',
                                border: 'none',
                                '& .super-app-theme--header': {
                                    // backgroundColor: theme.palette.primary.main,
                                    backgroundColor: theme.palette.gray.tableHeader,
                                    color: theme.palette.gray.tableHeaderText,
                                },
                                backgroundColor: 'white',
                                '& 	.MuiDataGrid-menuIconButton': {
                                    color: theme.palette.gray.tableHeaderText,
                                },
                                '& 	.MuiDataGrid-sortIcon': {
                                    color: theme.palette.gray.tableHeaderText,
                                },
                            }}
                        />
                        :
                        <div className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} style={{ flexDirection: 'column', backgroundColor: '#fff', height: '100%', }}>
                            <img src="images/emptyStatus/nolist.png" alt="empty" style={{ width: 180 }} />
                            <Typography style={{ margin: 10, textAlign: "center" }}>{t('emptyStatus.noComission')}</Typography>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    justifyCenter:{
        justifyContent:'center',
    }
}));


const inputStyle = createTheme({
    components: {
      // Name of the component
      MuiOutlinedInput: {
        styleOverrides: {
          // Name of the slot
          input: {
            // Some CSS
            padding:'28.5px 14px',
            boxSizing:'inherit',
            borderRadius:48
          },
          root:{
              borderRadius:48,
              boxShadow:'3px 3px 8px 0 #ccc'
          },
        },
      },
    },
  })

const selectStyle = createTheme({
    components: {
      // Name of the component
      MuiOutlinedInput: {
        styleOverrides: {
          // Name of the slot
          input: {
            // Some CSS
            padding:'16.5px 14px',
            boxSizing:'inherit',
            borderRadius:48
          },
          root:{
              borderRadius:48,
              boxShadow:'3px 3px 8px 0 #ccc'
          },
        },
      },
    },
  })

