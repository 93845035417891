import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import _, { filter } from 'lodash';
import InstalmentDetail from './InstalmentDetail';

// MUI
import { Typography, Grid, FormControl, MenuItem, Select, IconButton } from '@mui/material';

// ICONS
import { FiPlus, FiEdit2, FiDollarSign } from "react-icons/fi";

export default function PaymentListing({
    type, // order-payments/instalment-details
    pendingStatus, // order.status/payment.status
    totalAmount, // order.total_price/payment.amount
    paymentMethodList, selectedPaymentList, totalPayment,
    FPXRedirect, handlePaymentSelect, handlePaymentModalOpen, handlePaymentApproveModalOpen,
    order, setOrder, tradeInList, paymentStatusList, setSelectedPaymentList, inputErrors, setInputErrors // for order-payments only
}) {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { permissions } = useSelector(state => state.general);

    return (
        < div style={{ padding: 15 }}>
            {/* ADD PAYMENT */}
            < div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{ paddingBottom: 25 }}>
                <Typography style={{ textTransform: 'uppercase', color: theme.palette.gray.ltext }}>{type === 'order-payments' ? t('order.paymentBreakdown') : t('order.instalmentDetails')}</Typography>
                {
                    pendingStatus === 20 && _.includes(permissions, 'order-payment-create')
                        ?
                        <div className={styles.flexMiddle}>
                            <Typography style={{ color: theme.palette.secondary.main, paddingRight: 15 }}>{t('order.addPayment')}</Typography>
                            <FormControl >
                                {
                                    type === 'instalment-details' ?
                                        <Select
                                            IconComponent={FiPlus}
                                            style={{ width: 30, height: 30, borderRadius: 5, color: theme.palette.secondary.main }}
                                            className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
                                            onChange={(event) => handlePaymentSelect(event.target.value)}
                                            value=''
                                        >
                                            {
                                                paymentMethodList.filter(list => list.code === 'instalment' && list.available).map(filteredPayment => {
                                                    return filteredPayment.available_payment.map(row => {
                                                        let paymentDetail = _.find(paymentMethodList, function (p) { return row === p.code; });
                                                        return (
                                                            <MenuItem key={paymentDetail.code} value={paymentDetail.code}>{paymentDetail[i18n.language]}</MenuItem>)
                                                    })
                                                })
                                            }
                                        </Select>
                                        :
                                        <Select
                                            IconComponent={FiPlus}
                                            style={{ width: 30, height: 30, borderRadius: 5, color: theme.palette.secondary.main }}
                                            className={`head ${styles.flexMiddle} ${styles.justifyCenter}`}
                                            onChange={(event) => handlePaymentSelect(event.target.value)}
                                            value=''
                                        >
                                            {_.map(paymentMethodList, payment => {
                                                if (payment.available && (type === 'instalment-details' ? payment.code !== 'instalment' : true)) {
                                                    return (
                                                        <MenuItem key={payment.code} value={payment.code}>{payment[i18n.language]}</MenuItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Select>
                                }
                            </FormControl>
                        </div>
                        : null
                }
            </div >
            {/* EDIT/DELETE PAYMENT */}
            {
                _.size(selectedPaymentList) > 0
                    ?
                    _.map(selectedPaymentList, (payment, key) => {
                        let paymentInfo = _.find(paymentMethodList, function (p) { return payment.payment_method === p.code; });
                        let instalment_sum = 0;
                        return (
                            <Grid container spacing={3} style={{ paddingBottom: 18 }} key={payment.id}>
                                <Grid item xs={6}>
                                    <div style={{ display: 'flex', alignItems: 'flex-start', }}>
                                        <div style={{ width: 'fit-content' }}>
                                            <div style={{ width: 10, height: 10, borderRadius: 48, backgroundColor: (payment.status === 20 ? '#f9db43' : (payment.status === 40 ? '#52ceb7' : '#ef4343')) }} />
                                        </div>
                                        <div style={{ marginTop: -5, paddingLeft: 15 }}>
                                            <div className={styles.flexMiddle}>
                                                <Typography style={{ color: theme.palette.gray.dtext }}>{paymentInfo ? paymentInfo[i18n.language] : payment.payment_method}</Typography>
                                                {
                                                    payment.payment_method === 'online-payment-gateway'
                                                        ?
                                                        (payment.status === 20
                                                            ?
                                                            <div style={{ paddingLeft: 15 }}>
                                                                <IconButton onClick={() => FPXRedirect(payment)}>
                                                                    <FiDollarSign style={{ fontSize: 18, color: theme.palette.secondary.main }} />
                                                                </IconButton>
                                                            </div>
                                                            : null)
                                                        :
                                                        _.includes(permissions, 'order-payment-approve')
                                                            ?
                                                            <div style={{ paddingLeft: 15 }}>
                                                                <IconButton onClick={() => handlePaymentApproveModalOpen(key, payment)}>
                                                                    <FiEdit2 style={{ fontSize: 18, color: theme.palette.secondary.main }} />
                                                                </IconButton>
                                                            </div>
                                                            :
                                                            pendingStatus === 20 && payment.status === 20 && _.includes(permissions, 'order-payment-edit')
                                                                ?
                                                                (type === 'order-payments' &&
                                                                    _.size(payment.instalment_details) > 0) ? null
                                                                    :
                                                                    <div style={{ paddingLeft: 15 }}>
                                                                        <IconButton onClick={() => handlePaymentModalOpen(key, payment)}>
                                                                            <FiEdit2 style={{ fontSize: 18, color: theme.palette.secondary.main }} />
                                                                        </IconButton>
                                                                    </div>
                                                                : null
                                                }
                                            </div>
                                            {
                                                payment.payment_method === 'instalment'
                                                    ?
                                                    <InstalmentDetail
                                                        order={order} setOrder={setOrder} payment={payment} tradeInList={tradeInList} paymentStatusList={paymentStatusList} paymentMethodList={paymentMethodList} orderPaymentList={selectedPaymentList} setOrderPaymentList={setSelectedPaymentList}
                                                        inputErrors={inputErrors} setInputErrors={setInputErrors} FPXRedirect={FPXRedirect}
                                                    />
                                                    : null
                                            }
                                            <Typography style={{ color: theme.palette.gray.ltext, fontSize: 14 }}>{payment.remark ? payment.remark : ''}</Typography>
                                            {
                                                type === 'instalment-details'
                                                ?
                                                <Typography style={{ color: theme.palette.gray.ltext, fontSize: 14 }}>{payment.updated_at ? payment.updated_at : ''}</Typography>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{ color: theme.palette.gray.dtext, textAlign: 'right', paddingTop: 0 }}>
                                        <Typography><NumberFormat thousandSeparator={true} prefix="RM " value={payment.amount} decimalScale={2} fixedDecimalScale={true} displayType="text" /></Typography>
                                    </div>
                                    {
                                        payment.payment_method === 'instalment'
                                            ?
                                            <div>
                                                {
                                                    _.map(payment.instalment_details, instalment => {
                                                        if (instalment.status !== 60) {
                                                            instalment_sum += parseFloat(instalment.amount);
                                                        }
                                                    })
                                                }
                                                <div style={{ color: theme.palette.gray.dtext, textAlign: 'right', paddingTop: 0, fontsize: 14, fontStyle: 'italic' }}>
                                                    <Typography><NumberFormat thousandSeparator={true} prefix="RM " value={instalment_sum} decimalScale={2} fixedDecimalScale={true} displayType="text" /></Typography>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                    :
                    <Typography style={{ color: theme.palette.gray.ltext, fontSize: 14 }}>{t('order.noPayment')}</Typography>
            }
            {/* PAYMENT BALANCE */}
            {
                parseFloat(totalAmount - totalPayment) !== 0
                    ?
                    <div style={{ paddingTop: 12 }}>
                        <Typography style={{ textAlign: 'right', color: '#ce4653' }}>{t('pos.balancePaymentAmount') + ': '}<NumberFormat thousandSeparator={true} value={parseFloat(totalAmount - totalPayment).toFixed(2)} displayType="text" /></Typography>
                    </div>
                    : null
            }
        </div >
    )
}

const useStyles = makeStyles(theme => ({
    spaceBetween: {
        justifyContent: 'space-between'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
}));