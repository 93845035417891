import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getUrl, postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { useSelector } from 'react-redux';
import _ from 'lodash';
// MUI
import { Grid, Typography, Link, LinearProgress, Breadcrumbs, Button, Tabs, Tab, Dialog, DialogTitle, Divider, DialogActions, TextField, DialogContent } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarColumnsButton, GridActionsCellItem } from '@mui/x-data-grid';
import { makeStyles, useTheme } from '@mui/styles';
//ICON
import { MdModeEdit, MdClose } from "react-icons/md";
import { FiArrowLeft } from "react-icons/fi";
function CustomToolbar() {
    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer} style={{ paddingBottom: 10 }}>
        <GridToolbarExport style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} csvOptions={{ allColumns: true, disableToolbarButton: true }} printOptions={{ disableToolbarButton: true }} />
        <GridToolbarDensitySelector style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
        <GridToolbarFilterButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
        <GridToolbarColumnsButton style={{ color: '#CDAC89', width: 150, maxWidth: '100%' }} />
        </GridToolbarContainer>
        );
    }
    export default function Index() {
        const { t, i18n } = useTranslation();
        const [state, setState] = useState([]);
        const [pageSize, setPageSize] = useState(5);
        const [tableLoading, setTableLoading] = useState(false);
        const { addAlert, setLoading } = useNotificationLoading();
        const theme = useTheme();
        let navigate = useNavigate();
        const styles = useStyles();
        const { permissions } = useSelector(state => state.general);
        const [typeTab, setTypeTab] = useState('all');
        const [typeList, setTypeList] = useState([]);
        const [voidState, setVoidState] = useState({
            open: false,
            data: {
                id: '',
                description: '',
                payment_method: 'cash',
            },
            inputErrors: {},
        });
        const [columnVisibilityModel, setColumnVisibilityModel] = useState({
            actions: permissions.includes('credit-note-edit'),
        });
        const [paymentList, setPaymentList] = useState([]);
        const [ creditNoteId, setCreditNoteId] = useState([]);
        
        const callApi = () => {
            getUrl('/credit-notes', { type: typeTab }).then(response => {
                setTableLoading(false);
                if (response.status) {
                    setState(response.data.listing);
                    setTypeList(response.data.type_list);
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setTableLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
            getUrl('/credit-notes', { type: typeTab, status: 10 }).then(response => {
                setLoading(false);
                if (response.status) {      
                    setCreditNoteId(response.data.listing);
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        useEffect(() => {
            setLoading(true);
            getUrl(`/order-payments`).then(response => {
                setLoading(false);
                if (response.status) {
                    setPaymentList(response.data);
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
            // eslint-disable-next-line
            
            
            /* getUrl('/credit-notes', { type: typeTab, status: 10 }).then(response => {
                setLoading(false);
                if (response.status) {      
                    setCreditNoteId(response.data.listing);
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }); */
            // eslint-disable-next-line
        }, []);
        useEffect(() => {
            setTableLoading(true);
            callApi();
            // eslint-disable-next-line
        }, [typeTab]);
        const handleTypeTabChange = (event, newValue) => {
            setTypeTab(newValue);
        };
        const handleVoidChange = () => {
            setVoidState({ ...voidState, open: !voidState.open })
        }
        const handleChange = ({ target }) => {
            let { name, value} = target;
            if (name === 'id') {
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            }
            setVoidState({ ...voidState, data: { ...voidState.data, [name]: value } })
            
            
        };
        
        const submitData = () => {
            setLoading(true);
            setVoidState({ ...voidState, inputErrors: {} });
            postUrl(`/void-credit-note`, voidState.data).then(response => {
                
                setLoading(false);
                let { status, data, errors, message } = response;
                if (status) {
                    addAlert('', message, 'success', '');
                    callApi();
                    handleVoidChange();
                    voidState.data.description = '';
                } else {
                    setVoidState({ ...voidState, inputErrors: errors });
                    addAlert('', message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
            
            /* getUrl('/credit-notes', { type: typeTab, status: 10 }).then(response => {
                setLoading(false);
                if (response.status) {      
                    setCreditNoteId(response.data.listing);
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }); */
            
        }
        const editRole = React.useCallback(
            (id) => () => {
                navigate(`/credit-note/${id}`);
            },
            // eslint-disable-next-line
            [],
            );
            const columns = useMemo(() => [
                {
                    field: 'id',
                    headerName: `ID`,
                    flex: 1,
                    headerClassName: 'super-app-theme--header',
                    align: 'center',
                    headerAlign: 'center',
                },
                {
                    field: 'type',
                    headerName: `${t('creditNote.type.title')}`,
                    flex: 1,
                    headerClassName: 'super-app-theme--header',
                    align: 'center',
                    headerAlign: 'center',
                    valueFormatter: ({ value }) => t('creditNote.type.' + value) || "-",
                },
                {
                    field: 'used_credit_note',
                    headerName: `${t('creditNote.usedCreditNoteId')}`,
                    flex: 1,
                    headerClassName: 'super-app-theme--header',
                    align: 'center',
                    headerAlign: 'center',
                    valueFormatter: ({ value }) => value || "-",
                },
                {
                    field: 'customer',
                    headerName: `${t('creditNote.customer')}`,
                    flex: 1,
                    headerClassName: 'super-app-theme--header',
                    align: 'center',
                    headerAlign: 'center',
                    valueFormatter: ({ value }) => value?.name || "-",
                },
                {
                    field: 'amount',
                    headerName: `${t('creditNote.amount')}`,
                    flex: 1,
                    headerClassName: 'super-app-theme--header',
                    align: 'center',
                    headerAlign: 'center',
                    valueFormatter: ({ value }) => parseFloat(value).toFixed(2),
                },
                {
                    field: 'creator',
                    headerName: `${t('creditNote.createdBy')}`,
                    flex: 1,
                    headerClassName: 'super-app-theme--header',
                    align: 'center',
                    headerAlign: 'center',
                    valueGetter: ({ value }) => value?.name || "-"
                },
                {
                    field: 'status',
                    headerName: `${t('creditNote.status.title')}`,
                    flex: 1,
                    headerClassName: 'super-app-theme--header',
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (params) => { return <div key={params}>{params.value != null ? t('creditNote.status.' + params.value) : "-"}</div>; },
                },
                {
                    field: 'created_at',
                    headerName: `${t('roles.createdAt')}`,
                    flex: 1,
                    headerClassName: 'super-app-theme--header',
                    align: 'center',
                    headerAlign: 'center',
                },
                {
                    field: 'actions',
                    type: 'actions',
                    headerName: `${t('table.actions')}`,
                    width: 70,
                    headerClassName: 'super-app-theme--header',
                    align: 'center',
                    headerAlign: 'center',
                    getActions: (params) => [
                        <GridActionsCellItem
                        icon={<MdModeEdit style={{ color: theme.palette.button.main }} size={25} />}
                        label={t('button.edit')}
                        onClick={editRole(params.id)}
                        />,
                    ],
                },
            ],
            // eslint-disable-next-line
            [editRole, i18n, t],
            );
            return (
                <div>
                <Link underline='none' component={RouterLink} to={`/dashboard`}>
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.back')}</Typography>
                </div>
                </Link>
                <div style={{ paddingBottom: 5 }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t('title.creditNotes')}</b></Typography>
                {
                    _.includes(permissions, "credit-note-create") &&
                    <>
                    <Link underline='none' to={`/create-credit-note`} component={RouterLink}>
                    <Button variant="contained">{t('button.create')}</Button>
                    </Link>
                    {/* <Link underline='none' to={`/create-credit-note?type=voided`} component={RouterLink}> */}
                    <Button variant="contained" style={{ marginLeft: 5 }} onClick={handleVoidChange}>{t('button.void')}</Button>
                    {/* </Link> */}
                    </>
                }
                </div>
                <div>
                <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                {t('title.dashboard')}
                </Link>
                <Typography color="text.primary">{t('title.creditNotes')}</Typography>
                </Breadcrumbs>
                </div>
                </Grid>
                </div>
                <Tabs value={typeTab} onChange={handleTypeTabChange} variant="scrollable" scrollButtons="auto">
                <Tab label={t('creditNote.type.all')} value={'all'} />
                {
                    _.size(typeList) &&
                    _.map(typeList, type => {
                        return (
                            <Tab label={type[i18n.language]} value={type.code} key={type.code} />
                            )
                        })
                    }
                    </Tabs>
                    <div>
                    <div style={{ height: 450, width: '100%', overflowX: 'scroll', marginTop: 20, boxShadow: '0 0 20px 0 #dadada', borderRadius: '15px' }}>
                    <div style={{ display: 'flex', height: '100%', padding: 0 }}>
                    <div style={{ flexGrow: 1 }}>
                    <DataGrid
                    rows={state}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    components={{
                        Toolbar: CustomToolbar,
                        LoadingOverlay: LinearProgress,
                    }}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                    loading={tableLoading ? true : false}
                    disableSelectionOnClick
                    sx={{
                        marginTop: 0,
                        borderRadius: '15px',
                        paddingTop: '15px',
                        border: 'none',
                        '& .super-app-theme--header': {
                            // backgroundColor: theme.palette.primary.main,
                            backgroundColor: theme.palette.gray.tableHeader,
                            color: theme.palette.gray.tableHeaderText,
                        },
                        backgroundColor: 'white',
                        '& 	.MuiDataGrid-menuIconButton': {
                            color: theme.palette.gray.tableHeaderText,
                        },
                        '& 	.MuiDataGrid-sortIcon': {
                            color: theme.palette.gray.tableHeaderText,
                        },
                    }}
                    />
                    </div>
                    </div>
                    </div>
                    </div>
                    <Dialog open={voidState.open} onClose={() => { setVoidState({ ...voidState, inputErrors: {} }); handleVoidChange(); }} maxWidth='md' fullWidth>
                    <DialogTitle>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid>
                    {t('title.creditNoteVoid')}
                    </Grid>
                    <Grid>
                    <div onClick={() => { setVoidState({ ...voidState, inputErrors: {} }); handleVoidChange(); }} style={{ cursor: 'pointer' }}><MdClose /></div>
                    </Grid>
                    </Grid>
                    </DialogTitle>
                    <DialogContent>
                    <Divider />
                    <Grid container spacing={3} style={{ paddingTop: 15 }}>
                    <Grid item xs={12} sm={6}>
                    <TextField
                    name="id"
                    label={t("creditNote.id")}
                    variant="outlined"
                    select
                    fullWidth
                    //value={voidState.data.id}
                    InputLabelProps={{ shrink: true }}
                    helperText={voidState.inputErrors && voidState.inputErrors?.id ? voidState.inputErrors?.id : ''}
                    error={voidState.inputErrors && voidState.inputErrors?.id ? true : false}
                    onChange={handleChange} 
                    SelectProps={{ native: true }}
                    >
                    
                    <option key={0} value={0} >
                    
                    {t('creditNote.defaultSelect')}
                    
                    
                    </option>
                    {creditNoteId.map((option,index) => (
                        <option key={index} value={option.id}>
                        {t('creditNote.creditNoteId')}:{option.id} &nbsp;
                        {t('creditNote.totalAmount')}:{parseFloat(option.amount).toFixed(2)}
                        
                        </option>
                        ))}
                        </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                        name="payment_method"
                        label={t('creditNote.paymentMethod')}
                        variant="outlined"
                        select
                        fullWidth
                        value={voidState.data.payment_method}
                        InputLabelProps={{ shrink: true }}
                        helperText={voidState.inputErrors && voidState.inputErrors?.payment_method ? voidState.inputErrors?.payment_method : ''}
                        error={voidState.inputErrors && voidState.inputErrors?.payment_method ? true : false}
                        onChange={handleChange}
                        SelectProps={{ native: true }}
                        >
                        {paymentList.map((option) => (
                            <option key={option.code} value={option.code}>
                            {option[`${i18n.language}`]}
                            </option>
                            ))}
                            </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                            <TextField
                            name="description"
                            label={t("creditNote.description")}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={5}
                            value={voidState.data.description}
                            helperText={voidState.inputErrors && voidState.inputErrors?.description ? voidState.inputErrors?.description : ''}
                            error={voidState.inputErrors && voidState.inputErrors?.description ? true : false}
                            onChange={handleChange}
                            />
                            </Grid>
                            </Grid>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={() => { setVoidState({ ...voidState, inputErrors: {} }); handleVoidChange(); }}>{t('button.cancel')}</Button>
                            <Button onClick={submitData}>{t('button.save')}</Button>
                            </DialogActions>
                            </Dialog>
                            </div>
                            );
                        }
                        const useStyles = makeStyles(theme => ({
                            flexMiddle: {
                                display: 'flex',
                                alignItems: 'center',
                            },
                        }));