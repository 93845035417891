import React, { useState } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { makeStyles, useTheme } from '@mui/styles';
import { Link, Breadcrumbs, Grid, Typography, TextField, Box, InputAdornment, IconButton, Button } from '@mui/material';
import { FiArrowLeft } from 'react-icons/fi';

import useNotificationLoading from '../../helpers/useNotificationLoading';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { putUrl } from '../../helpers/ApiAction';
import _ from 'lodash';

import { useNavigate } from 'react-router-dom';



const StaffPassword = () => {
    const [showPassword, setShowPassword] = useState({
        password: false,
        password_confirmation: false,
    });
    const [state, setState] = useState({
        password: "",
        password_confirmation: ""
    });
    const [inputErrors, setInputErrors] = useState({
        password: null,
        password_confirmation: null
    })

    const { addAlert, setLoading } = useNotificationLoading();
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    let { id } = useParams();
    const navigate = useNavigate();
    const handleChange = ({ target }) => {
		const { name, value } = target;
		setState({ ...state, [name]: value });
	}

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const updatePassword = () => {
        setLoading(true);
        setInputErrors();
        putUrl(`/users/${id}/password`, state).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ password: "", password_confirmation: "" });
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <span onClick={() => navigate(-1)} style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase',
cursor:'pointer' }}>{t('button.back')}</span>
                </div>
            
            <div style={{ paddingBottom: '5%' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ paddingBottom: 15, fontSize: 18, fontWeight: 'bold' }}>{`${t('button.edit')} ${t('title.staffPassword')}`}</Typography>
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to={`/dashboard`}>
                                {t('title.dashboard')}
                            </Link>
                            <Link underline="hover" color="inherit" component={RouterLink} to={`/staffs`}>
                                {t('title.staffs')}
                            </Link>
                            <Typography color="text.primary">{`${t('button.edit')} ${t('title.staffPassword')}`}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
                <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                            <TextField
                                name="password"
                                label={t('user.password')}
                                type={showPassword.password ? 'text' : 'password'}
                                value={state.password}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" 
                                helperText={inputErrors && inputErrors.password ? ( _.isArray(inputErrors.password) ? inputErrors.password[0] : inputErrors.password ) : ''}
                                error={inputErrors && inputErrors.password ? true : false}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword('password')}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword.password ? <MdVisibility /> : <MdVisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                            <TextField
                                name="password_confirmation"
                                label={t('user.confirmPassword')}
                                type={showPassword.password_confirmation ? 'text' : 'password'}
                                value={state.password_confirmation}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" helperText={inputErrors && ( _.isArray(inputErrors.password_confirmation) ? inputErrors.password_confirmation[0] : inputErrors.password_confirmation ) ? inputErrors.password_confirmation : ''}
                                error={inputErrors && inputErrors.password_confirmation ? true : false}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword('password_confirmation')}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword.password_confirmation ? <MdVisibility /> : <MdVisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link underline='none' to={`/staffs`} component={RouterLink}>
                        <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                    </Link>
                    <Button size="large" variant="contained" className={styles.buttonStyle} onClick={updatePassword}>{t('button.save')}</Button>
                </Box>
            </div>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },

}));

export default StaffPassword