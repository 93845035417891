import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl, putUrl, deleteUrl } from '../../helpers/ApiAction';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

// MUI
import { Box, Button, Typography } from '@mui/material';
import { List, Grid, TextField, Divider, Modal, Hidden, Drawer, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

// ICONS
import { FiPlus, FiMinus, FiChevronsRight, FiMoreVertical, FiChevronsLeft } from "react-icons/fi";
// import { IoTrashOutline } from "react-icons/io5";

// AddCartModal
const cartStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '80%',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
};

export default function Step1Cart({ handleNext, cartList, setCartList, calculateSumTotal, sumTotal }) {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { name } = useSelector(state => state.user);
    const [editPriceModal, setEditPriceModal] = useState({
        open: false,
        cartItem: {},
        sell_price: 0
    });
    const [checkout, setCheckout] = useState(false);

    useEffect(() => {
        setLoading(true);
        refreshCheckoutButton(cartList);
        setLoading(false);
        // eslint-disable-next-line
    }, [cartList]);

    // DRAWER
    const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
    const [showHiddenPrice, setShowHiddenPrice] = useState(false);
    const [staffPassword, setStaffPassword] = useState('');
    // ACCORDION
    const [expanded, setExpanded] = useState(false);

    // DRAWER
    const hiddenPriceCheckDrawer = () => (
        <Box
            sx={{ width: 350 }}
            role="presentation"
        >
            <Button onClick={handleDrawerClose} style={{ backgroundColor: theme.palette.button.main, color: '#fff', height: 48, marginTop: 172, marginLeft: -64, position: 'fixed', borderRadius: '11px 0 0 11px', boxShadow:'inset -5px 0px 9px 0 #0000002e' }} className={styles.translateXAnimation} ><FiChevronsRight style={{fontSize:25}} /></Button>
            {
                showHiddenPrice ?
                    <List style={{ padding: '25px 25px' }}>
                        <Typography style={{ color: theme.palette.secondary.main, fontSize: 25, fontWeight: 'bold' }}>
                            {t('menu.listing')}
                        </Typography>
                        <Typography style={{ color: theme.palette.gray.ltext, fontSize: 14 }}>
                            {t('menu.totalItems', { total: _.size(cartList) })}
                        </Typography>
                        {
                            _.map(cartList, cart => {
                                return (
                                    <div key={cart.id}>
                                        <Grid container style={{ margin: 0, padding: '15px 0', maxWidth: '100%' }}>
                                            <Grid item xs={5} sm={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={_.size(cart.product.images)?cart.product.images[0].file_name:"/images/emptyStatus/noproductimg.jpg"} style={{ width: '100%' }} alt="product_image" />
                                            </Grid>
                                            <Grid item xs={7} sm={8} style={{ paddingLeft: 8 }}>
                                                <Typography style={{ color: theme.palette.secondary.main, fontSize: 15 }}>{cart.product.name}</Typography>
                                                <div style={{ color: theme.palette.gray.ltext, paddingTop: 10 }}>
                                                    <Typography style={{ fontSize: 14 }}><b>{t('menu.minPrice') + ': '}<NumberFormat thousandSeparator={true} value={cart.product.min_sell_price} displayType="text" /></b></Typography>
                                                    <Typography style={{ fontSize: 14 }}>{t('menu.costPrice') + ': '}<NumberFormat thousandSeparator={true} value={cart.product.cost_price2} displayType="text" /></Typography>
                                                    <Typography style={{ fontSize: 14, marginTop: 10 }}>{t('menu.createdBy') + ': '}{cart.product.created_by_name}</Typography>
                                                    <Typography style={{ fontSize: 14 }}>{t('menu.updatedBy') + ': '}{cart.product.updated_by_name}</Typography>
                                                    <Typography style={{ fontSize: 14 }}>{t('menu.approvedBy') + ': '}{cart.product.approved_by_name}</Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </div>
                                )
                            })
                        }
                        <Button style={{ width: '100%', background: theme.palette.secondary.main, textTransform: 'uppercase', color: '#fff', textAlign: 'center', marginTop: 25, padding: '8px 15px', borderRadius: 8 }} onClick={handleDrawerClose}>
                            <Typography>{t('button.close')}</Typography>
                        </Button>
                    </List>
                    :
                    <List style={{ padding: '25px 25px' }}>
                        <Typography style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                            {t('menu.passwordVerify')}
                        </Typography>
                        <div style={{ padding: '50px 0 25px' }}>
                            <Typography style={{ color: theme.palette.gray.main, fontSize: 12 }}>{t('menu.staffUsername')}</Typography>
                            <Typography style={{ color: theme.palette.secondary.main, fontSize: 18 }}>{name}</Typography>
                        </div>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField id="standard-basic" label={t('textField.password')} variant="outlined" style={{ margin: 0 }} type="password" onChange={(event) => setStaffPassword(event.target.value)} />
                        </Box>
                        <Button style={{ width: '100%', background: theme.palette.secondary.main, color: '#fff', textAlign: 'center', marginTop: 25, padding: '8px 15px', borderRadius: 8 }} onClick={verifyStaffPassword} >
                            <Typography>{t('button.proceed')}</Typography>
                        </Button>
                    </List>
            }
        </Box>
    );
    const verifyStaffPassword = () => {
        getUrl(`/users/password-validation`, { password: staffPassword }).then(response => {
            if (response.status) {
                setShowHiddenPrice(true);
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }
    const handleDrawerClose = () => {
        setShowHiddenPrice(false);
        setRightDrawerOpen(false);
        setStaffPassword('');
    }

    // ACCORDION
    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // PRODUCT ORDER
    const clearAllCart = () => {
        setLoading(true);
        deleteUrl(`/carts/clear`).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                setCartList({});
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }
    const handleOnClick = (cartId, action) => {
        if (action === 'remove' || action === 'restore') {
            removeRestoreCartItem(cartId, action);
        } else {
            let item = _.find(cartList, function (c) { return parseInt(c.id) === parseInt(cartId); });
            setEditPriceModal({
                open: true,
                cartItem: item,
                sell_price: item.sell_price,
            });
        }
    }
    const handleCartModalClose = () => {
        setEditPriceModal({
            open: false,
            cartItem: {},
            sell_price: 0,
        });
    }
    const editCartItem = () => {
        setLoading(true);
        putUrl(`/carts/${editPriceModal.cartItem.id}`, { sell_price: editPriceModal.sell_price }).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                let tempCart = cartList;
                let item = {};
                _.map(tempCart, cart => {
                    if (parseInt(cart.id) === parseInt(editPriceModal.cartItem.id)) {
                        item = cart;
                        item.sell_price = parseFloat(editPriceModal.sell_price).toFixed(2);
                    }
                })
                calculateSumTotal(tempCart);
                setCartList(tempCart);
                handleCartModalClose();
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }
    const removeRestoreCartItem = (cartId, action) => {
        setLoading(true);
        putUrl(`/carts/${cartId}/${action}`).then(response => {
            if (response.status) {
                addAlert('', response.message, 'success', '');
                let tempCart = cartList;
                let item = {};
                _.map(tempCart, cart => {
                    if (parseInt(cart.id) === parseInt(cartId)) {
                        item = cart;
                        item.status = response.cart_status;
                    }
                })
                calculateSumTotal(tempCart);
                setCartList(tempCart);
                refreshCheckoutButton(tempCart);
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }
    const handleAmountChange = (type, amount) => {
        if (type === 'amount') {
            setEditPriceModal({ ...editPriceModal, sell_price: parseFloat(amount) });
        } else if (type === 'add') {
            setEditPriceModal({ ...editPriceModal, sell_price: parseFloat(parseFloat(editPriceModal.sell_price) + parseFloat(amount)).toFixed(2) });
        } else if (type === 'deduct') {
            setEditPriceModal({ ...editPriceModal, sell_price: parseFloat(parseFloat(editPriceModal.sell_price) - parseFloat(amount)).toFixed(2) });
        }
    }

    // 
    const refreshCheckoutButton = (listing) => {
        let valid = true;
        _.map(listing, (cartItem) => {
            if(parseInt(cartItem.status) === 2){
                valid = false;
            }
        })
        setCheckout(valid);
    }

    return (
        <Box sx={{ width: '100%' }}>
            {/* PRODUCT ORDER */}
            <div style={{ padding: 20 }}>
                {/* HEADER AND DRAWER */}
                <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} >
                    <Typography className={styles.titleText}>{t('pos.productOrder')}</Typography>
                    <div>
                        <div className={styles.checkPriceButton} onClick={() => setRightDrawerOpen(!rightDrawerOpen)}><FiChevronsLeft style={{ fontSize: 25 }} /></div>
                        <Drawer
                            anchor="right"
                            open={rightDrawerOpen}
                            onClose={handleDrawerClose}
                        >
                            {hiddenPriceCheckDrawer()}
                        </Drawer>
                    </div>
                </div>
                {
                    _.size(cartList) ?
                        <>
                            {/* LISTING */}
                            <div style={{ paddingTop: 25 }}>
                                {
                                    _.map(cartList, cartItem => {
                                        return (
                                            <Accordion expanded={expanded === cartItem.id} onChange={handleChangeAccordion(cartItem.id)} style={{ boxShadow: '2px 1px 20px 0 #e1e1e1', borderRadius: 15, marginBottom: 5 }} sx={{ '&:before': { display: 'none' } }} key={cartItem.id}>
                                                <AccordionSummary>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={12} md={9}>
                                                            <Hidden smUp>
                                                                <center>
                                                                    <img src={_.size(cartItem.product.images)?cartItem.product.images[0].file_name:"/images/emptyStatus/noproductimg.jpg"} style={{ width: 150, maxWidth: '100%' }} alt="product_image" />
                                                                </center>
                                                            </Hidden>
                                                            <div className={styles.flexMiddle}>
                                                                <Hidden only={'xs'}>
                                                                    <img src={_.size(cartItem.product.images)?cartItem.product.images[0].file_name:"/images/emptyStatus/noproductimg.jpg"} style={{ width: 150, maxWidth: '100%' }} alt="product_image" />
                                                                </Hidden>
                                                                <div style={{ padding: '0 15px', color: theme.palette.gray.dtext }}>
                                                                    <Typography style={{ fontSize: 21, fontWeight: 'bold', textDecoration: cartItem.status ? 'none' : 'line-through', fontStyle: (parseInt(cartItem.status) === 2 ? 'italic' : 'non-italic') }}>
                                                                        {cartItem.product.name + (parseInt(cartItem.status) === 2 ? ' (' + t('pos.pendingItem') + ')' : '')}
                                                                    </Typography>
                                                                    <Typography style={{ paddingTop: 15 }}>
                                                                        {t('menu.serialNo') + ': ' + (cartItem.product.serial_no ? cartItem.product.serial_no : '-')}
                                                                        <br />
                                                                        {/* {t('menu.warrantyPeriod') + ': ' + (cartItem.product.warranty_period ? cartItem.product.warranty_period : '-')}
                                                                        <br /> */}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={3}>
                                                            <Hidden only={'xs'}>
                                                                <div className={styles.flexBottom} style={{ height: '100%', padding: 15, justifyContent: 'space-between', flexDirection: 'column' }}>
                                                                    <Hidden only={['xs', 'sm']}>
                                                                        <FiMoreVertical style={{ color: theme.palette.secondary.main, fontSize: 25 }} />
                                                                    </Hidden>
                                                                    <div>
                                                                        <Typography style={{ textAlign: 'right', color: theme.palette.gray.ltext, fontSize: 18 }}>
                                                                            RM
                                                                        </Typography>
                                                                        <Typography style={{ fontSize: 25, color: parseInt(cartItem.status) === 1 ? theme.palette.secondary.main : theme.palette.gray.main, fontWeight: 'bold' }}>
                                                                            <NumberFormat thousandSeparator={true} value={cartItem.sell_price} displayType="text" />
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </Hidden>
                                                            <Hidden smUp>
                                                                <Typography style={{ padding: '20px 15px 0', color: parseInt(cartItem.status) === 1 ? theme.palette.secondary.main : theme.palette.gray.main, fontSize: 21 }}>
                                                                    <NumberFormat thousandSeparator={true} prefix="RM " value={cartItem.sell_price} displayType="text" />
                                                                </Typography>
                                                            </Hidden>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container>
                                                        {/* <Grid item xs={4} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} >
                                                            <Button style={{ backgroundColor: cartItem.status ? theme.palette.secondary.main : '#adadad', padding: 15, color: cartItem.status ? '#fff' : theme.palette.gray.main, width: "100%" }} onClick={() => handleOnClick(cartItem.id, 'edit')} disabled={cartItem.status ? false : true}>
                                                                <img src={cartItem.status ? 'images/icons/price.png' : 'images/icons/price2.png'} style={{ width: 30, maxWidth: '100%', }} alt="edit" />
                                                                <Hidden only={'xs'}>
                                                                    <Typography style={{ marginLeft: 15 }}>
                                                                        {t('pos.editPrice')}
                                                                    </Typography>
                                                                </Hidden>
                                                            </Button>
                                                        </Grid> */}
                                                        <Grid item xs={6} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} >
                                                            <Button style={{ backgroundColor: cartItem.status ? theme.palette.primary.main : '#adadad', padding: 15, color: cartItem.status ? '#fff' : theme.palette.gray.main, width: "100%" }} onClick={() => handleOnClick(cartItem.id, 'remove')} disabled={cartItem.status ? false : true}>
                                                                <img src={cartItem.status ? 'images/icons/trash.png' : 'images/icons/trash2.png'} style={{ width: 30, maxWidth: '100%', }} alt="remove" />
                                                                <Hidden only={'xs'}>
                                                                    <Typography style={{ marginLeft: 15 }}>
                                                                        {t('pos.removeItem')}
                                                                    </Typography>
                                                                </Hidden>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6} className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} >
                                                            <Button style={{ backgroundColor: cartItem.status ? '#adadad' : theme.palette.button.main, padding: 15, color: cartItem.status ? theme.palette.gray.main : '#fff', width: "100%" }} onClick={() => handleOnClick(cartItem.id, 'restore')} disabled={cartItem.status ? true : false}>
                                                                <img src={cartItem.status ? 'images/icons/restore2.png' : 'images/icons/restore.png'} style={{ width: 30, maxWidth: '100%', }} alt="restore" />
                                                                <Hidden only={'xs'}>
                                                                    <Typography style={{ marginLeft: 15 }}>
                                                                        {t('pos.restoreItem')}
                                                                    </Typography>
                                                                </Hidden>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    })
                                }
                            </div>
                            {/* SUM TOTAL */}
                            <Hidden only={'xs'}>
                                <div style={{ paddingTop: 25 }} className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                                    <Button style={{ color: theme.palette.secondary.main, fontSize: 18, borderBottom: '3px solid', width: 'fit-content' }} onClick={clearAllCart}><b>{t('button.clearAllList')}</b></Button>
                                    <Typography style={{ color: theme.palette.secondary.main, fontSize: 22 }}>
                                        <span style={{ color: theme.palette.gray.ltext, paddingRight: 15, fontSize: 18 }}>{t('pos.totalPrice') + ': '}</span>
                                        <b><NumberFormat thousandSeparator={true} prefix="RM " value={sumTotal} displayType="text" /></b>
                                    </Typography>
                                </div>
                            </Hidden>
                            <Hidden smUp>
                                <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{ padding: '40px 0 50px' }}>
                                    <Typography style={{ color: theme.palette.gray.ltext, fontSize: 18 }}>{t('pos.totalPrice') + ': '}</Typography>
                                    <Typography style={{ color: theme.palette.secondary.main, fontSize: 22, }}>
                                        <b><NumberFormat thousandSeparator={true} prefix="RM " value={sumTotal} displayType="text" /></b>
                                    </Typography>
                                </div>
                            </Hidden>
                            {/* BUTTON */}
                            <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                                <Hidden smUp>
                                    <Button style={{ color: theme.palette.secondary.main, fontSize: 18, borderBottom: '3px solid', width: 'fit-content' }} onClick={clearAllCart}><b>{t('button.clearAllList')}</b></Button>
                                </Hidden>
                                <div style={{ margin: '25px auto' }} >
                                    <Button style={{ padding: '8px 15px', width: 180, maxWidth: '100%', borderRadius: 48, background: (checkout ? theme.palette.secondary.main : theme.palette.gray.main), boxShadow: '2px 2px 20px 0 #efefef', color: '#fff', fontSize: 18, textAlign: 'center', textTransform: 'uppercase' }} onClick={handleNext} disabled={!checkout}>{t('button.nextStep')}</Button>
                                </div>
                            </div>
                        </>
                        :
                        <div className={`head ${styles.flexMiddle} ${styles.justifyCenter}`} style={{flexDirection:'column'}}>
                            <img src="images/emptyStatus/emptycart.png" alt="empty" style={{width:180}} />
                            <Typography style={{ margin: 10, textAlign: "center" }}>{t('emptyStatus.emptyCartList')}</Typography>
                        </div>
                }
            </div>

            {/* EDIT PRICE MODAL */}
            <Modal
                open={editPriceModal.open}
                onClose={handleCartModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={cartStyle}>
                    <center>
                        <img src={editPriceModal.cartItem.product?(_.size(editPriceModal.cartItem.product.images)?editPriceModal.cartItem.product.images[0].file_name:"/images/emptyStatus/noproductimg.jpg"):""} style={{ width: 150, borderRadius: 10, boxShadow: '5px 3px 7px 0 #c7c7c778', maxWidth: '100%' }} alt="product_image" />
                    </center>
                    <div style={{ textAlign: 'center', paddingTop: 15, color: theme.palette.gray.dtext }}>
                        <Typography>{editPriceModal.cartItem.product ? editPriceModal.cartItem.product.name : ''}</Typography>
                        <Typography style={{ color: theme.palette.gray.ltext, paddingTop: 25 }}>{t('menu.amount') + ' (RM)'}</Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ backgroundColor: theme.palette.secondary.main, width: 30, height: 30, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '2px 2px 6px 0 #8f72547a' }} onClick={() => handleAmountChange('deduct', 100)}>
                                <FiMinus style={{ fontSize: 20, color: '#fff' }} />
                            </div>
                            <TextField
                                sx={{ input: { color: theme.palette.secondary.main, fontSize: 25, fontWeight: "bold", textAlign: "center" } }}
                                variant="standard"
                                type="number"
                                value={editPriceModal.sell_price}
                                InputProps={{ disableUnderline: true }}
                                onChange={(event) => handleAmountChange('amount', event.target.value)}
                            />
                            <div style={{ backgroundColor: theme.palette.secondary.main, width: 30, height: 30, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '2px 2px 6px 0 #8f72547a' }} onClick={() => handleAmountChange('add', 100)}>
                                <FiPlus style={{ fontSize: 20, color: '#fff' }} />
                            </div>
                        </div>
                        <div style={{ paddingTop: 25, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <Button style={{ border: theme.palette.button.borderMain, width: 'fit-content', minWidth: 150, padding: '8px 15px', borderRadius: 5, boxShadow: '2px 2px 6px 0 #8f72547a' }} onClick={handleCartModalClose}>
                                <Typography style={{ color: theme.palette.button.main }}>{t('button.cancel')}</Typography>
                            </Button>
                            <Button style={{ backgroundColor: theme.palette.button.main, width: 'fit-content', minWidth: 150, padding: '8px 15px', borderRadius: 5, boxShadow: '2px 2px 6px 0 #8f72547a' }} onClick={editCartItem}>
                                <Typography style={{ color: '#fff' }}>{t('button.save')}</Typography>
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    spaceBetween: {
        justifyContent: 'space-between'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    flexBottom: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    titleText: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold!important',
        fontSize: '21px!important'
    },
    checkPriceButton: {
        backgroundColor: theme.palette.button.main,
        color: '#fff',
        height: 48,
        marginTop: -25,
        borderRadius: '11px 0 0 11px',
        position: 'absolute!important',
        boxShadow:'0 4px 3px 0 #00000026',
        right: 0,
        width:70,
        display:'flex', 
        justifyContent:'center',
        alignItems:'center',
        paddingRight:0,
            transition:'all 0.30s 0.1s',
        "&:hover": {
            backgroundColor: theme.palette.button.main,
            paddingRight:40,
            transition:'all 0.30s 0.1s ease-in'
          },
        cursor: 'pointer',
    },
    translateXAnimation:{
        paddingLeft:0,
            transition:'all 0.30s 0.1s ease-in',
        "&:hover": {
            paddingLeft:27,
            transition:'all 0.30s 0.1s ease-in'
          },
    },
}));
