import {
    STORE_PROFILE,
    RESET_PROFILE,
} from '../actions/types';

const INITIAL_STATE = {
    id: null,
    name: '',
    email: '',
    mobile: '',
    staff_no:'',
    ic:'',
    bank_name:'',
    bank_account:'',
    addresses:'',
    profile_picture:''
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case STORE_PROFILE:
            return { 
                ...state,
                id: actions.payload.id,
                name: actions.payload.name,
                email: actions.payload.email,
                mobile: actions.payload.mobile,
                staff_no: actions.payload.staff_no,
                ic: actions.payload.ic,
                bank_name: actions.payload.bank_name,
                bank_account: actions.payload.bank_account,
                addresses: actions.payload.addresses,
                profile_picture: actions.payload.profile_picture,
            };
        case RESET_PROFILE:
            return INITIAL_STATE;
        default:
            return state;
    }
}