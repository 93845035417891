import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl, putUrl, postUrl } from '../../helpers/ApiAction';
import _ from 'lodash';

// MUI
import { Box, Grid, TextField, Button, Typography, FormControl, MenuItem } from '@mui/material';

export default function Step2Customer({ handleBack, handleNext, customerId, setCustomerId, searchText, setSearchText }) {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [customerList, setCustomerList] = useState({});
    const [state, setState] = useState({
        name: '',
        gender:'-',
        email: '',
        mobile: '',
        ic: '',
        country: '',
        zip: '',
        state: '',
        city: '',
        address: '',
        address2: ''
    });
    const [inputErrors, setInputErrors] = useState({});

    useEffect(() => {
        setLoading(true);
        getUrl(`/customers/search`, { q: searchText }).then(response => {
            setLoading(false);
            if (response.status) {
                setCustomerList(response.data.listing);
                let selectedCustomer = _.find(response.data.listing, function (c) { return c.id === customerId; })
                if (_.size(selectedCustomer)) {
                    setState({
                        name: selectedCustomer.name,
                        gender:selectedCustomer.gender,
                        email: selectedCustomer.email,
                        mobile: selectedCustomer.mobile,
                        ic: selectedCustomer.ic,
                        country: selectedCustomer.addresses[0].country,
                        zip: selectedCustomer.addresses[0].zip,
                        state: selectedCustomer.addresses[0].state,
                        city: selectedCustomer.addresses[0].city,
                        address: selectedCustomer.addresses[0].address1,
                        address2: selectedCustomer.addresses[0].address2
                    });
                } else {
                    setCustomerId(0);
                    setState({
                        name: '',
                        gender:'-',
                        email: '',
                        mobile: '',
                        ic: '',
                        country: '',
                        zip: '',
                        state: '',
                        city: '',
                        address: '',
                        address2: ''
                    });
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const handleSearch = () => {
        setLoading(true);
        getUrl(`/customers/search`, { q: searchText }).then(response => {
            setLoading(false);
            if (response.status) {
                setCustomerList(response.data.listing);
                setCustomerId(0);
                setState({
                    name: '',
                    gender:'-',
                    email: '',
                    mobile: '',
                    ic: '',
                    country: '',
                    zip: '',
                    state: '',
                    city: '',
                    address: '',
                    address2: ''
                });
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleCustomerChange = (event) => {
        setCustomerId(event.target.value);
        if (event.target.value === 0) {
            setState({
                name: '',
                gender:'-',
                email: '',
                mobile: '',
                ic: '',
                country: '',
                zip: '',
                state: '',
                city: '',
                address: '',
                address2: ''
            });
        } else {
            _.map(customerList, customer => {
                if (event.target.value === customer.id) {
                    setState({
                        name: customer.name,
                        gender:customer.gender,
                        email: customer.email,
                        mobile: customer.mobile,
                        ic: customer.ic,
                        country: _.size(customer.addresses)> 0?customer.addresses[0].country:'',
                        zip: _.size(customer.addresses)> 0?customer.addresses[0].zip:'',
                        state: _.size(customer.addresses)> 0?customer.addresses[0].state:'',
                        city: _.size(customer.addresses)> 0?customer.addresses[0].city:'',
                        address: _.size(customer.addresses)> 0?customer.addresses[0].address1:'',
                        address2: _.size(customer.addresses)> 0?customer.addresses[0].address2:''
                    });
                }
            })
        }
    }

    const submitCustomerData = () => {
        setLoading(true);
        setInputErrors();
        if (parseInt(customerId) === 0) {
            // add new customer
            postUrl('/customers', state).then(response => {
                setLoading(false);
                if (response.status) {
                    handleNext();
                    setSearchText(state.name);
                    setCustomerId(response.data.id);
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            // update customer
            let selectedCustomer = _.find(customerList, function (c) { return c.id === customerId; })
            let postData = { ...state, status: selectedCustomer.status };
            putUrl(`/customers/${customerId}`, postData).then(response => {
                setLoading(false);
                if (response.status) {
                    handleNext();
                    setSearchText(state.name);
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            <div style={{ padding: 20 }}>
                <Typography className={styles.titleText}>{t('pos.customerDetails')}</Typography>
                <FormControl style={{ width: '100%', maxWidth: '100%', margin: "30px 10px" }}>
                    <TextField
                        label={t('pos.searchPlaceholder')}
                        variant="outlined"
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                    />
                </FormControl>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                select
                                id="standard-basic"
                                label={t('pos.selectExistsCustomer')}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={customerId}
                                onChange={handleCustomerChange}
                            >
                                <MenuItem value={0}>
                                    {t('pos.newCustomer')}
                                </MenuItem>
                                {
                                    _.map(customerList, customer => {
                                        return (
                                            <MenuItem key={customer.id} value={customer.id}>
                                                {customer.name + ' - ' + customer.ic}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.name')}
                                variant="outlined"
                                type="text"
                                autoComplete="name"
                                value={state.name}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.gender.title')}
                                variant="outlined"
                                select
                                value={state.gender}
                                InputLabelProps={{ shrink: true }} 
                                helperText={inputErrors && inputErrors.gender? inputErrors.gender : ''}
                                error={inputErrors && inputErrors.gender ? true : false}
                                onChange={({ target }) => setState({ ...state, gender: target.value })}
                                SelectProps={{ native: true }}
                            >
                                {['-','male','female'].map((option) => (
                                    <option key={option} value={option}>
                                        {t('user.gender.'+option)}
                                    </option>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.nric')}
                                variant="outlined"
                                autoComplete="ic"
                                value={state.ic}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.ic ? inputErrors.ic : ''}
                                error={inputErrors && inputErrors.ic ? true : false}
                                onChange={({ target }) => setState({ ...state, ic: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.email')}
                                variant="outlined"
                                type="email"
                                autoComplete="email"
                                value={state.email}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                error={inputErrors && inputErrors.email ? true : false}
                                onChange={({ target }) => setState({ ...state, email: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.mobile')}
                                variant="outlined"
                                autoComplete="mobile"
                                value={state.mobile}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                error={inputErrors && inputErrors.mobile ? true : false}
                                onChange={({ target }) => setState({ ...state, mobile: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.address')}
                                variant="outlined"
                                type="text"
                                autoComplete="address"
                                value={state.address}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.address ? inputErrors.address : ''}
                                error={inputErrors && inputErrors.address ? true : false}
                                onChange={({ target }) => setState({ ...state, address: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField id="standard-basic"
                                label={t('user.address2')}
                                variant="outlined"
                                type="text"
                                autoComplete="address2"
                                value={state.address2}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.address2 ? inputErrors.address2 : ''}
                                error={inputErrors && inputErrors.address2 ? true : false}
                                onChange={({ target }) => setState({ ...state, address2: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.country')}
                                variant="outlined"
                                type="text"
                                autoComplete="country"
                                value={state.country}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.country ? inputErrors.country : ''}
                                error={inputErrors && inputErrors.country ? true : false}
                                onChange={({ target }) => setState({ ...state, country: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.zip')}
                                variant="outlined"
                                autoComplete="zip"
                                value={state.zip}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.zip ? inputErrors.zip : ''}
                                error={inputErrors && inputErrors.zip ? true : false}
                                onChange={({ target }) => setState({ ...state, zip: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.city')}
                                type="text"
                                autoComplete="city"
                                value={state.city}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" helperText={inputErrors && inputErrors.city ? inputErrors.city : ''}
                                error={inputErrors && inputErrors.city ? true : false}
                                onChange={({ target }) => setState({ ...state, city: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate autoComplete="on"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('user.state')}
                                type="text"
                                autoComplete="state"
                                value={state.state}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" helperText={inputErrors && inputErrors.state ? inputErrors.state : ''}
                                error={inputErrors && inputErrors.state ? true : false}
                                onChange={({ target }) => setState({ ...state, state: target.value })}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <div className={`head ${styles.flexMiddle} ${styles.center}`} style={{ paddingTop: 25 }} >
                    <Button
                        style={{ padding: '8px 15px', width: 180, marginRight: 15, maxWidth: '100%', borderRadius: 48, border: '2px solid #cdac89', boxShadow: '2px 2px 20px 0 #efefef', color: theme.palette.secondary.main, fontSize: 18, textAlign: 'center', textTransform: 'uppercase' }}
                        onClick={handleBack}
                    >
                        {t('button.backStep')}
                    </Button>
                    <Button
                        style={{ padding: '8px 15px', width: 180, maxWidth: '100%', borderRadius: 48, background: theme.palette.secondary.main, boxShadow: '2px 2px 20px 0 #efefef', color: '#fff', fontSize: 18, textAlign: 'center', textTransform: 'uppercase' }}
                        onClick={submitCustomerData}
                    >
                        {t('button.nextStep')}
                    </Button>
                </div>
            </div>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    center: {
        justifyContent: 'center'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    titleText: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold!important',
        fontSize: '21px!important'
    },
}));