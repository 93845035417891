import React, { useState, useEffect, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';

// MUI
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Grid, Typography, Box, Link, OutlinedInput, LinearProgress, Hidden ,Breadcrumbs} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { makeStyles, useTheme } from '@mui/styles';
import { FaPlus } from "react-icons/fa";

import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import _ from 'lodash';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" style={{height: 10, borderRadius: 8,}}  {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" style={{fontSize:18, color:'#777'}} >{`${Math.round(
                props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function WarrantyReport() {
    const [searchText, setSearchText] = useState("");
    const [summary, setSummary] = useState(null);
    const [data, setData] = useState(null);

    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    
const navigate = useNavigate();

    
    useEffect(() => {
        setLoading(true);
        getUrl(`services-summary`).then(response => {
            setLoading(false);
            if(response.status) {
                setSummary(response.data);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        // eslint-disable-next-line
    }, []);

    const searchWarranty = (e) => {
        if (e.key === 'Enter' && searchText) {
            getUrl(`/services?q=${searchText}`).then(response => {
                if (response.status) {
                    setData(response.data.listing);
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return(
        <div>
             <div style={{ paddingBottom: 30, justifyContent:'space-between' }} className={styles.flexMiddle}>
         
                <div className={styles.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <span onClick={() => navigate(-1)} style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase',
cursor:'pointer' }}>{t('button.back')}</span>
                </div>
        

            
            <div>
                <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Link underline="hover" color="inherit" component={RouterLink} to="/warranty">
                        {t('title.warranty')}
                    </Link>
                    <Typography color="text.primary">{t('warranty.warrantyReport')}</Typography>
                </Breadcrumbs>
            </div>
            </div>

            <Grid container spacing={3}>
                { _.map(summary, (summaryTotal, summaryStatus) => {
                    let color = '#f46464';
                    switch(summaryStatus) {
                        case '20':
                            color = '#f4c04e';
                            break;
                        case '30':
                            color = '#47ba80';
                            break;
                        default:
                            color = '#f46464';
                            break;
                    }
                    if(summaryStatus !== '40') {
                        return (
                            <Grid key={summaryStatus} item xs={12} sm={4}>
                                <div className={styles.categoryBox}>
                                    <div style={{ backgroundColor: color }} className={styles.colorBox}>
                                        <div>
                                            <Typography style={{ fontSize: 35, fontWeight: 'bold' }}>{ summaryTotal }</Typography>
                                            <Typography style={{ fontSize: 14, textTransform:'uppercase' }}>{t(`warranty.statusList.${summaryStatus}`)}</Typography>
                                        </div>
                                        <FaPlus style={{ fontSize: 40 }} />
                                    </div>
                                </div>
                            </Grid>
                        )
                    }
                    return null
                })}
            </Grid>

            <div style={{width:'100%', maxWidth:'100%', margin:'40px 0 30px'}}>
                <ThemeProvider theme={inputStyle}>
                    <FormControl style={{ width: '100%', maxWidth:'100%' }}>
                        <OutlinedInput placeholder={t('warranty.searchByOrder')} value={searchText} onChange={({ target }) => setSearchText(target.value)} onKeyDown={searchWarranty} />
                    </FormControl>
                </ThemeProvider>
            </div>

            { _.size(data) ?
                <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} >
                    <Typography style={{ fontSize: 18, paddingRight: 15, color: theme.palette.gray.ltext }}><b>{t('warranty.warrantyReport')}</b></Typography>
                </div>
            : null }

            { _.map(data, dataItem => {
                return <SearchResult key={dataItem.id} data={dataItem} />
            })}
        </div>
    );
}

const SearchResult = memo(({ data }) => {
    const [progress, setProgress] = useState(10);

    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        let newProgress = 0;
        switch(data.status) {
            case 10:
                newProgress = 10
                break;
            case 20:
                newProgress = 40;
                break;
            case 30:
                newProgress = 70;
                break;
            case 40:
                newProgress = 100;
                break;
            default:
                newProgress = progress;
        }
        setProgress(newProgress);
        // eslint-disable-next-line
    }, [data]);

    const color = useMemo(() => {
        switch (data.status) {
            case 10:
                return '#f46464'; // red
            case 20:
                return '#f4c04e'; // yellow
            case 30:
                return '#47ba80'; // green
            case 40:
                return '#89837c'; // complete
            default:
                return '#f4c04e'; // yellow
        }
    }, [data]);

    return (
        <div style={{paddingTop:15}}>
            <Grid container style={{alignItems:'center'}}>
                <Grid item xs={12} sm={6} style={{paddingTop:25}}>
                    <div className={styles.flexMiddle}>
                        <img src={ data ? (_.size(data.product.images) ? data.product.images[0]['file_name'] : "/images/emptyStatus/noproductimg.jpg") : null } style={{ width: 120, borderRadius:15, boxShadow:'#0b0b0b24 4px 3px 6px 0px', border:'2px solid #e3e3e3' }} alt="empty product" />
                        <div style={{ paddingLeft: 20, color: theme.palette.gray.dtext }}>
                            <Typography className={styles.titleText}>{ data ? data.customer.name : '-' }</Typography>
                            <Typography className={`head ${styles.textLine2} ${styles.productContentText}`}>{ data ? data.product.name : '-' }</Typography>
                            <Typography className={styles.productContentText}>{`${t('warranty.serviceNo')}: ${data ? data.service_no : '-'}`}</Typography>
                            <Hidden smUp>
                                <div style={{ display: 'flex', alignItems: 'flex-start', paddingTop: 10 }}>
                                    <div style={{ width: 'fit-content' }}>
                                        <div style={{ width: 10, height: 10, borderRadius: 48, backgroundColor: color }} />
                                    </div>
                                    <div style={{ marginTop: -5, paddingLeft: 15 }} className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                                        <div>
                                            <Typography style={{ color, fontSize: 14 }}>{t(`warranty.statusList.${data ? data.status : null}`)}...</Typography>
                                            <Typography style={{ color: theme.palette.gray.ltext, fontSize: 14, }}>{ data ? data.created_at : '-' }</Typography>
                                        </div>
                                        <Link underline='none' component={RouterLink} to={`/warranty-report/${data ? data.id : null}`} styles={{paddingLeft:5}}>
                                            <FiArrowRight style={{ paddingTop: 5,  fontSize: 25, color }} />
                                        </Link>
                                    </div>
                                </div>
                            </Hidden>
                        </div>
                    </div>
                </Grid>
                <Hidden only={'xs'}>
                    <Grid item xs={12} sm={6} style={{ paddingTop: 25 }}>
                        <Typography style={{ fontSize: 21, color }}>{t(`warranty.statusList.${data ? data.status : null}`)}...</Typography>
                        <ThemeProvider theme={progressBar}>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabel value={progress} color={data.status === 10 ? 'red' : data.status === 20 ? 'yellow' : data.status === 30 ? 'green' : 'complete'} />
                            </Box>
                        </ThemeProvider>
                        <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{ width: '100%', color: theme.palette.gray.ltext }}>
                            <div>
                                <Typography>{t('warranty.lastUpdate')}</Typography>
                                <Typography>{ data ? data.created_at : '-' }</Typography>
                            </div>
                            <Link underline='none' component={RouterLink} to={`/warranty-report/${data ? data.id : null}`}>
                                <Typography style={{ fontSize: 18, color, textAlign: 'right' }}>{t('general.checkDetails')} <FiArrowRight style={{ paddingTop: 5 }} /></Typography>
                            </Link>
                        </div>
                        
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    )
})

const useStyles = makeStyles(theme => ({
    categoryBox:{
        borderRadius:18,
        padding:5,
        boxShadow:'4px 4px 12px 0 #00000036'
    },
    colorBox:{
        padding:15,
        borderRadius:15,
        color:'#fff',
        borderBottom:'7px solid #00000024',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    spaceBetween:{
        justifyContent:'space-between'
    },
    justifyCenter:{
        justifyContent:'center'
    },
    flexMiddle:{
        display:'flex',
        alignItems:'center',
        width:'100%'
    },
    textLine2: {
        display: '-webkit-box',
        maxWidth: '100%',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    },
    titleText:{
        lineHeight:1.2,
        fontWeight:'bold!important',
        paddingBottom:5,
        [theme.breakpoints.down('sm')]: {
            fontSize:'16px!important'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize:'24px!important',
        },
    },
    productContentText:{
        [theme.breakpoints.down('sm')]: {
            fontSize:'14px!important'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize:'16px!important',
        },
    }
    
  }));

  const inputStyle = createTheme({
    components: {
      // Name of the component
      MuiOutlinedInput: {
        styleOverrides: {
          // Name of the slot
          input: {
            // Some CSS
            padding:'28.5px 14px',
            boxSizing:'inherit',
            borderRadius:48
          },
          root:{
              borderRadius:48,
              boxShadow:'3px 3px 8px 0 #ccc'
          },
        },
      },
    },
  });

  const progressBar = createTheme({
    components: {
        // Name of the component
        MuiLinearProgress: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    background: '#dddddd'
                },
            },
        },
    },
    palette: {
        red: {
            main: '#f46464',
        },
        yellow: {
            main: '#f4c04e',
        },
        green: {
            main: '#47ba80',
        },
        complete: {
            main: '#89837c',
        },
    },
});

  
