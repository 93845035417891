import React, { useState, useEffect, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import _ from 'lodash';

// MUI
import { Link, Box, Grid, Typography, Hidden, LinearProgress } from '@mui/material';
import { FiArrowRight } from "react-icons/fi";

//PROGRESS BAR
import PropTypes from 'prop-types';
function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" style={{
                    height: 10,
                    borderRadius: 8,
                }}  {...props} />

            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" style={{ fontSize: 18, color: '#777' }} >{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default function ProfileWarranty({ permissions, serviceList }) {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <div style={{ padding: '30px 0' }}>
            <Typography className={styles.subText} style={{ paddingBottom: 8 }}>
                <b>{t('customer.warrantyRecord')}</b>
            </Typography>
            {
                _.size(serviceList) > 0
                    ?
                    _.map(serviceList, service => {
                        return (
                            <Warranty key={service.id} data={service} permissions={permissions} />
                        )
                    })
                    :
                    <div className={styles.flexCenterMiddle} style={{ flexDirection: 'column' }}>
                        <img src="/images/emptyStatus/nolist.png" alt="nolist" style={{ width: 180, margin: '0 auto' }} />
                        <Typography style={{ color: theme.palette.gray.main }}>
                            {t('emptyStatus.noRecord')}
                        </Typography>
                    </div>
            }
        </div>
    );
}

const Warranty = memo(({ data, permissions }) => {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [progress, setProgress] = useState(10);

    useEffect(() => {
        let newProgress = progress;
        switch (data.status) {
            case 10:
                newProgress = 10
                break;
            case 20:
                newProgress = 40;
                break;
            case 30:
                newProgress = 70;
                break;
            case 40:
                newProgress = 100;
                break;
            default:
                newProgress = 0;
        }
        setProgress(newProgress);
    }, [data, progress]);

    const color = useMemo(() => {
        switch (data.status) {
            case 10:
                return '#f46464'; // red
            case 20:
                return '#f4c04e'; // yellow
            case 30:
                return '#47ba80'; // green
            case 40:
                return '#89837c'; // complete
            default:
                return '#f4c04e'; // yellow
        }
    }, [data]);

    return (
        <div style={{ paddingTop: 15 }}>
            <Grid container style={{ alignItems: 'center' }}>
                <Grid item xs={12} sm={6} style={{ paddingTop: 25 }}>
                    <div className={styles.flexMiddle}>
                        <img src={data ? (_.size(data.product.images) ? data.product.images[0]['file_name'] : "/images/emptyStatus/noproductimg.jpg") : null} style={{ width: 120, borderRadius: 15, boxShadow: '#0b0b0b24 4px 3px 6px 0px', border: '2px solid #e3e3e3' }} alt='product_image' />
                        <div style={{ paddingLeft: 20, color: theme.palette.gray.dtext }}>
                            <Typography className={styles.titleText}>{data ? data.customer.name : '-'}</Typography>
                            <Typography className={`head ${styles.textLine2} ${styles.productContentText}`}>{data ? data.product.name : '-'}</Typography>
                            <Typography className={styles.productContentText}>{`${t('warranty.serviceNo')}: ${data ? data.service_no : '-'}`}</Typography>
                            <Hidden smUp>
                                <div style={{ display: 'flex', alignItems: 'flex-start', paddingTop: 10 }}>
                                    <div style={{ width: 'fit-content' }}>
                                        <div style={{ width: 10, height: 10, borderRadius: 48, backgroundColor: color }} />
                                    </div>
                                    <div style={{ marginTop: -5, paddingLeft: 15 }} className={`head ${styles.flexMiddle} ${styles.spaceBetween}`}>
                                        <div>
                                            <Typography style={{ color: color, fontSize: 14 }}>{t(`warranty.statusList.${data ? data.status : null}`)}...</Typography>
                                            <Typography style={{ color: theme.palette.gray.ltext, fontSize: 14, }}>{data ? data.created_at : '-'}</Typography>
                                        </div>
                                        <Link underline='none' component={RouterLink} to={`/warranty-report/${data ? data.id : null}`} styles={{ paddingLeft: 5 }}>
                                            <FiArrowRight style={{ paddingTop: 5, fontSize: 25, color: color }} />
                                        </Link>
                                    </div>
                                </div>
                            </Hidden>
                        </div>
                    </div>
                </Grid>
                <Hidden only={'xs'}>
                    <Grid item xs={12} sm={6} style={{ paddingTop: 25 }}>
                        <Typography style={{ fontSize: 21, color: color }}>{t(`warranty.statusList.${data ? data.status : null}`)}...</Typography>
                        <ThemeProvider theme={progressBar}>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabel value={progress} color={data.status === 10 ? 'red' : data.status === 20 ? 'yellow' : data.status === 30 ? 'green' : 'complete'} />
                            </Box>
                        </ThemeProvider>
                        <div className={`head ${styles.flexMiddle} ${styles.spaceBetween}`} style={{ width: '100%', color: theme.palette.gray.ltext }}>
                            <div>
                                <Typography>{t('warranty.lastUpdate')}</Typography>
                                <Typography>{data ? data.created_at : '-'}</Typography>
                            </div>
                            {
                                _.includes(permissions, 'warranty')
                                    ?
                                    <Link underline='none' component={RouterLink} to={`/warranty-report/${data ? data.id : null}`}>
                                        <Typography style={{ fontSize: 18, color: color, textAlign: 'right' }}>{t('general.checkDetails')} <FiArrowRight style={{ paddingTop: 5 }} /></Typography>
                                    </Link>
                                    : null
                            }
                        </div>
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    )
})

const useStyles = makeStyles(theme => ({
    titleText: {
        fontSize: '24px!important',
        color: theme.palette.gray.main,
    },
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    contentText: {
        fontSize: '18px!important',
        color: theme.palette.gray.main,
    },
    spaceBetween: {
        justifyContent: 'space-between'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    productContentText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px!important'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px!important',
        },
    },
    flexCenterMiddle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

const progressBar = createTheme({
    components: {
        // Name of the component
        MuiLinearProgress: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    background: '#dddddd'
                },
            },
        },
    },
    palette: {
        red: {
            main: '#f46464',
        },
        yellow: {
            main: '#f4c04e',
        },
        green: {
            main: '#47ba80',
        },
        complete: {
            main: '#89837c',
        },
    },
});
